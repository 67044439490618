import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  list: {
    items: [],
    isFetched: false,
    isLoaded: false,
    meta: {
      current: 1,
      total: 1
    }
  },
  open_list: {
    items: [],
    isFetched: false,
    isLoaded: false,
    meta: {
      current: 1,
      total: 1
    }
  },
  workers: {
    items: [],
    isFetched: false,
    isLoaded: false
  },
  open_workers: {
    items: [],
    isFetched: false,
    isLoaded: false
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.LIST.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetched: false
        }
      };
    }
    case Constants.LIST.SUCCESS: {
      const { items, meta } = action.payload;

      return {
        ...state,
        list: {
          items,
          meta,
          isFetched: true,
          isLoaded: true
        }

      };
    }
    case Constants.LIST.FAILURE: {
      return {
        ...state,
        list: {
          ...state.list,
          items: [],
          isFetched: true
        }
      };
    }
    case Constants.OPEN_LIST.REQUEST: {
      return {
        ...state,
        open_list: {
          ...state.open_list,
          isFetched: false
        }
      };
    }
    case Constants.OPEN_LIST.SUCCESS: {
      const { items, meta } = action.payload;

      return {
        ...state,
        open_list: {
          items,
          meta,
          isFetched: true,
          isLoaded: true
        }

      };
    }
    case Constants.OPEN_LIST.FAILURE: {
      return {
        ...state,
        open_list: {
          ...state.open_list,
          items: [],
          isFetched: true
        }
      };
    }
    case Constants.WORKERS.REQUEST: {
      return {
        ...state,
        workers: {
          ...state.workers,
          isFetched: false
        }
      };
    }
    case Constants.WORKERS.SUCCESS: {
      const { items } = action.payload;

      return {
        ...state,
        workers: {
          items,
          isFetched: true,
          isLoaded: true
        }

      };
    }
    case Constants.WORKERS.FAILURE: {
      return {
        ...state,
        workers: {
          ...state.workers,
          items: [],
          isFetched: true
        }
      };
    }
    case Constants.OPEN_WORKERS.REQUEST: {
      return {
        ...state,
        open_workers: {
          ...state.open_workers,
          isFetched: false
        }
      };
    }
    case Constants.OPEN_WORKERS.SUCCESS: {
      const { items } = action.payload;

      return {
        ...state,
        open_workers: {
          items,
          isFetched: true,
          isLoaded: true
        }

      };
    }
    case Constants.OPEN_WORKERS.FAILURE: {
      return {
        ...state,
        open_workers: {
          ...state.open_workers,
          items: [],
          isFetched: true
        }
      };
    }
    default:
      return state;
  }
}