import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

import * as Fields from 'components/Fields';

import * as Api from '../../api';
import * as Mappers from '../../mappers';

interface IProps {
  name: string;
  label: string;
  applicationId: number;
}

const File: React.FC<IProps> = ({ name, label, applicationId, ...props }) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const value = get(values, `${name}`) || 0;

  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState({
    id: 0,
    name: '',
    extension: '',
    size: 0
  });

  useEffect(() => {
    if(value){
      Api.FileInfo({ id: value, applicationId })
        .then(({ data }) => {
          const file = Mappers.file(get(data, 'data'));
          setFile(file);
        })
        .catch(() => {

        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [value]);

  const onSelect = (file: File | undefined) => {
    if(!file){
      return;
    }

    const data = new FormData();
    data.append('file', file);

    setLoading(true);
    Api.FileUpload({ data, applicationId })
      .then(({ data }) => {
        const file = Mappers.file(get(data, 'data'));
        setFile(file);
        setFieldValue(name, String(file.id));
      })
      .catch(() => {

      })
      .finally(() => {
        setLoading(false);
      })
  };

  const onView = (id: number) => {
    Api.FileDownload({ id, applicationId })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
  };

  return (
    <Fields.File
      {...{ name, label, isLoading, onSelect, onView, file }}
      {...props}
      disabled={isSubmitting}
    />
  );
};

export default File;