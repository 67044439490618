import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import get from 'lodash/get';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Report[];
  meta: Types.IEntity.Meta;
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  name: string;
  params?: Partial<Types.IReportParams>;
  onSuccess?: ({ items }: { items: Types.IEntity.Report[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useReport = ({
  name,
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
}: IProps): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.Report[]>(state =>
    get(state, `certificate.report[${name}].items`, [])
  );
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state =>
    get(state, `certificate.report[${name}].meta`, {})
  );
  const isFetched = useSelector<Store.Types.IState, boolean>(
    state => !!get(state, `certificate.report[${name}].isFetched`, false)
  );
  const isLoaded = useSelector<Store.Types.IState, boolean>(
    state => !!get(state, `certificate.report[${name}].isLoaded`, false)
  );

  useDeepCompareEffect(() => {
    dispatch(
      Actions.Report.request({
        name,
        params: {
          registerId: params.registerId || 0,
          page: params.page || 1,
          limit: params.limit || 10
        },
        callback: {
          onSuccess,
          onError,
          onFinally
        }
      })
    );
  }, [params]);

  return {
    items,
    meta,
    isFetched,
    isLoaded
  };
};

export default useReport;
