import axios, { AxiosInstance } from 'axios';

import config from 'config';

import * as Store from 'store';

const request: AxiosInstance = axios.create({
  baseURL: config.api.base_url,
  responseType: 'json',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  },
  params: {}
});

const subscribe = (state: Store.Types.IState) => {
  const accessToken = state.auth.token.accessToken;
  
  if (accessToken) {
    request.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  } else {
    delete request.defaults.headers['Authorization'];
  }
};

export default {
  request,
  subscribe
};
