import React from 'react';
import cx from 'classnames';

import classes from './LanguageSwitcher.module.scss';

interface IProps {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}

const LanguageSwitcher: React.FC<IProps> = ({ className, onClick, children }) => (
  <div className={cx(classes.item, className)} {...{ onClick }}>{children}</div>
);

export default LanguageSwitcher;