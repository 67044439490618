import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import isObject from 'lodash/isObject';

const ScrollToErrorField: React.FC = () => {
  const { isValid, submitCount, errors, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (!isSubmitting && !isValid && submitCount > 0) {
      const firstErrorKey = getFirstErrorKey(errors);
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
      }
    }
  }, [isSubmitting, submitCount, isValid, errors]);

  return null;
};

const getFirstErrorKey = (object, keys: string[] = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join('.');
};

export default ScrollToErrorField;