import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import trim from 'lodash/trim';
import lowerCase from 'lodash/lowerCase';
import includes from 'lodash/includes';

import * as Store from 'store';

import * as Mappers from './mappers';
import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => {
  return Object.entries(state.document.entities).reduce((prev, [key, item]) => {
    if (get(item, 'status') && !get(item, 'isDeleted')) {
      return { ...prev, [key]: item };
    }
    return { ...prev };
  }, {});
};

export const getZeroList = createSelector<Store.Types.IState, Types.IEntities, Types.IEntity.Document[]>(
  getEntities,
  entities => {
    return Object.values(entities).filter(item => item.type.key === 'ZERO');
  }
);

export const getListByCategory = createSelector<
  Store.Types.IState,
  number,
  Types.IEntities,
  number[],
  Types.IEntity.Document[]
>(
  getEntities,
  (state, categoryId) => get(state.document, `listByCategory.${categoryId}.ids`, []) || [],
  (entities, ids) => {
    const normalized = denormalize({ document: ids }, { document: [Schema] }, { document: entities });
    let items = get(normalized, 'document', []) || [];
    items = items.filter(item => item);
    items = orderBy(items, ['position', 'id'], ['asc', 'asc']);
    return items;
  }
);

export const getSingle = createSelector<Store.Types.IState, number, Types.IEntities, number, Types.IEntity.Document>(
  state => state.document.entities,
  (_, id) => id,
  (entities, id) => {
    let normalized;
    if (get(entities, id)) {
      normalized = denormalize({ document: id }, { document: Schema }, { document: entities });
    }

    let item = get(normalized, 'document');
    if (item) {
      return item;
    }
    return {};
  }
);

export const getListSearch = createSelector<
  Store.Types.IState,
  string,
  Types.IEntities,
  string,
  boolean,
  number,
  string,
  Types.IEntity.Document[]
>(
  getEntities,
  (state, search) => search,
  (state, search, showAll) => showAll,
  (state, search, showAll, filter) => get(filter, 'categoryId'),
  (state, search, showAll, filter) => get(filter, 'applicantType'),
  (entities, search, showAll, categoryId, applicantType) => {
    let items: Types.IEntity.Document[] = [];

    if (showAll) {
      items = Object.values(entities);
    }

    if (search && trim(search)) {
      search = trim(lowerCase(search));
      items = items.filter(
        item =>
          includes(trim(lowerCase(get(item, 'title.uz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'title.oz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'title.ru') || '')), search) ||
          includes(trim(lowerCase(get(item, 'title.en') || '')), search) ||
          includes(trim(lowerCase(get(item, 'description.en') || '')), search) ||
          includes(trim(lowerCase(get(item, 'description.uz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'description.oz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'description.ru') || '')), search) ||
          includes(trim(lowerCase(get(item, 'description.en') || '')), search) ||
          includes(trim(lowerCase(get(item, 'tags') || '')), search)
      );
    }

    if (categoryId) {
      items = items.filter(item => Number(item.category_id) === Number(categoryId));
    }

    if (applicantType) {
      items = items.filter(item => (get(item, 'applicant_types') || []).includes(applicantType));
    }

    items = items.filter(item => get(item, 'status') && !get(item, 'isDeleted'));

    items = orderBy(items, ['category.title.uz', 'category_id', 'position', 'id'], ['asc', 'asc', 'asc', 'asc']);
    items = items.filter(item => item);
    return items;
  }
);

export const getInfo = createSelector<Store.Types.IState, number, { [key: string]: Types.Info }, number, Types.Info>(
  state => state.document.info,
  (_, id) => id,
  (info, id) => {
    return (
      get(info, `${id}`) || {
        isFetched: false,
        isLoaded: false,
        item: Mappers.info({})
      }
    );
  }
);
