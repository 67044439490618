import get from 'lodash/get';
import moment from 'moment';

import * as Types from './types';

export const certificate = (id: number, item: Types.IApi.Certificate, file: Types.IApi.File): Types.IEntity.Certificate => {
  const legalTin = get(item, '1.2.860.3.16.1.1') || '';
  const individualTin = get(item, 'UID') || '';
  const tin = legalTin ? legalTin : individualTin;
  const validFrom = get(item, 'VALIDFROM') || '';
  const validFromFormatted = validFrom ? moment(validFrom, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY') : '';
  const validTo = get(item, 'VALIDTO') || '';
  const validToFormatted = validTo ? moment(validTo, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY') : '';
  const isExpired = validTo ? moment().isAfter(moment(validTo, 'YYYY.MM.DD HH:mm:ss').format('YYYY-MM-DD')) : true;

  return {
    id,
    name: get(item, 'CN') || '',
    organization: get(item, 'O') || '',
    tin,
    isLegal: isLegalTin(tin),
    valid: {
      from: validFromFormatted,
      to: validToFormatted,
      isExpired
    },
    number: get(item, 'SERIALNUMBER') || '',
    file: {
      name: get(file, 'name') || '',
      disk: get(file, 'disk') || '',
      path: get(file, 'path') || '',
      alias: get(file, 'alias') || ''
    }
  };
};

export const isLegalTin = (tin: string): boolean => {
  return (tin.charAt(0) === '2' || tin.charAt(0) === '3');
};