import { useState } from 'react';
import get from 'lodash/get';

import * as Api from '../api';
import * as Mappers from '../mappers.ts';

interface IProps {
  registerId: number;
}

const useFees = ({ registerId }: IProps) => {
  const [invoice, setInvoice] = useState(Mappers.invoice({}));
  const [hasInvoice, setHasInvoice] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const check = ({ onSuccess = () => {}, onError = () => {}, onFinally = () => {} } = {}) => {
    setLoading(true);
    Api.Fees({ registerId })
      .then(({ data }) => {
        const invoice = Mappers.invoice(get(data, 'data'));
        setInvoice(invoice);
        setHasInvoice(true);
        onSuccess();
      })
      .catch(() => onError())
      .finally(() => {
        setLoading(false);
        onFinally();
      })
  };

  return {
    check,
    isLoading,
    hasInvoice,
    invoice,
    setInvoice
  };
};

export default useFees;