import { http } from 'services';

import * as Types from './types';

export const Rate = ({ values }: { values: Types.IForm.Rate }) => {
  return http.request.post(`/feedback/rating`, {
    rate: values.rate,
    note: values.comment,
    application_id: values.application_id
  });
};

export const Complaint = ({ values }: { values: Types.IForm.Complaint }) => {
  return http.request.post(`/feedback/complaint-public/set-complaint`, {
    full_name: values.name,
    phone: values.phone,
    body: values.comment
  });
};

export const Appeal = ({ values }: { values: Types.IForm.Appeal }) => {
  return http.request.post(`/notification/appeal/send`, {}, {
    params: {
      application_id: Number(values.applicationId),
      topic: values.theme,
      text: values.text
    }
  });
};

export const AppealTopics = () => {
  return http.request.get(`/notification/appeal/topics`);
};