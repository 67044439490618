import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Hooks from '../hooks';
import * as Types from '../types';
import * as Actions from '../actions';

interface IChildren {
  isAuthenticated: boolean;
  isFetched: boolean;
  profile: Types.IEntity.Profile;
}

interface IProps {
  children(props: IChildren): JSX.Element;
}

const Auth: React.FC<IProps> = ({ children }) => {
  const { isAuthenticated, isFetched, profile } = Hooks.useAuth();

  const dispatch = useDispatch();

  const { accessToken } = useSelector<Store.Types.IState, Types.IEntity.Token>(state => state.auth.token);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(Actions.Profile.request());
    }
  }, [accessToken]);

  return children({ isAuthenticated, isFetched, profile });
};

export default Auth;