import React, { useEffect } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import truncate from 'lodash/truncate';

import * as CategoryModule from 'modules/category';
import * as DocumentModule from 'modules/document';
import * as SystemModule from 'modules/system';
import * as StepperModule from 'modules/stepper';

import * as Icon from 'components/Icon';
import Type from 'components/Type';
import { Nav } from 'components/Modal';
import * as Breadcrumb from 'components/Breadcrumb';

import * as Hooks from '../../../hooks';

import classes from './Documents.module.scss';

interface IProps {
  isSelected: boolean;
}

const Documents: React.FC<IProps> = ({ isSelected }) => {
  const { language } = SystemModule.Hooks.useLanguage();
  const stepper = StepperModule.useContext();
  const { state: { categoryId, subCategoryId, documentId }, methods } = CategoryModule.Context.Single.useContext();
  const category = Hooks.useSingle({ id: categoryId });
  const subCategory = Hooks.useSingle({ id: subCategoryId });

  const { items: documents } = DocumentModule.Hooks.useListByCategory({
    categoryId: !category.children.length ? category.id : subCategory.id
  });

  useEffect(() => {
    setTimeout(() => {
      isSelected && onClick(documentId);
    }, 0)
  }, []);

  const onClick = (id: number) => {
    methods.setDocument(id);
    stepper.next();
  };

  let title = get(category, `title[${language}]`);
  let description = get(category, `description[${language}]`);
  if (category.children.length) {
    title = get(subCategory, `title[${language}]`);
    description = get(subCategory, `description[${language}]`);
  }

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={methods.setClose} showPrev={stepper.canPrev} onPrev={() => stepper.prev()} />
      <div className={cx(classes.breadcrumb, !stepper.canPrev && classes.breadcrumbNoPrev)}>
        {!!category.children.length && (
          <Breadcrumb.List variant="dark">
            <Breadcrumb.Item onClick={() => stepper.prev()}>{truncate(get(category, `title[${language}]`), { length: 40 })}</Breadcrumb.Item>
            <Breadcrumb.Item>{truncate(title, { length: 40 })}</Breadcrumb.Item>
          </Breadcrumb.List>
        )}
      </div>
      <div className={classes.header}>
        <div className={classes.image}>
          <img src={category.image} />
        </div>
        <div className={classes.headerContent}>
          <div className={classes.title}>{title}</div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>
      <div className={classes.list}>
        {documents.map(item => (
          <div key={item.id} className={classes.listItem} onClick={() => onClick(item.id)}>
            <div className={classes.listItemTitle}>{get(item, `title[${language}]`)}</div>
            <div className={classes.listItemType}>
              <Type variant="blue">{get(item, `type.title[${language}]`)}</Type>
            </div>
            <div className={classes.listItemIcon}>
              <Icon.System.Right />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Documents;