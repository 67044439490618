import get from 'lodash/get';

import * as Types from './types';

export const category = (item: Types.IApi.Category.Response, parentId?: number): Types.IEntity.Category => {
  const id = get(item, 'id') || 0;
  const subCategories = (get(item, 'sub_categories') || []);

  return ({
    id,
    title: langStrings(get(item, 'title')),
    description: langStrings(get(item, 'description')),
    image: get(item, 'icon_id') || '',
    gradient: {
      start: get(item, 'gradient_start_color') || '',
      end: get(item, 'gradient_end_color') || ''
    },
    soon: !!get(item, 'soon'),
    position: get(item, 'position') || 0,
    parentId: parentId || 0,
    children: subCategories.map(s => category(s, id)) || []
  });
};

export const langStrings = (item?: Types.IApi.Category.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};