import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Store from 'store';

import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => state.staticPage.entities;

const getDenormalizedList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntities,
  number[],
  Types.IEntity.StaticPage[]
>(
  getEntities,
  (state, name) => get(state, `staticPage.ids`),
  (entities, ids) => {
    const items = denormalize({ staticPage: ids }, { staticPage: [Schema] }, { staticPage: entities });
    return get(items, 'staticPage', []) || [];
  }
);

const getSortedList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.StaticPage[],
  Types.IEntity.StaticPage[]
>(getDenormalizedList, items => {
  items = orderBy(items, ['id'], ['desc']);

  return items;
});

export const getList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.StaticPage[],
  Types.IEntity.StaticPage[]
>(getSortedList, items => items);

export const getSingle = createSelector<Store.Types.IState, string, Types.IEntities, string, Types.IEntity.StaticPage>(
  getEntities,
  (_, id) => id,
  (entities, id) => {
    let normalized;
    if (get(entities, id)) {
      normalized = denormalize({ staticPage: id }, { staticPage: Schema }, { staticPage: entities });
    }

    let item = get(normalized, 'staticPage');
    if (item) {
      return item;
    }
    return {};
  }
);
