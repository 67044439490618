export enum LIST {
  REQUEST = '@@BRANCHES/LIST/REQUEST',
  SUCCESS = '@@BRANCHES/LIST/SUCCESS',
  FAILURE = '@@BRANCHES/LIST/FAILURE'
}

export enum OPEN_LIST {
  REQUEST = '@@BRANCHES/OPEN_LIST/REQUEST',
  SUCCESS = '@@BRANCHES/OPEN_LIST/SUCCESS',
  FAILURE = '@@BRANCHES/OPEN_LIST/FAILURE'
}

export enum WORKERS {
  REQUEST = '@@BRANCHES/WORKERS/REQUEST',
  SUCCESS = '@@BRANCHES/WORKERS/SUCCESS',
  FAILURE = '@@BRANCHES/WORKERS/FAILURE'
}

export enum OPEN_WORKERS {
  REQUEST = '@@BRANCHES/OPEN_WORKERS/REQUEST',
  SUCCESS = '@@BRANCHES/OPEN_WORKERS/SUCCESS',
  FAILURE = '@@BRANCHES/OPEN_WORKERS/FAILURE'
}
