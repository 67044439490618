import { takeLatest, call, put, all } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { callback, date, token } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: { data: unknown } }> = yield call(Api.List, { date, token });
    const item = get(data, 'data') || {};
    const categories = orderBy((get(item, 'categories') || []), ['count'], ['desc']).map(item => Mappers.category(item));
    const days = Mappers.days(get(item, 'days'));
    const statuses = Mappers.statuses(get(item, 'statuses'));
    yield put(Actions.List.success({ categories, days, statuses }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { categories, statuses, days });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.List.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* User(action: ReturnType<typeof Actions.User.request>) {
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.User }> = yield call(Api.User);
    const user = Mappers.user(get(data, 'data'));
    yield put(Actions.User.success({ user }));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.User.failure({ error: errorMessage }));
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List),
    takeLatest(Constants.USER.REQUEST, User)
  ]);
}
