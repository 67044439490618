import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';

import { history } from 'services';

import * as ActModule from 'modules/act';
import * as ApplicationModule from 'modules/application';
import * as AuthModule from 'modules/auth';
import * as CategoryModule from 'modules/category';
import * as CertificateModule from 'modules/certificate';
import * as DigitalSignModule from 'modules/digitalSign';
import * as DocumentModule from 'modules/document';
import * as EmployeeModule from 'modules/employee';
import * as FeedbackModule from 'modules/feedback';
import * as InstructionModule from 'modules/instruction';
import * as NewsModule from 'modules/news';
import * as NotificationModule from 'modules/notification';
import * as OrganizationModule from 'modules/organization';
import * as ProcessModule from 'modules/process';
import * as RegistryModule from 'modules/registry';
import * as StaticPageModule from 'modules/staticPage';
import * as StatsModule from 'modules/stats';
import * as SystemModule from 'modules/system';
import * as VideoInstructionModule from 'modules/videoInstruction';
import * as BranchesModule from 'modules/branches';



import * as Types from './types';

const rootReducer: Reducer<Types.IState> = combineReducers<Types.IState>({
  router: connectRouter(history),
  act: ActModule.Reducer,
  application: ApplicationModule.Reducer,
  auth: AuthModule.Reducer,
  category: CategoryModule.Reducer,
  certificate: CertificateModule.Reducer,
  digitalSign: DigitalSignModule.Reducer,
  document: DocumentModule.Reducer,
  employee: EmployeeModule.Reducer,
  feedback: FeedbackModule.Reducer,
  instruction: InstructionModule.Reducer,
  news: NewsModule.Reducer,
  notification: NotificationModule.Reducer,
  organization: OrganizationModule.Reducer,
  process: ProcessModule.Reducer,
  registry: RegistryModule.Reducer,
  staticPage: StaticPageModule.Reducer,
  stats: StatsModule.Reducer,
  system: SystemModule.Reducer,
  videoInstruction: VideoInstructionModule.Reducer,
  branches: BranchesModule.Reducer
});

export default rootReducer;
