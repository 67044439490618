import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { useOuterClick } from 'react-outer-click';

import classes from './Profile.module.scss';

interface IProps {
  name: string;
  company: string;
  dropdown?: React.ReactNode;
  className?: string;
}

const Profile: React.FC<IProps> = ({ name, company, dropdown, className }) => {
  const [isOpen, setOpen] = useState(false);
  const el = useRef(null);

  useOuterClick(el, () => setOpen(false));

  return (
    <div ref={el} className={cx(classes.wrapper, isOpen && classes[`wrapper--open`], className)}>
      <div className={classes.wrapperInner} onClick={() => setOpen(!isOpen)}>
        <div className={classes.content}>
          <div className={classes.name}>{name}</div>
          {!!company && (
            <div className={classes.company}>{company}</div>
          )}
        </div>
      </div>
      <div className={classes.dropdown} onClick={() => setOpen(false)}>{dropdown}</div>
    </div>
  );
};

export default Profile;