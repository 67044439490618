import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const Single = ({
  slug
}: Types.IApi.StaticPage.Request): AxiosPromise<{ data: Types.IApi.StaticPage.Response }> => {
  return http.request.get<{ data: Types.IApi.StaticPage.Response }>(`/admin/open_source/alert/${slug}`);
};
