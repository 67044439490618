import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({
  params
}: {
  params: Types.IParams;
}): AxiosPromise<{ data: { registers: Types.IApi.Certificate.Response[] } }> => {
  return http.request.get<{ data: { registers: Types.IApi.Certificate.Response[] } }>(`/register/list`, {
    params: {
      search: params.search,
      page: params.page - 1,
      size: params.limit,
      sort: 'id,desc'
    }
  });
};

export const Single = ({
  id
}: Types.IApi.Certificate.Request): AxiosPromise<{ data: Types.IApi.Certificate.Response }> => {
  return http.request.get<{ data: Types.IApi.Certificate.Response }>(`/register/view?id=${id}`);
};

export const Report = ({
  params
}: {
  params: Types.IReportParams;
}): AxiosPromise<{ data: { content: Types.IApi.Report[] } }> => {
  return http.request.get<{ data: { content: Types.IApi.Report[] } }>(
    `/register/register-report-public/list-by-register-id/${params.registerId}`,
    {
      params: {
        page: params.page - 1,
        size: params.limit,
        sort: 'id,desc'
      }
    }
  );
};
