import React, { cloneElement, isValidElement } from 'react';

import classes from './Dropdown.module.scss';

interface IProps {
  title: string;
  icon: React.ReactNode;
  container?: React.ReactNode;
  onClick?: () => void;
}

const Item: React.FC<IProps> = ({ title, icon, container, onClick }) => {
  const resultProps = {
    className: classes.item,
    onClick
  };

  const resultChildren = (
    <>
      <span className={classes.itemTitle}>{title}</span>
      <span className={classes.itemIcon}>{icon}</span>
    </>
  );

  const resultContainer = isValidElement(container) ? (
    container
  ) : (
    <div/>
  );

  return cloneElement(
    resultContainer,
    resultProps,
    resultChildren
  );
};

export default Item;