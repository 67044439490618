import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({
  params
}: {
  params: Types.IParams;
}): AxiosPromise<{ data: { content: Types.IApi.VideoInstruction.Response[] } }> => {
  return http.request.get<{ data: { content: Types.IApi.VideoInstruction.Response[] } }>(
    `/admin/open_source/video_instruction`,
    {
      params: {
        page: params.page - 1,
        size: params.limit
      }
    }
  );
};

export const Single = ({
  id
}: Types.IApi.VideoInstruction.Request): AxiosPromise<{ data: Types.IApi.VideoInstruction.Response }> => {
  return http.request.get<{ data: Types.IApi.VideoInstruction.Response }>(`/admin/open_source/video_instruction/${id}`);
};
