import { action } from 'typesafe-actions';

import * as Constants from './constants';
import * as Types from './types';

export const List = {
  request: (args?: Types.IAction.List.Request) => action(Constants.LIST.REQUEST, args),
  success: (args: Types.IAction.List.Success) => action(Constants.LIST.SUCCESS, args),
  failure: (args: Types.IAction.List.Failure) => action(Constants.LIST.FAILURE, args)
};

export const OpenList = {
  request: (args?: Types.IAction.OpenList.Request) => action(Constants.OPEN_LIST.REQUEST, args),
  success: (args: Types.IAction.OpenList.Success) => action(Constants.OPEN_LIST.SUCCESS, args),
  failure: (args: Types.IAction.OpenList.Failure) => action(Constants.OPEN_LIST.FAILURE, args)
};

export const Workers = {
  request: (args?: Types.IAction.Workers.Request) => action(Constants.WORKERS.REQUEST, args),
  success: (args: Types.IAction.Workers.Success) => action(Constants.WORKERS.SUCCESS, args),
  failure: (args: Types.IAction.Workers.Failure) => action(Constants.WORKERS.FAILURE, args)
};

export const OpenWorkers = {
  request: (args?: Types.IAction.OpenWorkers.Request) => action(Constants.OPEN_WORKERS.REQUEST, args),
  success: (args: Types.IAction.OpenWorkers.Success) => action(Constants.OPEN_WORKERS.SUCCESS, args),
  failure: (args: Types.IAction.OpenWorkers.Failure) => action(Constants.OPEN_WORKERS.FAILURE, args)
};
