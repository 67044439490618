import React from 'react';

import * as PaymentModule from 'modules/payment';
import * as StepperModule from 'modules/stepper';

import Prepayment from './components/Prepayment';

import classes from './PaymentForm.module.scss';
import Confirm from './components/Confirm';

interface IProps {
  applicationId?: number;
  registerId?: number;
  serial: string;
  amount: string;
  close: () => void;
  onSuccess: () => void;
}

const PaymentForm: React.FC<IProps> = ({ applicationId, registerId, serial, amount, close, onSuccess }) => {

  return (
    <PaymentModule.Context.Provider {...{ applicationId, registerId, serial, amount }}>
      <div className={classes.wrapper}>
        <StepperModule.Provider
          firstStepKey="prepayment"
          steps={{
            prepayment: {
              next: 'confirm',
              component: (
                <Prepayment {...{ close }} />
              )
            },
            confirm: {
              prev: 'prepayment',
              component: (
                <Confirm {...{ close, onSuccess }} />
              )
            }
          }}
        />
      </div>
    </PaymentModule.Context.Provider>
  );
};

export default PaymentForm;