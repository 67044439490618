import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ token }: { token: any }): AxiosPromise<{ data: Types.IApi.Organization }> => {
  return http.request.get<{ data: Types.IApi.Organization }>(`/admin/handbook/organizations-by-rating`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    }
  });
};

export const Single = ({ id, token }: { id: number; token: any }): AxiosPromise<{ data: Types.IApi.Organization }> => {
  return http.request.get<{ data: Types.IApi.Organization }>(`/admin/handbook/organization-view/${id}`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    }
  });
};