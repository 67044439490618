import React, { useState } from 'react';
import { useLayer } from 'react-laag';
import cx from 'classnames';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import * as Icon from 'components/Icon';

import classes from './Table.module.scss';

interface Item {
  title: string;
  onClick?: () => void;
  icon: React.ReactNode;
  isVisible?: boolean;
}

interface IProps {
  items?: Item[];
  className?: string;
}

const Action: React.FC<IProps> = ({ items, className }) => {
  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    overflowContainer: true,
    placement: 'bottom-end',
    auto: true,
    possiblePlacements: ['bottom-end'],
    snap: false,
    preferX: 'left',
    triggerOffset: 5,
    containerOffset: 0,
    container: 'content',
    onOutsideClick: () => setOpen(false),
    onDisappear: disappearType => {
      if (disappearType === 'full') {
        setOpen(false);
      }
    }
  });


  return (
    <div
      {...triggerProps}
      className={cx(classes.action, className)}
      onClick={(e) => {
        if(items && items.length){
          e.stopPropagation();
          setOpen(!isOpen)
        }
      }}
    >
      <div className={classes.actionIcon}>
        <Icon.System.More/>
      </div>

      {items && items.length && (
        <Transition in={isOpen} timeout={{ enter: 0, exit: 200 }} unmountOnExit mountOnEnter>
          {(state: TransitionStatus) => (
            <>
              {renderLayer(
                <div {...layerProps}>
                  <div className={classes.actionMenuList} style={{ ...transition[state] }}>
                    {items.map(item => (
                      <div className={classes.actionMenuItem} onClick={item.onClick}>
                        <div className={classes.actionMenuItemTitle}>{item.title}</div>
                        <div className={classes.actionMenuItemIcon}>{item.icon}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

const transition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transition: 'all .2s ease-in-out' },
  exited: { opacity: 0, visibility: 'hidden' }
};

export default Action;
