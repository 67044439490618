import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import * as Types from '../types';

import classes from '../Field.module.scss';

interface IChildren {
  onFocus: () => void;
  onBlur: () => void;
}

interface IProps extends Types.FieldProps {
  type?: 'textarea';
  children: (props: IChildren) => React.ReactNode;
}

const Field: React.FC<IProps> = ({ name, label, required, disabled, readOnly, hasError, error, type, autoFocus, prefix, suffix, icon, isIconDisabled, onIconClick, children }) => {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      const input = document?.getElementById(name);
      if (input) {
        setTimeout(() => input.focus(), 100);
      }
    }
  }, []);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <div className={cx(classes.wrapper, focused && classes['wrapper--focused'], disabled && classes['wrapper--disabled'], readOnly && classes['wrapper--readonly'], hasError && classes['wrapper--error'], type && classes[`wrapper--type-${type}`])}>
      <label>
        {!!label && <div className={classes.label} title={label}>{!!required && <span>*</span>}{label}</div>}
        <div className={classes.wrapperInner}>
          {!!prefix && <div className={classes.prefix}>{prefix}</div>}
          {children({ onFocus, onBlur })}
          {!!icon && <div className={cx(classes.icon, isIconDisabled && classes.iconDisabled, !!onIconClick && classes.iconClickable)} onClick={onIconClick}>{icon}</div>}
          {!!suffix && <div className={classes.suffix}>{suffix}</div>}
        </div>
      </label>
      {!!hasError && (
        <div className={classes.errorLabel}>{error}</div>
      )}
    </div>
  );
};

export default Field;