import { useSelector } from 'react-redux';

import * as Store from 'store';

import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  entities: Types.IEntities;
  items: {
    parent: Types.IEntity.Category[];
    children: Types.IEntity.Category[];
  };
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  search?: string;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useListSearch = ({ search = '' }: IProps = {}): IChildren => {
  const entities = useSelector<Store.Types.IState, Types.IEntities>(state => state.category.entities);
  const parentItems = useSelector<Store.Types.IState, Types.IEntity.Category[]>(state => Selectors.getParentListSearch(state, search));
  const childrenItems = useSelector<Store.Types.IState, Types.IEntity.Category[]>(state => Selectors.getChildrenListSearch(state, search));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.category.list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.category.list.isLoaded);

  return {
    entities,
    items: {
      parent: parentItems,
      children: childrenItems
    },
    isFetched,
    isLoaded
  };
};

export default useListSearch;