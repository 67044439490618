import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import * as PaymentModule from 'modules/payment';
import * as StepperModule from 'modules/stepper';
import * as SystemModule from 'modules/system';

import TextBlock from 'components/TextBlock';
import * as Fields from 'components/Fields';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import classes from '../PaymentForm.module.scss';

interface IProps {
  close: () => void;
}

const Prepayment: React.FC<IProps> = ({ close }) => {
  const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const stepper = StepperModule.useContext();
  const { state: { amount, applicationId, serial }, methods } = PaymentModule.Context.useContext();

  return (
    <>
      <div className={classes.title}>{t('pay_with_a_plastic_card')}</div>
      <TextBlock variant="BLUE" className={classes.info}>
        {t('Payment_amount')}: <b>{t('license_price', { value: amount })}</b>
      </TextBlock>
      <PaymentModule.Forms.Prepayment
        {...{ applicationId, serial, language }}
        onSuccess={(phone) => {
          methods.setPhone(phone);
          stepper.next()
        }}
        onError={(message) => toast.error(message)}
      >
        {({ isSubmitting }) => (
          <>
            {isSubmitting && (
              <div className={classes.spinner}>
                <Spinner/>
              </div>
            )}
            <Fields.Mask
              name="card.number"
              label={t('card_number')}
              mask="#### #### #### ####"
              type="NUMBER"
              placeholder="•••• •••• •••• ••••"
              required
            />
            <Fields.Mask
              name="card.expires"
              label={t('card_expires')}
              mask="##/##"
              type="NUMBER"
              placeholder={t('card_expires_placeholder')}
              required
            />
            <div className={classes.buttonList}>
              <Button htmlType="button" variant="grey" block className="m-r-16" onClick={close}>{t('Cancel')}</Button>
              <Button variant="blue" block>{t('Pay')}</Button>
            </div>
          </>
        )}
      </PaymentModule.Forms.Prepayment>
    </>
  );
};

export default Prepayment;