import { createContext } from 'react';

import * as Types from '../types';

export const initialState: Types.IContext.State = {
  applicationId: undefined,
  registerId: undefined,
  amount: '',
  serial: '',
  phone: '',
  values: {
    card: {
      number: '',
      expires: ''
    }
  }
};

const context = createContext<Types.IContext.Value>({
  state: { ...initialState },
  methods: {
    setPhone: () => null,
    setValues: () => null
  }
});

export default context;