export const ENTITIES = '@@CATEGORY/ENTITIES';

export enum LIST {
  REQUEST = '@@ACT/LIST/REQUEST',
  SUCCESS = '@@ACT/LIST/SUCCESS',
  FAILURE = '@@ACT/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@ACT/SINGLE/REQUEST',
  SUCCESS = '@@ACT/SINGLE/SUCCESS',
  FAILURE = '@@ACT/SINGLE/FAILURE'
}

export const STORE_RESET = '@@STORE/RESET';

export enum CATEGORY {
  CHECKED = 'CHECKED',
  SUSPENSION = 'SUSPENSION',
  RE_CHECKED = 'RE_CHECKED',
  RENEWAL = 'RENEWAL',
  COURT_APPLICATION = 'COURT_APPLICATION',
  COURT_DECISION = 'COURT_DECISION',
  LICENSE_DECISION = 'LICENSE_DECISION',
  LICENSE_CONCLUSION = 'LICENSE_CONCLUSION',
  AGREEMENTS_DECISION = 'AGREEMENTS_DECISION',
  AGREEMENTS_CONCLUSION = 'AGREEMENTS_CONCLUSION'
}
