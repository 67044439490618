import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Selectors from '../selectors';
import * as Types from '../types';
import { useEffect } from 'react';
import * as Actions from '../actions';

interface IChildren {
  item: Types.IEntity.Instruction;
  isFetched: boolean;
}

interface IProps {
  id: number;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useSingle = ({ id, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps): IChildren => {
  const dispatch = useDispatch();

  const item = useSelector<Store.Types.IState, Types.IEntity.Instruction>(state => Selectors.getSingle(state, id));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.instruction.single.isFetched);

  useEffect(() => {
    dispatch(
      Actions.Single.request({
        id,
        callback: {
          onSuccess,
          onError,
          onFinally
        }
      })
    );
  }, []);

  return { item, isFetched };
};

export default useSingle;
