import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ date, token }): AxiosPromise<{ data: { data: unknown } }> => {
  return http.request.get<{ data: { data: unknown } }>(`/application/open_source/stat`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    },
    params: {
      date: date ? date : null
    }
  });
};

export const StatByDocument = ({
  document_id,
  token
}): AxiosPromise<{
  data: {
    statuses: Types.IEntity.Status;
    months: Types.IEntity.Month[];
    rating:{
      avg:number
      count:number
    }
  };
}> => {
  return http.request.get(`/application/open-source/stat-by-document`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    },
    params: {
      document_id
    }
  });
};

export const User = (): AxiosPromise<{ data: Types.IEntity.User }> => {
  return http.request.get<{ data: Types.IEntity.User }>(`/application/stat/user`);
};
