import React, { useEffect, useState } from 'react';
import maxBy from 'lodash/maxBy';
import get from 'lodash/get';

import Item, { IProps as ItemProps } from './Item';

import classes from './GraphStats.module.scss';

interface IProps {
  title: string;
  date: string
  items: Omit<ItemProps, 'max'>[];
}

const GraphStats: React.FC<IProps> = ({ title, date, items }) => {
  const [max, setMax] = useState(0);

  useEffect(() => {
    const highest = maxBy(items, 'count.all');
    setMax(get(highest, 'count.all') || 0);
  }, [items]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <div className={classes.date}>{date}</div>
      </div>
      <div className={classes.columns}>
        {items.map(item => (
          <Item key={item.title} {...item} {...{ max }} />
        ))}
      </div>
    </div>
  );
};

export default GraphStats;