import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Store from 'store';

import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => state.videoInstruction.entities;

const getDenormalizedList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntities,
  number[],
  Types.IEntity.VideoInstruction.Response[]
>(
  getEntities,
  (state, name) => get(state, `videoInstruction.list[${name}].ids`),
  (entities, ids) => {
    const items = denormalize(
      { videoInstruction: ids },
      { videoInstruction: [Schema] },
      { videoInstruction: entities }
    );
    return get(items, 'videoInstruction', []) || [];
  }
);

const getSortedList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.VideoInstruction.Response[],
  Types.IEntity.VideoInstruction.Response[]
>(getDenormalizedList, items => {
  items = orderBy(items, ['position', 'id'], ['asc', 'desc']);

  return items;
});

export const getList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.VideoInstruction.Response[],
  Types.IEntity.VideoInstruction.Response[]
>(getSortedList, items => items);

export const getSingle = createSelector<
  Store.Types.IState,
  number,
  Types.IEntities,
  number,
  Types.IEntity.VideoInstruction.Response
>(
  getEntities,
  (_, id) => id,
  (entities, id) => {
    let normalized;
    if (get(entities, id)) {
      normalized = denormalize({ videoInstruction: id }, { videoInstruction: Schema }, { videoInstruction: entities });
    }

    let item = get(normalized, 'videoInstruction');
    if (item) {
      return item;
    }
    return {};
  }
);
