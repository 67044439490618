import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';

import * as DocumentModule from 'modules/document';

import * as Constants from './constants';
import * as Types from './types';

export const process = (item: Types.IApi.Process): Types.IEntity.Process => {
  return {
    applicationId: get(item, 'data.application_id') || 0,
    applicationType: get(item, 'data.application_type') || Constants.TYPE.REGISTRATION,
    documentId: get(item, 'data.document_id') || 0,
    isComplete: !!get(item, 'data.is_complete'),
    status: get(item, 'data.status'),
    values: get(item, 'data.values'),
    currentStep: get(item, 'data.last_step_id') || 0
  };
};

export const info = (item: Types.IApi.Info.Response): Types.IEntity.Info => {
  const stepsByPosition = _stepsByPosition(get(item, 'steps') || []);
  const groups = _groupsByStepPosition(get(item, 'field_groups') || [], stepsByPosition);
  const fields = _fields(get(item, 'field_groups') || [], stepsByPosition);

  return {
    document: DocumentModule.Mappers.document(get(item, 'document') || {}),
    steps: stepsByPosition,
    groups,
    fields
  };
};

export const _stepsByPosition = (steps: Types.IApi.Info.Response['steps']): Types.IEntity.Info['steps'] => {
  return orderBy(steps || [], ['position', 'id'], ['asc', 'asc']).reduce(
    (prev, curr) => ({
      ...prev,
      [String(curr.position || 0)]: step(curr, curr.position || 0)
    }),
    {}
  ) as Types.IEntity.Info['steps'];
};

export const _groupsByStepPosition = (
  groups: Types.IApi.Info.Response['field_groups'],
  steps: Types.IEntity.Info['steps']
): Types.IEntity.Info['groups'] => {
  let entities: Types.IEntity.Info['groups']['entities'] = {};
  let byStepId: Types.IEntity.Info['groups']['byStepId'] = {};
  let byStepPosition: Types.IEntity.Info['groups']['byStepPosition'] = {};

  const groupsByStepId = groupBy(groups, i => `${i.step_id}`) || {};

  Object.values(steps).forEach(step => {
    const stepGroups = groupsByStepId[step.id];
    if (stepGroups) {
      orderBy(stepGroups, ['position'], ['asc']).forEach(group => {
        const key = group.key;
        if (key) {
          entities = { ...entities, [String(group.key)]: fieldGroup(group) };
          byStepId = { ...byStepId, [step.id]: [...(get(byStepId, `${step.id}`) || []), key] };
          byStepPosition = {
            ...byStepPosition,
            [step.position]: [...(get(byStepPosition, `${step.position}`) || []), key]
          };
        }
      });
    }
  });

  return { entities, byStepId, byStepPosition };
};

export const _fields = (
  groups: Types.IApi.Info.Response['field_groups'],
  stepsByPosition: Types.IEntity.Info['steps']
): Types.IEntity.Info['fields'] => {
  let entities: Types.IEntity.Info['fields']['entities'] = {};
  let byStep: Types.IEntity.Info['fields']['byStep'] = {};
  let byGroup: Types.IEntity.Info['fields']['byGroup'] = {};
  let byActionId: Types.IEntity.Info['fields']['byActionId'] = {};

  const extraFieldsByStep = {
    [Constants.STEP_TYPE.SPECIALIZATION]: ['SELECTED_SPECIALIZATIONS'],
    [Constants.STEP_TYPE.REQUIREMENTS]: ['SELECTED_REQUIREMENTS'],
    [Constants.STEP_TYPE.STATUS]: [
      'APPLICATION_STATUS',
      'APPLICATION_REVIEW_DEADLINE',
      'APPLICATION_REVIEW_DATE_BEGIN',
      'APPLICATION_REVIEW_DATE_END',
      'APPLICATION_REVIEW_DEADLINE_ADJUST',
      'REGISTER_ID'
    ],
    [Constants.STEP_TYPE.PAYMENT]: [
      'INVOICE_ISSUE_DATE',
      'INVOICE_AMOUNT',
      'INVOICE_AMOUNT_ONLINE',
      'INVOICE_SERIAL',
      'INVOICE_QR_CODE',
      'INVOICE_STATUS',
      'INVOICE_BANK_ACCOUNT',
      'INVOICE_PAYEE',
      'INVOICE_PAYER',
      'INVOICE_DETAILS',
      'INVOICE_BUDGET_ACCOUNT',
      'INVOICE_BANK_NAME',
      'INVOICE_BANK_MFO'
    ]
  };

  const groupByStepId = groupBy(groups, i => `${i.step_id}`) || {};

  Object.values(stepsByPosition).forEach(step => {
    const groups = groupByStepId[step.id] || [];
    const stepExtraFields = extraFieldsByStep[step.type] || [];

    stepExtraFields.forEach(fieldKey => {
      entities = { ...entities, [fieldKey]: field({ key: fieldKey }) };
    });

    byStep = {
      ...byStep,
      [String(step.position)]: [...(get(byStep, `${step.position}`) || []), ...stepExtraFields]
    };

    (groups || []).forEach(group => {
      if (group.key) {
        let groupFields = get(group, 'fields') || [];
        let fieldsKeys: string[] = [];
        const fieldsByKey =
          (orderBy(groupFields, ['position'], ['asc']) || []).reduce<{ [key: string]: Types.IEntity.Field }>(
            (prev, f) => {
              const key = get(f, 'key');
              if (!key) {
                return { ...prev };
              }

              fieldsKeys = [...fieldsKeys, key];
              if (f.actionId) {
                byActionId = {
                  ...byActionId,
                  // @ts-ignore
                  [String(step.position)]: { ...(get(byActionId, `${step.position}`) || {}), [f.actionId]: key }
                };
              }

              const fieldSubFields = (subFields(get(f, 'fields')) || []).reduce((prev, f) => {
                const key = get(f, 'key');
                if (!key) {
                  return { ...prev };
                }

                // fieldsKeys = ([...fieldsKeys, key]);
                if (f.actionId) {
                  byActionId = {
                    ...byActionId,
                    // @ts-ignore
                    [String(step.position)]: { ...(get(byActionId, `${step.position}`) || {}), [f.actionId]: key }
                  };
                }

                return { ...prev, [String(key)]: field(f) };
              }, {});

              return { ...prev, [String(key)]: field(f), ...fieldSubFields };
            },
            {}
          ) || {};

        entities = { ...entities, ...fieldsByKey };
        byGroup = { ...byGroup, [String(group.key)]: fieldsKeys };
        byStep = {
          ...byStep,
          [String(step.position)]: [...(get(byStep, `${step.position}`) || []), ...fieldsKeys]
        };
      }
    });
  });

  return { entities, byStep, byGroup, byActionId };
};

const subFields = fields => {
  return (fields || []).reduce((prev, field) => {
    if (!field) {
      return [...prev];
    }

    if (get(field, 'fields')) {
      return [...prev, ...subFields(get(field, 'fields'))];
    }

    return [...prev, field];
  }, []);
};

export const values = (
  values: Types.IApi.Value[] = [],
  info: Types.IEntity.Info
): Types.IEntity.Info['fields']['entities'] => {
  const fields = info.fields.entities;
  return (values || []).reduce((prev, field) => {
    const fieldValue = get(fields, `${get(field, 'key')}`) || {};

    const key = get(field, 'key') || '';
    let value = String(get(field, 'value') || '');
    const duplicable_values = (get(field, 'duplicable_values') || []).map(item => ({
      ...item,
      readonly: get(item, 'readonly') != undefined ? !!get(item, 'readonly') : !!get(fieldValue, 'readonly')
    }));
    const options = get(field, 'choice_options') || get(fieldValue, 'options') || [];
    if (!key) {
      return { ...prev };
    }

    const mask = String(get(fieldValue, 'input.mask') ? get(fieldValue, 'input.mask') : '');
    if (mask && mask.includes('+') && value.includes('+')) {
      value = value.replace('+', '');
    }

    return {
      ...prev,
      [key]: { ...fieldValue, key, value, duplicable_values, options }
    };
  }, {});
};

export const step = (item: Types.IApi.Step, number: number): Types.IEntity.Step => {
  const id = get(item, 'id') || 0;
  return {
    id,
    number,
    title: langStrings(get(item, 'title')),
    position: get(item, 'position') || 0,
    type: get(item, 'type') || Constants.STEP_TYPE.FORM
  };
};

export const fieldGroup = (item: Types.IApi.FieldGroup): Types.IEntity.FieldGroup => {
  return {
    key: item.key || '',
    title: langStrings(item.title),
    position: item.position || 0,
    isDuplicate: !!item.group_duplicate
  };
};

export const field = (item: Types.IApi.Field): Types.IEntity.Field => {
  let min_length = item.input_min_length || 0;
  let max_length = item.input_max_length || 0;
  let mask = item.input_mask || '';

  if (mask) {
    const length = mask.replace(/[^#]+/g, '').length;
    min_length = length;
    max_length = length;
  }

  return {
    key: item.key || '',
    type: item.type ? Constants.FIELD_TYPE[item.type] : Constants.FIELD_TYPE.INPUT,
    title: langStrings(item.title),
    value: '',
    duplicable_values: [],
    required: !!item.required || false,
    visible: !!item.visible || false,
    readonly: !!item.readonly || false,
    disabled: !!item.disabled || false,
    placeholder: langStrings(item.placeholder),
    position: {
      number: item.position || 0,
      size:
        item.position_side === Constants.FIELD_POSITION_TYPE.FULL
          ? Constants.FIELD_POSITION_TYPE.FULL
          : Constants.FIELD_POSITION_TYPE.HALF
    },
    input: {
      keyboard: item.input_keyboard ? Constants.FIELD_KEYBOARD[item.input_keyboard] : Constants.FIELD_KEYBOARD.TEXT,
      mask: item.input_mask || '',
      min_length,
      max_length,
      prefix: item.input_prefix || '',
      suffix: item.input_suffix || ''
    },
    date: {
      start: item.date_start || '',
      end: item.date_end || ''
    },
    text_block: {
      color: item.text_block_color
        ? Constants.FIELD_TEXT_BLOCK_COLOR[item.text_block_color]
        : Constants.FIELD_TEXT_BLOCK_COLOR.SIMPLE,
      type: item.text_block_type
        ? Constants.FIELD_TEXT_BLOCK_TYPE[item.text_block_type]
        : Constants.FIELD_TEXT_BLOCK_TYPE.TEXT,
      content: langStrings(item.text_block_content)
    },
    options: item.choice_options || [],
    action: {
      id: get(item, 'actionId') || '',
      ids: get(item, 'group_action_ids') ? (get(item, 'group_action_ids') || '').split(':') : [],
      key: get(item, 'actionKey') || '',
      loadOptions: get(item, 'choiceOptionsAuto') || ''
    },
    info: {
      isVisible: !!get(item, 'hasInfo'),
      content: langStrings(get(item, 'infoContent') || {})
    },
    fields: (get(item, 'fields') || []).map(item => field(item)),
    isDuplicate: !!get(item, 'group_duplicate')
  };
};

export const specialization = (item: Types.IApi.Specialization.Item): Types.IEntity.Specialization => {
  return {
    id: get(item, 'id') || 0,
    name: langStrings(get(item, 'name')),
    expiryDate: get(item, 'expiryDate') || '',
    annualFee: get(item, 'annualFee') || 0,
    description: langStrings(get(item, 'description')),
    position: get(item, 'position') || 0
  };
};

export const requirements = (item: Types.IApi.Requirements.Item): Types.IEntity.Requirement => {
  return {
    id: get(item, 'id') || 0,
    content: langStrings(get(item, 'content')),
    position: get(item, 'position') || 0
  };
};

export const actionEvent = (item: Types.IApi.ActionEvent.Item): Types.IEntity.ActionEvent => {
  return {
    title: get(item, 'title') ? langStrings(get(item, 'title')) : undefined,
    options: get(item, 'choice_options') !== undefined ? get(item, 'choice_options') || [] : undefined,
    value: get(item, 'value') !== undefined ? get(item, 'value') || '' : undefined,
    readonly: get(item, 'readonly') !== undefined ? !!get(item, 'readonly') : undefined,
    required:
      get(item, 'required') !== undefined && get(item, 'required') !== null ? !!get(item, 'required') : undefined,
    disabled: get(item, 'disabled') !== undefined ? !!get(item, 'disabled') : undefined,
    visible: get(item, 'visible') !== undefined ? !!get(item, 'visible') : undefined,
    duplicable_values: get(item, 'duplicable_values') !== undefined ? get(item, 'duplicable_values') : undefined
  };
};

export const actionEventList = (
  items: Types.IApi.ActionEvent.Response,
  process: Types.IEntity.Process,
  info: Types.IEntity.Info
): Types.IEntity.ActionEventList => {
  const fields = info.fields.entities;
  const currentStep = process.currentStep;
  const stepFieldKeys = info.fields.byActionId[currentStep] || [];

  let values = {};

  const actionFields =
    (items || []).reduce<{ [key: string]: Types.IEntity.ActionEvent }>((prev, field) => {
      const key = get(field, 'actionId');
      if (!key) {
        return { ...prev };
      }
      return { ...prev, [String(key)]: actionEvent(field) };
    }, {}) || {};

  const newFields =
    Object.values(stepFieldKeys).reduce<{ [key: string]: Types.IEntity.Field }>((prev, fieldKey) => {
      const field = fields[fieldKey];

      if (!field) {
        return { ...prev };
      }
      let actionField = actionFields[field.action.id];
      console.log(actionField);
      if (!actionField) {
        return { ...prev };
      }

      const readonly = get(actionField, 'readonly');

      actionField = {
        ...actionField,
        readonly: undefined
      };

      const actionFieldFiltered = pickBy(actionField, value => value !== undefined);

      values = {
        ...values,
        [field.key]: {
          value: actionField.value,
          duplicable_values: actionField?.duplicable_values,
          readonly: !!readonly
        }
      };

      return {
        ...prev,
        [field.key]: {
          ...field,
          ...actionFieldFiltered,
          value: field.value || '',
          duplicable_values: field?.duplicable_values || []
        }
      };
    }, {}) || {};

  return { fields: newFields, values };
};

export const file = (item: Types.IApi.FileInfo.Response): Types.IEntity.File => {
  return {
    id: get(item, 'fileId') || 0,
    name: get(item, 'name') || '',
    extension: get(item, 'extension') || '',
    size: get(item, 'size') || 0
  };
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};
