import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  stats: {
    statuses: {
      all: 0,
      in_review: 0,
      auto_completed: 0,
      denied: 0,
      completed: 0
    },
    categories: [],
    days: [],
    user: {
      all: 0,
      applications_documents: 0,
      completed: 0,
      denied: 0,
      in_payment: 0,
      in_process: 0,
      registers: 0,
      review: 0
    }
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.LIST.SUCCESS: {
      const { categories, days, statuses } = action.payload;
      return {
        ...state,
        stats: {
          ...state.stats,
          categories,
          days,
          statuses
        }
      };
    }
    case Constants.USER.SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        stats: {
          ...state.stats,
          user
        }
      }
    }
    default:
      return state;
  }
};
