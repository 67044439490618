import { ActionType } from 'typesafe-actions';
import merge from 'lodash/merge';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  entities: {},
  messages: [],
  list: {
    ids: [],
    isFetched: false,
    isLoaded: false,
    meta: {
      current: 1,
      total: 1
    }
  },
  unReadCount: 0
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.ENTITIES: {
      const { items, params } = action.payload;

      let entities = items;
      if (!params || params?.page !== 1) {
        entities = merge({}, state.entities, items);
      }

      return {
        ...state,
        entities
      };
    }
    case Constants.LIST.REQUEST: {
      const { params } = action.payload;

      return {
        ...state,
        list: {
          ...state.list,
          meta: params.page !== 1 ? state.list.meta : initialState.list.meta,
          isFetched: false
        }
      };
    }
    case Constants.LIST.SUCCESS: {
      const { ids, meta, params } = action.payload;

      let newIds = ids;
      if (params.page !== 1) {
        newIds = [...state.list.ids, ...ids];
      }

      return {
        ...state,
        list: {
          ...state.list,
          ids: newIds,
          meta,
          isFetched: true,
          isLoaded: true
        }
      };
    }
    case Constants.LIST.FAILURE: {
      return {
        ...state,
        list: {
          ...state.list,
          isLoaded: true,
          isFetched: true
        }
      };
    }
    case Constants.UN_READ_COUNT.SUCCESS: {
      const { count } = action.payload;
      return {
        ...state,
        unReadCount: count
      };
    }
    case Constants.MARK_AS_READ.SUCCESS: {
      return {
        ...state,
        unReadCount: state.unReadCount > 0 ? state.unReadCount - 1 : 0
      };
    }
    case Constants.ON_MESSAGE: {
      const { item } = action.payload;
      return {
        ...state,
        unReadCount: state.unReadCount + 1,
        messages: [item, ...state.messages]
      };
    }
    case Constants.REMOVE_MESSAGE: {
      const { id } = action.payload;
      return {
        ...state,
        messages: state.messages.filter(message => message.id !== id)
      };
    }
    case Constants.STORE_RESET: {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
}