import React, { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { yup as yupCreator } from 'services';

import * as Actions from '../actions';
import * as Types from '../types';

type FormValues = Partial<Types.IForm.LoginViaFaceID>

interface IChildren extends FormikProps<FormValues> { }

interface IProps {
  onSubmit?: () => void;
  onSuccess?: () => void;
  onError?: ({ error }: { error: string }) => void;
  onFinally?: () => void;
  children(props: IChildren): JSX.Element;
}

let cancelSource = axios.CancelToken.source();

const LoginViaFaceID: React.FC<IProps> = ({ onSubmit = () => { }, onSuccess = () => { }, onError = () => { }, onFinally = () => { }, children }) => {
  const dispatch = useDispatch();

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const yup = yupCreator();
  const validationSchema = (
    yup.object().shape<FormValues>({
      serial: yup.string().required(),
      number: yup.number().required(),
      data: yup.string().required()
    })
  );

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if (!isSubmitting) {
      setSubmitting(true);
      cancelSource = axios.CancelToken.source();

      onSubmit();

      dispatch(Actions.LoginViaFaceID.request({
        values,
        cancelSource,
        callback: {
          onSuccess,
          onError: (error) => {
            setSubmitting(false);
            onError(error);
          },
          onFinally
        }
      }));
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        serial: undefined,
        number: undefined,
        data: undefined,
      }}
      validateOnMount
      enableReinitialize
      {...{ validationSchema }}
    >
      {(props: FormikProps<FormValues>) => (
        <Form style={{ height: '100%' }}>
          {children(props)}
        </Form>
      )}
    </Formik>
  );
};

export default LoginViaFaceID;