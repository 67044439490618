import React from 'react';
import { FieldHookConfig, useField } from 'formik';
import cx from 'classnames';

import * as Types from '../types';

import Field from '../Field';

import classes from '../Field.module.scss';

interface IProps extends Types.FieldProps {}

const Textarea: React.FC<IProps> = ({ placeholder, maxLength, showError = true, disabled, readOnly, ...props }) => {
  const [field, meta] = useField(props as FieldHookConfig<any>);

  const hasError = !!(showError && meta.touched && meta.error);

  return (
    <Field {...{ hasError, error: meta.error, disabled, readOnly, type: 'textarea', ...props }}>
      {({ onFocus, onBlur }) => (
        <textarea
          {...field}
          {...{ placeholder, maxLength, disabled, onFocus, readOnly }}
          id={field.name}
          value={field.value || ''}
          className={cx(classes.input, classes.textarea)}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          rows={4}
          onBlur={(e) => {
            field.onBlur(e);
            onBlur();
          }}
        />
      )}
    </Field>
  );
};

export default Textarea;