import React from 'react';
import cx from 'classnames';

import classes from './Table.module.scss';

interface IProps {
  category: string;
  src: string;
  className?: string;
  gradient?: {
    start: string;
    end: string;
  }
  children: React.ReactNode;
}

const TitleCell: React.FC<IProps> = ({ category, src, gradient, children, className }) => (
  <td
    className={cx(classes.cell, classes.titleCell, className)}
    style={gradient && { '--gradient-start': `${gradient.start}`, '--gradient-end': `${gradient.end}` } as React.CSSProperties}
  >
    <div className={classes.titleCellInner}>
      <div className={classes.titleCellImage}>
        <img {...{ src }} alt="table-image"/>
      </div>
      <div className={classes.titleCellContent}>
        <div className={classes.titleCellCategory}>{category}</div>
        <div className={classes.titleCellValue}>{children}</div>
      </div>
    </div>
  </td>
);

export default TitleCell;
