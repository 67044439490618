import React from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import classes from './FieldSwitch.module.scss';

interface IProps {
  name: string;
  label: string;
  value: string;
  disabled?: boolean;
  required?: boolean;
  isLoading?: boolean;
  onChange?: (value) => void;
}

const Switch: React.FC<IProps> = ({ label, value, disabled, required, isLoading, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  return (
    <label className={cx(classes.wrapper, disabled && classes.wrapperDisabled)}>
      <div className={classes.label} title={label}>{!!required && <span>*</span>}{label}</div>
      <div className={classes.inputWrapper}>
        <input
          {...{ disabled }}
          type="checkbox"
          className={classes.input}
          name={field.name}
          checked={field.value === value}
          value={value}
          onChange={() => helpers.setValue(!field.value ? value : '')}
        />
        <div className={classes.switcher}>
          <div className={classes.switcherDot}/>
        </div>
      </div>
      {hasError && (
        <div className={classes.errorLabel}>{meta.error}</div>
      )}
    </label>
  );
};

export default Switch;