import React, { useEffect, useRef, useState } from 'react';
import { useOuterClick } from 'react-outer-click';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import cx from 'classnames';
import get from 'lodash/get';

import { storage } from 'services';

import * as Icon from 'components/Icon';
import CheckboxBase from 'components/Fields/Checkbox/CheckboxBase';

import classes from './Accessibility.module.scss';

const Accessibility: React.FC = () => {
  const appearanceStorageState = storage.local.get('appearance');
  const imageStorageState = storage.local.get('appearance-image');
  const fontSizeStorageState = storage.local.get('appearance-fontSize');
  const voiceStorageState = storage.local.get('appearance-responsiveVoice');

  const [appearance, setAppearance] = useState(() => {
    if (['blackAndWhite', 'blackAndWhiteInvert'].includes(appearanceStorageState)) {
      return appearanceStorageState;
    }
    return '';
  });
  const [isImage, setImage] = useState(imageStorageState == null ? true : !!imageStorageState);
  const [fontSize, setFontSize] = useState(fontSizeStorageState == null ? 0 : Number(fontSizeStorageState || 0));
  const [isVoice, setVoice] = useState(voiceStorageState == null ? false : !!voiceStorageState);
  const isInitialMount = useRef(true);

  const [isVisible, setVisible] = useState(false);

  const el = useRef(null);
  useOuterClick(el, () => setVisible(false));

  useEffect(() => {
    const classes = ['blackAndWhite', 'blackAndWhiteInvert'];
    classes.filter(i => i !== appearance).forEach(i => {
      document.body.classList.remove(i);
    });
    if (appearance) {
      document.body.classList.add(appearance);
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    storage.local.set('appearance', appearance);
  }, [appearance])

  useEffect(() => {
    if (!isImage) {
      document.body.classList.add('noImage');
    } else {
      document.body.classList.remove('noImage');
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    storage.local.set('appearance-image', isImage);
  }, [isImage])

  useEffect(() => {
    document.body.style.zoom = fontSize ? `1.${fontSize || 0}` : '1';

    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    storage.local.set('appearance-fontSize', fontSize || 0);
  }, [fontSize]);

  useEffect(() => {
    document.onselectionchange = () => {
      // @ts-ignore
      const responsiveVoice = window.responsiveVoice
      if (responsiveVoice && responsiveVoice.voiceSupport()) {
        responsiveVoice.pause();
        responsiveVoice.cancel();

        if (isVoice) {
          let selection = document.getSelection();
          const value = get(selection, 'focusNode.data') || '';
          if (value) {
            responsiveVoice.speak(value, 'Russian Female');
          }
        }
      }
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    storage.local.set('responsiveVoice', isVoice);
  }, [isVoice])

  return (
    <div ref={el} className={cx(classes.wrapper, isVisible && classes.wrapperVisible)}>
      <div className={classes.button} onClick={() => setVisible((value) => !value)}>
        <Icon.System.Show />
      </div>
      <Transition
        in={isVisible}
        timeout={{ enter: 0, exit: 300 }}
        mountOnEnter
        unmountOnExit
      >
        {(state: TransitionStatus) => (
          <div className={classes.dropdown} style={{ ...transition[state] }}>
            <div className={classes.title}>Кўриниш</div>
            <div className={classes.appearanceList}>
              <div className={cx(classes.appearance, classes.appearanceNormal)} onClick={() => setAppearance('')}>A</div>
              <div className={cx(classes.appearance, classes.appearanceBlackAndWhite)} onClick={() => setAppearance('blackAndWhite')}>A</div>
              <div className={cx(classes.appearance, classes.appearanceDark)} onClick={() => setAppearance('blackAndWhiteInvert')}>A</div>
              <div className={cx(classes.appearance, classes.appearanceImage, isImage && classes.appearanceImageExists)} onClick={() => setImage((value) => !value)} />
            </div>

            <div className={classes.title}>Шрифт ўлчами</div>
            <div className={classes.fontSizeList}>
              {[...Array(3)].map((_, i) => {
                const number = i;
                return (
                  <div className={cx(classes.fontSizeItem, classes[`fontSizeItem-${number}`], fontSize === number && classes.fontSizeItemActive)} onClick={() => setFontSize(number)}>A</div>
                )
              })}
            </div>

            <div className={classes.title}>Экран сухандони</div>
            <div onClick={() => setVoice(value => !value)}>
              <CheckboxBase value={isVoice} onChange={() => { }} />
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

const transition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transform: 'translateX(-50%) translateY(0)' },
  exited: { opacity: 0, visibility: 'hidden' }
};

export default Accessibility;
