export enum STATUS {
  IN_PROCESS = 'IN_PROCESS',
  CORRECTION = 'CORRECTION',
  REVIEW = 'REVIEW',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  COMPLETED = 'COMPLETED',
  WAITING_TERMINATION = 'WAITING_TERMINATION',
  IN_PAYMENT = 'IN_PAYMENT',
}

export enum LIST {
  REQUEST = '@@APPLICATION/LIST/REQUEST',
  SUCCESS = '@@APPLICATION/LIST/SUCCESS',
  FAILURE = '@@APPLICATION/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@APPLICATION/SINGLE/REQUEST',
  SUCCESS = '@@APPLICATION/SINGLE/SUCCESS',
  FAILURE = '@@APPLICATION/SINGLE/FAILURE'
}

export const STORE_RESET = '@@STORE/RESET';