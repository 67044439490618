import React from 'react';
import cx from 'classnames';

import classes from './Breadcrumb.module.scss';

interface IProps {
  className?: string;
  variant?: 'light' | 'dark';
  children: React.ReactNode;
}

const List: React.FC<IProps> = ({ className, variant, children }) => (
  <div className={cx(classes.list, classes[`list--${variant}`], className)}>{children}</div>
);

export default List;