import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path xmlns="http://www.w3.org/2000/svg" d="M17.7545 14C18.9966 14 20.0034 15.0069 20.0034 16.2489V16.8243C20.0034 17.7186 19.6838 18.5834 19.1023 19.2628C17.5329 21.0963 15.1457 22.0012 12.0004 22.0012C8.8545 22.0012 6.46849 21.096 4.90219 19.2618C4.32242 18.5828 4.00391 17.7194 4.00391 16.8266V16.2489C4.00391 15.0069 5.01076 14 6.25278 14H17.7545ZM17.7545 15.5H6.25278C5.83919 15.5 5.50391 15.8353 5.50391 16.2489V16.8266C5.50391 17.3622 5.69502 17.8803 6.04287 18.2877C7.29618 19.7554 9.26206 20.5012 12.0004 20.5012C14.7387 20.5012 16.7063 19.7553 17.9627 18.2874C18.3117 17.8798 18.5034 17.3609 18.5034 16.8243V16.2489C18.5034 15.8353 18.1681 15.5 17.7545 15.5Z" fill="#202022"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0003 2.00439C14.7617 2.00439 17.0003 4.24297 17.0003 7.00439C17.0003 9.76582 14.7617 12.0044 12.0003 12.0044C9.23882 12.0044 7.00024 9.76582 7.00024 7.00439C7.00024 4.24297 9.23882 2.00439 12.0003 2.00439ZM8.50024 7.00439C8.50024 5.0714 10.0673 3.50439 12.0003 3.50439C13.9333 3.50439 15.5003 5.0714 15.5003 7.00439C15.5003 8.93739 13.9333 10.5044 12.0003 10.5044C10.0673 10.5044 8.50024 8.93739 8.50024 7.00439Z" fill="#34CEFF"/>
  </SvgIcon>
);
