import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

interface IProps {
  dsn?: string;
  environment?: string;
  release?: string;
}

export const init = ({ dsn, environment, release }: IProps) => {
  if (dsn) {
    Sentry.init({
      dsn,
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing()
      ],
      tracesSampleRate: 1.0,
      environment,
      release
    });
  }
};