import { AxiosPromise, CancelTokenSource } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ params, cancelSource }: { params: Types.IParams; cancelSource: CancelTokenSource; }): AxiosPromise<{ data: { content: Types.IApi.Notification[] } }> => {
  return http.request.get<{ data: { content: Types.IApi.Notification[] } }>(`/notification/public/push`, {
    params: {
      page: params.page - 1,
      size: params.limit
    },
    cancelToken: cancelSource.token
  });
};

export const UnReadCount = (): AxiosPromise<{ data: number }> => {
  return http.request.get<{ data: number }>(`/notification/public/push/count-not-read`);
};

export const MarkAsRead = ({ id }: { id: number }) => {
  return http.request.get(`/notification/public/push/make-as-read/${id}`);
};

export const SetToken = ({ token, language }: { token: string, language }) => {
  return http.request.post(`/notification/public/fcm-token`, {
    token,
    lang: language
  });
};