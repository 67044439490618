import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ params, filter }: Types.IApi.Act.Request): AxiosPromise<{ data: { acts: Types.IApi.Act.Response[] } }> => {
  return http.request.get<{ data: { acts: Types.IApi.Act.Response[] } }>(`/register/act/list`, {
    params: {
      ...filter,
      search: params.search,
      page: params.page - 1,
      size: params.limit,
      sort: 'id,desc'
    }
  });
};

export const Single = ({ id }): AxiosPromise<{ data: Types.IApi.Act.Response }> => {
  return http.request.get<{ data: Types.IApi.Act.Response }>(`/register/act/view?id=${id}`);
};