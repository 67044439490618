import { useQuery } from 'react-query';
import { Constants, Types } from '..';
import { StatByDocument } from '../api';
import { getCaptchaToken } from 'helpers/captcha';
import { get } from 'lodash';

interface IProps {
  document_id: number;
}
type IData = {
  statuses: Types.IEntity.Status;
  months: Types.IEntity.Month[];
  rating:{
    avg:number
    count:number
  }
};
const useByDocument = ({ document_id }: IProps) => {
  return useQuery<IData, string, IData>(
    [Constants.ENTITY, 'stat-by-document', document_id],
    async () => {
      const token = await getCaptchaToken();
      const { data } = await StatByDocument({ document_id, token });
      return get(data, 'data');
    },
    {
      enabled: !!document_id,
      initialData: {
        statuses: {
          all: 0,
          in_review: 0,
          auto_completed: 0,
          denied: 0,
          completed: 0
        },
        months: [],
        rating:{
          avg:0,
          count:0
        }
      }
    }
  );
};

export default useByDocument;
