import { createContext } from 'react';

import * as Types from './types';

export const initialState: Types.State = {
  id: 0,
  isVisible: false
};

const context = createContext<Types.Value>({
  state: { ...initialState },
  setState: () => null,
  methods: {
    setVisible: (id) => null,
    setClose: () => null
  }
});

export default context;