import get from 'lodash/get';
import moment from 'moment';

import * as Helpers from 'helpers';

import * as Types from './types';

export const documentList = (items: Types.IApi.Document.Response[]): Types.IEntity.Document[] => {
  return items.map(item => document(item));
};

export const document = (item: Types.IApi.Document.Response): Types.IEntity.Document => ({
  id: item.id || 0,
  category_id: item.category_id || 0,
  title: langStrings(item.title),
  description: langStrings(item.description),
  category: {
    title: langStrings(get(item, 'category.title')),
    image: get(item, 'category.icon_id'),
    gradient: {
      start: get(item, 'category.gradient_start_color'),
      end: get(item, 'category.gradient_end_color')
    }
  },
  applicant_types: get(item, 'applicant_types') || [],
  type: {
    key: get(item, 'type') || '',
    title: langStrings(item.type_title)
  },
  position: item.position || 0,
  tags: get(item, 'tags') || '',
  isDeleted: !!get(item, 'deleted'),
  status: !!get(item, 'status')
});

export const info = (item: Types.IApi.Info.Response): Types.IEntity.Info => {
  const document_date = get(item, 'document_date') ? moment(get(item, 'document_date')).format('DD.MM.YYYY') : '';
  const price = Number((get(item, 'service_cost') || '').replace(/\s/g, ''));
  const priceBegin = Number((get(item, 'service_cost_begin') || '').replace(/\s/g, ''));
  const fastReviewPrice = Number((get(item, 'review_fast_price') || '').replace(/\s/g, ''));

  console.log(item)

  return ({
    organization: langStrings(get(item, 'organization')),
    price: price ? Helpers.currency.format(price) : '',
    priceBegin: priceBegin ? Helpers.currency.format(priceBegin) : '',
    duration: get(item, 'expiry_date') ? Number(get(item, 'expiry_date')) : 0,
    applicantType: langStrings(get(item, 'applicant_type') || {}),
    applicantTypes: (get(item, 'applicant_types') || []).reduce((prev, curr) => curr ? ([...prev, String(curr)]) : ([...prev]), []),
    link: get(item, 'document_link') || '',
    review: {
      isWeekDays: !!get(item, 'review_week_days'),
      time: get(item, 'review_time') || 0,
      timeBegin: get(item, 'review_time_begin') || 0,
      fast_price: fastReviewPrice ? Helpers.currency.format(fastReviewPrice) : '0',
    },
    extra: (get(item, 'additional_data') || []).map(extra => ({
      title: langStrings(extra),
      position: get(extra, 'position') || 0
    })),
    type: {
      key: get(item, 'document.type') || '',
      title: langStrings(get(item, 'document.type_title'))
    },
    document: {
      date: document_date,
      number: get(item, 'document_number') || ''
    },
    category: {
      id: get(item, 'category_id') || 0,
      parent_id: get(item, 'category_parent_id') || 0
    },
    expiry: get(item, 'expiry') || "",
    handbookLink: get(item, 'handbook_link') || "",
    expire_date: get(item, 'expiry_date') ? String(get(item, 'expiry_date')) : '',
    expire_date_begin: get(item, 'expiry_date_begin') ? String(get(item, 'expiry_date_begin')) : '',
    usageFee: !!get(item, 'usageFee'),
    expire_date_name: langStrings(get(item, 'expiry_date_name')),
    state_duty_name: langStrings(get(item, 'state_duty_name')),
    duty: !!get(item, 'state_duty') && get(item, 'state_duty') !== '0' ? Helpers.currency.format(get(item, 'state_duty') || 0) : '',
    dutyBegin: !!get(item, 'state_duty_begin') && get(item, 'state_duty_begin') !== '0' ? Helpers.currency.format(get(item, 'state_duty_begin') || 0) : '',
    re_registration: (get(item, 're_registration_price') && Number(get(item, 're_registration_price'))) ? Helpers.currency.format(get(item, 're_registration_price') || 0) : '',
    automatic_give: get(item, 'automatic_give')
  });
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};

export const documentIdsByCategoryId = (items: Types.IApi.Document.Response[]): Types.IEntity.DocumentIdsByCategoryId => {
  let grouped = {};

  (items || []).forEach(item => {
    const id = get(item, 'id');
    const categoryId = get(item, 'category_id');
    if (id && categoryId) {
      grouped = {
        ...grouped,
        [String(categoryId)]: [...(get(grouped, `${categoryId}`) || []), id]
      };
    }

  });

  return grouped;
};