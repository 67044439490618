import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import get from 'lodash/get';

import * as Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import classes from './FieldFile.module.scss';

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  isLoading?: boolean
  file: {
    name: string;
  }
  onSelect: (file: File | undefined) => void;
  onView: (value: number) => void;
}

const File: React.FC<IProps> = ({ label, disabled, required, isLoading, file, onSelect, onView, ...props }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  const hasValue = !!field.value;

  const wrapperProps = {
    className: cx(classes.wrapper, disabled && classes['wrapper--disabled'], hasError && classes['wrapper--error'])
  };

  const Wrapper = ({ children }) => hasValue ? <div {...wrapperProps}>{children}</div> : <label {...wrapperProps}>{children}</label>;

  return (
    <Wrapper>
      {!!label && <div className={classes.label} title={label}>{!!required && <span>*</span>}{label}</div>}
      <div className={classes.wrapperInner}>
        {hasValue ? (
          <>
            <div className={classes.icon}>
              <Icon.System.Docs/>
            </div>
            <div className={classes.value}>{file.name}</div>
          </>
        ) : (
          <div className={classes.placeholder}>{t('select_file', { defaultValue: 'Выберите файл' })}</div>
        )}
        <div className={classes.buttonList}>
          {isLoading ? (
            <div className={classes.button}>
              <Spinner size={16}/>
            </div>
          ) : (
            <>
              {hasValue ? (
                <>
                  <div className={classes.button} onClick={() => helpers.setValue('')}>
                    <Icon.System.Delete/>
                  </div>
                  <div className={classes.button} onClick={() => onView(field.value)}>
                    <Icon.System.Show/>
                  </div>
                </>
              ) : (
                <div className={classes.button}>
                  <input
                    className={classes.input}
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelect(get(e, 'target.files[0]'))}
                  />
                  <Icon.System.Upload/>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {hasError && (
        <div className={classes.errorLabel}>{meta.error}</div>
      )}
    </Wrapper>
  );
};

export default File;