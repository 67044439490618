import { useEffect, useState } from 'react';
import get from 'lodash/get';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Types from '../types';

interface IProps {
  id: number;
  applicationId: number;
}

interface IReturn {
  isLoading: boolean;
  file: Types.IEntity.File;
}

const useFile = ({ id, applicationId }: IProps): IReturn => {
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState({
    id: 0,
    name: '',
    extension: '',
    size: 0
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      Api.FileInfo({ id, applicationId })
        .then(({ data }) => {
          const file = Mappers.file(get(data, 'data'));
          setFile(file);
        })
        .catch(() => {

        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return { isLoading, file };
};

export default useFile;