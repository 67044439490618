import React from 'react'
import { useMutation } from 'react-query'
import { http } from 'services'
import classes from "../ApplicationView.module.scss"
import { Nav } from 'components/Modal'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'

const Notification = ({ applicationId, text, onClose }: { applicationId: number, text: string; onClose(): void }) => {
    const history = useHistory()

    const { t, i18n } = useTranslation()
    const { mutate, isLoading } = useMutation({
        async mutationFn() {
            await http.request.post(`/external/notification/application-expired/${applicationId}`)
            return null
        },
        onSuccess() {
            history.push(`/application/${applicationId}`)
        },
        onError(e: any) {
            if (e.response.data) {
                toast.error(e.response.data?.message?.title[i18n.language])
            }
        }
    })
    return (
        <div className={classes.notification}>
            <Nav showClose variant='dark' />
            <div className={classes.notificationContent}>
                <b>
                    {
                        t('notification_alert', {
                            applicationId,
                            text
                        })
                    }
                </b>
            </div>
            <div className={classes.notificationFooter}>
                <Button variant="red" onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button variant="green" isLoading={isLoading} disabled={isLoading} onClick={() => mutate()}>
                    {t('continue')}
                </Button>
            </div>
        </div >
    )
}

export default Notification