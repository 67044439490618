import React from 'react';

interface IProps {
  serial: string;
  children: React.ReactNode;
}

const HumoOrVisa: React.FC<IProps> = ({ children, serial }) => (
  <form
    action="https://infopay.ofb.uz"
    method="POST"
    target="_blank"
    onSubmit={e => e.stopPropagation()}
    style={{
      width: '100%'
    }}
  >
    <input type="hidden" name="token" value="567n94f6521j648-58a23-r69l4-009-02" />
    <input type="hidden" name="invoice" value={serial} />
    <button
      type="submit"
      style={{
        width: '100%',
        textAlign: 'start'
      }}
    >
      {children}
    </button>
  </form>
);

export default HumoOrVisa;
