import React, { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import ScrollToErrorField from 'components/Fields/ScrollToError';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface FormValues extends Types.IForm.Update {}

interface IChildren extends FormikProps<FormValues> {
  handleBack: () => void;
}

interface IProps {
  applicationId: number;
  stepId: number;
  position: number;
  className?: string;
  onSuccess?: ({ process }: { process: Types.IEntity.Process; }) => void;
  onError?: (error: { message: string }) => void;
  onFinally?: () => void;

  children(props: IChildren): JSX.Element;
}

let cancelSource = axios.CancelToken.source();

const Update: React.FC<IProps> = ({ applicationId, stepId, position, className, onSuccess = () => {}, onError = () => {}, onFinally = () => {}, children }) => {
  const dispatch = useDispatch();
  const values = useSelector<Store.Types.IState, Types.IForm.Update>(state => Selectors.getValues(state, position));

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  let formFn: FormikProps<FormValues>;
  const bindForm = (form) => {
    formFn = form;
  };

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if (!isSubmitting) {
      setSubmitting(true);
      cancelSource = axios.CancelToken.source();

      dispatch(Actions.Update.request({
        applicationId,
        stepId,
        position,
        values,
        cancelSource,
        callback: {
          onSuccess,
          onError,
          onFinally: () => {
            setSubmitting(false);
            onFinally();
          }
        }
      }));
    }
  };

  const handleBack = () => {
    if(!position){
      return;
    }

    if(formFn){
      const { isSubmitting, setSubmitting } = formFn;

      if (!isSubmitting) {
        setSubmitting(true);
        cancelSource = axios.CancelToken.source();

        dispatch(Actions.Back.request({
          applicationId,
          stepId,
          position,
          changePosition: position - 1,
          cancelSource,
          callback: {
            onSuccess,
            onError,
            onFinally: () => {
              setSubmitting(false);
              onFinally();
            }
          }
        }));
      }
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ ...values }}
      validateOnMount
      enableReinitialize
    >
      {(props: FormikProps<FormValues>) => {
        bindForm(props);
        return (
          <Form {...{ className }}>
            <ScrollToErrorField/>
            {children({ ...props, handleBack })}
          </Form>
        )
      }}
    </Formik>
  );
};

export default Update;