import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import * as SystemModule from 'modules/system';

import { http } from 'services';

import ImageAuthInvoice from 'components/ImageAuth/Invoice';
import Button from 'components/Button';
import * as Icon from 'components/Icon';
import { Nav } from 'components/Modal';

import classes from './CashDesk.module.scss';

interface IProps {
  serial: string;
  close: () => void;
}

const CashDesk: React.FC<IProps> = ({ serial, close }) => {
  const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();

  const [isDownloading, setDownloading] = useState(false);
  const [isPrinting, setPrinting] = useState(false);

  const download = ({ id, onSuccess, onFinally }) => {
    http.request
      .get(`/receipt/${id}/pdf?language=${language}`, {
        baseURL: config.document.base_url,
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .finally(onFinally)
  };

  const downloadReceipt = (id) => {
    setDownloading(true);
    download({
      id,
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `receipt-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
      onFinally: () => setDownloading(false)
    });
  };

  const printReceipt = (id) => {
    setPrinting(true);
    download({
      id,
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
      onFinally: () => setPrinting(false)
    });
  };
  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={close} variant="light"/>
      <div className={classes.title}>{t('through_the_bank_cash_desk')}</div>
      <div className={classes.content}>
        <div className={classes.contentTitle}>{t('to_pay_through_the_bank_cashier_you_need')}</div>

        <div className={classes.step}>1 - {t('save_or_print_your_invoice')}</div>
        <div className={classes.step}>2 - {t('show_the_invoice_to_the_cashier')}</div>
        <div className={classes.step}>3 - {t('pay_the_invoice')}</div>
        <div className={classes.info}>{t('payment_info')}</div>

        <div className={classes.buttonList}>
          <Button variant="stroke" isLoading={isDownloading} disabled={isDownloading} prefixIcon={<Icon.System.Download/>} htmlType="button" onClick={() => downloadReceipt(serial)}>
            {t('save_invoice')}
          </Button>
          <Button variant="stroke" isLoading={isPrinting} disabled={isPrinting} className="m-l-12" prefixIcon={<Icon.System.Print/>} htmlType="button" onClick={() => printReceipt(serial)}>
            {t('print_invoice')}
          </Button>
        </div>
        <div className={classes.receipt}>
          <ImageAuthInvoice invoiceNumber={serial} {...{ language }} />
        </div>
      </div>
    </div>
  );
};

export default CashDesk;