import React from 'react';
import cx from 'classnames';

import StepBar from 'components/StepBar';

import classes from './Table.module.scss';

interface IProps {
  step: {
    current: number;
    total: number;
  }
  className?: string;
  children: React.ReactNode;
}

const StepCell: React.FC<IProps> = ({ step, children, className }) => (
  <div className={cx(classes.stepCell, className)}>
    <div className={classes.stepCellTitle}>{children}</div>
    <div className={classes.stepCellValue}>
      <StepBar {...step} size="small" variant="blue"/>
    </div>
  </div>
);

export default StepCell;
