import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ id, params }): AxiosPromise<{ data: { branches: Types.IApi.Branches.Response[] } }> => {
  return http.request.get<{ data: { branches: Types.IApi.Branches.Response[] } }>(`/register/branches`, {
    params: {
      register_id: id,
      ...params
    }
  });
};

export const OpenList = ({ uuid, token, params }): AxiosPromise<{ data: { branches: Types.IApi.OpenBranches.Response[] } }> => {
  return http.request.get<{ data: { branches: Types.IApi.OpenBranches.Response[] } }>(`/register/open_source/${uuid}/branches`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    },
    params: {
      ...params
    }
  });
};

export const Workers = ({ id }): AxiosPromise<{ data: { steps: Types.IApi.Workers.Response[] } }> => {
  return http.request.get<{ data: { steps: Types.IApi.Workers.Response[] } }>(`/register/branches/data/${id}`);
};

export const OpenWorkers = ({ id, token, uuid }): AxiosPromise<{ data: { steps: Types.IApi.OpenWorkers.Response[] } }> => {
  return http.request.get<{ data: { steps: Types.IApi.OpenWorkers.Response[] } }>(`/register/open_source/${uuid}/branches/${id}/data`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    },
  });
};