import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';

import * as Store from 'store';

import Schema from './schema';
import * as Types from './types';

const getEntities = (state: Store.Types.IState): Types.IEntities => state.notification.entities;

const getDenormalizedList = createSelector<Store.Types.IState, Types.IEntities, number[], Types.IEntity.Notification[]>(
  getEntities,
  (state) => state.notification.list.ids,
  (entities, ids) => {
    const normalized = denormalize(
      { 'notification': ids },
      { 'notification': [Schema] },
      { 'notification': entities }
    );
    return get(normalized, 'notification', []);
  }
);

const getSortedList = createSelector<Store.Types.IState, Types.IEntity.Notification[], Types.IEntity.Notification[]>(
  getDenormalizedList,
  (items) => {
    items = orderBy(items, ['unix'], ['desc']);
    return items;
  }
);

export const getGroupedList = createSelector<Store.Types.IState, Types.IEntity.Notification[], Types.IListItem[]>(
  getSortedList,
  (items) => {
    let curDate;
    let prevDate;
    return items.filter(i => i).reduce<Types.IListItem[]>((acc, item) => {
      curDate = item.date.day;
      const shouldSetDateRow = (!prevDate || (prevDate && prevDate !== curDate));
      if (shouldSetDateRow) {
        acc.push({ type: 'day', value: curDate });
      }
      acc.push({ type: 'notification', value: item });
      prevDate = curDate;
      return acc;
    }, []);
  }
);