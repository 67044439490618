import * as yup from 'yup';

import i18n from '../i18n';

export default () => {
  const translations = {
    mixed: {
      required: i18n.t('field_validation_required'),
      oneOf: 'Не соответствует новому паролю'
    },
    string: {
      required: i18n.t('field_validation:required'),
      min: i18n.t('field_validation_min_length', { value: '${min}' })
    },
    number: {
      min: i18n.t('field_validation:amount_min_limit', { amount: '${min}' }),
      max: i18n.t('field_validation:amount_max_limit', { amount: '${max}' })
    }
  };

  yup.setLocale(translations);

  return yup;
};