import React from 'react';
import cx from 'classnames';

import * as Icon from 'components/Icon';

import classes from './FieldCheckBox.module.scss';

interface IProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  hasError?: boolean;
}

const CheckboxBase: React.FC<IProps> = ({ value, onChange, disabled, hasError }) => {
  return (
    <div
      className={cx(
        classes.wrapper,
        value && classes.wrapperActive,
        disabled && classes.wrapperDisabled,
        hasError && classes.wrapperError
      )}
    >
      <input
        {...{ disabled }}
        className={classes.input}
        type="checkbox"
        checked={value}
        onChange={e => onChange(e.target.checked)}
      />
      <div className={classes.icon}>
        <Icon.System.Check />
      </div>
    </div>
  );
};

export default CheckboxBase;
