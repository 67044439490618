import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';
import { useEffect } from 'react';

const useUser = (): Types.IEntity.User => {
  const dispatch = useDispatch();

  const user = useSelector<Store.Types.IState, Types.IEntity.User>(state => state.stats.stats.user);

  useEffect(() => {
    dispatch(
      Actions.User.request()
    );
  }, []);

  return user;
};

export default useUser;
