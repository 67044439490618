import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';

interface IProps {
  code: string;
  state: string;
  clientType?: string;
  serviceId?: string;
}

interface IReturn {
  isAuthenticated: boolean;
}

const useLogin = ({ code, state, clientType, serviceId }: IProps): IReturn => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector<Store.Types.IState, boolean>(state => state.auth.isAuthenticated);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.auth.isFetched);

  useEffect(() => {
    if (!isFetched) {
      return;
    }
    if (!!code && !!state) {
      dispatch(Actions.Login.request({
        values: {
          code: String(code),
          state: String(state),
          clientType,
          serviceId
        }
      }));
    }
  }, [code, state]);

  return {
    isAuthenticated
  }
};

export default useLogin;