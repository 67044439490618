import store from 'store2';
import cookie from 'js-cookie';

const storageAPI = (type: 'local' | 'session') => ({
  get: (key: string) => {
    return store[type].get(key);
  },
  set: (key: string, value: any) => {
    return store[type].set(key, value);
  },
  remove: (key: string) => {
    return store[type].remove(key);
  }
});

const cookieAPI = () => {
  const attributes = {
    // domain: process.env.REACT_APP_ENV !== 'development' ? '.upay.uz' : undefined,
    secure: process.env.REACT_APP_ENV !== 'development',
    expires: process.env.REACT_APP_ENV !== 'development' ? (1 / 24 / 4) : 1
  };

  return ({
    get: (key: string) => {
      return cookie.get(key);
    },
    set: (key: string, value: any, expires: number = attributes.expires) => {
      return cookie.set(key, value, { ...attributes, expires });
    },
    remove: (key: string) => {
      return cookie.remove(key, attributes);
    }
  });
};

const storage = {
  local: storageAPI('local'),
  session: storageAPI('session'),
  cookie: cookieAPI()
};

export default storage;