import React from 'react';
import get from 'lodash/get';

import * as CategoryModule from 'modules/category';
import * as DocumentModule from 'modules/document';
import * as StepperModule from 'modules/stepper';

import SubCategory from './SubCategory';
import Documents from './Documents';
import DocumentView from './DocumentView';

import * as Hooks from '../../hooks';

const View: React.FC = () => {
  const { state: { categoryId, isSubCategorySelected, isDocumentSelected } } = CategoryModule.Context.Single.useContext();
  const category = Hooks.useSingle({ id: categoryId });

  return (
    <DocumentModule.Context.Single.Provider>
      <StepperModule.Provider
        firstStepKey={(!(get(category, 'children.length'))) ? 'documents' : 'subCategory'}
        steps={{
          subCategory: {
            next: 'documents',
            component: (
              <SubCategory isSelected={isSubCategorySelected}/>
            )
          },
          documents: {
            prev: !!get(category, 'children.length') ? 'subCategory' : '',
            next: 'documentView',
            component: (
              <Documents isSelected={isDocumentSelected}/>
            )
          },
          documentView: {
            prev: 'documents',
            component: (
              <DocumentView/>
            )
          }
        }}
      />
    </DocumentModule.Context.Single.Provider>
  );
};

export default View;