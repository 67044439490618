import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Types from './types';

export const instruction = (item: Types.IApi.Instruction.Response): Types.IEntity.Instruction => {
  const images = orderBy(get(item, 'image_files') || [], ['fileId'], ['desc']);

  return ({
    id: get(item, 'id') || 0,
    position: get(item, 'position') || 0,
    title: langString(item, 'title'),
    short_content: langString(item, 'short_content'),
    date: get(item, 'updated_at', '') || '',
    image: get(images, '[0].download_uri') || '',
    contents: orderBy((get(item, 'instruction_content_dtos') || []).map(s => instructionContent(s)) || [], ['position', 'id'], ['asc', 'asc'])
  });
};

export const langString = (item?: Types.IApi.Instruction.Response | null, fieldName?: string): Types.IEntity.LangStrings => {
  return {
    ru: get(item, `${fieldName}_ru`, '') || '',
    uz: get(item, `${fieldName}_uz`, '') || '',
    oz: get(item, `${fieldName}_oz`, '') || '',
    en: get(item, `${fieldName}_en`, '') || ''
  };
};

export const instructionContent = (item?: Types.IApi.InstructionContent | null): Types.IEntity.InstructionContent => {
  return {
    id: get(item, 'id') || 0,
    subTitle: langString(item, 'sub_title'),
    content: langString(item, 'content')
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? (get(item, 'currentPage') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};
