import get from 'lodash/get';

import * as Types from './types';

export const act = (item: Types.IApi.Act.Response): Types.IEntity.Act => ({
  id: get(item, 'id') || 0,
  key: get(item, 'category.key') || '',
  title: langStrings(get(item, 'category.title')),
  applicationId: get(item, 'application_id') || 0,
  number: get(item, 'number') || '',
  date: get(item, 'date') || '',
  file: get(item, 'file.download_uri') || '',
  fileName: get(item, 'file.name') || '',
  organization: langStrings(get(item, 'register.organization')),
  document: langStrings(get(item, 'register.document')),
  category: {
    title: langStrings(get(item, 'register.category.title')),
    image: get(item, 'register.category.icon_id') || '',
    gradient: {
      start: get(item, 'register.category.gradient_start_color') || '',
      end: get(item, 'register.category.gradient_end_color') || ''
    }
  },
  type: {
    key: get(item, 'register.type') || '',
    title: langStrings(get(item, 'register.type_title'))
  },
  conclusionFile: {
    downloadLink: get(item, 'conclusion_file.download_uri') || '',
    fileId: get(item, 'conclusion_file.fileId') || '',
    name: get(item, 'conclusion_file.name') || '',
  },
  canAnswer: !!get(item, 'response'),
  expiryDate: get(item, 'expiry_date'),
  register_number: get(item, 'register.number'),
  registration_date: get(item, 'registration_date'),
  response: {
    files: (get(item, 'response_files') || []).map(item => ({
      id: get(item, 'fileId'),
      name: get(item, 'name')
    })),
    text: get(item, 'response_text') || '',
    date: get(item, 'response_date') || '',
  }
});

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? ((get(item, 'currentPage') || 0) + 1) : 1,
    total: get(item, 'totalPages') || 1
  };
};