import { action } from 'typesafe-actions';

import * as Constants from './constants';
import * as Types from './types';

export const Entities = (args: Types.IAction.Entities) => action(Constants.ENTITIES, args);

export const List = {
  request: (args: Types.IAction.List.Request) => action(Constants.LIST.REQUEST, args),
  success: (args: Types.IAction.List.Success) => action(Constants.LIST.SUCCESS, args),
  failure: (args: Types.IAction.List.Failure) => action(Constants.LIST.FAILURE, args)
};

export const UnReadCount = {
  request: (args?: Types.IAction.UnReadCount.Request) => action(Constants.UN_READ_COUNT.REQUEST, args),
  success: (args: Types.IAction.UnReadCount.Success) => action(Constants.UN_READ_COUNT.SUCCESS, args),
  failure: (args: Types.IAction.UnReadCount.Failure) => action(Constants.UN_READ_COUNT.FAILURE, args)
};

export const MarkAsRead = {
  request: (args: Types.IAction.MarkAsRead.Request) => action(Constants.MARK_AS_READ.REQUEST, args),
  success: (args?: Types.IAction.MarkAsRead.Success) => action(Constants.MARK_AS_READ.SUCCESS, args),
  failure: (args: Types.IAction.MarkAsRead.Failure) => action(Constants.MARK_AS_READ.FAILURE, args)
};

export const RequestPermission = {
  request: (args?: Types.IAction.RequestPermission.Request) => action(Constants.REQUEST_PERMISSION.REQUEST, args),
  success: (args?: Types.IAction.RequestPermission.Success) => action(Constants.REQUEST_PERMISSION.SUCCESS, args),
  failure: (args: Types.IAction.RequestPermission.Failure) => action(Constants.REQUEST_PERMISSION.FAILURE, args)
};

export const SetToken = {
  request: (args?: Types.IAction.SetToken.Request) => action(Constants.SET_TOKEN.REQUEST, args),
  success: (args?: Types.IAction.SetToken.Success) => action(Constants.SET_TOKEN.SUCCESS, args),
  failure: (args: Types.IAction.SetToken.Failure) => action(Constants.SET_TOKEN.FAILURE, args)
};

export const DeleteToken = {
  request: (args?: Types.IAction.DeleteToken.Request) => action(Constants.DELETE_TOKEN.REQUEST, args),
  success: (args?: Types.IAction.DeleteToken.Success) => action(Constants.DELETE_TOKEN.SUCCESS, args),
  failure: (args: Types.IAction.DeleteToken.Failure) => action(Constants.DELETE_TOKEN.FAILURE, args)
};

export const onMessage = (args: Types.IAction.onMessage) => action(Constants.ON_MESSAGE, args);
export const RemoveMessage = (args: Types.IAction.RemoveMessage) => action(Constants.REMOVE_MESSAGE, args);

export const StoreReset = () => action(Constants.STORE_RESET);