import React, { useState } from 'react';

import * as LanguageSwitcher from 'components/LanguageSwitcher';

import * as Hooks from '../hooks';
import * as Constants from '../constants';

const Language: React.FC = () => {
  const { language, methods } = Hooks.useLanguage();

  const [isOpen, setOpen] = useState(false);

  return (
    <LanguageSwitcher.List
      current={Constants.LANGUAGES_INFO[language]?.title}
      isOpen={isOpen}
      onClick={() => setOpen(state => !state)}
      close={() => setOpen(false)}
    >
      {Object.values(Constants.LANGUAGES_INFO).filter(l => l.key !== language).map(language => (
        <LanguageSwitcher.Item
          key={language.key}
          onClick={() => {
            setOpen(state => !state);
            methods.set(language.key);
          }}
        >
          {language.title}
        </LanguageSwitcher.Item>
      ))}
    </LanguageSwitcher.List>
  );
};

export default Language;