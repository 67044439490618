import { takeLatest, call, put, all } from 'redux-saga/effects';
import { normalize, NormalizedSchema } from 'normalizr';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import Schema from './schema';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { callback, token } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.Organization[] }> = yield call(Api.List, { token });
    const items = (get(data, 'data') || []).map(application => Mappers.organization(application));
    const { entities, result }: NormalizedSchema<{ organization: Types.IEntities }, number[]> = normalize(items, [Schema]);
    yield put(Actions.Entities({ items: entities?.organization }));
    yield put(Actions.List.success({ ids: result }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.List.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Single(action: ReturnType<typeof Actions.Single.request>) {
  const { id, callback, token } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.Organization }> = yield call(Api.Single, { id, token });
    const item = Mappers.organization((get(data, 'data') || {}));
    const { entities, result }: NormalizedSchema<{ organization: Types.IEntities }, number> = normalize(item, Schema);
    yield put(Actions.Entities({ items: entities?.organization }));
    yield put(Actions.Single.success({ id: result }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { item });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Single.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List),
    takeLatest(Constants.SINGLE.REQUEST, Single)
  ]);
}