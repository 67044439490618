import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import trim from 'lodash/trim';
import includes from 'lodash/includes';
import lowerCase from 'lodash/lowerCase';

import * as Store from 'store';

import * as Mappers from './mappers';
import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => state.organization.entities;

const getDenormalizedList = createSelector<Store.Types.IState, Types.IEntities, number[], Types.IEntity.Organization[]>(
  getEntities,
  (state) => get(state, `organization.list.ids`),
  (entities, ids) => {
    const items = denormalize(
      { 'organization': ids },
      { 'organization': [Schema] },
      { 'organization': entities }
    );
    return get(items, 'organization', []) || [];
  }
);

const getSortedList = createSelector<Store.Types.IState, Partial<Types.IParams>, Types.IEntity.Organization[], Partial<Types.IParams>, Types.IEntity.Organization[]>(
  getDenormalizedList,
  (_, params) => params,
  (items, params) => {

    items = items.filter(item => item.status);
    items = orderBy(items, ['id'], ['desc']);

    if(params){
      if(params.search){
        const search = trim(lowerCase(params.search));
        items = items.filter(item => (
          includes(trim(lowerCase(get(item, 'name.uz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'name.oz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'name.ru') || '')), search) ||
          includes(trim(lowerCase(get(item, 'name.en') || '')), search) ||
          includes(trim(lowerCase(get(item, 'address.uz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'address.oz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'address.ru') || '')), search) ||
          includes(trim(lowerCase(get(item, 'address.en') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.name.uz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.name.oz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.name.ru') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.name.en') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.position.uz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.position.oz') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.position.ru') || '')), search) ||
          includes(trim(lowerCase(get(item, 'director.position.en') || '')), search)
        ))
      }

      if(params.sort && params.sort.key && params.sort.value){
        let key = '';
        switch (params.sort.key) {
          case 'rating':
            key = 'stats.rate.avg';
            break;
          case 'received':
            key = 'stats.application.total';
            break;
          case 'reviewing':
            key = 'stats.application.reviewing';
            break;
          case 'rejected':
            key = 'stats.application.rejected';
            break;
          case 'done':
            key = 'stats.application.done';
            break;
        }
        if(key){
          items = orderBy(items, [key, 'id'], [params.sort.value ? 'desc' : 'asc', 'desc']);
        }
      }
    }

    return items;
  }
);

export const getList = createSelector<Store.Types.IState, Partial<Types.IParams>, Types.IEntity.Organization[], Types.IEntity.Organization[]>(
  getSortedList,
  (items) => items
);

export const getSingle = createSelector<Store.Types.IState, number, Types.IEntities, number, Types.IEntity.Organization>(
  getEntities,
  (_, id) => id,
  (entities, id) => {
    let item = get(entities, `${id}`);
    if(item){
      return item;
    }
    return Mappers.organization();
  }
);
