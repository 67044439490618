import get from 'lodash/get';

import * as Types from './types';

export const staticPage = (item: Types.IApi.StaticPage.Response): Types.IEntity.StaticPage => {
  return {
    id: get(item, 'id') || 0,

    title: langString(item, 'title'),
    description: langString(item, 'description'),
    slug: get(item, 'slug') || '',
    status: get(item, 'number') || 0,
    created: { date: get(item, 'created_at', '') || '', by: '' + get(item, 'created_by_id', '') || '' }
  };
};

export const langString = (
  item?: Types.IApi.StaticPage.Response | null,
  fieldName?: string
): Types.IEntity.LangStrings => {
  return {
    ru: get(item, `${fieldName}_ru`, '') || '',
    uz: get(item, `${fieldName}_uz`, '') || '',
    oz: get(item, `${fieldName}_oz`, '') || '',
    en: get(item, `${fieldName}_en`, '') || ''
  };
};
