import { action } from 'typesafe-actions';

import * as Constants from './constants';
import * as Types from './types';

export const StoreReset = () => action(Constants.STORE_RESET);

export const Start = {
  request: (args: Types.IAction.Start.Request) => action(Constants.START.REQUEST, args),
  success: (args: Types.IAction.Start.Success) => action(Constants.START.SUCCESS, args),
  failure: (args: Types.IAction.Start.Failure) => action(Constants.START.FAILURE, args)
};

export const StartByRegister = {
  request: (args: Types.IAction.StartByRegister.Request) => action(Constants.START_BY_REGISTER.REQUEST, args),
  success: (args: Types.IAction.StartByRegister.Success) => action(Constants.START_BY_REGISTER.SUCCESS, args),
  failure: (args: Types.IAction.StartByRegister.Failure) => action(Constants.START_BY_REGISTER.FAILURE, args)
};

export const StartByBranch = {
  request: (args: Types.IAction.StartByBranch.Request) => action(Constants.START_BY_BRANCH.REQUEST, args),
  success: (args: Types.IAction.StartByBranch.Success) => action(Constants.START_BY_BRANCH.SUCCESS, args),
  failure: (args: Types.IAction.StartByBranch.Failure) => action(Constants.START_BY_BRANCH.FAILURE, args)
};

export const StartByBranchSingle = {
  request: (args: Types.IAction.StartByBranchSingle.Request) => action(Constants.START_BY_BRANCH_SINGLE.REQUEST, args),
  success: (args: Types.IAction.StartByBranchSingle.Success) => action(Constants.START_BY_BRANCH_SINGLE.SUCCESS, args),
  failure: (args: Types.IAction.StartByBranchSingle.Failure) => action(Constants.START_BY_BRANCH_SINGLE.FAILURE, args)
};

export const Update = {
  request: (args: Types.IAction.Update.Request) => action(Constants.UPDATE.REQUEST, args),
  success: (args: Types.IAction.Update.Success) => action(Constants.UPDATE.SUCCESS, args),
  failure: (args: Types.IAction.Update.Failure) => action(Constants.UPDATE.FAILURE, args)
};

export const Back = {
  request: (args: Types.IAction.Back.Request) => action(Constants.BACK.REQUEST, args),
  success: (args: Types.IAction.Back.Success) => action(Constants.BACK.SUCCESS, args),
  failure: (args: Types.IAction.Back.Failure) => action(Constants.BACK.FAILURE, args)
};

export const Resume = {
  request: (args: Types.IAction.Resume.Request) => action(Constants.RESUME.REQUEST, args),
  success: (args: Types.IAction.Resume.Success) => action(Constants.RESUME.SUCCESS, args),
  failure: (args: Types.IAction.Resume.Failure) => action(Constants.RESUME.FAILURE, args)
};

export const Cancel = {
  request: (args: Types.IAction.Cancel.Request) => action(Constants.CANCEL.REQUEST, args),
  success: (args?: Types.IAction.Cancel.Success) => action(Constants.CANCEL.SUCCESS, args),
  failure: (args: Types.IAction.Cancel.Failure) => action(Constants.CANCEL.FAILURE, args)
};

export const Specialization = {
  request: (args: Types.IAction.Specialization.Request) => action(Constants.SPECIALIZATION.REQUEST, args),
  success: (args: Types.IAction.Specialization.Success) => action(Constants.SPECIALIZATION.SUCCESS, args),
  failure: (args: Types.IAction.Specialization.Failure) => action(Constants.SPECIALIZATION.FAILURE, args)
};

export const Requirements = {
  request: (args: Types.IAction.Requirements.Request) => action(Constants.REQUIREMENTS.REQUEST, args),
  success: (args: Types.IAction.Requirements.Success) => action(Constants.REQUIREMENTS.SUCCESS, args),
  failure: (args: Types.IAction.Requirements.Failure) => action(Constants.REQUIREMENTS.FAILURE, args)
};

export const ActionEvent = {
  request: (args: Types.IAction.ActionEvent.Request) => action(Constants.ACTION_EVENT.REQUEST, args),
  success: (args: Types.IAction.ActionEvent.Success) => action(Constants.ACTION_EVENT.SUCCESS, args),
  failure: (args: Types.IAction.ActionEvent.Failure) => action(Constants.ACTION_EVENT.FAILURE, args)
};