
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';

export function* ByUuid(action: ReturnType<typeof Actions.ByUuid.request>) {
    const { values, token, callback } = action.payload;
    try {
        const { data }: AxiosResponse<{ data: { data: unknown } }> = yield call(Api.ByUuid, { values, token });


        yield put(Actions.ByUuid.success());
        if (callback?.onSuccess) {
            yield call(callback.onSuccess, get(data, 'uuid'));
        }
    } catch (error) {
        const errorResponse = error as AxiosError;
        const errorMessage = errorResponse?.response?.data?.message;
        yield put(Actions.ByUuid.failure({ error: errorMessage }));
        if (callback?.onError) {
            yield call(callback.onError, errorMessage);
        }
    } finally {
        if (callback?.onFinally) {
            yield call(callback.onFinally);
        }
    }
}


export function* ByNumber(action: ReturnType<typeof Actions.ByNumber.request>) {
    const { values, token, callback } = action.payload;

    try {
        const { data }: AxiosResponse<{ data: { data: unknown } }> = yield call(Api.ByNumber, { values, token });

        console.log(data)

        yield put(Actions.ByNumber.success());
        if (callback?.onSuccess) {
            yield call(callback.onSuccess, get(data, 'data.register_id'));
        }
    } catch (error) {
        const errorResponse = error as AxiosError;
        const errorMessage = errorResponse?.response?.data?.message;
        yield put(Actions.ByNumber.failure({ error: errorMessage }));
        if (callback?.onError) {
            yield call(callback.onError, errorMessage);
        }
    } finally {
        if (callback?.onFinally) {
            yield call(callback.onFinally);
        }
    }
}

export default function* root() {
    yield all([
        takeLatest(Constants.BY_NUMBER.REQUEST, ByNumber),
        takeLatest(Constants.BY_UUID.REQUEST, ByUuid),
    ]);
}