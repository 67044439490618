import React from 'react';
import get from 'lodash/get';
import HtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';

import * as ProcessModule from 'modules/process';
import * as SystemModule from 'modules/system';

import classes from './DocumentInfo.module.scss';

interface IProps {
  documentId: number;
  specializationIds: string[];
}

const Requirements: React.FC<IProps> = ({ documentId, specializationIds }) => {
  const { language } = SystemModule.Hooks.useLanguage();
  const { t } = useTranslation();

  const requirements = ProcessModule.Hooks.useRequirements({ documentId, specializationIds });

  return (
    <>
      {!!requirements.items.length && (
        <div className={classes.requirements}>
          <div className={classes.requirementsTitle}>{t('Requirements')}</div>
          <div className={classes.list}>
            {orderBy(requirements.items, ['position'], ['asc']).map((item, i) => (
              <div key={item.id} className={classes.listItem}>
                <div className={classes.listItemNumber}>{i + 1}</div>
                <div className={classes.listItemContent}>{HtmlParser(get(item, `content[${language}]`))}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Requirements;