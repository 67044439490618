import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6 19H3a1 1 0 01-1-1V8a1 1 0 011-1h3V3a1 1 0 011-1h10a1 1 0 011 1v4h3a1 1 0 011 1v10a1 1 0 01-1 1h-3v2a1 1 0 01-1 1H7a1 1 0 01-1-1v-2zm0-2v-1a1 1 0 011-1h10a1 1 0 011 1v1h2V9H4v8h2zM8 4v3h8V4H8zm0 13v3h8v-3H8z"/>
    <path fill="#34CEFF" d="M8 10H5v2h3v-2z"/>
    <path fill="#34CEFF" fillRule="evenodd" d="M18 17v4a1 1 0 01-1 1H7a1 1 0 01-1-1v-5a1 1 0 011-1h10a1 1 0 011 1v1zM8 20v-3h8v3H8z" clipRule="evenodd"/>
  </SvgIcon>
);