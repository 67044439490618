import React from 'react'
import classes from "../RegistryView.module.scss"
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import cx from 'classnames';
import useWorkers from 'modules/branches/hooks/useOpenWorkers';
import * as Details from 'components/WorkersDetails';
import Spinner from 'components/Spinner';
import * as Icon from 'components/Icon';
import HtmlParser from 'react-html-parser';
import { Nav } from 'components/Modal';
import Download from 'components/Download';

const WorkersModal = ({ id, uuid, close }: { id: string, uuid: string, close?: () => void }) => {
    const { t, i18n } = useTranslation()
    const { items, isLoaded, isFetched } = useWorkers({
        id: Number(id),
        uuid,
    })

    const getFieldValue = (field, position = 0) => {
        let ans: any = '';

        switch (field.type) {
            case 'DROP_DOWN':
            case 'RADIO_BUTTON':
            case 'SPECIALIZATIONS':
                ans = get(field, `value[${i18n.language}]`)

                break;
            case 'CHECK_BOX':
                ans = field?.duplicable_values?.length
                    ? field?.duplicable_values
                        .sort((a, b) => a - b)
                        .map(value =>
                            value.option ? (
                                <div key={value}>
                                    {value.option[i18n.language]}
                                    <br />
                                </div>
                            ) : (
                                ''
                            )
                        )
                    : '';
                break;
            case 'FILE':
                ans =
                    field.value && field.value !== '[]' ? (
                        <Download id={field.value} applicationId={+id} />
                    ) : (
                        ''
                    );
                break;
            case 'MAP':
                const [latitude, longitude] = field.value ? field.value.split(':') : [];
                ans =
                    field.value && field.value !== '[]' ? (
                        <a
                            href={`https://www.google.com/maps/place/${latitude}+${longitude}/@${latitude},${longitude},10z`}
                            target="_blank"
                            className={classes.fieldMap}
                        >
                            <div className={classes.fieldFileTitle}>Google Maps</div>
                            <div className={classes.fieldFileIcon}>
                                <Icon.System.Pin />
                            </div>
                        </a>
                    ) : (
                        ''
                    );
                break;
            case 'TEXT_EDITOR': {
                return <div>{HtmlParser(field.value)}</div>;
            }
            case "SUB_GROUP":
                for (var i = 0; i < get(field, 'duplicable_values.length'); i++) {
                    let length = get(field, `duplicable_values[${i}][duplicable_values][${position}][duplicable_values].length`) || 0

                    ans = [...Array(length)].map((_, idx) => {
                        // eslint-disable-next-line array-callback-return
                        return <div key={idx} className={classes.sub_group}>
                            {
                                get(field, `duplicable_values`).map(obj => {
                                    const items = (get(obj, `duplicable_values[${position}][duplicable_values]`) || []).filter(
                                        option => option.position === idx + 1
                                    );

                                    return (
                                        <Details.Item
                                            className={cx(obj.type === 'TEXT_BLOCK' && 'textBlock')}
                                            title={obj.title[i18n.language] || ''}
                                        >
                                            {items.length > 1 ? (
                                                <>
                                                    {items.map((item, i) => (
                                                        <div className={i > 0 ? 'm-t-12' : ''}>
                                                            <div>{getFieldValue({ ...item, type: obj.type })}</div>
                                                            {get(item, `description[${i18n.language}]`) && (
                                                                <div style={{ color: 'var(--content-secondary)' }}>
                                                                    {get(item, `description[${i18n.language}]`)}
                                                                    <br />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <>{getFieldValue({ ...(get(items, '0') || {}), type: obj.type })}</>
                                            )}
                                        </Details.Item>
                                    );

                                })
                            }
                        </div>

                    })
                }
                break;
            default:
                ans = field.value;
        }

        return ans !== '[]' ? ans : '';
    };


    if (!isLoaded && !isFetched) {
        return <Spinner />
    }

    return (
        <>
            <Nav showClose onClose={close} />
            <div className={classes.workers}>
                {(Array.isArray(items) && !!items.length) ?
                    items
                        .filter(step => step.type !== 'PAYMENT')
                        .map((step, idx) => {

                            return (
                                <>
                                    {step.groups.map((group, index) => {
                                        if (group.group_duplicate) {
                                            let length = get(group, 'fields.0.duplicable_values.length') || 1;

                                            return [...Array(length)].map((item, idx) => {

                                                return (
                                                    <Details.Wrapper key={index} title={idx === 0 && group.title[i18n.language]} className="m-b-32">
                                                        {group.fields.map((field, index) => {
                                                            const items = (get(field, `duplicable_values`) || []).filter(
                                                                item => item.position === idx + 1
                                                            );

                                                            if (field.type === 'SUB_GROUP') {
                                                                return (
                                                                    <>
                                                                        <Details.Item
                                                                            className={cx(field.type === 'SUB_GROUP' && 'textBlock')}
                                                                            key={idx}
                                                                            title={field.title[i18n.language] || ''}
                                                                        />
                                                                        {getFieldValue({ ...field, type: field.type }, idx)}
                                                                    </>
                                                                );
                                                            }

                                                            return (
                                                                <Details.Item
                                                                    className={cx(field.type === 'TEXT_BLOCK' && 'textBlock')}
                                                                    key={idx}
                                                                    title={field.title[i18n.language] || ''}
                                                                >
                                                                    {items.length > 1 ? (
                                                                        <>
                                                                            {items.map((item, i) => (
                                                                                <div className={i > 0 ? 'm-t-12' : ''}>
                                                                                    <div>{getFieldValue({ ...item, type: field.type })}</div>
                                                                                    {get(item, `description[${i18n.language}]`) && (
                                                                                        <div style={{ color: 'var(--content-secondary)' }}>
                                                                                            {get(item, `description[${i18n.language}]`)}
                                                                                            <br />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {getFieldValue({ ...(get(items, '0') || {}), type: field.type })}
                                                                        </>
                                                                    )}
                                                                </Details.Item>
                                                            );
                                                        })}
                                                    </Details.Wrapper>
                                                );
                                            });
                                        }

                                        return (
                                            <Details.Wrapper title={group.title[i18n.language]} className="m-b-32">
                                                {group.fields.map((field, idx) => {
                                                    const description = get(field, `description[${i18n.language}]`) || '';

                                                    return (
                                                        <>
                                                            {step.type === 'FORM' ? (
                                                                <Details.Item
                                                                    className={cx(field.type === 'TEXT_BLOCK' && 'textBlock')}
                                                                    key={idx}
                                                                    title={field.title[i18n.language] || ''}
                                                                >
                                                                    {getFieldValue(field)}
                                                                </Details.Item>
                                                            ) : step.type === 'REQUIREMENTS' ? (
                                                                <Details.Requirement
                                                                    key={idx}
                                                                    title={getFieldValue({ ...field, type: 'DROP_DOWN' })}
                                                                />
                                                            ) : (
                                                                <Details.Requirement key={idx} position="specialization">
                                                                    <>
                                                                        {getFieldValue({ ...field, type: 'DROP_DOWN' })}
                                                                        {!!description && <div>{description}</div>}
                                                                    </>
                                                                </Details.Requirement>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </Details.Wrapper>
                                        );
                                    })}
                                </>
                            );
                        }) : (
                        <h4 className={classes.empty}>
                            {t('empty_content_not_data')}
                        </h4>
                    )}
            </div>
        </>

    )
}

export default WorkersModal