import React, { useState } from 'react'
import classes from "./Download.module.scss"
import { useTranslation } from 'react-i18next'

import * as Helpers from 'helpers';
import * as Icon from 'components/Icon';

interface IProps {
    id: string,
    applicationId: number
}

const Download: React.FC<IProps> = ({ id, applicationId }) => {
    const [percent, setPercent] = useState<number>(0)
    const { t } = useTranslation()
    console.log(percent)

    return (
        <div className={classes.wrapper}
            onClick={() => {
                Helpers.downloadFile({
                    id: Number(id), applicationId: Number(applicationId),
                    onDownloadProgress: (val) => setPercent(val),
                    endDownloadProgress: () => setTimeout(() => setPercent(0), 1000)
                })
            }}>
            <div className={classes.content}>
                <div className={classes.title}>
                    {percent ? t("File_downloading") : t("File")}
                </div>
                {
                    percent ? (
                        <div className={classes.progress}>
                            {percent} %
                        </div>
                    ) : (
                        <div className={classes.icon}>
                            <Icon.System.Download />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Download