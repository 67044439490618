import { useGoogleCaptcha } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  item: Types.IEntity.Registry;
  isFetched: boolean;
  isLoading: boolean;
}

interface IProps {
  id: string;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useSingle = ({ id, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps): IChildren => {
  const dispatch = useDispatch();

  const { handleLoad, isLoading } = useGoogleCaptcha();

  const item = useSelector<Store.Types.IState, Types.IEntity.Registry>(state => state.registry.single.item);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.registry.single.isFetched);

  useEffect(
    () =>
      handleLoad({
        onSuccess: token => {
          console.log(token);

          if (token) {
            dispatch(
              Actions.Single.request({
                id,
                token,
                callback: {
                  onSuccess,
                  onError,
                  onFinally
                }
              })
            );
          }
        }
      }),
    [id]
  );

  return { item, isFetched, isLoading };
};

export default useSingle;
