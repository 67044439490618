import React from 'react';
import { Form, Formik, FormikProps } from 'formik';

import * as Types from '../types';

interface FormValues extends Types.IForm.Update {}

interface IChildren extends FormikProps<FormValues> {}

interface IProps {
  values: FormValues;
  className?: string;
  onSuccess?: ({ values }: { values: FormValues }) => void;
  onError?: (error: { message: string }) => void;
  onFinally?: () => void;

  children(props: IChildren): JSX.Element;
}

const Duplicate: React.FC<IProps> = ({ values, className, onSuccess = () => {}, children }) => {

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if (!isSubmitting) {
      setSubmitting(true);
      onSuccess({ values })
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ ...values }}
      validateOnMount
      enableReinitialize
    >
      {(props: FormikProps<FormValues>) => (
        <Form {...{ className }}>
          {children(props)}
        </Form>
      )}
    </Formik>
  );
};

export default Duplicate;
