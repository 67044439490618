import React from 'react';
import cx from 'classnames';

import classes from './Type.module.scss';

interface IProps {
  children: React.ReactNode;
  variant: 'gray' | 'dark-gray' | 'blue' | 'white';
  className?: string;
}

const Type: React.FC<IProps> = ({ children, variant = 'gray', className }) => (
  <div className={cx(classes.wrapper, classes[`wrapper--variant-${variant}`], className)}>{children}</div>
);

export default Type;