import { useGoogleCaptcha } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  item: Types.IEntity.Organization;
  isFetched: boolean;
}

interface IProps {
  id: number;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useSingle = ({ id, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps): IChildren => {
  const dispatch = useDispatch();
  const { handleLoad } = useGoogleCaptcha();

  const item = useSelector<Store.Types.IState, Types.IEntity.Organization>(state => Selectors.getSingle(state, id));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.organization.single.isFetched);

  useEffect(() => {
    handleLoad({
      onSuccess(token) {
        if (token) {
          dispatch(
            Actions.Single.request({
              token,
              id,
              callback: {
                onSuccess,
                onError,
                onFinally
              }
            })
          );
        }
      }
    });
  }, []);

  return { item, isFetched };
};

export default useSingle;
