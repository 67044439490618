import React from 'react';

import * as Hooks from '../hooks';

const LanguageListener: React.FC = () => {
  Hooks.useLanguageListener();

  return null;
};

export default LanguageListener;