import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import cx from 'classnames';

import config from 'config';

import * as AuthModule from 'modules/auth';
import * as SystemModule from 'modules/system';

import * as Icon from 'components/Icon';

import classes from './MobileMenu.module.scss';

interface IProps {
  onSettings: (type) => void;
}

const MobileMenu: React.FC<IProps> = ({ onSettings }) => {
  const { t } = useTranslation();
  const { isAuthenticated, profile, company, methods } = AuthModule.Hooks.useAuth();
  const { language, methods: LanguageMethods } = SystemModule.Hooks.useLanguage();

  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isProfileVisible, setProfileVisible] = useState(false);

  const menu = [
    { to: '/', title: t('menu.main'), isVisible: true },
    { to: '/cabinet', title: t('menu.my_cabinet'), isVisible: isAuthenticated },
    { to: '/registry', title: t('menu.registry'), isVisible: true },
    { to: '/help', title: t('menu.help'), isVisible: true },
    { to: '/documents', title: t('chech_documents'), isVisible: true },
    { to: '/statistics', title: t('Statistics'), isVisible: true },
    { to: '/news', title: t('News'), isVisible: true }
  ];

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.button} onClick={() => setMenuVisible(!isMenuVisible)}>
          {!isMenuVisible ? <Icon.System.Bars /> : <Icon.System.CloseSimple />}
        </div>
        <Link to="/" className={cx(classes.logo)}>
          <Icon.Logo.Horizontal width={35} />
        </Link>
        <Link to="/" className={cx(classes.logoHorizontal)}>
          <Icon.Logo.Horizontal width={35} />
        </Link>
        <div
          className={cx(classes.button, classes.buttonProfile)}
          onClick={() => (isAuthenticated ? setProfileVisible(!isProfileVisible) : window.open(config.sso.link))}
        >
          {!isProfileVisible ? <Icon.System.UserMobile /> : <Icon.System.CloseSimple />}
        </div>
      </div>

      <Transition in={isMenuVisible} timeout={{ enter: 0, exit: 200 }} unmountOnExit mountOnEnter>
        {(state: TransitionStatus) => (
          <div className={classes.dropdown} style={{ ...transition[state] }}>
            <div className={classes.language}>
              {Object.values(SystemModule.Constants.LANGUAGES_INFO).map(item => (
                <div
                  className={cx(classes.languageItem, language === item.key && classes.languageItemActive)}
                  onClick={() => LanguageMethods.set(item.key)}
                >
                  {item.title}
                </div>
              ))}
            </div>
            <div className={classes.list}>
              {menu
                .filter(m => m.isVisible)
                .map(menu => (
                  <NavLink to={menu.to} className={classes.listItem} activeClassName={classes.listItemActive} exact>
                    <div className={classes.listItemTitle}>{menu.title}</div>
                  </NavLink>
                ))}
              {/* <a className={classes.listItem} href="https://b-advice.uz/" target="__blank">
                {t('b-advice')}
              </a> */}
            </div>
          </div>
        )}
      </Transition>

      <Transition in={isProfileVisible} timeout={{ enter: 0, exit: 200 }} unmountOnExit mountOnEnter>
        {(state: TransitionStatus) => (
          <div className={cx(classes.dropdown, classes.dropdownProfile)} style={{ ...transition[state] }}>
            <div className={classes.profile}>
              <div className={classes.profileName}>{profile.fullName}</div>
              {!!company.name && <div className={classes.profileCompany}>{company.name}</div>}
            </div>
            <div className={cx(classes.list, classes.listProfile)}>
              <Link to="/cabinet" className={classes.listItem} onClick={() => setProfileVisible(false)}>
                <div className={classes.listItemTitle}>{t('My_applications')}</div>
                <div className={classes.listItemIcon}>
                  <Icon.System.Plus />
                </div>
              </Link>
              <Link to="/cabinet" className={classes.listItem} onClick={() => setProfileVisible(false)}>
                <div className={classes.listItemTitle}>{t('My_documents')}</div>
                <div className={classes.listItemIcon}>
                  <Icon.System.Docs />
                </div>
              </Link>
              {profile.type !== AuthModule.Constants.TYPE.INDIVIDUAL && (
                <div
                  className={classes.listItem}
                  onClick={() => {
                    onSettings(2);
                    setProfileVisible(false);
                  }}
                >
                  <div className={classes.listItemTitle}>{t('My_organization')}</div>
                  <div className={classes.listItemIcon}>
                    <Icon.System.Company />
                  </div>
                </div>
              )}
              <div
                className={classes.listItem}
                onClick={() => {
                  onSettings(1);
                  setProfileVisible(false);
                }}
              >
                <div className={classes.listItemTitle}>{t('My_details')}</div>
                <div className={classes.listItemIcon}>
                  <Icon.System.User />
                </div>
              </div>
              <div className={classes.listItem} onClick={() => methods.logout()}>
                <div className={classes.listItemTitle}>{t('Logout')}</div>
                <div className={classes.listItemIcon}>
                  <Icon.System.Logout />
                </div>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
};

const transition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transform: 'translateY(0)' },
  exited: { opacity: 0, visibility: 'hidden' }
};

export default MobileMenu;
