import { all, fork } from 'redux-saga/effects';

import * as ActModule from 'modules/act';
import * as ApplicationModule from 'modules/application';
import * as AuthModule from 'modules/auth';
import * as CategoryModule from 'modules/category';
import * as CertificateModule from 'modules/certificate';
import * as DigitalSignModule from 'modules/digitalSign';
import * as DocumentModule from 'modules/document';
import * as EmployeeModule from 'modules/employee';
import * as FeedbackModule from 'modules/feedback';
import * as InstructionModule from 'modules/instruction';
import * as NewsModule from 'modules/news';
import * as NotificationModule from 'modules/notification';
import * as OrganizationModule from 'modules/organization';
import * as ProcessModule from 'modules/process';
import * as RegistryModule from 'modules/registry';
import * as StaticPageModule from 'modules/staticPage';
import * as StatsModule from 'modules/stats';
import * as SystemModule from 'modules/system';
import * as VideoInstructionModule from 'modules/videoInstruction';
import * as Branches from 'modules/branches';
import * as CheckDocumentsModule from 'modules/checkDocument';


export default function* rootSaga() {
  yield all([
    fork(ActModule.Sagas),
    fork(ApplicationModule.Sagas),
    fork(AuthModule.Sagas),
    fork(CategoryModule.Sagas),
    fork(CertificateModule.Sagas),
    fork(DigitalSignModule.Sagas),
    fork(DocumentModule.Sagas),
    fork(EmployeeModule.Sagas),
    fork(FeedbackModule.Sagas),
    fork(InstructionModule.Sagas),
    fork(NewsModule.Sagas),
    fork(NotificationModule.Sagas),
    fork(OrganizationModule.Sagas),
    fork(ProcessModule.Sagas),
    fork(RegistryModule.Sagas),
    fork(StaticPageModule.Sagas),
    fork(StatsModule.Sagas),
    fork(SystemModule.Sagas),
    fork(VideoInstructionModule.Sagas),
    fork(Branches.Sagas),
    fork(CheckDocumentsModule.Sagas)
  ]);
}
