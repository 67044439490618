import React, { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { yup as yupCreator } from 'services';

import * as Actions from '../actions';
import * as Types from '../types';

interface FormValues extends Types.IForm.Rate {}

interface IChildren extends FormikProps<FormValues> {}

interface IProps {
  values?: Partial<FormValues>;
  onSubmit?: () => void;
  onSuccess?: (data: string) => void;
  onError?: ({ error }: { error: string }) => void;
  onFinally?: () => void;
  children(props: IChildren): JSX.Element;
}

let cancelSource = axios.CancelToken.source();

const Rate: React.FC<IProps> = ({
  values,
  onSubmit = () => {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {},
  children
}) => {
  const dispatch = useDispatch();

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const yup = yupCreator();
  const validationSchema = yup.object().shape<FormValues>({
    rate: yup.number().required(),
    comment: yup.string(),
    application_id: yup.number().required()
  });

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if (!isSubmitting) {
      setSubmitting(true);
      cancelSource = axios.CancelToken.source();

      onSubmit();

      dispatch(
        Actions.Rate.request({
          values,
          cancelSource,
          callback: {
            onSuccess,
            onError: error => {
              setSubmitting(false);
              onError(error);
            },
            onFinally
          }
        })
      );
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        rate: 5,
        comment: '',
        application_id: 0,
        ...values
      }}
      validateOnMount
      enableReinitialize
      {...{ validationSchema }}
    >
      {(props: FormikProps<FormValues>) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Rate;
