import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import * as YMap from 'react-yandex-maps';

import classes from './FieldMap.module.scss';
import { get } from 'lodash';
import Axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import * as Icon from 'components/Icon';
interface IProps {
  name: string;
  label?: string;
  required?: boolean;
}

const Map: React.FC<IProps> = ({ label, required, ...props }) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField(props);
  const [latitude, longitude] = (field.value || ':').split(':');
  const [zoom, setZoom] = useState<number>(10)
  const [downloading, setDownloading] = useState<boolean>(true)
  const { values } = useFormikContext();

  useEffect(() => {
    if (get(values, 'LATITUDE.value') && get(values, 'LONGITUDE.value')) {
      helpers.setValue(`${get(values, 'LATITUDE.value')}:${get(values, 'LONGITUDE.value')}`);
    }
  }, [values])

  const download = () => {
    setDownloading(true);
    Axios.get('https://static-maps.yandex.ru/1.x/', {
      params: {
        size: '650,450',
        z: zoom,
        ll: `${longitude},${latitude}`,
        l: 'map',
        pt: `${longitude},${latitude},round`
      },
      responseType: "arraybuffer"
    }).then(({ data }) => {
      console.log(data)
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${latitude}-${longitude}.jpg`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
      .catch(() => toast.error(`${t('internal_error')}`))
      .finally(() => {
        setDownloading(false);
      });
  };



  const setValue = (e) => {
    setZoom(get(e, 'originalEvent.target[_zoom]'))
    let cords = e.get('coords');
    if (!cords) {
      cords = e.originalEvent.target.geometry.getCoordinates();
    }
    const [latitude, longitude] = cords;
    helpers.setValue(`${latitude}:${longitude}`);
  };

  const hasError = !!(meta.touched && meta.error);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {!!label && <div className={classes.label} title={label}>{!!required && <span>*</span>}{label}</div>}
        {
          !!latitude && !!longitude && <Button
            prefixIcon={<Icon.System.Download />}
            htmlType='button'
            isLoading={downloading}
            onClick={download}>
            {t('download_map')}
          </Button>
        }
      </div>

      <div className={classes.map}>
        <YMap.YMaps>
          <YMap.Map
            width="100%"
            height="400px"
            defaultState={{ center: [41.311441204786504, 69.27993542630561], zoom: 6 }}
            onClick={(e) => setValue(e)}
          >
            <YMap.Placemark
              geometry={[latitude, longitude]}
              options={{ draggable: true }}
              onDragEnd={(e) => setValue(e)}
            />
            <YMap.FullscreenControl />
            <YMap.ZoomControl />
          </YMap.Map>
        </YMap.YMaps>
      </div>
      {hasError && (
        <div className={classes.errorLabel}>{meta.error}</div>
      )}
    </div>
  );
};

export default Map;