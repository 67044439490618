import get from 'lodash/get';

import * as Types from './types';

export const employee = (item: Types.IApi.Employee): Types.IEntity.Employee => {
  const firstname = get(item, 'firstname') || '';
  const lastname = get(item, 'lastname') || '';
  const middlename = get(item, 'middlename') || '';
  return {
    id: item.id || 0,
    name: `${lastname && `${lastname} `}${firstname && `${firstname} `}${middlename}`,
    documents: get(item, 'documents') || [],
    organization: {
      id: get(item, 'organization.id') || 0,
      name: langStrings(get(item, 'organization_name'))
    },
  };
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? (get(item, 'currentPage') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1,
    size: get(item, 'totalItems') || 15
  };
};
