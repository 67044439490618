import { useGoogleCaptcha } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Branches[];
  meta: Types.IEntity.Meta;
  isFetched: boolean;
  isLoaded: boolean;
  isLoading: boolean
}

interface IProps {
  uuid: string;
  params?: Partial<Types.IBranchParams>;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useOpenList = ({
  uuid,
  params = {},
  onSuccess = () => { },
  onError = () => { },
  onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();
  const { handleLoad, isLoading } = useGoogleCaptcha()
  const items = useSelector<Store.Types.IState, Types.IEntity.Branches[]>(state => state.branches.open_list.items);
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state =>
    state.branches.open_list.meta
  );
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.branches.open_list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.branches.open_list.isLoaded);

  useEffect(() => {
    handleLoad({
      onSuccess(token) {
        if (uuid && token) {
          dispatch(Actions.OpenList.request({
            token,
            uuid,
            params: {
              page: params.page || 0,
              limit: params.limit || 10
            },
            callback: {
              onSuccess,
              onError,
              onFinally
            }
          }));
        }
      },
    })

  }, [uuid, params.page]);

  return {
    items,
    meta,
    isFetched,
    isLoaded,
    isLoading
  };
};

export default useOpenList;