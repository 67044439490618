import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import HtmlParser from 'react-html-parser';
import get from 'lodash/get';

import * as ProcessModule from 'modules/process';
import * as SystemModule from 'modules/system';

import classes from './CancelModal.module.scss';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import { useTranslation } from 'react-i18next';

interface IProps {
  applicationId: number;
  onClose: () => void;
  onConfirmSuccess: () => void;
}

const CancelModal: React.FC<IProps> = ({ applicationId, onClose, onConfirmSuccess }) => {
  const { t } = useTranslation();
  const { cancel } = ProcessModule.Hooks.useProcessCancel();
  const { language } = SystemModule.Hooks.useLanguage();
  const [isLoading, setLoading] = useState(false);

  const onConfirm = () => {
    setLoading(true);
    cancel(applicationId, {
      onSuccess: (data) => {
        const message = get(data, `message.title[${language}]`) || '';
        !!message && toast.success(message);
        onConfirmSuccess();
      },
      onError: (error) => {
        const message = get(error, `message.title[${language}]`) || '';
        const type = get(error, `message.type`) || '';
        !!message && (type === 'INFO' ? toast(message) : toast.error(message));
      },
      onFinally: () => {
        setLoading(false);
        onClose();
      }
    })
  };

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.spinner}>
          <Spinner/>
        </div>
      )}
      <div className={classes.title}>{t('application_cancel_title')}</div>
      <div className={classes.description}>
        {HtmlParser(t('application_cancel_description_1'))}
        {HtmlParser(t('application_cancel_description_2'))}
      </div>
      <div className={classes.buttonList}>
        <Button className={classes.button} onClick={onClose} variant="grey" htmlType="button">{t('close')}</Button>
        <Button className={classes.button} variant="red" htmlType="button" onClick={onConfirm}>{t('Confirm')}</Button>
      </div>
    </div>
  );
};

export default CancelModal;