import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import get from 'lodash/get';

import * as FeedbackModule from 'modules/feedback';

import { Nav } from 'components/Modal';
import Button from 'components/Button';
import * as Fields from 'components/Fields';
import Spinner from 'components/Spinner';

import * as Hooks from '../../hooks';

import classes from './Appeal.module.scss';

interface IProps {
  applicationId: number;
  close: () => void;
}

const Appeal: React.FC<IProps> = ({ applicationId, close }) => {
  const { t, i18n } = useTranslation();
  const { items, isLoaded } = Hooks.useAppealTopics();

  return (
    <div className={classes.wrapper}>
      <Nav variant="dark" size="small" showClose onClose={close}/>
      <FeedbackModule.Forms.Appeal
        {...{ applicationId }}
        onSuccess={() => {
          toast.success(`${t('thanks_for_your_feedback')}`);
          close();
        }}
        onError={(error) => {
          const validations = get(error, `validations`) || [];
          validations.forEach(validation => {
            const message = get(validation, 'message');
            !!message && toast.error(get(validation, 'message'))
          });
        }}
      >
        {({ isSubmitting }) => (
          <div className={classes.wrapperInner}>
            {isSubmitting && (
              <div className={classes.spinner}>
                <Spinner/>
              </div>
            )}
            <div className={classes.title}>{t('send_a_complaint_over_the_organization')}</div>
            <div className={classes.fields}>
              <Fields.Select
                label={t('subject_of_the_complaint')}
                name="theme"
                options={items.map(item => ({
                  name: get(item, `name[${i18n.language}]`),
                  value: get(item, 'key') || ''
                }))}
                isLoading={!isLoaded}
                required
              />
              <Fields.Textarea
                label={t('appeal')}
                name="text"
                placeholder={t('here_you_can_report_the_work_of_an_authorized_organization')}
                required
              />
            </div>
            <Button className={classes.button} htmlType="submit" size="large" variant="green">{t('Send')}</Button>
          </div>
        )}
      </FeedbackModule.Forms.Appeal>
    </div>
  );
};

export default Appeal;