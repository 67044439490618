import get from 'lodash/get';
import moment from 'moment';

import * as Constants from './constants';
import * as Types from './types';

export const notification = (item: Types.IApi.Notification): Types.IEntity.Notification => {
  return {
    id: get(item, 'id') || 0,
    title: langString(item, 'title'),
    description: langString(item, 'description'),
    image: get(item, 'action_data.image_url'),
    type: get(item, 'notification_type') || Constants.TYPE.MESSAGE,
    date: {
      day: moment(get(item, 'created_date')).startOf('day').unix(),
      unix: moment(get(item, 'created_date')).unix(),
      hour: moment(get(item, 'created_date')).format('HH:mm')
    },
    isRead: !!get(item, 'read_date')
  };
};

export const langString = (item?: Types.IApi.Notification | null, fieldName?: string): Types.IEntity.LangStrings => {
  return {
    ru: get(item, `${fieldName}_ru`, '') || '',
    uz: get(item, `${fieldName}_uz`, '') || '',
    oz: get(item, `${fieldName}_oz`, '') || '',
    en: get(item, `${fieldName}_en`, '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'number') ? (get(item, 'number') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};

export const message = (item: Types.IApi.Message): Types.IEntity.Message => {
  const id = Math.random().toString(36).substr(2, 10);
  const time = moment(get(item, 'data.date')).format('HH:mm');

  return {
    id,
    title: get(item, 'notification.title') || '',
    body: get(item, 'notification.body') || '',
    image: get(item, 'data.imageUrl') || '',
    type: get(item, 'data.type') || Constants.TYPE.MESSAGE,
    time
  };
};