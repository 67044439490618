import React, { useEffect, useRef } from 'react';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cx from 'classnames';

import * as NotificationModule from 'modules/notification';

import Spinner from 'components/Spinner';

import Item from './Item';
import Empty from './Empty';

import classes from './NotificationList.module.scss';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 80
});

interface IProps {
  close: () => void;
}

const NotificationList: React.FC<IProps> = ({ close }) => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const { items, isFetched, isLoaded, meta, methods } = NotificationModule.Hooks.useList();

  useEffect(() => {
    cache.clearAll();
    // @ts-ignore
    listRef.current && listRef.current.recomputeRowHeights && listRef.current.recomputeRowHeights();
  }, [items.length]);

  if (!isFetched && !isLoaded) {
    return (
      <div className={classes.spinner}>
        <Spinner/>
      </div>
    );
  }

  if (isLoaded && !items.length) {
    return (
      <Empty/>
    );
  }

  const rowRenderer = ({ index, key, style, parent }) => {
    const item = items[index];
    return (
      <CellMeasurer
        {...{ key, cache, parent }}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => {

          if (item.type === 'day') {
            const date = moment.unix(item.value);
            const today = moment();
            const yesterday = moment().subtract(1, 'days');

            let value = date.format('DD MMMM, YYYY');

            if (date.isSame(today, 'days')) {
              value = t('Today');
            }

            if (date.isSame(yesterday, 'days')) {
              value = t('Yesterday');
            }

            return (
              <div ref={registerChild} {...{ key, style }}>
                <div className={cx(classes.date, !index && classes.dateFirst)}>{value}</div>
              </div>
            );
          }

          return (
            <div ref={registerChild} {...{ key, style }}>
              <Item item={item.value} {...{ close }}/>
            </div>
          )
        }}
      </CellMeasurer>
    );
  };

  const isRowLoaded = ({ index }) => meta.current < meta.total ? index < items.length : true;

  return (
    <div className={classes.wrapper}>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={methods.Next}
        rowCount={items.length + 1}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                // @ts-ignore
                ref={element => {
                  listRef.current = element;
                  registerChild(element);
                }}
                width={width}
                height={height}
                rowCount={items.length}
                overscanRowCount={0}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                noRowsRenderer={() => (
                  <div className={classes.spinner}>
                    <Spinner/>
                  </div>
                )}
                rowRenderer={rowRenderer}
                onRowsRendered={onRowsRendered}
                className={classes.wrapperInner}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
      {!isFetched && !isLoaded && (
        <Spinner/>
      )}
    </div>
  );
};

export default NotificationList;