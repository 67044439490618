import React from 'react';
import cx from 'classnames';

import * as Icon from 'components/Icon';

import classes from './ModalNav.module.scss';

interface IProps {
  showClose?: boolean;
  onClose?: () => void;
  showPrev?: boolean;
  onPrev?: () => void;
  variant?: 'dark' | 'light';
  size?: 'small';
  className?: string;
}

const Nav: React.FC<IProps> = ({ showClose, onClose, showPrev, onPrev, variant, className, size }) => (
  <div className={cx(classes.wrapper, variant && classes[`wrapper--variant-${variant}`], size && classes[`wrapper--size-${size}`], className)}>
    <div className={classes.block}>
      {showPrev && (
        <div className={classes.back} onClick={onPrev}>
          <Icon.System.Back />
        </div>
      )}
    </div>
    <div className={classes.block}>
      {showClose && (
        <>
          <div className={classes.close} onClick={onClose}>
            <Icon.System.CrossFilled />
          </div>
          <div className={cx(classes.close, classes.closeMobile)} onClick={onClose}>
            <Icon.System.CloseSimple />
          </div>
        </>
      )}
    </div>
  </div>
);

export default Nav;