import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({
  params
}: {
  params: Types.IParams;
}): AxiosPromise<{ data: { content: Types.IApi.Instruction.Response[] } }> => {
  return http.request.get<{ data: { content: Types.IApi.Instruction.Response[] } }>(`/admin/open_source/instruction`, {
    params: {
      page: params.page - 1,
      size: params.limit
    }
  });
};

export const Single = ({
  id
}: Types.IApi.Instruction.Request): AxiosPromise<{ data: Types.IApi.Instruction.Response }> => {
  return http.request.get<{ data: Types.IApi.Instruction.Response }>(`/admin/open_source/instruction/${id}`);
};
