import { ActionType } from 'typesafe-actions';
import merge from 'lodash/merge';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  entities: {},
  ids: [],
  single: {
    id: 0,
    isFetched: false
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.ENTITIES: {
      const { items } = action.payload;
      return {
        ...state,
        entities: merge({}, state.entities, items)
      };
    }

    case Constants.SINGLE.REQUEST: {
      return {
        ...state,
        single: {
          ...state.single,
          isFetched: false
        }
      };
    }
    case Constants.SINGLE.SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        single: {
          ...state.single,
          id,
          isFetched: true
        }
      };
    }
    case Constants.SINGLE.FAILURE: {
      return {
        ...state,
        single: {
          ...state.single,
          isFetched: true
        }
      };
    }
    case Constants.STORE_RESET: {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
};
