import React, { cloneElement, isValidElement } from 'react';
import cx from 'classnames';

import Spinner from 'components/Spinner';

import classes from './Button.module.scss';

interface IProps {
  variant?: 'blue' | 'green' | 'grey' | 'stroke' | 'stroke-blue' | 'white' | 'white-one' | 'red';
  size?: 'middle' | 'large';
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  htmlType?: 'button' | 'submit';
  block?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  container?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Button: React.FC<IProps> = ({ variant = 'blue', size = 'middle', prefixIcon, suffixIcon, htmlType, block, disabled, isLoading, children, container, className, onClick }) => {
  const resultProps = {
    className: cx(
      classes.wrapper,
      variant && classes[`wrapper--variant-${variant}`],
      size && classes[`wrapper--size-${size}`],
      isLoading && classes[`wrapper--loading`],
      block && classes[`wrapper--block`],
      disabled && classes[`wrapper--disabled`],
      prefixIcon && classes[`wrapper--icon-prefix`],
      suffixIcon && classes[`wrapper--icon-suffix`],
      className
    ),
    onClick,
    disabled
  };

  const resultChildren = (
    <>
      {!!prefixIcon && (
        <span className={classes.icon}>{prefixIcon}</span>
      )}
      {!!children && (
        <span className={classes.text}>{children}</span>
      )}
      {!!suffixIcon && (
        <span className={classes.icon}>{suffixIcon}</span>
      )}
      {!!isLoading && (
        <span className={classes.loading}>
          <Spinner/>
        </span>
      )}
    </>
  );

  const resultContainer = isValidElement(container) ? (
    container
  ) : (
    <button type={htmlType}/>
  );

  return cloneElement(
    resultContainer,
    resultProps,
    resultChildren
  );
};

export default Button;
