import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './StepBar.module.scss';

interface IProps {
  current: number;
  total: number;
  variant?: 'white' | 'blue';
  size?: 'small' | 'middle' | 'large';
  showTitle?: boolean;
  isFull?: boolean;
}

const StepBar: React.FC<IProps> = ({ current, total, variant = 'white', size = 'large', showTitle, isFull }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(classes.wrapper, classes[`wrapper--variant-${variant}`], classes[`wrapper--size-${size}`], isFull && classes[`wrapper--full`])}>
      {showTitle && (
        <div className={classes.title}>{t('step_title', { current, total })}</div>
      )}
      <div className={classes.list}>
        {[...Array(total)].map((_, i) => (
          <div key={i + 1} className={cx(classes.item, (i + 1) <= current && classes.itemActive)}/>
        ))}
      </div>
    </div>
  );
};

export default StepBar;