import React from 'react';
import cx from 'classnames';

import classes from './Details.module.scss';

interface IProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

const Item: React.FC<IProps> = ({ title, className, children }) => (
  <div className={cx(classes.item, className)}>
    {title && (
      <div className={classes.itemTitle}>{title}</div>
    )}
    <div className={classes.itemValue}>{children}</div>
  </div>
);

export default Item;