import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import trim from 'lodash/trim';
import includes from 'lodash/includes';
import lowerCase from 'lodash/lowerCase';

import * as Store from 'store';

import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => state.category.entities;

const getDenormalizedList = createSelector<Store.Types.IState, Types.IEntities, number[], Types.IEntity.Category[]>(
  getEntities,
  state => state.category.list.ids,
  (entities, ids) => {
    const items = denormalize({ category: ids }, { category: [Schema] }, { category: entities });
    return get(items, 'category', []);
  }
);

const getSortedList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.Category[],
  string,
  Types.IEntity.Category[]
>(
  getDenormalizedList,
  (state, search) => search,
  (items, search) => {
    if (search && trim(search)) {
      search = trim(lowerCase(search));
      items = items.filter(
        c =>
          includes(trim(lowerCase(get(c, 'title.uz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'title.oz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'title.ru') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.en') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.uz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.oz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.ru') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.en') || '')), search)
      );
    }
    items = orderBy(items, ['position', 'id'], ['asc', 'asc']);
    return items;
  }
);

export const getList = createSelector<Store.Types.IState, string, Types.IEntity.Category[], Types.IEntity.Category[]>(
  getSortedList,
  items => items
);

const getSearchList = createSelector<Store.Types.IState, string, Types.IEntities, string, Types.IEntity.Category[]>(
  getEntities,
  (state, search) => search,
  (entities, search) => {
    let items: Types.IEntity.Category[] = [];
    if (search && trim(search)) {
      search = trim(lowerCase(search));
      items = Object.values(entities).filter(
        c =>
          includes(trim(lowerCase(get(c, 'title.uz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'title.oz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'title.ru') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.en') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.uz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.oz') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.ru') || '')), search) ||
          includes(trim(lowerCase(get(c, 'description.en') || '')), search)
      );
    }
    items = orderBy(items, ['position', 'id'], ['asc', 'asc']);
    return items;
  }
);

export const getParentListSearch = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.Category[],
  Types.IEntity.Category[]
>(getSearchList, items => items.filter(item => !item.parentId));

export const getChildrenListSearch = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.Category[],
  Types.IEntity.Category[]
>(getSearchList, items => items.filter(item => item.parentId));

export const getSingle = createSelector<Store.Types.IState, number, Types.IEntities, number, Types.IEntity.Category>(
  getEntities,
  (_, id) => id,
  (entities, id) => {
    let normalized;
    if (get(entities, id)) {
      normalized = denormalize({ category: id }, { category: Schema }, { category: entities });
    }

    let item = get(normalized, 'category');
    if (item) {
      return item;
    }
    return {};
  }
);
