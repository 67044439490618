import configure from './configure';
import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

export { configure };

export const { store } = configure();

export {
  Actions,
  Constants,
  Types
};