import { Nav } from 'components/Modal';
import React, { useState } from 'react';
import classes from '../ApplicationView.module.scss';
import { Form, Formik } from 'formik';
import { Text } from 'components/Fields';
import { useTranslation } from 'react-i18next';
import Uploader from './Uploader';
import Button from 'components/Button';
import { http } from 'services';
import * as Yup from 'yup';
import { get } from 'lodash';
import toast from 'react-hot-toast';

interface IProps {
  applicationId: number;
  onClose: () => void;
}

const Appeal: React.FC<IProps> = ({ applicationId, onClose }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);

  function handleSubmit(v) {
    setLoading(true);
    http.request
      .post('/process/appeal', {
        application_id: applicationId,
        text: get(v, 'text'),
        file_ids: [get(v, 'file')]
      })
      .then(() => toast.success(t('appeal_successfull_send').toString()))
      .catch(() => toast.error(t('appeal_error').toString()))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        file: '',
        text: ''
      }}
      validationSchema={Yup.object().shape({
        file: Yup.string().required(),
        text: Yup.string().required()
      })}
    >
      {() => (
        <Form>
          <Nav showClose onClose={onClose} />
          <div className={classes.appeal}>
            <div>
              <Text placeholder={t('placeholder_text')} label={t('appeal_text')} name="text" required />
            </div>
            <div>
              <Uploader applicationId={applicationId} label={t('appeal_file')} name="file" required />
            </div>
            <div className={classes.appealButtons}>
              <Button variant="red" htmlType="button" onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button variant="green" htmlType="submit" isLoading={isLoading}>
                {t('submit')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Appeal;
