export const ENTITIES = '@@VIDEO_INSTRUCTION/ENTITIES';

export enum LIST {
  REQUEST = '@@VIDEO_INSTRUCTION/LIST/REQUEST',
  SUCCESS = '@@VIDEO_INSTRUCTION/LIST/SUCCESS',
  FAILURE = '@@VIDEO_INSTRUCTION/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@VIDEO_INSTRUCTION/SINGLE/REQUEST',
  SUCCESS = '@@VIDEO_INSTRUCTION/SINGLE/SUCCESS',
  FAILURE = '@@VIDEO_INSTRUCTION/SINGLE/FAILURE'
}

export enum STATUS {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  SUSPENDED = 'SUSPENDED',
  EXPIRED = 'EXPIRED'
}

export const STORE_RESET = '@@STORE/RESET';
