import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';

import * as Store from 'store';

import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => state.employee.entities;

const getDenormalizedList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntities,
  number[],
  Types.IEntity.Employee[]
>(
  getEntities,
  (state, name) => get(state, `employee.list[${name}].ids`) || [],
  (entities, ids) => {
    const items = denormalize({ employee: ids }, { employee: [Schema] }, { employee: entities });
    return get(items, 'employee', []);
  }
);

const getSortedList = createSelector<
  Store.Types.IState,
  string,
  Types.IEntity.Employee[],
  string,
  Types.IEntity.Employee[]
>(
  getDenormalizedList,
  (state, search) => search,
  items => items
);

export const getList = createSelector<Store.Types.IState, string, Types.IEntity.Employee[], Types.IEntity.Employee[]>(
  getSortedList,
  items => items
);
