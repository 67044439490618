import { action } from 'typesafe-actions';

import * as Constants from './constants';
import * as Types from './types';

export const Rate = {
  request: (args: Types.IAction.Rate.Request) => action(Constants.RATE.REQUEST, args),
  success: (args?: Types.IAction.Rate.Success) => action(Constants.RATE.SUCCESS, args),
  failure: (args: Types.IAction.Rate.Failure) => action(Constants.RATE.FAILURE, args)
};

export const Complaint = {
  request: (args: Types.IAction.Complaint.Request) => action(Constants.COMPLAINT.REQUEST, args),
  success: (args?: Types.IAction.Complaint.Success) => action(Constants.COMPLAINT.SUCCESS, args),
  failure: (args: Types.IAction.Complaint.Failure) => action(Constants.COMPLAINT.FAILURE, args)
};

export const Appeal = {
  request: (args: Types.IAction.Appeal.Request) => action(Constants.APPEAL.REQUEST, args),
  success: (args?: Types.IAction.Appeal.Success) => action(Constants.APPEAL.SUCCESS, args),
  failure: (args: Types.IAction.Appeal.Failure) => action(Constants.APPEAL.FAILURE, args)
};

export const AppealTopics = {
  request: (args?: Types.IAction.AppealTopics.Request) => action(Constants.APPEAL_TOPICS.REQUEST, args),
  success: (args: Types.IAction.AppealTopics.Success) => action(Constants.APPEAL_TOPICS.SUCCESS, args),
  failure: (args: Types.IAction.AppealTopics.Failure) => action(Constants.APPEAL_TOPICS.FAILURE, args)
};