import get from 'lodash/get';

import * as Types from './types';

export const variables = (items: Types.IApi.Settings.Variable[]): Types.IEntity.Variables => {
  const variables = (items || []).reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value } ), {});

  return {
    isUnderConstruction: !!Number(get(variables, 'under_construction_fo'))
  };
};