import React from 'react';
import cx from 'classnames';

import { System } from 'components/Icon';

import classes from './Toast.module.scss';

interface IProps {
  type: 'success' | 'error' | 'loading' | 'blank';
}

const Icon: React.FC<IProps> = ({ type }) => {
  const icon = {
    'success': <System.CheckFilled/>,
    'error': <System.Error/>,
    'loading': <System.Info/>,
    'blank': <System.Info/>
  };

  return (
    <div className={cx(classes.icon, type && classes[`icon--${type}`])}>{icon[type]}</div>
  );
};

export default Icon;