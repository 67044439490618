import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Category[];
  entities: Types.IEntities;
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  search?: string;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({ search = '', onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps = {}): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.Category[]>(state => Selectors.getList(state, search));
  const entities = useSelector<Store.Types.IState, Types.IEntities>(state => Selectors.getEntities(state));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.category.list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.category.list.isLoaded);

  useEffect(() => {
    dispatch(Actions.List.request({
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, []);

  return {
    items,
    entities,
    isFetched,
    isLoaded
  };
};

export default useList;