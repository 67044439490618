import get from 'lodash/get';
import moment from 'moment';

import * as Helpers from 'helpers';

import * as Types from './types';

export const organization = (item?: Types.IApi.Organization): Types.IEntity.Organization => {
  const statsGraph: Types.IEntity.Stats[] = Object.entries(get(item, 'applications_daily') || {}).map(([key, item]) => {
    const date = moment(key).unix();
    const day = moment(key).format('DD');
    return ({
      date,
      day: Number(day),
      all: get(item, 'total') || 0,
      processing: get(item, 'rejected') || 0,
      rejected: get(item, 'rejected') || 0,
      approved: get(item, 'confirmed') || 0
    })
  });

  return {
    id: get(item, 'id') || 0,
    name: langStrings('name', item),
    address: langStrings('address', item),
    director: {
      name: langStrings('director', item),
      position: langStrings('director_position', item)
    },
    stats: {
      graph: statsGraph,
      rate: {
        avg: Number(Helpers.currency.format(get(item, 'avg_rate') || 0, { separator: '', precision: 1 })),
        count: get(item, 'count_rates') || 0
      },
      application: {
        total: get(item, 'total_application_count') || 0,
        reviewing: get(item, 'review_applications_count') || 0,
        rejected: get(item, 'rejected_applications_count') || 0,
        done: get(item, 'confirmed_applications_count') || 0
      },
      documents: get(item, 'documents_count') || 0
    },
    documents: (get(item, 'documents') || []).map(document => ({
      id: get(document, 'id') || 0,
      type: get(document, 'type') || '',
      name: langStrings('name', document)
    })),
    status: !!get(item, 'publish')
  };
};

export const langStrings = (key: string, item?: Types.IApi.Organization): Types.IEntity.LangStrings => {
  return {
    uz: get(item, `${key}_uz`) || '',
    oz: get(item, `${key}_oz`) || '',
    ru: get(item, `${key}_ru`) || '',
    en: get(item, `${key}_en`) || ''
  };
};
