import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import orderBy from 'lodash/orderBy';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Requirement[];
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  documentId: number;
  specializationIds: string[];
  onSuccess?: ({ requirements }: { requirements: Types.IEntity.Requirement[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

let cancelSource = axios.CancelToken.source();

const useRequirements = ({ documentId, specializationIds, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps): IChildren => {
  const dispatch = useDispatch();

  // const documentId = useSelector<Store.Types.IState, number>(state => state.process.process.documentId);
  const items = useSelector<Store.Types.IState, Types.IEntity.Requirement[]>(state => orderBy(state.process.requirements.items, ['position'], ['asc']));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.process.requirements.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.process.requirements.isLoaded);

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  useEffect(() => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.Requirements.request({
      documentId,
      specializationIds,
      cancelSource,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, []);

  return { items, isFetched, isLoaded };
};

export default useRequirements;