import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import * as Actions from '../actions';
import * as Constants from '../constants';

interface IChildren {
  start: (type: Constants.TYPE, callback?: ICallback) => void;
  branch: (type: Constants.TYPE, callback?: ICallback, id?: number) => void;
  branchSingle: (type: Constants.TYPE, id: number, callback?: ICallback) => void;
}

interface ICallback {
  onSuccess?: () => void;
  onError?: (error: string) => void;
  onFinally?: () => void;
}

interface IProps {
  registerId: number;
}

let cancelSource = axios.CancelToken.source();

const useProcessStart = ({ registerId }: IProps): IChildren => {
  const dispatch = useDispatch();

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const start = (type: Constants.TYPE, callback?: { onSuccess: () => {}, onError: () => {}, onFinally: () => {} }) => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.StartByRegister.request({
      registerId,
      type,
      cancelSource,
      callback: {
        onSuccess: callback?.onSuccess,
        onError: callback?.onError,
        onFinally: callback?.onFinally
      }
    }));
  };

  const branch = (type: Constants.TYPE, callback?: { onSuccess: () => {}, onError: () => {}, onFinally: () => {} }, id?: number) => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.StartByBranch.request({
      registerId,
      type,
      id,
      cancelSource,
      callback: {
        onSuccess: callback?.onSuccess,
        onError: callback?.onError,
        onFinally: callback?.onFinally
      }
    }));
  };

  const branchSingle = (type: Constants.TYPE, id: number, callback?: { onSuccess: () => {}, onError: () => {}, onFinally: () => {} }) => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.StartByBranchSingle.request({
      registerId,
      id,
      type,
      cancelSource,
      callback: {
        onSuccess: callback?.onSuccess,
        onError: callback?.onError,
        onFinally: callback?.onFinally
      }
    }));
  };

  return { start, branch, branchSingle };
};

export default useProcessStart;