import { useDispatch } from 'react-redux';

import * as Actions from '../actions';
import { useEffect } from 'react';

interface IProps {
  id: number;
  isRead: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useMarkAsRead = ({ id, isRead, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if(isRead){
      return;
    }
    dispatch(
      Actions.MarkAsRead.request({
        id,
        callback: {
          onSuccess,
          onError,
          onFinally
        }
      })
    );
  }, []);

  return null;
};

export default useMarkAsRead;