import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  appealTopics: {
    items: [],
    isFetched: false,
    isLoaded: false
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.APPEAL_TOPICS.REQUEST: {
      return {
        ...state,
        appealTopics: {
          ...state.appealTopics,
          isFetched: false
        }
      }
    }
    case Constants.APPEAL_TOPICS.SUCCESS: {
      const { items } = action.payload;
      return {
        ...state,
        appealTopics: {
          ...state.appealTopics,
          items,
          isFetched: true,
          isLoaded: true
        }
      }
    }
    case Constants.APPEAL_TOPICS.FAILURE: {
      return {
        ...state,
        appealTopics: {
          ...state.appealTopics,
          isFetched: true,
          isLoaded: true
        }
      }
    }
    default:
      return state;
  }
}