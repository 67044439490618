import { AxiosPromise, CancelTokenSource } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const Login = ({ values }: { values: Types.IForm.Login }): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.get<Types.IApi.Login.Response>(`/oauth/sso/access_token`, {
    params: {
      code: values.code,
      state: values.state,
      client_type: values.clientType
    }
  });
};

export const LoginChangeToken = (): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.post<Types.IApi.Login.Response>(
    `/oauth/sso/change-access-token`,
    {},
    {
      params: {
        state: 'FRONT_OFFICE'
      }
    }
  );
};

export const LoginViaFaceID = ({
  values,
  cancelSource
}: {
  values: Partial<Types.IForm.LoginViaFaceID>;
  cancelSource: CancelTokenSource;
}): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.post<Types.IApi.Login.Response>(
    `/oauth/face-id/image`,
    {
      serial: values.serial,
      number: values.number,
      image: values.data
    },
    {
      cancelToken: cancelSource.token
    }
  );
};

export const LoginViaDS = ({
  key,
  cancelSource
}: {
  key: string;
  cancelSource: CancelTokenSource;
}): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.post<Types.IApi.Login.Response>(`/oauth/eds/login?state=FRONT_OFFICE`, key, {
    cancelToken: cancelSource.token
  });
};

export const LoginViaEmail = ({
  values,
  cancelSource
}: {
  values: Types.IForm.LoginViaEmail;
  cancelSource: CancelTokenSource;
}): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.post<Types.IApi.Login.Response>(
    `/oauth/email/login`,
    {
      email: values.email,
      password: values.password
    },
    {
      cancelToken: cancelSource.token
    }
  );
};

export const RegisterViaEmail = ({
  values,
  cancelSource
}: {
  values: Types.IForm.RegisterViaEmail;
  cancelSource: CancelTokenSource;
}): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.post<Types.IApi.Login.Response>(
    `/oauth/email/registration`,
    {
      country_id: values.countryId,
      firstname: values.firstname,
      lastname: values.lastname,
      middlename: values.middlename,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password
    },
    {
      cancelToken: cancelSource.token
    }
  );
};

export const EmailVerification = ({
  values,
  cancelSource
}: {
  values: Types.IForm.EmailVerification;
  cancelSource: CancelTokenSource;
}): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.post<Types.IApi.Login.Response>(
    `/oauth/email/verification/${values.token}`,
    {},
    {
      cancelToken: cancelSource.token
    }
  );
};

export const Logout = (): AxiosPromise => {
  return http.request.get(`/oauth/logout`);
};

export const Profile = (): AxiosPromise<Types.IApi.Login.Response> => {
  return http.request.get<Types.IApi.Login.Response>(`/oauth/check_token`);
};
