export enum TYPE {
  LICENSE = 'LICENSE',
  NOTIFICATION = 'NOTIFICATION',
  PERMIT = 'PERMIT',
  CERTIFICATE = 'CERTIFICATE',
  CONCLUSION = 'CONCLUSION',
  CONFIRMATION = 'CONFIRMATION'
}

export const ENTITIES = '@@DOCUMENT/ENTITIES';

export enum LIST {
  REQUEST = '@@DOCUMENT/LIST/REQUEST',
  SUCCESS = '@@DOCUMENT/LIST/SUCCESS',
  FAILURE = '@@DOCUMENT/LIST/FAILURE'
}

export enum LIST_BY_APPLICANT {
  REQUEST = '@@DOCUMENT/LIST_BY_APPLICANT/REQUEST',
  SUCCESS = '@@DOCUMENT/LIST_BY_APPLICANT/SUCCESS',
  FAILURE = '@@DOCUMENT/LIST_BY_APPLICANT/FAILURE'
}

export enum LIST_BY_CATEGORY {
  REQUEST = '@@DOCUMENT/LIST_BY_CATEGORY/REQUEST',
  SUCCESS = '@@DOCUMENT/LIST_BY_CATEGORY/SUCCESS',
  FAILURE = '@@DOCUMENT/LIST_BY_CATEGORY/FAILURE'
}

export enum INFO {
  REQUEST = '@@DOCUMENT/INFO/REQUEST',
  SUCCESS = '@@DOCUMENT/INFO/SUCCESS',
  FAILURE = '@@DOCUMENT/INFO/FAILURE'
}
