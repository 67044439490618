import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path d="M5 6H19" stroke="#202022" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 12H19" stroke="#34CEFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 18H19" stroke="#202022" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
);
