export const ENTITIES = '@@NEWS/ENTITIES';

export enum LIST {
  REQUEST = '@@NEWS/LIST/REQUEST',
  SUCCESS = '@@NEWS/LIST/SUCCESS',
  FAILURE = '@@NEWS/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@NEWS/SINGLE/REQUEST',
  SUCCESS = '@@NEWS/SINGLE/SUCCESS',
  FAILURE = '@@NEWS/SINGLE/FAILURE'
}

export const STORE_RESET = '@@STORE/RESET';
