import React, { useRef } from 'react';
import cx from 'classnames';
import { useOuterClick } from 'react-outer-click';

import classes from './LanguageSwitcher.module.scss';

interface IProps {
  className?: string;
  current: string;
  isOpen: boolean;
  onClick: () => void;
  close: () => void;
  children: React.ReactNode;
}

const List: React.FC<IProps> = ({ className, current, isOpen, onClick, close, children }) => {
  const el = useRef(null);
  useOuterClick(el, close);

  return (
    <div ref={el} className={cx(classes.wrapper, isOpen && classes.wrapperOpen, className)}>
      <div className={classes.current} {...{ onClick }}>{current}</div>
      <div className={classes.list}>{children}</div>
    </div>
  );
};

export default List;