import { ActionType } from 'typesafe-actions';
import merge from 'lodash/merge';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  entities: {},
  list: {
    all: {
      ids: [],
      isFetched: false,
      isLoaded: false,
      meta: {
        current: 1,
        total: 1
      }
    }
  },
  report: {
    all: {
      items: [],
      isFetched: false,
      isLoaded: false,
      meta: {
        current: 1,
        total: 1
      }
    }
  },
  single: {
    id: 0,
    isFetched: false
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.ENTITIES: {
      const { items } = action.payload;
      return {
        ...state,
        entities: merge({}, state.entities, items)
      };
    }
    case Constants.LIST.REQUEST: {
      const { name, params } = action.payload;

      const list = state.list[name] || {};
      let isLoaded = !!list.isLoaded;
      if (get(list, 'meta.current') !== params.page) {
        isLoaded = false;
      }

      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...list,
            ids: list.ids || [],
            isFetched: false,
            isLoaded
          }
        }
      };
    }
    case Constants.LIST.SUCCESS: {
      const { name, ids, meta } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...(state.list[name] || {}),
            ids,
            meta,
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    case Constants.LIST.FAILURE: {
      const { name } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...(state.list[name] || {}),
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    case Constants.SINGLE.REQUEST: {
      return {
        ...state,
        single: {
          ...state.single,
          isFetched: false
        }
      };
    }
    case Constants.SINGLE.SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        single: {
          ...state.single,
          id,
          isFetched: true
        }
      };
    }
    case Constants.SINGLE.FAILURE: {
      return {
        ...state,
        single: {
          ...state.single,
          isFetched: true
        }
      };
    }

    case Constants.REPORT.REQUEST: {
      const { name } = action.payload;

      return {
        ...state,
        report: {
          ...state.report,
          [name]: {
            ...state.report[name],
            isFetched: false
          }
        }
      };
    }
    case Constants.REPORT.SUCCESS: {
      const { name, items, meta } = action.payload;
      return {
        ...state,
        report: {
          ...state.report,
          [name]: {
            ...state.report[name],
            items,
            meta,
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    case Constants.REPORT.FAILURE: {
      const { name } = action.payload;
      return {
        ...state,
        report: {
          ...state.report,
          [name]: {
            ...state.report[name],
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    case Constants.STORE_RESET: {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
};
