import React from 'react';

import * as Types from '../types';

import context from './context';

const useContext = (): Types.IContext.Value => {
  return React.useContext(context);
};

export default useContext;