import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import * as FeedbackModule from 'modules/feedback';

import { Nav } from 'components/Modal';
import Button from 'components/Button';
import * as Fields from 'components/Fields';
import Spinner from 'components/Spinner';

import classes from './Complaint.module.scss';

interface IProps {
  close: () => void;
}

const Complaint: React.FC<IProps> = ({ close }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Nav variant="dark" size="small" showClose onClose={close} />
      <FeedbackModule.Forms.Complaint
        onSuccess={() => toast.success(`${t('thanks_for_your_feedback')}`)}
        onFinally={() => close()}
      >
        {({ isSubmitting }) => (
          <div className={classes.wrapperInner}>
            {isSubmitting && (
              <div className={classes.spinner}>
                <Spinner />
              </div>
            )}
            <div className={classes.title}>{t('suggestions_or_comments')}</div>
            <div className={classes.description}>{t('help_us_improve_the_system_please_rate_the_quality_of_the_services_provided')}</div>
            <div className={classes.fields}>
              <Fields.Text label={t('Full_name')} name="name" placeholder={t('enter_full_name')} />
              <Fields.Phone label={t('phone_number')} name="phone" />
              <Fields.Textarea label={t('comment')} name="comment" placeholder={t('here_you_can_leave_suggestions_or_comments')} />
            </div>
            <Button className={classes.button} htmlType="submit" size="large" variant="green">{t('Send')}</Button>
          </div>
        )}
      </FeedbackModule.Forms.Complaint>
    </div>
  );
};

export default Complaint;