export const ENTITY = "REGISTER"

export enum LIST {
  REQUEST = '@@REGISTRY/LIST/REQUEST',
  SUCCESS = '@@REGISTRY/LIST/SUCCESS',
  FAILURE = '@@REGISTRY/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@REGISTRY/SINGLE/REQUEST',
  SUCCESS = '@@REGISTRY/SINGLE/SUCCESS',
  FAILURE = '@@REGISTRY/SINGLE/FAILURE'
}