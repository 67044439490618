import React from 'react';
import Paginate from 'react-paginate';
import cx from 'classnames';

import * as Icon from 'components/Icon';

import classes from './Pagination.module.scss';

interface IProps {
  total: number;
  current: number;
  pageRangeDisplayed?: number;
  onChange: (current: number) => void;
}

const Pagination: React.FC<IProps> = ({ total, current, pageRangeDisplayed = 5, onChange }) => {
  if (total <= 1) {
    return null;
  }

  return (
    <div>
      <Paginate
        {...{ pageRangeDisplayed }}
        pageCount={total}
        forcePage={current - 1}
        onPageChange={({ selected }) => onChange(selected)}
        containerClassName={classes.wrapper}
        pageClassName={classes.item}
        pageLinkClassName={classes.itemLink}
        activeClassName={classes.itemActive}
        activeLinkClassName={classes.itemActiveLink}
        previousClassName={classes.itemPrev}
        previousLinkClassName={cx(classes.itemLink, classes.itemPrevLink)}
        nextClassName={classes.itemNext}
        nextLinkClassName={cx(classes.itemLink, classes.itemNextLink)}
        disabledClassName={classes.itemDisabled}
        previousLabel={<Icon.System.ChevronLeft/>}
        nextLabel={<Icon.System.ChevronRight/>}
        breakLabel={'...'}
        breakClassName={classes.itemLink}
        marginPagesDisplayed={1}
        subContainerClassName={'pages pagination'}
      />
    </div>
  );
};

export default Pagination;