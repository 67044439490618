import React from 'react';

import * as Hooks from '../hooks';

const Init: React.FC = () => {
  Hooks.useInit();

  return null;
};

export default Init;