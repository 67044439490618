import React, { cloneElement, isValidElement } from 'react';

import * as Icon from 'components/Icon';

import classes from './Breadcrumb.module.scss';

interface IProps {
  icon?: React.ReactNode;
  onClick?: () => void;
  container?: React.ReactNode;
  children: React.ReactNode;
}

const Item: React.FC<IProps> = ({ icon, onClick, container, children }) => {

  const resultProps = {
    className: classes.item,
    onClick
  };

  const resultChildren = (
    <>
      {!!icon && (
        <div className={classes.itemIcon}>{icon}</div>
      )}
      <div className={classes.itemValue}>{children}</div>
      <div className={classes.itemSeparator}>
        <Icon.System.ChevronRight/>
      </div>
    </>
  );

  const resultContainer = isValidElement(container) ? (
    container
  ) : (
    <div/>
  );

  return cloneElement(
    resultContainer,
    resultProps,
    resultChildren
  );
};

export default Item;