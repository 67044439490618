import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import * as SystemModule from 'modules/system';
import * as CategoryModule from 'modules/category';
import * as DocumentModule from 'modules/document';

import * as Icon from 'components/Icon';
import Modal from 'components/Modal';
import Type from 'components/Type';

import classes from './SearchResultList.module.scss';

interface IProps {
  search: string;
}

const SearchResultList: React.FC<IProps> = ({ search }) => {
  const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const { state, methods } = CategoryModule.Context.Single.useContext();
  const Category = CategoryModule.Hooks.useListSearch({ search });
  const { items: DocumentItems } = DocumentModule.Hooks.useListSearch({ search, showAll: true });

  return (
    <>
      <div className={classes.wrapper}>

        {!!Category.items.parent.length && (
          <div className={classes.listWrapper}>
            <div className={classes.listTitle}>{t('categories')}</div>
            <div className={classes.list}>
              {Category.items.parent.map(item => (
                <div key={item.id} className={classes.listItem}>
                  <div className={classes.categoryItem} onClick={() => !item.soon && methods.setCategory(item.id)}>
                    <div className={classes.categoryItemImage}>
                      <img src={item.image}/>
                    </div>
                    <div className={classes.categoryItemContent}>
                      {item.soon && (
                        <div className={classes.categoryItemType}>
                          <Type variant="blue">{t('Quickly')}</Type>
                        </div>
                      )}
                      <div className={classes.categoryItemTitle}>{get(item, `title[${language}]`)}</div>
                      <div className={classes.categoryItemDescription}>{get(item, `description[${language}]`)}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!!Category.items.children.length && (
          <div className={classes.listWrapper}>
            <div className={classes.listTitle}>{t('subcategories')}</div>
            <div className={classes.list}>
              {Category.items.children.map(item => (
                <div key={item.id} className={classes.listItem}>
                  <div className={classes.subCategoryItem} onClick={() => !item.soon && methods.setSubCategory(item.id, item.parentId)}>
                    <div className={classes.subCategoryItemTitle}>{get(item, `title[${language}]`)}</div>
                    {item.soon && (
                      <div className={classes.subCategoryItemType}>
                        <Type variant="blue">{t('Quickly')}</Type>
                      </div>
                    )}
                    <div className={classes.subCategoryItemIcon}>
                      <Icon.System.Right/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!!DocumentItems.length && DocumentItems.filter(d => d.category_id).length && (
          <div className={classes.listWrapper}>
            <div className={classes.listTitle}>{t('licenses_and_documents')}</div>
            <div className={cx(classes.list, classes.listFull)}>
              {DocumentItems.map(item => {
                const categoryId = item.category_id;
                const parentCategoryId = get(Category, `entities[${item.category_id}].parentId`);

                if(!categoryId){
                  return null;
                }

                return (
                  <div key={item.id} className={classes.listItem}>
                    <div
                      className={classes.subCategoryItem}
                      onClick={() => methods.setDocument(item.id, parentCategoryId ? parentCategoryId : categoryId, parentCategoryId ? categoryId : parentCategoryId)}
                    >
                      <div className={classes.subCategoryItemTitle}>{get(item, `title[${language}]`)}</div>
                      <div className={classes.subCategoryItemType}>
                        <Type variant="blue">{get(item, `type.title[${language}]`)}</Type>
                      </div>
                      <div className={classes.subCategoryItemIcon}>
                        <Icon.System.Right/>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {!Category.items.parent.length && !Category.items.children.length && !DocumentItems.filter(d => d.category_id).length && (
          <div className={classes.empty}>
           <div className={classes.emptyIcon}>
             <Icon.Placeholder.Search/>
           </div>
            <div className={classes.emptyTitle}>{t('nothing_found')}</div>
            <div className={classes.emptyDescription}>{t('try_searching_by_category_name_or_license_name')}</div>
          </div>
        )}

      </div>

      <Modal size="middle" isOpen={state.isVisible} close={methods.setClose}>
        <CategoryModule.Containers.View/>
      </Modal>
    </>
  );
};

export default SearchResultList;