import React from 'react';
import { useTranslation } from 'react-i18next';

import * as CertificateModule from 'modules/certificate';

import * as Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import classes from '../CertificateVew.module.scss';

interface IProps {
  certificateId: number;
}

const ReportList: React.FC<IProps> = ({ certificateId }) => {
  const { t } = useTranslation();

  const { items, isFetched, isLoaded } = CertificateModule.Hooks.useReport({
    name: 'all',
    params: { registerId: certificateId }
  });

  if (!isFetched && !isLoaded) {
    return <Spinner />;
  }

  return (
    <div className={classes.report}>
      <div className={classes.reportTitle}>{t('list_of_reports')}</div>
      <div className={classes.reportTable}>
        <div className={classes.reportTableHeader}>
          <div className={classes.reportTableHeaderRow}>
            <div className={classes.reportTableHeaderColumn}>{t('File')}</div>
            <div className={classes.reportTableHeaderColumn}>{t('date_of_sending')}</div>
          </div>
        </div>
        <div className={classes.reportTableBody}>
          {items.map(item => {
            return (
              <div key={item.id} className={classes.reportTableBodyRow}>
                <div className={classes.reportTableBodyColumn}>
                  <div className={classes.reportFile}>
                    <div className={classes.reportFileIcon}>
                      <Icon.System.Docs />
                    </div>
                    <div className={classes.reportFileContent}>
                      <div className={classes.reportFileName}>{item.report_file.name}</div>
                      <div className={classes.reportFileSize}>{item.report_file.size} </div>
                    </div>
                  </div>
                </div>
                <div className={classes.reportTableBodyColumn}>{item.created_date}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReportList;
