import React, { useState } from 'react';
import classes from "./DocumentGenerator.module.scss"
import { useTranslation } from 'react-i18next';

import config from 'config';
import Button from 'components/Button';
import * as Icon from 'components/Icon';

interface IProps {
  id?: number,
  uuid?: string
}

const DocumentGenerator: React.FC<IProps> = ({ id, uuid }) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false)
  const file = `${config.document.base_url}/certificate${uuid ? '/uuid' : ''}/${id || uuid}/pdf?language=${i18n.language}`;

  return (
    <div className={classes.wrapper}>
      <div className={classes.actions}>
        <Button
          variant="stroke"
          prefixIcon={<Icon.System.Download />}
          className="m-r-12"
          container={<a href={`${file}&download`} target="_blank" rel="noreferrer" />}
        >
          {t('Save')}
        </Button>
        <Button
          variant="stroke"
          prefixIcon={<Icon.System.Print />}
          container={<a href={file} target="_blank" rel="noreferrer" />}
        >
          {t('Print')}
        </Button>
      </div>
      <div className={classes.document}>
        {
          visible ? (
            <iframe width="100%" height={840} src={`${file}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`} frameBorder="0" />
          ) : (
            <div className={classes.documentAction}>
              <img src={require('assets/images/document.png')} />
              <Button
                variant="blue"
                prefixIcon={<Icon.System.Show />}
                onClick={() => setVisible(true)}
              >
                {t('view_document')}
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default DocumentGenerator;
