import React from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import classes from './FieldRadio.module.scss';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  options: Array<any>;
  optionLabel?: string | ((option) => string);
  optionValue?: string | ((option) => string);
  disabled?: boolean;
  required?: boolean;
  isLoading?: boolean;
  onChange?: (value) => void;
}

const Radio: React.FC<IProps> = ({
  label,
  placeholder,
  options,
  optionLabel = 'name',
  optionValue = 'value',
  disabled,
  required,
  isLoading,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  const optionLabelFn = option => (typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel]);
  const optionValueFn = option => (typeof optionValue === 'function' ? optionValue(option) : option[optionValue]);

  return (
    <div className={cx(classes.wrapper, disabled && classes.wrapperDisabled)}>
      {!!label && (
        <div className={classes.label} title={label}>
          {!!required && <span>*</span>}
          {label}
        </div>
      )}
      <div className={classes.optionList}>
        {options.map(option => {
          const label = optionLabelFn(option);
          const value = optionValueFn(option);

          return (
            <label key={value} className={classes.optionItem}>
              <div className={classes.optionItemLabel}>{label}</div>
              <div className={classes.optionItemValue}>
                <input
                  type="radio"
                  className={classes.optionInput}
                  name={field.name}
                  checked={field.value === value}
                  value={value}
                  onChange={() => helpers.setValue(value)}
                />
                <div className={classes.optionInputView} />
              </div>
            </label>
          );
        })}
      </div>
      {hasError && <div className={classes.errorLabel}>{meta.error}</div>}
    </div>
  );
};

export default Radio;
