import { takeLatest, call, put, all } from 'redux-saga/effects';
import { normalize, NormalizedSchema } from 'normalizr';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import Schema from './schema';
import * as Types from './types';

export function* Single(action: ReturnType<typeof Actions.Single.request>) {
  const { slug, callback } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.StaticPage.Response }> = yield call(Api.Single, { slug });
    const item = Mappers.staticPage(get(data, 'data') || {});
    const { entities, result }: NormalizedSchema<{ staticPage: Types.IEntities }, number> = normalize(item, Schema);
    yield put(Actions.Entities({ items: entities?.staticPage }));
    yield put(Actions.Single.success({ id: result }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { item });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Single.failure({ id: +slug, error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([takeLatest(Constants.SINGLE.REQUEST, Single)]);
}
