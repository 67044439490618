import React from 'react';

import * as AuthModule from 'modules/auth';
import * as NotificationModule from 'modules/notification';

import classes from './LayoutBlank.module.scss';

interface IProps {
  children: React.ReactNode;
}

const Blank: React.FC<IProps> = ({ children }) => {
  const { isAuthenticated } = AuthModule.Hooks.useAuth();

  return (
    <>
      {isAuthenticated && (
        <NotificationModule.Components.Messages/>
      )}
      <div className={classes.wrapper}>{children}</div>
    </>
  );
};

export default Blank;