import { http } from 'services';

import * as Types from './types';

export const ByUuid = ({ values, token }: { values: Types.IForm.ByUuid, token: string }) => {
    return http.request.get(`/file/document/check`, {
        params: {
            uuid: values.documentUuid
        },
        headers: {
            // ...(token ? { 'captcha-response': token } : null)
        },
    });
};


export const ByNumber = ({ values, token }: { values: Types.IForm.ByNumber, token: string }) => {
    return http.request.post(`/feedback/rating`, {
        document_number: values.documentNumber,
        document_date: values.documentDate,
    }, {
        headers: {
            ...(token ? { 'captcha-response': token } : null)
        },
    });
};
