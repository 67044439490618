import { action } from 'typesafe-actions';

import * as Constants from './constants';
import * as Types from './types';

export const Login = {
  request: (args: Types.IAction.Login.Request) => action(Constants.LOGIN.REQUEST, args),
  success: (args: Types.IAction.Login.Success) => action(Constants.LOGIN.SUCCESS, args),
  failure: (args: Types.IAction.Login.Failure) => action(Constants.LOGIN.FAILURE, args)
};

export const LoginChangeToken = {
  request: (args: Types.IAction.LoginChangeToken.Request) => action(Constants.LOGIN_CHANGE_TOKEN.REQUEST, args),
  success: (args: Types.IAction.LoginChangeToken.Success) => action(Constants.LOGIN_CHANGE_TOKEN.SUCCESS, args),
  failure: (args: Types.IAction.LoginChangeToken.Failure) => action(Constants.LOGIN_CHANGE_TOKEN.FAILURE, args)
};

export const LoginViaDS = {
  request: (args: Types.IAction.LoginViaDS.Request) => action(Constants.LOGIN_VIA_DS.REQUEST, args),
  success: (args: Types.IAction.LoginViaDS.Success) => action(Constants.LOGIN_VIA_DS.SUCCESS, args),
  failure: (args: Types.IAction.LoginViaDS.Failure) => action(Constants.LOGIN_VIA_DS.FAILURE, args)
};
export const LoginViaFaceID = {
  request: (args: Types.IAction.LoginViaFaceID.Request) => action(Constants.LOGIN_VIA_FACE_ID.REQUEST, args),
  success: (args: Types.IAction.LoginViaFaceID.Success) => action(Constants.LOGIN_VIA_FACE_ID.SUCCESS, args),
  failure: (args: Types.IAction.LoginViaFaceID.Failure) => action(Constants.LOGIN_VIA_FACE_ID.FAILURE, args)
};

export const LoginViaEmail = {
  request: (args: Types.IAction.LoginViaEmail.Request) => action(Constants.LOGIN_VIA_EMAIL.REQUEST, args),
  success: (args: Types.IAction.LoginViaEmail.Success) => action(Constants.LOGIN_VIA_EMAIL.SUCCESS, args),
  failure: (args: Types.IAction.LoginViaEmail.Failure) => action(Constants.LOGIN_VIA_EMAIL.FAILURE, args)
};

export const RegisterViaEmail = {
  request: (args: Types.IAction.RegisterViaEmail.Request) => action(Constants.REGISTER_VIA_EMAIL.REQUEST, args),
  success: (args: Types.IAction.RegisterViaEmail.Success) => action(Constants.REGISTER_VIA_EMAIL.SUCCESS, args),
  failure: (args: Types.IAction.RegisterViaEmail.Failure) => action(Constants.REGISTER_VIA_EMAIL.FAILURE, args)
};

export const EmailVerification = {
  request: (args: Types.IAction.EmailVerification.Request) => action(Constants.EMAIL_VERIFICATION.REQUEST, args),
  success: (args: Types.IAction.EmailVerification.Success) => action(Constants.EMAIL_VERIFICATION.SUCCESS, args),
  failure: (args: Types.IAction.EmailVerification.Failure) => action(Constants.EMAIL_VERIFICATION.FAILURE, args)
};

export const Logout = {
  request: () => action(Constants.LOGOUT.REQUEST),
  success: () => action(Constants.LOGOUT.SUCCESS)
};

export const Profile = {
  request: (args?: Types.IAction.Profile.Request) => action(Constants.PROFILE.REQUEST, args),
  success: (args: Types.IAction.Profile.Success) => action(Constants.PROFILE.SUCCESS, args),
  failure: (args: Types.IAction.Profile.Failure) => action(Constants.PROFILE.FAILURE, args)
};

export const StoreReset = () => action(Constants.STORE_RESET);
