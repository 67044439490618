import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import { Nav } from 'components/Modal';

import classes from "./TaxiDeliveryType.module.scss";

interface IProps {
    isLoading?: boolean
    close: () => void;
    onSelect: (value: string) => void;
}

const DELIVERY_TYPE = ["LICENSE", 'LICENSE_SHEET', "SHEET", "CHANGE", "SHEET_CHANGE", "LICENSE_TERMINATION", "SHEET_TERMINATION"]

const TaxiDeliveryType: React.FC<IProps> = ({ isLoading = false, close, onSelect }) => {
    const {
        t,
    } = useTranslation();

    return (
        <div className={classes.wrapper}>
            {isLoading && (
                <div className={classes.spinner}>
                    <Spinner />
                </div>
            )}
            <Nav showClose onClose={close} />
            <div className={classes.title}>{t('select_the_type_of_taxi_delivery')}</div>
            <div className={classes.list}>
                {DELIVERY_TYPE.map(item => (
                    <div key={item} className={classes.listItem} onClick={() => onSelect(item)}>
                        <div className={classes.listItemTitle}>{t(`enum_delivery_type_${item}`)}</div>
                        <div className={classes.listItemIcon}>
                            <Icon.System.Right />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TaxiDeliveryType;
