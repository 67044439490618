import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Act[];
  isFetched: boolean;
  isLoaded: boolean;
  meta: Types.IEntity.Meta;
}

interface IProps {
  filter?: Types.IFilter;
  params?: Partial<Types.IParams>;
  onRequest?: () => void;
  onSuccess?: ({ items }: { items: Types.IEntity.Act[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({ filter = {}, params = {}, onRequest = () => { }, onSuccess = () => { }, onError = () => { }, onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.Act[]>(state => Selectors.getList(state));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.act.list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.act.list.isLoaded);
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state => state.act.list.meta);

  useDeepCompareEffect(() => {
    onRequest();
    dispatch(Actions.List.request({
      filter,
      params: {
        search: params.search,
        page: params.page || 1,
        limit: params.limit || 10
      },
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, [filter, params]);

  return {
    items,
    isFetched,
    isLoaded,
    meta
  };
};

export default useList;