import React from 'react';
// import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import * as NotificationModule from 'modules/notification';
import * as SystemModule from 'modules/system';

import classes from './NotificationList.module.scss';

interface IProps {
  item: NotificationModule.Types.IEntity.Notification;
  close: () => void;
}

const Item: React.FC<IProps> = ({ item, close }) => {
  // const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  NotificationModule.Hooks.useMarkAsRead({ id: item.id, isRead: item.isRead });

  const description = get(item, `description[${language}]`);

  return (
    <div className={classes.item}>
      <div className={classes.itemContent}>
        <div className={classes.itemTitle}>{get(item, `title[${language}]`)}</div>
        {description && (
          <div className={classes.itemDescription}>{description}</div>
        )}
        <div className={classes.itemFooter}>
          {/*<div className={classes.itemLink} onClick={close}>{t('read_more')}</div>*/}
          <div className={classes.itemTime}>{item.date.hour}</div>
        </div>
      </div>
      <div className={classes.itemImage}>
        <img src={item.image}/>
      </div>
    </div>
  );
};

export default Item;