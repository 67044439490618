import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import * as PaymentModule from 'modules/payment';
import * as SystemModule from 'modules/system';

import TextBlock from 'components/TextBlock';
import * as Fields from 'components/Fields';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import classes from '../PaymentForm.module.scss';

interface IProps {
  close: () => void;
  onSuccess: () => void;
}

const Confirm: React.FC<IProps> = ({ close, onSuccess }) => {
  const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const { state: { applicationId, serial, phone }} = PaymentModule.Context.useContext();

  return (
    <>
      <div className={classes.title}>{t('payment_confirm')}</div>
      <TextBlock variant="BLUE" className={classes.info}>
        <span>{t('we_sent_a_6_digit_verification_code_to_the_number')}:</span>
        <span><b>{phone}</b></span>
      </TextBlock>
      <PaymentModule.Forms.Confirm
        {...{ applicationId, serial, language }}
        onSuccess={() => {
          close();
          onSuccess();
        }}
        onError={(message) => toast.error(message)}
      >
        {({ isSubmitting }) => (
          <>
            {isSubmitting && (
              <div className={classes.spinner}>
                <Spinner/>
              </div>
            )}
            <Fields.Mask
              name="code"
              label={t('confirm_code')}
              mask="######"
              placeholder="••••••"
              type="NUMBER"
              required
            />
            <div className={classes.buttonList}>
              <Button htmlType="button" variant="grey" block className="m-r-16" onClick={close}>{t('Cancel')}</Button>
              <Button variant="blue" block>{t('Confirm')}</Button>
            </div>
          </>
        )}
      </PaymentModule.Forms.Confirm>
    </>
  );
};

export default Confirm;