import get from 'lodash/get';

import * as Types from './types';

export const videoInstruction = (
  item: Types.IApi.VideoInstruction.Response
): Types.IEntity.VideoInstruction.Response => ({
  id: get(item, 'id') || 0,
  position: get(item, 'position') ? Number(get(item, 'position')) : 0,
  title: langString(item, 'title'),
  created: {
    date: get(item, 'created_at', '') || '',
    by: get(item, 'created_by_id', '') || ''
  },
  color: {
    start: get(item, 'color_start', '') || '',
    end: get(item, 'color_end', '') || ''
  },
  video: langString(item, 'video'),
  image:
    get(item, 'image_files[0].download_uri', 'https://image-placeholder.com/images/actual-size/160x160.png') ||
    'https://image-placeholder.com/images/actual-size/160x160.png'
});

export const langString = (
  item?: Types.IApi.VideoInstruction.Response | null,
  fieldName?: string
): Types.IEntity.LangStrings => {
  return {
    ru: get(item, `${fieldName}_ru`, '') || '',
    uz: get(item, `${fieldName}_uz`, '') || '',
    oz: get(item, `${fieldName}_oz`, '') || '',
    en: get(item, `${fieldName}_en`, '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? (get(item, 'currentPage') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};
