import React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';

import * as Helpers from 'helpers';

import * as ProcessModule from 'modules/process';

import * as Details from 'components/Details';
import * as Icon from 'components/Icon';

import classes from './ApplicationView.module.scss';

interface IProps {
  field?: any,
  title: string;
  value: string;
  type: ProcessModule.Constants.FIELD_TYPE;
  stepType: ProcessModule.Constants.STEP_TYPE;
  applicationId: number;
}

const DetailByType: React.FC<IProps> = ({ title, value, type, stepType, applicationId, field }) => {
  const { t, i18n } = useTranslation();

  const FIELD_TYPE = ProcessModule.Constants.FIELD_TYPE;
  const STEP_TYPE = ProcessModule.Constants.STEP_TYPE;

  if (type === FIELD_TYPE.CHECK_BOX && field?.duplicableValues?.length) {

    return (
      <Details.Item {...{ title }}>
        {
          field?.duplicableValues
            .map(value =>
              value.option ? (
                <>
                  {value.option[i18n.language]}
                  <br />
                </>
              ) : null
            )
        }
      </Details.Item>
    )
  }

  if (!value || value === '[]') {
    return null;
  }

  if (type === FIELD_TYPE.TEXT_BLOCK) {
    return null;
  }

  if (type === FIELD_TYPE.TEXT_EDITOR) {
    return (
      <Details.Item className={classes.detailItemHtml} {...{ title }}>
        <div className={classes.detailItemContent}>
          {HtmlParser(value)}
        </div>
      </Details.Item>
    );
  }

  if (type === FIELD_TYPE.FILE && Number(value)) {
    return (
      <Details.Item {...{ title }}>
        <div className={classes.fieldFile} onClick={() => Helpers.downloadFile({ id: Number(value), applicationId })}>
          <div className={classes.fieldFileTitle}>{t('File')}</div>
          <div className={classes.fieldFileIcon}>
            <Icon.System.Download />
          </div>
        </div>
      </Details.Item>
    );
  }

  if (type === FIELD_TYPE.SWITCH) {
    return (
      <Details.Item {...{ title }}>
        {Number(value) === 1 ? t('yes') : t('no')}
      </Details.Item>
    );
  }

  if (type === FIELD_TYPE.MAP) {
    const [latitude, longitude] = value ? value.split(':') : [];
    if (latitude && longitude) {
      return (
        <Details.Item {...{ title }}>
          <a href={`https://www.google.com/maps/place/${latitude}+${longitude}/@${latitude},${longitude},10z`}
            target="_blank" className={classes.fieldMap}>
            <div className={classes.fieldFileTitle}>Google Maps</div>
            <div className={classes.fieldFileIcon}>
              <Icon.System.Pin />
            </div>
          </a>
        </Details.Item>
      );
    }
  }

  if ([STEP_TYPE.SPECIALIZATION, STEP_TYPE.REQUIREMENTS].includes(stepType)) {
    return (
      <Details.Item>
        <div className={classes.detailItemContent}>{HtmlParser(value)}</div>
      </Details.Item>
    );
  }

  return (
    <Details.Item {...{ title }}>{value}</Details.Item>
  );
};

export default DetailByType;
