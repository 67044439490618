export enum TYPE {
  MESSAGE = 'MESSAGE',
  APPLICATION = 'APPLICATION',
  NEWS = 'NEWS'
}

export const ENTITIES = '@@NOTIFICATION/ENTITIES';

export enum LIST {
  REQUEST = '@@NOTIFICATION/LIST/REQUEST',
  SUCCESS = '@@NOTIFICATION/LIST/SUCCESS',
  FAILURE = '@@NOTIFICATION/LIST/FAILURE'
}

export enum UN_READ_COUNT {
  REQUEST = '@@NOTIFICATION/UN_READ_COUNT/REQUEST',
  SUCCESS = '@@NOTIFICATION/UN_READ_COUNT/SUCCESS',
  FAILURE = '@@NOTIFICATION/UN_READ_COUNT/FAILURE'
}

export enum MARK_AS_READ {
  REQUEST = '@@NOTIFICATION/MARK_AS_READ/REQUEST',
  SUCCESS = '@@NOTIFICATION/MARK_AS_READ/SUCCESS',
  FAILURE = '@@NOTIFICATION/MARK_AS_READ/FAILURE'
}

export enum REQUEST_PERMISSION {
  REQUEST = '@@NOTIFICATION/REQUEST_PERMISSION/REQUEST',
  SUCCESS = '@@NOTIFICATION/REQUEST_PERMISSION/SUCCESS',
  FAILURE = '@@NOTIFICATION/REQUEST_PERMISSION/FAILURE',
}

export enum SET_TOKEN {
  REQUEST = '@@NOTIFICATION/SET_TOKEN/REQUEST',
  SUCCESS = '@@NOTIFICATION/SET_TOKEN/SUCCESS',
  FAILURE = '@@NOTIFICATION/SET_TOKEN/FAILURE',
}

export enum DELETE_TOKEN {
  REQUEST = '@@NOTIFICATION/DELETE_TOKEN/REQUEST',
  SUCCESS = '@@NOTIFICATION/DELETE_TOKEN/SUCCESS',
  FAILURE = '@@NOTIFICATION/DELETE_TOKEN/FAILURE',
}

export const ON_MESSAGE = 'ON_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const STORE_RESET = '@@STORE/RESET';