import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Store from 'store';

import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => state.certificate.entities;


const getDenormalizedList = createSelector<Store.Types.IState, string, Types.IEntities, number[], Types.IEntity.Certificate[]>(
  getEntities,
  ((state, name) => get(state, `certificate.list[${name}].ids`)),
  (entities, ids) => {
    const items = denormalize(
      { 'certificate': ids },
      { 'certificate': [Schema] },
      { 'certificate': entities }
    );
    return get(items, 'certificate', []) || [];
  }
);

const getSortedList = createSelector<Store.Types.IState, string, Types.IEntity.Certificate[], Types.IEntity.Certificate[]>(
  getDenormalizedList,
  (items) => {
    items = orderBy(items, ['id'], ['desc']);
    return items;
  }
);

export const getList = createSelector<Store.Types.IState, string, Types.IEntity.Certificate[], Types.IEntity.Certificate[]>(
  getSortedList,
  (items) => items
);

export const getSingle = createSelector<Store.Types.IState, number, Types.IEntities, number, Types.IEntity.Certificate>(
  getEntities,
  (_, id) => id,
  (entities, id) => {
    let normalized;
    if (get(entities, id)) {
      normalized = denormalize(
        { 'certificate': id },
        { 'certificate': Schema },
        { 'certificate': entities }
      );
    }

    let item = get(normalized, 'certificate');
    if(item){
      return item;
    }
    return {};
  }
);