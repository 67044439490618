const get = require('lodash/get');

/**
 * This function convert a string to spesifice mask format string
 *
 * @param {String} value - The value
 * @param {String} pattern - The pattern
 * @param {String} [defaultValue=""] -the default value
 * @returns {String} - it returns string mask
 */
const stringMask = (value, pattern, defaultValue = '') => {
  if (!pattern || !pattern.length) {
    return value;
  }

  if (!value) {
    return '';
  }

  if (value.length !== pattern.match(/#/g).length) {
    return value;
  }

  let i = 0;
  let v = value.toString();

  return pattern.replace(/#/g, _ => get(v, `[${i++}]`, defaultValue));
};

export default stringMask;
