import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import get from 'lodash/get';

import * as DocumentModule from 'modules/document';

import * as Context from '../context';

interface IProps {
  serviceId: number;
}

const DocumentView: React.FC<IProps> = ({ serviceId }) => {
  const history = useHistory();
  const { methods } = Context.Single.useContext();
  const { item, isLoaded } = DocumentModule.Hooks.useInfo({ documentId: serviceId });

  useEffect(() => {
    if(serviceId && isLoaded){
      let categoryId = get(item, 'category.parent_id') ? get(item, 'category.id') : undefined;
      let categoryParentId = get(item, 'category.parent_id') ? get(item, 'category.parent_id') : get(item, 'category.id');

      methods.setDocument(Number(serviceId), categoryParentId, categoryId);
    }

    if(isLoaded && !get(item, 'category.id')){
      history.push('/');
    }
  }, [isLoaded]);

  return null;
};

export default DocumentView;