import * as React from 'react';
import { useToaster } from 'react-hot-toast';

import ToastBar from './ToastBar';

import classes from './Toast.module.scss';

const Toaster: React.FC = () => {
  const { toasts, handlers } = useToaster({
    duration: 3500
  });

  return (
    <div className={classes.wrapper} onMouseEnter={handlers.startPause} onMouseLeave={handlers.endPause}>
      {toasts.map((toast) => (
        <ToastBar
          key={toast.id}
          onHeight={(height) => handlers.updateHeight(toast.id, height)}
          toast={toast}
          offset={handlers.calculateOffset(toast.id, {
            reverseOrder: true
          })}
        />
      ))}
    </div>
  );
};

export default Toaster;