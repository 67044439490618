import { ComponentType } from 'react';

function lazyRetry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          lazyRetry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default lazyRetry;