import { useSelector } from 'react-redux';

import * as Store from 'store';

import * as Selectors from '../selectors';
import * as Types from '../types';

interface IProps {
  id: number;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useSingle = ({ id }: IProps): Types.IEntity.Category => {
  return useSelector<Store.Types.IState, Types.IEntity.Category>(state => Selectors.getSingle(state, id));
};

export default useSingle;