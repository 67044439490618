import { takeLatest, call, put, all } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';

export function* Rate(action: ReturnType<typeof Actions.Rate.request>) {
  const { values, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: { data: unknown } }> = yield call(Api.Rate, { values });

    yield put(Actions.Rate.success());
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, get(data, 'data.register_id'));
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Rate.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, errorMessage);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Complaint(action: ReturnType<typeof Actions.Complaint.request>) {
  const { values, callback } = action.payload;
  try {
    yield call(Api.Complaint, { values });
    yield put(Actions.Complaint.success());
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, {});
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Complaint.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, errorMessage);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Appeal(action: ReturnType<typeof Actions.Appeal.request>) {
  const { values, callback } = action.payload;
  try {
    yield call(Api.Appeal, { values });
    yield put(Actions.Appeal.success());
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, {});
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    const errorViolations = errorResponse?.response?.data?.violations;
    yield put(Actions.Appeal.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage, validations: errorViolations });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* AppealTopics(action: ReturnType<typeof Actions.AppealTopics.request>) {
  try {
    const { data } = yield call(Api.AppealTopics);
    yield put(Actions.AppealTopics.success({ items: get(data, 'data') || [] }));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.AppealTopics.failure({ error: errorMessage }));
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.RATE.REQUEST, Rate),
    takeLatest(Constants.COMPLAINT.REQUEST, Complaint),
    takeLatest(Constants.APPEAL.REQUEST, Appeal),
    takeLatest(Constants.APPEAL_TOPICS.REQUEST, AppealTopics)
  ]);
}