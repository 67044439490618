import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#clip0)">
      <path d="M15.75 15.75h4.5v-12h-12v4.5"/>
      <path d="M3.75 8.25h12v12h-12v-12z"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h24v24H0z"/>
      </clipPath>
    </defs>
  </SvgIcon>
);