import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 54 58">
      <path fill="#2B2A29" d="M27.22 47.78l2.22 1.26 2.19 1.3V55.44l-2.19 1.26L27.22 58l-2.21-1.29-2.22-1.26V50.33l2.22-1.29 2.21-1.26z"/>
      <path fill="#017663" d="M27.22 48l2.13 1.22 2.1 1.23V55.33l-2.1 1.2-2.13 1.23-2.12-1.23-2.13-1.2V50.45l2.13-1.23L27.22 48z"/>
      <path fill="#1FEBE0" d="M27.22 47.78V48l-4.25 2.46v4.88l-.18.12v-5.12l4.43-2.55z"/>
      <path fill="#2B2A29" d="M38.85 41.58l2.18 1.3 2.22 1.25v5.12l-2.22 1.26-2.18 1.29-2.22-1.29-2.22-1.26v-5.12l2.22-1.26 2.22-1.29z"/>
      <path fill="#017663" d="M38.85 41.82l2.1 1.2 2.12 1.23v4.88l-2.13 1.23-2.1 1.23-2.12-1.23-2.13-1.23v-4.88l2.13-1.23 2.13-1.2z"/>
      <path fill="#1FEBE0" d="M38.85 41.58v.24l-4.26 2.43v4.88l-.18.12v-5.12l4.44-2.55z"/>
      <path fill="#2B2A29" d="M49.57 35.95l2.21 1.29L54 38.53v5.09l-2.22 1.26-2.21 1.26-2.22-1.26-2.19-1.26v-5.09l2.2-1.3 2.2-1.28z"/>
      <path fill="#007B67" d="M49.57 36.16l2.12 1.23 2.13 1.23v4.88l-2.13 1.23-2.12 1.2-2.13-1.2-2.1-1.23v-4.88l2.1-1.23 2.13-1.23z"/>
      <path fill="#1FEBE0" d="M49.57 35.95v.21l-4.23 2.46v4.88l-.18.12v-5.1l4.4-2.57z"/>
      <path fill="#2B2A29" d="M4.4 35.95l2.22 1.29 2.22 1.29v5.09l-2.22 1.26-2.22 1.26-2.21-1.26L0 43.62v-5.09l2.19-1.3 2.21-1.28z"/>
      <path fill="#007E6B" d="M4.4 36.16l2.1 1.23 2.13 1.23v4.88L6.5 44.73l-2.1 1.2-2.12-1.2-2.1-1.23v-4.88l2.1-1.23 2.12-1.23z"/>
      <path fill="#1FEBE0" d="M4.4 35.95v.21L.18 38.62v4.88l-.18.12v-5.1l4.4-2.57z"/>
      <path fill="#2B2A29" d="M16 41.64l2.21 1.26 2.22 1.29v5.09l-2.22 1.29-2.22 1.29-2.21-1.29-2.19-1.29V44.2l2.19-1.29 2.21-1.26z"/>
      <path fill="#017663" d="M16 41.88l2.12 1.2 2.13 1.23v4.88l-2.13 1.2-2.13 1.23-2.12-1.23-2.1-1.2v-4.88l2.1-1.23 2.12-1.2z"/>
      <path fill="#1FEBE0" d="M16 41.64v.24l-4.23 2.43v4.88l-.18.1v-5.1l4.4-2.55z"/>
      <path fill="#2B2A29" d="M27.34 35.08l1.9 1.11 1.94 1.1V41.77l-1.95 1.11-1.89 1.1-1.94-1.1-1.92-1.1V37.3l1.92-1.11 1.94-1.1z"/>
      <path fill="#01806D" d="M27.34 35.26l1.83 1.08L31 37.39v4.25l-1.83 1.08-1.83 1.05-1.85-1.05-1.86-1.08V37.4l1.86-1.05 1.85-1.08z"/>
      <path fill="#1FEBE0" d="M27.34 35.08v.18l-3.7 2.13v4.25l-.16.12V37.3l3.86-2.22z"/>
      <path fill="#2B2A29" d="M38.94 28.76l1.82 1.05 1.83 1.08V35.1l-1.83 1.05-1.82 1.08-1.86-1.08-1.83-1.05V30.9l1.83-1.08 1.86-1.05z"/>
      <path fill="#019682" d="M38.94 28.94l1.73 1.02 1.77 1.02V35.02l-1.77.99-1.73 1.02L37.17 36l-1.77-.99v-4.04l1.77-1.02 1.77-1.02z"/>
      <path fill="#1FEBE0" d="M38.94 28.76v.18l-3.54 2.04v4.04l-.15.1v-4.23l3.69-2.13z"/>
      <path fill="#2B2A29" d="M49.69 24l1.85 1.05 1.83 1.04v4.26l-1.83 1.05-1.85 1.08-1.83-1.08-1.83-1.05v-4.26l1.83-1.04L49.69 24z"/>
      <path fill="#00A795" d="M49.69 24.18l1.76.99 1.77 1.01v4.08l-1.77 1.02-1.76.99-1.74-1-1.74-1.01v-4.08l1.74-1.01 1.74-1z"/>
      <path fill="#1FEBE0" d="M49.69 24v.18l-3.48 2v4.08l-.18.09v-4.26L49.7 24zM4.67 24v.18l-3.5 2v4.08l-.15.09v-4.26L4.67 24z"/>
      <path fill="#2B2A29" d="M4.4 24.06l1.98 1.14 1.98 1.13v4.56l-1.98 1.14-1.98 1.1-1.97-1.1-1.95-1.14v-4.56l1.95-1.13 1.97-1.14z"/>
      <path fill="#01A38E" d="M4.4 24.27l1.89 1.07 1.89 1.08v4.35l-1.89 1.1-1.89 1.08-1.88-1.07-1.9-1.11v-4.35l1.9-1.08 1.88-1.07z"/>
      <path fill="#1FEBE0" d="M4.4 24.06v.2L.63 26.43v4.35l-.15.12v-4.56l3.92-2.27z"/>
      <path fill="#2B2A29" d="M4.4 14.08l1.65.96 1.68.96v3.8l-1.68 1-1.65.95-1.64-.96-1.65-.99V16l1.65-.96 1.64-.96z"/>
      <path fill="#03BDAF" d="M4.4 14.26l1.59.9 1.59.9v3.68l-1.59.93-1.59.9-1.58-.9-1.56-.93v-3.68l1.56-.9 1.58-.9z"/>
      <path fill="#1FEBE0" d="M4.4 14.08v.18l-3.14 1.8v3.68l-.15.06V16l3.3-1.92z"/>
      <path fill="#2B2A29" d="M49.57 14.08l1.64.96 1.68.96v3.8l-1.68 1-1.64.95-1.65-.96-1.65-.99V16l1.65-.96 1.65-.96z"/>
      <path fill="#06C9BB" d="M49.57 14.26l1.58.9 1.6.9v3.68l-1.6.93-1.58.9-1.59-.9-1.56-.93v-3.68l1.56-.9 1.59-.9z"/>
      <path fill="#1FEBE0" d="M49.57 14.08v.18l-3.15 1.8v3.68l-.15.06V16l3.3-1.92z"/>
      <path fill="#2B2A29" d="M38.88 17.14l1.52.87 1.53.86v3.54l-1.53.87-1.52.87-1.53-.87-1.5-.87v-3.54l1.5-.86 1.53-.87z"/>
      <path fill="#04C4B5" d="M38.88 17.29l1.46.84 1.44.83v3.36l-1.44.84-1.46.84-1.44-.84-1.47-.84v-3.36l1.47-.83 1.44-.84z"/>
      <path fill="#1FEBE0" d="M38.88 17.14v.15l-2.91 1.67v3.36l-.12.09v-3.54l3.03-1.73z"/>
      <path fill="#2B2A29" d="M15.87 17.14l1.5.87 1.53.86v3.54l-1.53.87-1.5.87-1.52-.87-1.53-.87v-3.54l1.53-.86 1.52-.87z"/>
      <path fill="#02B4A4" d="M15.87 17.29l1.44.84 1.47.83v3.36l-1.47.84-1.44.84-1.46-.84-1.44-.84v-3.36l1.44-.83 1.46-.84z"/>
      <path fill="#1FEBE0" d="M15.87 17.14v.15l-2.9 1.67v3.36l-.15.09v-3.54l3.05-1.73z"/>
      <path fill="#2B2A29" d="M27.34 22.29l1.77 1.05 1.83 1.05v4.13l-1.83 1.05-1.77 1.08-1.82-1.08-1.8-1.05V24.4l1.8-1.05 1.82-1.05z"/>
      <path fill="#01AC9A" d="M27.34 22.47l1.71 1.02 1.7.99v3.95l-1.7 1.02-1.7.99-1.74-1-1.74-1v-3.96l1.74-1 1.73-1.01z"/>
      <path fill="#1FEBE0" d="M27.34 22.29v.18l-3.47 2v3.96l-.15.1v-4.14l3.62-2.1z"/>
      <path fill="#2B2A29" d="M27.34 10.64l1.62.95 1.68.96v3.84l-1.68.93-1.62.98-1.67-.98-1.65-.93v-3.84l1.65-.96 1.67-.95z"/>
      <path fill="#03C6B6" d="M27.34 10.79l1.56.92 1.59.93v3.66l-1.59.9-1.56.93-1.61-.93-1.56-.9v-3.66l1.56-.93 1.61-.92z"/>
      <path fill="#1FEBE0" d="M27.34 10.64v.15l-3.17 1.85v3.66l-.15.09v-3.84l3.32-1.91z"/>
      <path fill="#2B2A29" d="M27.34 0l1.41.8 1.44.85v3.29l-1.44.87-1.4.81-1.47-.8-1.41-.88V1.65l1.4-.84L27.35 0z"/>
      <path fill="#06CEC1" d="M27.34.15l1.38.78 1.35.78v3.2l-1.35.78-1.38.78-1.37-.78-1.38-.78V1.7l1.38-.77 1.37-.78z"/>
      <path fill="#1FEBE0" d="M27.34 0v.15L24.6 1.71v3.2l-.12.03v-3.3L27.34 0z"/>
      <path fill="#2B2A29" d="M15.96 5.63l1.41.81 1.47.87v3.3l-1.47.8-1.4.87-1.47-.87-1.41-.8V7.3l1.4-.87 1.47-.8z"/>
      <path fill="#04C3B3" d="M15.96 5.78l1.38.78 1.35.81v3.18l-1.35.77-1.38.81-1.37-.8-1.38-.78V7.37l1.38-.8 1.37-.79z"/>
      <path fill="#1FEBE0" d="M15.96 5.63v.15l-2.75 1.59v3.18l-.12.06V7.3l2.87-1.68z"/>
      <path fill="#2B2A29" d="M38.85 5.93l1.4.81 1.47.81v3.33l-1.47.8-1.4.87-1.44-.87-1.44-.8V7.55l1.44-.8 1.44-.82z"/>
      <path fill="#08D4C9" d="M38.85 6.05l1.37.78 1.35.8v3.16l-1.35.8-1.37.81-1.38-.8-1.38-.81V7.63l1.38-.8 1.38-.78z"/>
      <path fill="#1FEBE0" d="M38.85 5.93v.12l-2.76 1.59v3.15l-.12.09V7.55l2.88-1.62z"/>
      <path fill="#2B2A29" d="M15.87 28.76l1.83 1.05 1.83 1.08V35.1l-1.83 1.05-1.83 1.08-1.85-1.08-1.83-1.05V30.9l1.83-1.08 1.85-1.05z"/>
      <path fill="#009079" d="M15.87 28.94l1.74 1.02 1.77 1.02V35.02l-1.77.99-1.74 1.02L14.11 36l-1.74-.99v-4.04l1.74-1.02 1.76-1.02z"/>
      <path fill="#1FEBE0" d="M15.87 28.76v.18l-3.5 2.04v4.04l-.18.1v-4.23l3.68-2.13z"/>
  </SvgIcon>
);