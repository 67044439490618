import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import get from 'lodash/get';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';
import { useGoogleCaptcha } from 'hooks';

interface IChildren {
  items: Types.IEntity.News[];
  meta: Types.IEntity.Meta;
  isFetched: boolean;
  isLoaded: boolean;
  isLoading: boolean;
}

interface IProps {
  name: string;
  params?: Partial<Types.IParams>;
  onSuccess?: ({ items }: { items: Types.IEntity.News[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({
  name,
  params = {},
  onSuccess = () => { },
  onError = () => { },
  onFinally = () => { }
}: IProps): IChildren => {
  const dispatch = useDispatch();

  const { handleLoad, isLoading } = useGoogleCaptcha()

  const items = useSelector<Store.Types.IState, Types.IEntity.News[]>(state => Selectors.getList(state, name));
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state => get(state, `news.list[${name}].meta`));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => !!get(state, `news.list[${name}].isFetched`));
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => !!get(state, `news.list[${name}].isLoaded`));

  useDeepCompareEffect(() => {
    handleLoad({
      onSuccess(token) {
        if (token) {
          dispatch(
            Actions.List.request({
              token,
              name,
              params: {
                page: params.page || 1,
                limit: params.limit || 10
              },
              callback: {
                onSuccess,
                onError,
                onFinally
              }
            })
          );
        }
      },
    })

  }, [params]);

  return {
    items,
    meta,
    isFetched,
    isLoaded,
    isLoading
  };
};

export default useList;
