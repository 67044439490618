import { useQuery } from 'react-query';
import { Constants } from '..';
import { RegsterRefs } from '../api';
import { IQuery } from '../types';
import { registerRefs } from '../mappers';

interface IProps {
  registerId: number;
}

const useRegisterRef = ({ registerId }: IProps) => {
  const initialData = { item: registerRefs({}) } as IQuery.RegisterRefs;
  const { data = initialData, ...acc } = useQuery<IQuery.RegisterRefs, string, IQuery.RegisterRefs>(
    [Constants.ENTITY, 'register-refs', registerId],
    async () => {
      const { data } = await RegsterRefs({ registerId });
      return {
        item: registerRefs(data)
      };
    },
    {
      enabled: !!registerId,
      refetchOnWindowFocus:false,
      retry: false,
    }
  );
  return { ...data, ...acc };
};

export default useRegisterRef;
