import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import * as ApplicationModule from 'modules/application';
import * as CertificateModule from 'modules/certificate';

import Type from 'components/Type';
import Status from 'components/Status';

import classes from '../CertificateVew.module.scss';
import Empty from 'components/Empty';

interface IProps {
  items: CertificateModule.Types.IEntity.Application[];
}

const Applications: React.FC<IProps> = ({ items }) => {
  const { t, i18n } = useTranslation();

  const STATUS = ApplicationModule.Constants.STATUS;
  const status = {
    [STATUS.IN_PROCESS]: 'warning' as const,
    [STATUS.CORRECTION]: 'warning' as const,
    [STATUS.WAITING_TERMINATION]: 'warning' as const,
    [STATUS.REVIEW]: 'warning' as const,
    [STATUS.IN_PAYMENT]: 'warning' as const,
    [STATUS.DENIED]: 'danger' as const,
    [STATUS.CANCELLED]: 'danger' as const,
    [STATUS.COMPLETED]: 'success' as const
  };

  return (
    <div className={classes.table}>
      <div className={classes.tableHeader}>
        <div className={cx(classes.tableCell, classes.tableCellStatus)}>{t('Status')}</div>
        <div className={classes.tableCell}>{t('Application')}</div>
        <div className={cx(classes.tableCell, classes.tableCellRight)}>{t('Application_type')}</div>
        <div className={cx(classes.tableCell, classes.tableCellRight, classes.tableCellDate)}>{t('date_of_sending')}</div>
      </div>
      <div className={classes.tableBody}>
        {!!items.length ? items.map(item => (
          <div key={item.id} className={classes.tableRow}>
            <div className={cx(classes.tableCell, classes.tableCellStatus)}>
              <Status variant={get(status, `${get(item, 'status.key')}`)}>{get(item, `status.title[${i18n.language}]`)}</Status>
            </div>
            <div className={classes.tableCell}>№ {get(item, 'id')}</div>
            <div className={cx(classes.tableCell, classes.tableCellRight)}>
              <Type variant="blue">{get(item, `type.title[${i18n.language}]`) || get(item, 'type.key')}</Type>
            </div>
            <div className={cx(classes.tableCell, classes.tableCellRight, classes.tableCellDate)}>{get(item, 'date')}</div>
          </div>
        )) : <Empty />}
      </div>
    </div>
  );
};

export default Applications;