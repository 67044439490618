import { takeLatest, call, put, all } from 'redux-saga/effects';
import { normalize, NormalizedSchema } from 'normalizr';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import Schema from './schema';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { name, params, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: { registers: Types.IApi.Certificate.Response[] } }> = yield call(Api.List, {
      params
    });
    const items = (get(data, 'data.registers') || []).map(application => Mappers.certificate(application));
    const meta = Mappers.meta(get(data, 'data', {}) || {});
    const { entities, result }: NormalizedSchema<{ certificate: Types.IEntities }, number[]> = normalize(items, [
      Schema
    ]);
    yield put(Actions.Entities({ items: entities?.certificate }));
    yield put(Actions.List.success({ name, ids: result, meta }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.List.failure({ name, error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Single(action: ReturnType<typeof Actions.Single.request>) {
  const { id, callback } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.Certificate.Response }> = yield call(Api.Single, { id });
    const item = Mappers.certificate(get(data, 'data') || {});
    const { entities, result }: NormalizedSchema<{ certificate: Types.IEntities }, number> = normalize(item, Schema);
    yield put(Actions.Entities({ items: entities?.certificate }));
    yield put(Actions.Single.success({ id: result }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { item });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Single.failure({ id, error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Report(action: ReturnType<typeof Actions.Report.request>) {
  const { name, params, callback } = action.payload;

  try {
    const { data }: AxiosResponse<{ data: { content: Types.IApi.Report[] } }> = yield call(Api.Report, {
      params
    });
    const items = (get(data, 'content') || []).map(report => Mappers.report(report));
    //@ts-ignore
    const meta = Mappers.reportMeta(data);

    yield put(Actions.Report.success({ name, items, meta }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Report.failure({ name, error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List),
    takeLatest(Constants.REPORT.REQUEST, Report),
    takeLatest(Constants.SINGLE.REQUEST, Single)
  ]);
}
