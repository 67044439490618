import { action } from 'typesafe-actions';

import * as Constants from './constants';
import * as Types from './types';

export const ByUuid = {
    request: (args: Types.IAction.ByUuid.Request) => action(Constants.BY_UUID.REQUEST, args),
    success: (args?: Types.IAction.ByUuid.Success) => action(Constants.BY_UUID.SUCCESS, args),
    failure: (args: Types.IAction.ByUuid.Failure) => action(Constants.BY_UUID.FAILURE, args)
};

export const ByNumber = {
    request: (args: Types.IAction.ByNumber.Request) => action(Constants.BY_NUMBER.REQUEST, args),
    success: (args?: Types.IAction.ByNumber.Success) => action(Constants.BY_NUMBER.SUCCESS, args),
    failure: (args: Types.IAction.ByNumber.Failure) => action(Constants.BY_NUMBER.FAILURE, args)
};