import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';
import { useGoogleCaptcha } from 'hooks';

interface IChildren {
  items: Types.IEntity.Workers[];
  isFetched: boolean;
  isLoaded: boolean;
  isLoading: boolean
}

interface IProps {
  id: number;
  uuid: string;
  params?: Partial<Types.IBranchParams>;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useWorkers = ({
  id,
  uuid,
  onSuccess = () => { },
  onError = () => { },
  onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();
  const { handleLoad, isLoading } = useGoogleCaptcha()
  const items = useSelector<Store.Types.IState, Types.IEntity.Workers[]>(state => state.branches.open_workers.items);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.branches.open_workers.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.branches.open_workers.isLoaded);

  useEffect(() => {
    handleLoad({
      onSuccess(token) {
        if (token) {
          dispatch(Actions.OpenWorkers.request({
            token,
            id,
            uuid,
            callback: {
              onSuccess,
              onError,
              onFinally
            }
          }));
        }
      },
    })

  }, []);

  return {
    items,
    isFetched,
    isLoaded,
    isLoading
  };
};

export default useWorkers;