import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IReturn {
  items: Types.IEntity.Certificate[];
  isFetched: boolean;
}

interface IProps {
  onSuccess?: () => void;
  onError?: (error: string) => void;
  onFinally?: () => void;
}

const useList = ({ onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps = {}): IReturn => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.Certificate[]>(state => state.digitalSign.list.items);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.digitalSign.list.isFetched);

  useEffect(() => {
    dispatch(Actions.List.request({
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, []);

  return { items, isFetched };
};

export default useList;