import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Hooks from '../../hooks';
import * as Types from '../../types';

import classes from './NotificationMessages.module.scss';

interface IProps {
  item: Types.IEntity.Message;
  style: any;
}

const Item: React.FC<IProps> = ({ item, style }) => {
  const { t } = useTranslation();
  Hooks.useMessage({ id: item.id });

  return (
    <div className={classes.item} {...{ style }}>
      <div className={classes.itemContent}>
        <div className={classes.itemTitle}>{item.title}</div>
        <div className={classes.itemFooter}>
          <div className={classes.itemLink}>{t('read_more')}</div>
          <div className={classes.itemTime}>{item.time}</div>
        </div>
      </div>
      <div className={classes.itemImage}>
        <img src={item.image}/>
      </div>
    </div>
  );
};

export default Item;