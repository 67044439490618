import { createContext } from 'react';

import * as Types from './types';

export const initialState: Types.State = {
  categoryId: 0,
  subCategoryId: 0,
  documentId: 0,
  isVisible: false,
  isSubCategorySelected: false,
  isDocumentSelected: false
};

const context = createContext<Types.Value>({
  state: { ...initialState },
  setState: () => null,
  methods: {
    setCategory: (id: number) => null,
    setSubCategory: (id: number, categoryId?: number) => null,
    setDocument: (id: number, categoryId?: number, subCategoryId?: number) => null,
    setClose: () => null
  }
});

export default context;