import React from 'react';

export interface IProps {
  className?: string;
  viewBox?: string;
  fill?: string;
  width?: string;
  height?: string;
}

const SvgIcon: React.FC<IProps> = ({ viewBox, children, ...props }) => {
  return (
    <svg {...props} {...{ viewBox }}>
      {children}
    </svg>
  );
};

export default SvgIcon;