import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Menu.module.scss';

interface IProps {
  link?: boolean;
  to: string;
  children: React.ReactNode;
}

const Item: React.FC<IProps> = ({ link, to, children }) => {
  if (link) {
    return (
      <a className={classes.item} href={to} target="_blank">
        {children}
      </a>
    );
  }
  return (
    <NavLink className={classes.item} activeClassName={classes.itemActive} {...{ to }} exact>
      {children}
    </NavLink>
  );
};

export default Item;
