import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';
import { toast } from 'react-hot-toast';
import moment from 'moment';

// import config from 'config';

import { http } from 'services';

import * as CertificateModule from 'modules/certificate';
import * as SystemModule from 'modules/system';

import { Nav } from 'components/Modal';
import Type from 'components/Type';
import InfoBlock from 'components/InfoBlock';
import Button from 'components/Button';
import * as Icon from 'components/Icon';
import * as Details from 'components/Details';
import Spinner from 'components/Spinner';
import TextBlock from 'components/TextBlock';
// import * as Certificate from 'components/Certificate';

// import ProcessButtons from './components/ProcessButtons';
import Payments from './components/Payments';
import Applications from './components/Applications';
import ReportList from './components/ReportList';

import classes from './CertificateVew.module.scss';
import DocumentGenerator from 'components/DocumentGenerator';
import BranchesList from './components/BranchesList';
import DynamicProcessButtons from './components/DynamicProcessButtons';

interface IProps {
  id: number;
  close: () => void;
}

enum TAB {
  DOCUMENT = 1,
  INFO = 2,
  PAYMENTS = 3,
  APPLICATIONS = 4,
  REPORTS = 5,
  BRANCHES = 6
}

const View: React.FC<IProps> = ({ id, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const { item, isFetched, refetch } = CertificateModule.Hooks.useSingle({
    id,
    onError: () => {
      close();
      toast.error(`${t('internal_server_error')}`);
    }
  });

  const [activeTabId, setActiveTabId] = useState(TAB.DOCUMENT);
  // const [isDownloading, setDownloading] = useState(false);
  // const [isPrinting, setPrinting] = useState(false);

  const [isUploading, setUploading] = useState(false);

  const fileRef = useRef(null);

  if (!item.id || !isFetched) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  }

  const tabs = [
    { id: TAB.DOCUMENT, title: t('document') },
    { id: TAB.INFO, title: t('detailed_info') },
    { id: TAB.PAYMENTS, title: t('Payments') },
    { id: TAB.APPLICATIONS, title: t('Applications') },
    ...(item.branch ? [{ id: TAB.BRANCHES, title: t('branches') }] : [])
    // { id: TAB.REPORTS, title: t('reports') }
  ];

  // const download = ({ onSuccess, onFinally }) => {
  //   http.request
  //     .get(`/certificate/${id}/pdf?language=${language}`, {
  //       baseURL: config.document.base_url,
  //       responseType: 'arraybuffer'
  //     })
  //     .then(({ data }) => {
  //       onSuccess(data);
  //     })
  //     .finally(onFinally);
  // };

  const upload = ({ onSuccess, onFinally, file }) => {
    http.request.post(`/file/open_source/upload`, file).then(({ data }) => {
      http.request
        .post(`/process/register-report`, { register_id: id, report_file_id: data.data.fileId })
        .then(({ data }) => {
          onSuccess(data);
        })
        .finally(onFinally);
      onSuccess(data);
    });
  };

  // const downloadCertificate = () => {
  //   setDownloading(true);
  //   download({
  //     onSuccess: data => {
  //       const url = window.URL.createObjectURL(new Blob([data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', `certificate-${id}.pdf`);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //       window.URL.revokeObjectURL(url);
  //     },
  //     onFinally: () => setDownloading(false)
  //   });
  // };

  // const printCertificate = () => {
  //   setPrinting(true);
  //   download({
  //     onSuccess: data => {
  //       const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.target = '_blank';
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //       window.URL.revokeObjectURL(url);
  //     },
  //     onFinally: () => setPrinting(false)
  //   });
  // };

  const STATUS = CertificateModule.Constants.STATUS;
  const status = {
    [STATUS.ACTIVE]: 'green' as const,
    [STATUS.SUSPENDED]: 'orange' as const,
    [STATUS.SUSPEND_PROCESS]: 'orange' as const,
    [STATUS.NOT_PAID]: 'orange' as const,
    [STATUS.EXPIRED]: 'red' as const,
    [STATUS.CANCELED]: 'red' as const,
    [STATUS.CHANGED]: 'orange' as const,
    [STATUS.TERMINATED]: 'orange' as const
  };

  const feeDateBeginFormatted = moment(item.feeDate.begin, 'MM.YYYY').format('MMMM, YYYY');
  const feeDateEndFormatted = moment(item.feeDate.end, 'MM.YYYY').format('MMMM, YYYY');

  const uploadButtonClick = () => {
    if (fileRef && fileRef.current) {
      // @ts-ignore
      fileRef.current.click();
    }
  };

  const startUpload = () => {
    // @ts-ignore

    if (fileRef.current.files.length) {
      // @ts-ignore

      let file = fileRef.current.files[0];

      let formData = new FormData();

      formData.append('file', file);

      setUploading(true);
      upload({
        file: formData,
        onSuccess: () => {
          dispatch(
            CertificateModule.Actions.Report.request({
              name: 'all',
              params: {
                registerId: id,
                page: 1,
                limit: 10
              },
              callback: {}
            })
          );
        },
        onFinally: () => {
          setUploading(false);
        }
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={close} />
      <div className={classes.headerMobileTitle}>{t('document')}</div>
      <div className={classes.header}>
        <div className={classes.headerImage}>
          <img src={item.category.image} alt={get(item, `category.title[${language}]`)} />
        </div>
        <div className={classes.headerContent}>
          <div className={classes.tags}>
            <Type variant="blue" className="m-r-12">
              {get(item, `type.title[${language}]`)}
            </Type>
            <Type variant="dark-gray">{item.name}</Type>
          </div>
          <div className={classes.category}>{get(item, `category.title[${language}]`)}</div>
          <div className={classes.title}>{get(item, `document[${language}]`)}</div>
          <div className={classes.info}>
            <InfoBlock
              variant={status[item.status.key]}
              title={t('Status')}
              children={get(item, `status.title[${language}]`)}
              className="m-r-12"
            />
            <InfoBlock variant="white" title={t('License_number')} children={item.number} className="m-r-12" />
            <InfoBlock
              variant="white"
              title={t('date_of_issue')}
              children={item.registration.date}
              className="m-r-12"
            />
            {item.type.key !== 'NOTIFICATION' && (
              <InfoBlock variant="white" title={t('Valid_until')} children={item.expires} />
            )}
          </div>
        </div>
      </div>
      <div className={classes.tabWrapper}>
        <div className={classes.tabWrapperInner}>
          {tabs.map(tab => (
            <div
              key={tab.id}
              className={cx(classes.tabItem, tab.id === activeTabId && classes.tabItemActive)}
              onClick={() => setActiveTabId(tab.id)}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.content}>
        {[TAB.INFO, TAB.DOCUMENT].includes(activeTabId) && (
          <div className={classes.buttonList}>
            <div className={classes.buttonBlock}>
              <DynamicProcessButtons
                suspensionApplicationId={item.suspension_application_id}
                registerId={item.id}
                licenseId={item.number}
                feeDateEnd={item.feeDate.end}
              />
            </div>
            {/* {![STATUS.EXPIRED, STATUS.TERMINATED].includes(item.status.key) && (
              <div className={classes.buttonBlock}>
                <ProcessButtons
                  {...{ id }}
                  item={item}
                  licenseId={item.number}
                  status={item.status.key}
                  type={item.type.key}
                  hasUsageFee={item.hasUsageFee}
                  feeDateEnd={item.feeDate.end}
                  isArchive={item.isArchive}
                />
              </div>
            )} */}
            {/* {activeTabId === TAB.DOCUMENT && (
              <div className={classes.buttonBlock}>
                <Button
                  variant="stroke"
                  isLoading={isDownloading}
                  prefixIcon={<Icon.System.Download />}
                  className="m-r-12"
                  onClick={downloadCertificate}
                >
                  {t('Save')}
                </Button>
                <Button
                  variant="stroke"
                  isLoading={isPrinting}
                  prefixIcon={<Icon.System.Print />}
                  onClick={printCertificate}
                >
                  {t('Print')}
                </Button>
              </div>
            )} */}
          </div>
        )}

        {activeTabId === TAB.DOCUMENT && (
          // <div className={classes.document}>
          //   <Certificate.Main
          //     type={get(item, 'type.key')}
          //     name={get(item, 'name') || ''}
          //     number={get(item, 'number') || ''}
          //     document={get(item, `document[${language}]`) || ''}
          //     organization={get(item, `organization[${language}]`) || ''}
          //     register_number={get(item, 'registration.number') || ''}
          //     register_date={get(item, 'registration.date') || ''}
          //     tin={get(item, 'tin') || ''}
          //     owner_type={get(item, 'owner_type') || ''}
          //     pin={get(item, 'pin') || ''}
          //     expire_date={get(item, 'expires') || ''}
          //     region={get(item, `region[${language}]`) || ''}
          //     subRegion={get(item, `subRegion[${language}]`) || ''}
          //     village={get(item, `village[${language}]`) || ''}
          //     address={get(item, 'address') || ''}
          //     activity_addresses={get(item, 'activityAddresses') || []}
          //     brand_mark={get(item, 'brand_mark') || ''}
          //     qr={get(item, 'link')}
          //   />
          //   <Certificate.Specializations
          //     type={get(item, 'type.key')}
          //     number={get(item, 'number') || ''}
          //     specializations={get(item, 'specializations') || []}
          //     training_categories={get(item, 'training_categories') || []}
          //     specializationsByAddress={get(item, 'specializationsByAddress')}
          //     is_multi_specialization={get(item, 'is_multi_specialization')}
          //     qr={get(item, 'link')}
          //     additions={get(item, 'additions')}
          //   />
          // </div>
          <DocumentGenerator uuid={item.uuid} />
        )}

        {activeTabId === TAB.INFO && (
          <>
            <div className={classes.listTitle}>{t('detailed_info')}</div>
            <Details.List className="m-b-24">
              <Details.Item title={t('Status')}>{get(item, `status.title[${language}]`)}</Details.Item>
              <Details.Item title={t('name_of_licensee')}>{item.name}</Details.Item>
              <Details.Item title={t('Address')}>{item.address}</Details.Item>
              <Details.Item title={t('inn_of_licensee')}>{item.tin}</Details.Item>
              {/*<Details.Item title={t('license_series')}>{item.registration.serial}</Details.Item>*/}
              <Details.Item title={t('License_number')}>{item.number}</Details.Item>
              <Details.Item title={t('registry_number')}>{item.registration.number}</Details.Item>
              <Details.Item title={t('date_of_issue')}>{item.registration.date}</Details.Item>
              {item.type.key !== 'NOTIFICATION' && <Details.Item title={t('Valid_until')}>{item.expires}</Details.Item>}
              {!!item.activityAddresses && !!item.activityAddresses.length && (
                <Details.Item title={t('Activity_addresses')}>
                  {item.activityAddresses.map(activityAddress => (
                    <>
                      {get(activityAddress, `[${language}]`)} <br />
                    </>
                  ))}
                </Details.Item>
              )}
              {!!item.feeDate.begin && !!item.feeDate.end && (
                <Details.Item title={t('fees_start_end_date')}>
                  {item.feeDate.begin ? `${feeDateBeginFormatted} ${item.feeDate.end ? ' - ' : ''}` : ''}
                  {item.feeDate.end ? feeDateEndFormatted : ''}
                </Details.Item>
              )}
            </Details.List>
            {!!item.specializations && !!item.specializations.length && (
              <>
                <div className={classes.listTitle}>{t('Specializations')}</div>
                <div className={classes.list}>
                  {item.specializations.map((item, i) => {
                    const title = get(item, `[name][${language}]`);
                    const description = get(item, `[description][${language}]`);
                    return (
                      <div key={item.id} className={classes.listItem}>
                        <div className={classes.listItemNumber}>{i + 1}</div>
                        <div className={classes.listItemContent}>
                          <p>{HtmlParser(title)}</p>
                          {!!description && <p>{HtmlParser(description)}</p>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}

        {activeTabId === TAB.PAYMENTS && (
          <Payments
            handleFetch={() => refetch && refetch()}
            item={item}
            payments={item.payments}
            hasUsageFees={item.hasUsageFee}
            fees={item.fees}
            feeDateEnd={item.feeDate.end}
          />
        )}

        {activeTabId === TAB.BRANCHES && <BranchesList id={id} />}

        {activeTabId === TAB.APPLICATIONS && <Applications items={item.applications} />}

        {activeTabId === TAB.REPORTS && (
          <>
            <TextBlock className="m-b-24" variant="BLUE">
              {t('the_report_must_be_sent_before_the_10_th_day_of_each_month')}
            </TextBlock>
            <input
              type="file"
              ref={fileRef}
              onChange={startUpload}
              id="theFile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: 'none' }}
            />

            <div className={classes.buttonList}>
              <div className={classes.buttonBlock}>
                <Button variant="stroke" prefixIcon={<Icon.System.Download />} className="m-r-12">
                  {t('download_template')}
                </Button>
                <Button
                  variant="blue"
                  isLoading={isUploading}
                  prefixIcon={<Icon.System.Upload />}
                  onClick={uploadButtonClick}
                >
                  {t('send_report')}
                </Button>
              </div>
            </div>
            <ReportList certificateId={id} />
          </>
        )}
      </div>
    </div>
  );
};

export default View;
