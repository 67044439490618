import { takeLatest, call, all, put } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import { storage } from 'services';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Types from './types';

export function* LanguageChange(action: ReturnType<typeof Actions.Language.change>) {
  const { language } = action.payload;
  yield call(storage.local.set, 'language', language);
}

export function* Settings(action: ReturnType<typeof Actions.Settings.request>) {
  try {
    const { data }: AxiosResponse<Types.IApi.Settings.Variable[]> = yield call(Api.Settings);
    const variables = Mappers.variables(get(data, 'data'));
    yield put(Actions.Settings.success({ variables }));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Settings.failure({ error: errorMessage }));
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LANGUAGE.CHANGE, LanguageChange),
    takeLatest(Constants.SETTINGS.REQUEST, Settings)
  ]);
}