import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Document[];
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  search?: string;
  showAll?: boolean;
  filter?: Partial<Types.IFilter>;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useListSearch = ({ search = '', showAll = false, filter, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps = {}): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.Document[]>(state => Selectors.getListSearch(state, search, showAll, filter));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.document.listByCategory.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.document.listByCategory.isLoaded);
  const updated_date = useSelector<Store.Types.IState, number>(state => state.document.listByCategory.updated_date);

  useEffect(() => {
    dispatch(Actions.List.request({
      updated_date,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, []);

  return {
    items,
    isFetched,
    isLoaded
  };
};

export default useListSearch;