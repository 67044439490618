import { ActionType } from 'typesafe-actions';
import isEqual from 'lodash/isEqual';

import { storage } from 'services';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const language = storage.local.get('language');

const initialState: Types.IState = {
  language: Object.values(Constants.LANGUAGES).includes(language) ? language : Constants.LANGUAGES.OZ,
  settings: {
    isLoaded: false,
    variables: {
      isUnderConstruction: false
    }
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.LANGUAGE.CHANGE: {
      const { language } = action.payload;
      return {
        ...state,
        language
      };
    }
    case Constants.SETTINGS.SUCCESS: {
      const { variables } = action.payload;

      if(!state.settings.isLoaded || !isEqual(state.settings.variables, variables)){
        return {
          ...state,
          settings: {
            ...state.settings,
            isLoaded: true,
            variables
          }
        }
      }

      return state;
    }
    case Constants.SETTINGS.FAILURE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoaded: true
        }
      }
    }
    default:
      return state;
  }
}