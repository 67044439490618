import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

import * as Types from './types';
import * as Constants from './constants';

export const token = (item: Types.IApi.Login.Response): Types.IEntity.Token => {
  const token = get(item, 'data.token');
  return {
    accessToken: get(token, 'access_token') || '',
    refreshToken: get(token, 'refresh_token') || ''
  };
};

export const profile = (item: Types.IApi.Login.Response): Types.IEntity.Profile => {
  const user = get(item, 'data.user') || {};

  const firstName = get(user, 'firstname') || '';
  const lastName = get(user, 'lastname') || '';
  const middleName = get(user, 'middlename') || '';

  return {
    id: get(user, 'id') || 0,
    type: get(item, 'data.user_type') || Constants.TYPE.INDIVIDUAL,
    tin: get(user, 'tin') || 0,
    pinfl: get(user, 'pinfl') || '',
    username: get(user, 'username') || '',
    firstName,
    lastName,
    middleName,
    titleName: `${lastName ? `${startCase(lowerCase(lastName))} ` : ''}${firstName ? `${startCase(lowerCase(firstName))} ` : ''}`,
    fullName: `${lastName ? `${lastName} ` : ''}${firstName ? `${firstName} ` : ''}${middleName ? `${middleName} ` : ''}`,
    birthday: get(user, 'birth_date') || '',
    gender: get(user, 'gender') ? ((String(get(user, 'gender')) === '1' && 'Man') || (String(get(user, 'gender')) === '2' && 'Woman')) || '' : '',
    phone: get(user, 'mobile_phone') || '',
    email: get(user, 'email') || '',
    passport: {
      number: get(user, 'passport_serial_number') || '',
      place: get(user, 'pport_issue_place') || ''
    },
    registeredAt: get(user, 'registered_at') || ''
  };
};

export const company = (item: Types.IApi.Login.Response): Types.IEntity.Company => {
  const type = get(item, 'data.user_type') || Constants.TYPE.INDIVIDUAL;
  const company = type === Constants.TYPE.LEGAL_ENTITY ? (get(item, 'data.legal_entity') || {}) : (get(item, 'data.entrepreneur') || {});

  return {
    name: get(company, 'name') || '',
    tin: get(company, 'tin') || '',
    phone: get(company, 'phone') || '',
    address: get(company, 'address') || '',
    region: langStrings(get(company, 'address_region') || ''),
    subRegion: langStrings(get(company, 'address_sub_region') || ''),
    csdp: get(company, 'csdp') || '',
    oked: get(company, 'oked') || '',
    opf: langStrings(get(company, 'opf') || ''),
    register: {
      date: get(company, 'registration_date') || '',
      number: get(company, 'registration_number') || ''
    }
  };
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};