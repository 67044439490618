import React, { useState } from 'react';

import config from 'config';

import { http } from 'services';

import Spinner from 'components/Spinner';
import * as Icon from 'components/Icon';
import classes from './ImageAuth.module.scss';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Empty from 'components/Empty';

interface IProps {
  invoiceNumber: string;
  language?: string;
  hasDownload?: boolean;
  hideInvoice?: boolean;
}

const Invoice: React.FC<IProps> = ({ invoiceNumber, hasDownload, hideInvoice, language }) => {
  const [isLoading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [image, setImage] = useState('');
  const { t, i18n } = useTranslation();
  const img: React.Ref<HTMLImageElement> = React.createRef();
  const [isDownloading, setDownloading] = useState(false);
  const [isPrinting, setPrinting] = useState(false);

  React.useEffect(() => {
    if (!hideInvoice) {
      setLoading(true);
      http.request
        .get(`/receipt/${invoiceNumber}/image?language=${language || i18n.language}`, {
          baseURL: config.document.base_url,
          responseType: 'arraybuffer',
          headers: {
            Accept: 'image/png'
          }
        })
        .then(res => {
          const blob = new Blob([res.data], {
            type: 'image/png'
          });
          const objectURL = URL.createObjectURL(blob);
          setImage(objectURL);
        })
        .catch(e => setHasError(e))
        .finally(() => setLoading(false));
    }
  }, []);

  const download = ({ id, onSuccess, onFinally }) => {
    http.request
      .get(`/receipt/${id}/pdf?language=${language || i18n.language}`, {
        baseURL: config.document.base_url,
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .finally(onFinally);
  };

  const downloadReceipt = id => {
    setDownloading(true);
    download({
      id,
      onSuccess: data => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `receipt-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
      onFinally: () => setDownloading(false)
    });
  };

  const printReceipt = id => {
    setPrinting(true);
    download({
      id,
      onSuccess: data => {
        const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
      onFinally: () => setPrinting(false)
    });
  };

  if (!hideInvoice && isLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  }

  if (hasError) {
    return (
      <div className={classes.wrapper}>
        <Empty hasButton={false} title={t('invoice_not_found')} />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      {hasDownload && (
        <div className={classes.buttonList}>
          <Button
            variant="stroke"
            isLoading={isDownloading}
            disabled={isDownloading}
            prefixIcon={<Icon.System.Download />}
            htmlType="button"
            onClick={() => downloadReceipt(invoiceNumber)}
          />
          <Button
            variant="stroke"
            isLoading={isPrinting}
            disabled={isPrinting}
            className="m-l-12"
            prefixIcon={<Icon.System.Print />}
            htmlType="button"
            onClick={() => printReceipt(invoiceNumber)}
          />
        </div>
      )}
      {!hideInvoice ? <img src={image} alt="invoice-image" ref={img} /> : ''}
    </div>
  );
};

export default Invoice;
