import React from 'react';
import cx from 'classnames';

import classes from './Status.module.scss';

interface IProps {
  variant: 'success' | 'danger' | 'warning' | string;
  block?: boolean;
  className?: string;
  children: string;
}

const Status: React.FC<IProps> = ({ children, variant, block, className }) => (
  <div style={{ backgroundColor: variant }} className={cx(classes.wrapper, className, variant && classes[`wrapper--${variant}`], block && classes[`wrapper--block`])}>{children}</div>
);

export default Status;