import 'core-js';
import 'node-remove-polyfill';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query"
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import config from './config';

import 'services/i18n';
import 'services/moment';
import { sentry, storage } from 'services';

import App from './App';
import { get } from 'lodash';
import toast from 'react-hot-toast';
// import Snowfall from 'react-snowfall';

const queryResponseHandler = data => {
  const language = storage.local.get('language');

  const type = get(data, `data.message.type`);
  const message = get(data, `data.message.message[${language}]`);
  const status = get(data, 'data.status');

  if (message && status && type) {

    if (type === 'ERROR') {
      toast.error(message);
    }

    if (type === 'WARNING') {
      toast.error(message);
    }

    if (type === 'INFO') {
      toast.error(message);
    }
  }
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: () => queryResponseHandler,
    onError: queryResponseHandler
  }),
  mutationCache: new MutationCache({
    onSuccess: () => queryResponseHandler,
    onError: queryResponseHandler
  })
})

ReactDOM.render(
  <>
    {/* <Snowfall
      style={{
        position: 'fixed',
        width: '100vw',
        height: "100vh",
        zIndex: 999
      }}
      snowflakeCount={150}
      radius={[1, 5]}
      wind={[0, 7]}
      speed={[1, 6]}
    // images={images}
    /> */}
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </>,
  document.getElementById('root')
);

serviceWorker.unregister();

if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  sentry.init({
    dsn: config.sentry.dsn,
    environment: config.app.env,
    release: config.app.version
  });
}
