import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 28 22">
    <path  fill="currentColor" d="M24.8881 0.956004L1.2481 10.072C-0.365238 10.72 -0.355905 11.62 0.952095 12.0213L7.02143 13.9147L21.0641 5.05467C21.7281 4.65067 22.3348 4.868 21.8361 5.31067L10.4588 15.5787H10.4561L10.4588 15.58L10.0401 21.836C10.6534 21.836 10.9241 21.5547 11.2681 21.2227L14.2161 18.356L20.3481 22.8853C21.4788 23.508 22.2908 23.188 22.5721 21.8387L26.5974 2.868C27.0094 1.216 25.9668 0.468004 24.8881 0.956004Z"/>
  </SvgIcon>
);


