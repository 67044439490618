import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 64 64">
      <path fill="url(#paint0_linear)" d="M32 64a7.19 7.19 0 100-14.38A7.19 7.19 0 0032 64z"/>
      <path fill="url(#paint1_linear)" d="M32 49.62a7.19 7.19 0 00-7.16 6.52l7.83 7.83A7.19 7.19 0 0032 49.62z"/>
      <path fill="url(#paint2_linear)" d="M55.56 44.27H8.44a5.83 5.83 0 100 11.66h47.12a5.83 5.83 0 100-11.66z"/>
      <path fill="url(#paint3_linear)" d="M52.19 39.88V28.2A20.2 20.2 0 0036.94 8.62 5.7 5.7 0 0032 0a5.73 5.73 0 00-4.94 8.62A20.19 20.19 0 0011.82 28.2v11.68a4.58 4.58 0 01-4.58 4.58h49.52a4.58 4.58 0 01-4.57-4.58zM32 8a2.29 2.29 0 110-4.58 2.29 2.29 0 010 4.58z"/>
      <path fill="url(#paint4_linear)" d="M2.69 51.26a5.39 5.39 0 005.75 4.65h47.12a5.39 5.39 0 005.75-4.65H2.7z"/>
      <defs>
          <linearGradient id="paint0_linear" x1="30.86" x2="43.23" y1="54.91" y2="75.53" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFC200"/>
              <stop offset=".27" stopColor="#FB0"/>
              <stop offset=".66" stopColor="#FFA801"/>
              <stop offset="1" stopColor="#FF9102"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="43.13" x2="26.69" y1="66.11" y2="49.66" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFC200" stopOpacity="0"/>
              <stop offset=".2" stopColor="#FB0" stopOpacity=".2"/>
              <stop offset=".5" stopColor="#FFA700" stopOpacity=".5"/>
              <stop offset=".85" stopColor="#F80" stopOpacity=".85"/>
              <stop offset="1" stopColor="#FF7800"/>
          </linearGradient>
          <linearGradient id="paint2_linear" x1="24.79" x2="55.67" y1="38.08" y2="89.55" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFC200"/>
              <stop offset=".27" stopColor="#FB0"/>
              <stop offset=".66" stopColor="#FFA801"/>
              <stop offset="1" stopColor="#FF9102"/>
          </linearGradient>
          <linearGradient id="paint3_linear" x1="24.69" x2="52.87" y1="25.94" y2="52.47" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFC200"/>
              <stop offset=".27" stopColor="#FB0"/>
              <stop offset=".66" stopColor="#FFA801"/>
              <stop offset="1" stopColor="#FF9102"/>
          </linearGradient>
          <linearGradient id="paint4_linear" x1="58.25" x2=".02" y1="50.63" y2="56.64" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFC200" stopOpacity="0"/>
              <stop offset=".2" stopColor="#FB0" stopOpacity=".2"/>
              <stop offset=".5" stopColor="#FFA700" stopOpacity=".5"/>
              <stop offset=".85" stopColor="#F80" stopOpacity=".85"/>
              <stop offset="1" stopColor="#FF7800"/>
          </linearGradient>
      </defs>
  </SvgIcon>
);