import React from 'react';
import { useTranslation } from 'react-i18next';

import * as ProcessModule from 'modules/process';

import * as Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import { Nav } from 'components/Modal';

import classes from './ApplicantType.module.scss';

interface IProps {
  isLoading?: boolean
  close: () => void;
  onSelect: (value: string) => void;
}


const ApplicantType: React.FC<IProps> = ({ isLoading = false, close, onSelect }) => {
  const { t } = useTranslation();

  const APPLICANT_TYPE = ProcessModule.Constants.APPLICANT_TYPE;
  const items = [
    { title: t(APPLICANT_TYPE.INDIVIDUAL), type: APPLICANT_TYPE.FOREIGN_INDIVIDUAL },
    { title: t(APPLICANT_TYPE.LEGAL_ENTITY), type: APPLICANT_TYPE.FOREIGN_LEGAL_ENTITY }
  ];

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      )}
      <Nav showClose onClose={close} />
      <div className={classes.title}>{t('select_the_type_of_licensee')}</div>
      <div className={classes.list}>
        {items.map(item => (
          <div key={item.type} className={classes.listItem} onClick={() => onSelect(item.type)}>
            <div className={classes.listItemTitle}>{item.title}</div>
            <div className={classes.listItemIcon}>
              <Icon.System.Right />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicantType;
