import React from 'react';

import * as Types from '../types';

import Mask, { MASK_TYPE } from '../Mask';

interface IProps extends Types.FieldProps {
  nextElementId?: string;
}

const Phone: React.FC<IProps> = ({ ...props }) => (
  <Mask
    {...props}
    type={MASK_TYPE.NUMBER}
    mask="## ### ## ##"
    lazy={false}
    prefix="+998"
  />
);

export default Phone;