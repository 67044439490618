import { takeLatest, call, put, all } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { callback, id, params } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: { branches: Types.IApi.Branches.Response[] } }> = yield call(Api.List, { id, params });
    const items = (get(data, 'data.branches') || []).map(branch => Mappers.Branches(branch));
    const meta = Mappers.meta(get(data, 'data', {}) || {});
    yield put(Actions.List.success({ items, meta }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorData = errorResponse?.response?.data?.message;
    yield put(Actions.List.failure({ error: errorData }));
    if (callback?.onError) {
      yield call(callback.onError, errorData);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* OpenList(action: ReturnType<typeof Actions.OpenList.request>) {
  const { callback, uuid, params, token } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: { branches: Types.IApi.OpenBranches.Response[] } }> = yield call(Api.OpenList, { uuid, params, token });
    const items = (get(data, 'data.branches') || []).map(branch => Mappers.Branches(branch));
    const meta = Mappers.meta(get(data, 'data', {}) || {});
    yield put(Actions.OpenList.success({ items, meta }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorData = errorResponse?.response?.data?.message;
    yield put(Actions.OpenList.failure({ error: errorData }));
    if (callback?.onError) {
      yield call(callback.onError, errorData);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}



export function* Workers(action: ReturnType<typeof Actions.Workers.request>) {
  const { callback, id } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: { branches: Types.IApi.Workers.Response[] } }> = yield call(Api.Workers, { id });
    const items = (get(data, 'data.steps') || []).map(branch => Mappers.Workers(branch));
    yield put(Actions.Workers.success({ items }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorData = errorResponse?.response?.data?.message;
    yield put(Actions.Workers.failure({ error: errorData }));
    if (callback?.onError) {
      yield call(callback.onError, errorData);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* OpenWorkers(action: ReturnType<typeof Actions.OpenWorkers.request>) {
  const { callback, id, uuid, token } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: { branches: Types.IApi.OpenWorkers.Response[] } }> = yield call(Api.OpenWorkers, { id, uuid, token });
    const items = (get(data, 'data.steps') || []).map(branch => Mappers.Workers(branch));
    yield put(Actions.OpenWorkers.success({ items }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorData = errorResponse?.response?.data?.message;
    yield put(Actions.OpenWorkers.failure({ error: errorData }));
    if (callback?.onError) {
      yield call(callback.onError, errorData);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}


export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List),
    takeLatest(Constants.OPEN_LIST.REQUEST, OpenList),
    takeLatest(Constants.WORKERS.REQUEST, Workers),
    takeLatest(Constants.OPEN_WORKERS.REQUEST, OpenWorkers)

  ]);
}