import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IProps {
  token: string;
  onSuccess?: ({ message }: { message: Types.IEntity.LangStrings }) => void;
  onError?: ({ error }: { error: Types.IEntity.LangStrings }) => void;
  onFinally?: () => void;
}

interface IReturn {
  isAuthenticated: boolean;
}

let cancelSource = axios.CancelToken.source();

const useEmailVerification = ({ token, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps): IReturn => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector<Store.Types.IState, boolean>(state => state.auth.isAuthenticated);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.auth.isFetched);

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  useEffect(() => {
    if (!isFetched) {
      return;
    }
    if (!!token) {
      cancelSource = axios.CancelToken.source();

      dispatch(Actions.EmailVerification.request({
        values: {
          token
        },
        cancelSource,
        callback: {
          onSuccess,
          onError,
          onFinally
        }
      }));
    }
  }, [token]);

  return {
    isAuthenticated
  }
};

export default useEmailVerification;
