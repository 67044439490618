import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 64 64">
    <g clipPath="url(#clip0)">
      <path fill="url(#paint0_linear)" d="M41.83 45.55l4.22-4.22-3.66-3.67-4.23 4.23 3.67 3.66z"/>
      <path fill="url(#paint1_linear)" d="M24.4 46.35a22.44 22.44 0 110-44.88 22.44 22.44 0 010 44.88z"/>
      <path fill="url(#paint2_linear)" d="M24.4 0a23.9 23.9 0 110 47.82A23.9 23.9 0 0124.4 0zm0 43.85a19.94 19.94 0 100-39.88 19.94 19.94 0 000 39.88z"/>
      <path fill="url(#paint3_linear)" d="M61.24 63.3l2.55-2.56c.95-.95.95-2.47 0-3.42L47.86 41.4l-5.97 5.97 15.93 15.93c.95.95 2.47.95 3.42 0z"/>
      <path fill="url(#paint4_linear)" d="M45.63 39.65l-5.48 5.48a2.23 2.23 0 000 3.15l.27.28c.87.87 2.28.87 3.15 0l5.5-5.49c.86-.87.86-2.28 0-3.15l-.28-.27a2.23 2.23 0 00-3.16 0z"/>
      <path fill="url(#paint5_linear)" d="M44.62 40.66l-3.46 3.46a1.87 1.87 0 000 2.65l.78.78c.73.73 1.92.73 2.65 0l3.46-3.46c.73-.73.73-1.92 0-2.65l-.78-.78a1.87 1.87 0 00-2.65 0z"/>
      <path fill="url(#paint6_linear)" d="M43.35 41.93l-.92.92a1.24 1.24 0 000 1.75l1.68 1.67c.48.49 1.27.49 1.75 0l.91-.91c.49-.48.49-1.27 0-1.75l-1.67-1.68a1.24 1.24 0 00-1.75 0z"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="39.15" x2="43.17" y1="44.56" y2="40.55" gradientUnits="userSpaceOnUse">
        <stop stopColor="#97AAAA"/>
        <stop offset="1" stopColor="#E8EFEE"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="46.5" x2="1.5" y1="46" y2="1" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fff" stopOpacity=".5"/>
        <stop offset="1" stopColor="#fff" stopOpacity=".9"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="24.41" x2="24.41" y1="4.38" y2="58.77" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5B6A6A"/>
        <stop offset="1" stopColor="#303033"/>
      </linearGradient>
      <linearGradient id="paint3_linear" x1="55.21" x2="50.4" y1="51.67" y2="56.48" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5B6A6A"/>
        <stop offset="1" stopColor="#303033"/>
      </linearGradient>
      <linearGradient id="paint4_linear" x1="46.54" x2="42.41" y1="46.04" y2="41.91" gradientUnits="userSpaceOnUse">
        <stop stopColor="#97AAAA"/>
        <stop offset="1" stopColor="#E8EFEE"/>
      </linearGradient>
      <linearGradient id="paint5_linear" x1="40.98" x2="49.57" y1="40.49" y2="49.07" gradientUnits="userSpaceOnUse">
        <stop stopColor="#97AAAA"/>
        <stop offset="1" stopColor="#E8EFEE"/>
      </linearGradient>
      <linearGradient id="paint6_linear" x1="46.54" x2="42.41" y1="46.04" y2="41.91" gradientUnits="userSpaceOnUse">
        <stop stopColor="#97AAAA"/>
        <stop offset="1" stopColor="#E8EFEE"/>
      </linearGradient>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h64v64H0z" transform="translate(.5)"/>
      </clipPath>
    </defs>
  </SvgIcon>
);