import React from 'react';
import { FieldConfig, useField } from 'formik';
import get from 'lodash/get';

import CheckboxBase from './CheckboxBase';

interface IProps extends FieldConfig {
  name: string;
  value: string;
}

const Checkbox: React.FC<IProps> = ({ value, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  const values = ((get(field, 'value') || '').split(':') || []).filter(v => v);

  return (
    <CheckboxBase
      {...{ hasError }}
      value={values.includes(value)}
      onChange={() => {
        const set = new Set(values);
        if (set.has(value)) {
          set.delete(value);
        } else {
          set.add(value);
        }
        helpers.setValue(Array.from(set).join(':'));
        helpers.setTouched(true);
      }}
    />
  );
};

export default Checkbox;