export const ENTITIES = '@@CERTIFICATE/ENTITIES';

export enum LIST {
  REQUEST = '@@CERTIFICATE/LIST/REQUEST',
  SUCCESS = '@@CERTIFICATE/LIST/SUCCESS',
  FAILURE = '@@CERTIFICATE/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@CERTIFICATE/SINGLE/REQUEST',
  SUCCESS = '@@CERTIFICATE/SINGLE/SUCCESS',
  FAILURE = '@@CERTIFICATE/SINGLE/FAILURE'
}

export enum REPORT {
  REQUEST = '@@CERTIFICATE/REPORT/REQUEST',
  SUCCESS = '@@CERTIFICATE/REPORT/SUCCESS',
  FAILURE = '@@CERTIFICATE/REPORT/FAILURE'
}

export enum STATUS {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  SUSPENDED = 'SUSPENDED',
  SUSPEND_PROCESS = 'SUSPEND_PROCESS',
  EXPIRED = 'EXPIRED',
  NOT_PAID = 'NOT_PAID',
  CHANGED = 'CHANGED',
  TERMINATED = 'TERMINATED'
}

export const STORE_RESET = '@@STORE/RESET';
