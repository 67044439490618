import { createSelector } from 'reselect';
import get from 'lodash/get';

import * as Store from 'store';

import * as Constants from './constants';
import * as Types from './types';

const getSubGroupValues = (fields, entities) => {
  return (fields || []).reduce((prev, field) => {
    if (field.type === Constants.FIELD_TYPE.SUB_GROUP) {
      return ({
        ...prev,
        ...(getSubGroupValues(get(field, 'fields') || [], entities) || [])
      })
    }

    const fieldValue = get(entities, `${field.key}`);
    if (!fieldValue) {
      return { ...prev };
    }

    return ({
      ...prev,
      [field.key]: {
        key: field.key,
        value: get(fieldValue, 'value') || '',
        readonly: !!get(fieldValue, 'readonly'),
        duplicable_values: get(fieldValue, 'duplicable_values') || []
      }
    })
  }, {})
}

export const getValues = createSelector<Store.Types.IState, number, Types.IEntity.Info['fields']['entities'], Types.IEntity.Info['fields']['byStep'], number, Types.IEntity.Values>(
  (state) => state.process.info.fields.entities,
  (state) => state.process.info.fields.byStep,
  (state, position) => position,
  (entities, byStep, position) => {
    return Object.values(get(byStep, `[${position}]`) || {})
      .reduce((prev, fieldKey) => {
        const field = get(entities, `${fieldKey}`);
        if (!field) {
          return { ...prev };
        }
        if (field.type === Constants.FIELD_TYPE.SUB_GROUP) {
          const fields = getSubGroupValues(get(field, 'fields') || [], entities)
          return ({
            ...prev,
            ...fields
          })
        }

        return ({
          ...prev,
          [fieldKey]: {
            key: fieldKey,
            value: get(field, 'value') || '',
            readonly: !!get(field, 'readonly'),
            duplicable_values: get(field, 'duplicable_values') || []
          }
        });
      }, {});
  }
);
