import { useSelector } from 'react-redux';

import * as Store from 'store';

import * as Types from '../types';

const useMessages = (): Types.IEntity.Message[] => {
  return useSelector<Store.Types.IState, Types.IEntity.Message[]>(state => state.notification.messages);
};

export default useMessages;