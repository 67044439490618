import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useLayer } from 'react-laag';
import cx from 'classnames';

import classes from './GraphStats.module.scss';

export interface IProps {
  title: string;
  date: string;
  count: {
    all: number;
    processing: number;
    rejected: number;
    approved: number;
  };
  max: number;
}

const BAR_MAX_HEIGHT = 140;

const Item: React.FC<IProps> = ({ title, date, count, max }) => {
  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    overflowContainer: false,
    placement: 'right-end',
    auto: true,
    possiblePlacements: ['left-end', 'right-end'],
    snap: true,
    preferX: 'left',
    triggerOffset: 10,
    containerOffset: 0
  });

  const point = Number(BAR_MAX_HEIGHT / max);

  return (
    <>
      <div className={classes.item} {...triggerProps} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <div className={classes.itemBarWrapper}>
          <div className={classes.itemBar}>
            <div className={classes.itemBarProcessing} style={{ height: `${point * count.processing}px` }}/>
            <div className={classes.itemBarRejected} style={{ height: `${point * count.rejected}px` }}/>
            <div className={classes.itemBarApproved} style={{ height: `${point * count.approved}px` }}/>
          </div>
        </div>
        <div className={classes.itemDate}>{title}</div>
      </div>
      <Transition in={isOpen} timeout={{ enter: 100, exit: 0 }} unmountOnExit mountOnEnter>
        {(state: TransitionStatus) => (
          <>
            {renderLayer(
              <div {...layerProps}>
                <div className={classes.tooltip} style={{ ...transition[state] }}>
                  <div className={classes.tooltipTitle}>{date}</div>
                  <div className={cx(classes.tooltipStats, classes.tooltipStatsAll)}>
                    <div className={classes.tooltipStatsTitle}>Қабул қилинган аризалар</div>
                    <div className={classes.tooltipStatsValue}>{count.all}</div>
                  </div>
                  <div className={cx(classes.tooltipStats, classes.tooltipStatsProcessing)}>
                    <div className={classes.tooltipStatsTitle}>Кўриб чиқиш</div>
                    <div className={classes.tooltipStatsValue}>{count.processing}</div>
                  </div>
                  <div className={cx(classes.tooltipStats, classes.tooltipStatsRejected)}>
                    <div className={classes.tooltipStatsTitle}>Рад этилган аризалар</div>
                    <div className={classes.tooltipStatsValue}>{count.rejected}</div>
                  </div>
                  <div className={cx(classes.tooltipStats, classes.tooltipStatsApproved)}>
                    <div className={classes.tooltipStatsTitle}>Тугалланган аризалар</div>
                    <div className={classes.tooltipStatsValue}>{count.approved}</div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Transition>
    </>
  );
};

const transition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transition: 'all .1s ease-in-out' },
  exited: { opacity: 0, visibility: 'hidden' }
};

export default Item;