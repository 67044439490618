import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace, push } from 'connected-react-router';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import config from 'config';
import { storage } from 'services';

import * as NotificationModule from 'modules/notification';
import { CreateKey } from 'modules/digitalSign/sagas';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Types from './types';

export function* Login(action: ReturnType<typeof Actions.Login.request>) {
  const { values, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.Login, { values });
    const token = Mappers.token(data);
    const profile = Mappers.profile(data);
    const company = Mappers.company(data);

    yield call(storage.local.set, config.api.access_token_key, token.accessToken);
    yield call(storage.local.set, config.api.refresh_token_key, token.refreshToken);

    profile.type === 'INDIVIDUAL_ENTREPRENEUR'
      ? yield put(replace({ pathname: '/switcher' }))
      : yield put(replace({ pathname: values.serviceId ? `/service/${values.serviceId}` : '/cabinet', search: '' }));

    yield put(Actions.Login.success({ token, profile, company }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { token, profile });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(replace({ search: '' }));
    yield put(Actions.Login.failure({ error: errorMessage }));
    yield put(Actions.Logout.request());
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* LoginChangeToken(action: ReturnType<typeof Actions.LoginChangeToken.request>) {
  const { callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.LoginChangeToken);
    const token = Mappers.token(data);
    const profile = Mappers.profile(data);
    const company = Mappers.company(data);

    yield call(storage.local.set, config.api.access_token_key, token.accessToken);
    yield call(storage.local.set, config.api.refresh_token_key, token.refreshToken);
    yield put(replace({ pathname: '/cabinet', search: '' }));
    yield put(Actions.Login.success({ token, profile, company }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { token, profile });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(replace({ search: '' }));
    yield put(Actions.Login.failure({ error: errorMessage }));
    yield put(Actions.Logout.request());
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* LoginViaDS(action: ReturnType<typeof Actions.LoginViaDS.request>) {
  const { values, cancelSource, callback } = action.payload;
  try {
    const key = yield call(CreateKey, values.id);
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.LoginViaDS, { key, cancelSource });
    if (data.status_code === -2) {
      throw data.status_message;
    }
    const token = Mappers.token(data);
    const profile = Mappers.profile(data);
    const company = Mappers.company(data);
    yield call(storage.local.set, config.api.access_token_key, token.accessToken);
    yield call(storage.local.set, config.api.refresh_token_key, token.refreshToken);
    yield put(push('/'));
    yield put(Actions.LoginViaDS.success({ token, profile, company }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { token, profile });
    }
  } catch (e) {
    let error = '';
    if (e?.response) {
      error = (e as AxiosError)?.response?.data?.status_message;
    }

    if (typeof e === 'string') {
      error = e;
    }

    yield put(Actions.LoginViaDS.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { error });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* LoginViaEmail(action: ReturnType<typeof Actions.LoginViaEmail.request>) {
  const { values, cancelSource, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.LoginViaEmail, { values, cancelSource });
    const token = Mappers.token(data);
    const profile = Mappers.profile(data);
    const company = Mappers.company(data);
    yield call(storage.local.set, config.api.access_token_key, token.accessToken);
    yield call(storage.local.set, config.api.refresh_token_key, token.refreshToken);
    yield put(Actions.Login.success({ token, profile, company }));
    yield put(Actions.LoginViaEmail.success({}));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { token, profile });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message?.title;
    yield put(Actions.LoginViaEmail.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* RegisterViaEmail(action: ReturnType<typeof Actions.RegisterViaEmail.request>) {
  const { values, cancelSource, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.RegisterViaEmail, {
      values,
      cancelSource
    });
    yield put(Actions.RegisterViaEmail.success({ message: get(data, 'message.title') }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { message: get(data, 'message.title') });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message?.title;
    yield put(Actions.RegisterViaEmail.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* EmailVerification(action: ReturnType<typeof Actions.EmailVerification.request>) {
  const { values, cancelSource, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.EmailVerification, {
      values,
      cancelSource
    });
    if (get(data, 'status_message') === 'TOKEN_APPROVED') {
      throw data;
    }
    yield put(Actions.EmailVerification.success({ message: get(data, 'message.title') }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { message: get(data, 'message.title') });
    }
  } catch (error) {
    let errorMessage = get(error, 'message.title');
    if (error.isAxiosError) {
      const errorResponse = error as AxiosError;
      errorMessage = errorResponse?.response?.data?.message?.title;
    }
    yield put(Actions.EmailVerification.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}
export function* LoginViaFaceID(action: ReturnType<typeof Actions.LoginViaFaceID.request>) {
  const { values, cancelSource, callback } = action.payload;
 
  try {
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.LoginViaFaceID, {
      values,
      cancelSource
    });
  
    if (data.status_code === -2) {
      throw data.status_message;
    }
    const token = Mappers.token(data);
    const profile = Mappers.profile(data);
    const company = Mappers.company(data);
    yield put(Actions.LoginViaFaceID.success({ token, profile, company }));
    yield call(storage.local.set, config.api.access_token_key, token.accessToken);
    yield call(storage.local.set, config.api.refresh_token_key, token.refreshToken);
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { token, profile });
    }
  } catch (e) {
    let error = '';
    if (e?.response) {
      error = (e as AxiosError)?.response?.data?.status_message;
    }

    if (typeof e === 'string') {
      error = e;
    }

    yield put(Actions.LoginViaFaceID.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { error });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Logout(action: ReturnType<typeof Actions.Logout.request>) {
  try {
    yield call(Api.Logout);
  } catch (e) {}
  yield call(storage.local.remove, config.api.access_token_key);
  yield call(storage.local.remove, config.api.refresh_token_key);

  yield put(NotificationModule.Actions.DeleteToken.request());
  yield put(Actions.Logout.success());
  yield put(Actions.StoreReset());
}

export function* Profile(action: ReturnType<typeof Actions.Profile.request>) {
  try {
    const { data }: AxiosResponse<Types.IApi.Login.Response> = yield call(Api.Profile);
    const token = Mappers.token(data);
    const profile = Mappers.profile(data);
    const company = Mappers.company(data);
    yield put(Actions.Profile.success({ token, profile, company }));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Profile.failure({ error: errorMessage }));
    yield put(Actions.Logout.request());
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LOGIN.REQUEST, Login),
    takeLatest(Constants.LOGIN_CHANGE_TOKEN.REQUEST, LoginChangeToken),
    takeLatest(Constants.LOGIN_VIA_DS.REQUEST, LoginViaDS),
    takeLatest(Constants.LOGIN_VIA_EMAIL.REQUEST, LoginViaEmail),
    takeLatest(Constants.REGISTER_VIA_EMAIL.REQUEST, RegisterViaEmail),
    takeLatest(Constants.EMAIL_VERIFICATION.REQUEST, EmailVerification),
    takeLatest(Constants.LOGIN_VIA_FACE_ID.REQUEST, LoginViaFaceID),
    takeLatest(Constants.LOGOUT.REQUEST, Logout),
    takeLatest(Constants.PROFILE.REQUEST, Profile)
  ]);
}
