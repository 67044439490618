import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Store from 'store';

import Schema from './schema';
import * as Types from './types';

export const getEntities = (state: Store.Types.IState) => state.instruction.entities;

const getDenormalizedList = createSelector<Store.Types.IState, string, Types.IEntities, number[], Types.IEntity.Instruction[]>(
  getEntities,
  (state, name) => get(state, `instruction.list[${name}].ids`),
  (entities, ids) => {
    const items = denormalize({ instruction: ids }, { instruction: [Schema] }, { instruction: entities });
    return get(items, 'instruction', []) || [];
  }
);

const getSortedList = createSelector<Store.Types.IState, string, Types.IEntity.Instruction[], Types.IEntity.Instruction[]>(getDenormalizedList, items => {
  items = orderBy(items, ['position', 'id'], ['asc', 'desc']);
  return items;
});

export const getList = createSelector<Store.Types.IState, string, Types.IEntity.Instruction[], Types.IEntity.Instruction[]>(
  getSortedList,
  items => items
);

export const getSingle = createSelector<Store.Types.IState, number, Types.IEntities, number, Types.IEntity.Instruction>(
  getEntities,
  (_, id) => id,
  (entities, id) => {
    let normalized;
    if (get(entities, id)) {
      normalized = denormalize({ instruction: id }, { instruction: Schema }, { instruction: entities });
    }
    let item = get(normalized, 'instruction');
    if (item) {
      return item;
    }
    return {};
  }
);
