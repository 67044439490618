import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';
import { useGoogleCaptcha } from 'hooks';

interface IChildren {
  items: Types.IEntity.Registry[];
  isFetched: boolean;
  isLoaded: boolean;
  meta: Types.IEntity.Meta;
}

interface IProps {
  filter: Types.IFilter;
  params?: Partial<Types.IParams>;
  onRequest?: () => void;
  onSuccess?: ({ items }: { items: Types.IEntity.Registry[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({ filter, params = {}, onRequest = () => { }, onSuccess = () => { }, onError = () => { }, onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();

  const { handleLoad } = useGoogleCaptcha()

  const items = useSelector<Store.Types.IState, Types.IEntity.Registry[]>(state => Selectors.getList(state));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.registry.list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.registry.list.isLoaded);
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state => state.registry.list.meta);

  useDeepCompareEffect(() => handleLoad({
    onSuccess(token) {
      if (token) {
        onRequest();
        dispatch(Actions.List.request({
          token,
          filter,
          params: {
            page: params.page || 1,
            limit: params.limit || 10
          },
          callback: {
            onSuccess,
            onError,
            onFinally
          }
        }));
      }
    },
  }), [filter, params]);

  return {
    items,
    isFetched,
    isLoaded,
    meta
  };
};

export default useList;