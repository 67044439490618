import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import HtmlParser from 'react-html-parser';
import QRGenerator from 'react-qr-code';
import InfoBlock from 'components/InfoBlock';

import classes from './Invoice.module.scss';

interface IProps {
  applicationNumber?: string;
  date: string;
  amount: string;
  invoiceNumber: string;
  qr: string;
  status: 'OPEN' | 'EXPIRED' | 'PAID' | 'CANCELED';
  organization: string;
  account: string;
  bank: {
    name: string;
    account: string;
    code: string;
  };
  details: string;
}

const Invoice: React.FC<IProps> = ({
  applicationNumber,
  date,
  amount,
  invoiceNumber,
  qr,
  status,
  organization,
  account,
  bank,
  details
}) => {
  const { t } = useTranslation();

  const statuses = {
    PAID: t('invoice_payment_paid'),
    OPEN: t('invoice_payment_open'),
    EXPIRED: t('invoice_payment_expired'),
    CANCELED: t('invoice_payment_canceled')
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.sidebar}>
        <div className={classes.title}>
          {!!applicationNumber && (
            <>
              <div className={classes.titleKey}>{t('application_number')}</div>
              <div className={classes.titleValue}>{applicationNumber}</div>
            </>
          )}
        </div>
        <div className={classes.qrImage}>
          <QRCode value={qr || ''} size={120} />
        </div>
        <div className={classes.title}>
          <div className={classes.titleKey}>{t('application_notification_date')}</div>
          <div className={classes.titleValue}>{date}</div>
        </div>
        <div className={cx(classes.status, classes[`status--${status}`])}>{statuses[status]}</div>
      </div>
      <div className={classes.content}>
        <div className={classes.organization}>{organization}</div>
        <div className={classes.details}>
          <div className={classes.list}>
            <div className={classes.listItem}>
              <div className={classes.listItemTitle}>{t('name_of_the_recipient')}:</div>
              <div className={classes.listItemValue}>{bank.name}</div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.listItemTitle}>{t('recipient_s_account')}:</div>
              <div className={classes.listItemValue}>{bank.account}</div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.listItemTitle}>{t('personal_account')}:</div>
              <div className={classes.listItemValue}>{account}</div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.listItemTitle}>{t('recipient_bank_code_mfi')}:</div>
              <div className={classes.listItemValue}>{bank.code}</div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.listItemTitle}>{t('payment_details')}:</div>
              <div className={classes.listItemValue}>{details}</div>
            </div>
          </div>
          <div className={classes.info}>
            <div className={classes.infoTitle}>{t('payment_info_title')}:</div>
            <div className={classes.infoList}>
              <div className={classes.infoItem}>{HtmlParser(t('payment_info_1'))}</div>
              <div className={classes.infoItem}>{HtmlParser(t('payment_info_2', { details }))}</div>
              <div className={classes.infoItem}>{HtmlParser(t('payment_info_3'))}</div>
            </div>
          </div>
          <div className={classes.footer}>
            <InfoBlock className={classes.footerItem} title={t('invoice_number')} variant="gray">
              {invoiceNumber}
            </InfoBlock>
            <InfoBlock className={classes.footerItem} title={t('payment_amount')} variant="gray">
              {amount}
            </InfoBlock>
          </div>
        </div>
        <div className={classes.static}>
          <div className="staticTitle">
            Янги қонунчилик ҳужжатлари, ҳуқуқий ислоҳотлар ҳақида тезкор маълумотлар тақдим этувчи канал
          </div>
          <div className="staticQr">
            <QRGenerator value="https://t.me/huquqiyaxborot/" size={50} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
