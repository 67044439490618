import * as React from 'react';
import { useCallback } from 'react';
import { keyframes } from 'goober';
import { Toast } from 'react-hot-toast/dist/core/types';

import classes from './Toast.module.scss';
import Icon from './Icon';

interface IProps {
  toast: Toast;
  offset: number;
  onHeight: (height: number) => void;
}

const ToastBar: React.FC<IProps> = ({ toast, offset, ...props }) => {
  const ref = useCallback((el: HTMLElement | null) => {
    if (el) {
      const boundingRect = el.getBoundingClientRect();
      props.onHeight(boundingRect.height);
    }
  }, []);

  const animationStyle = toast?.height ? getAnimationStyle(toast.visible) : { opacity: 0 };

  return (
    <div className={classes.itemWrapper} style={{ transform: `translateY(${offset * (1)}px)` }}>
      <div ref={ref} className={classes.item} style={{ ...animationStyle }}>
        <Icon type={toast.type}/>
        {toast.message}
      </div>
    </div>
  );
};

const getAnimationStyle = (visible: boolean): React.CSSProperties => {
  const factor = 1;
  return visible
    ? {
      animation: `${keyframes`${enterAnimation(factor)}`} 0.35s cubic-bezier(.21,1.02,.73,1) forwards`
    }
    : {
      animation: `${keyframes`${exitAnimation(factor)}`} 0.8s forwards cubic-bezier(.06,.71,.55,1)`,
      pointerEvents: 'none'
    };
};

const enterAnimation = (factor: number) => `
  0% {transform: translate3d(0,${factor * -30}px,0); opacity:.5;}
  100% {transform: translate3d(0,0,0); opacity:1;}
`;

const exitAnimation = (factor: number) => `
  0% {transform: translate3d(0,0,-1px) ; opacity:1;}
  100% {transform: translate3d(0,${factor * -130}px,-1px); opacity:0;}
`;

export default React.memo(ToastBar);