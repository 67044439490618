import { useSelector } from 'react-redux';

import * as Store from 'store';

import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Document[];
  entities: Types.IEntities;
  isFetched: boolean;
}

interface IProps {
  categoryId: number;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useListByCategory = ({ categoryId }: IProps): IChildren => {
  const items = useSelector<Store.Types.IState, Types.IEntity.Document[]>(state =>
    Selectors.getListByCategory(state, categoryId)
  );
  const entities = useSelector<Store.Types.IState, Types.IEntities>(state => Selectors.getEntities(state));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.category.list.isFetched);

  return {
    items,
    entities,
    isFetched
  };
};

export default useListByCategory;
