import React, { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { yup as yupCreator } from 'services';

import * as Actions from '../actions';
import * as Types from '../types';
import { useAuth } from 'modules/auth/hooks';

interface FormValues extends Types.IForm.Complaint { }

interface IChildren extends FormikProps<FormValues> { }

interface IProps {
  values?: Partial<FormValues>;
  onSubmit?: () => void;
  onSuccess?: () => void;
  onError?: ({ error }: { error: string }) => void;
  onFinally?: () => void;
  children(props: IChildren): JSX.Element;
}

let cancelSource = axios.CancelToken.source();

const Complaint: React.FC<IProps> = ({ values, onSubmit = () => { }, onSuccess = () => { }, onError = () => { }, onFinally = () => { }, children }) => {
  const dispatch = useDispatch();
  const { profile } = useAuth()

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const yup = yupCreator();
  const validationSchema = (
    yup.object().shape<FormValues>({
      name: yup.string().required(),
      phone: yup.string().required(),
      comment: yup.string().required()
    })
  );

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if (!isSubmitting) {
      setSubmitting(true);
      cancelSource = axios.CancelToken.source();

      onSubmit();

      dispatch(Actions.Complaint.request({
        values,
        cancelSource,
        callback: {
          onSuccess,
          onError: (error) => {
            setSubmitting(false);
            onError(error);
          },
          onFinally
        }
      }));
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        name: profile.fullName || '',
        phone: profile.phone?.substring(profile.phone.length - 9, profile.phone.length) || '',
        comment: '',
        ...values
      }}
      validateOnMount
      enableReinitialize
      {...{ validationSchema }}
    >
      {(props: FormikProps<FormValues>) => (
        <Form>
          {children(props)}
        </Form>
      )}
    </Formik>
  );
};

export default Complaint;