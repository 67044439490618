import React, { useMemo } from 'react'

import classes from "./StartCard.module.scss"
import QuickStats from 'components/QuickStats'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import GraphStats from 'components/GraphStats'
import * as StatsModule from 'modules/stats';
import Spinner from 'components/Spinner'

const StatCard = ({ document_id }: { document_id: number }) => {
    const { t } = useTranslation()
    const { data, isFetched } = StatsModule.Hooks.useByDocument({ document_id });
    const { statuses, months, rating } = data!

    const graphItems = useMemo(() => {
        const mappers: any = []
        let lastYear = moment().year() - 1

        for (const item of months) {
            if (item.month === "01") {
                lastYear = lastYear + 1
            }
            const unixFormat = moment(`${item.month}.${lastYear}`, 'MM.YYYY').unix()

            mappers.push({
                title: `${item.month}`,
                date: moment.unix(unixFormat).format('DD MMMM YYYY'),
                count: {
                    all: item.statuses.all,
                    processing: item.statuses.in_review,
                    rejected: item.statuses.denied,
                    approved: item.statuses.completed
                }
            })
        }

        return mappers
    }, [data])

    if (!isFetched) {
        return (
            <div className={classes.wrapper}>
                <Spinner />
            </div>
        )
    }

    return (
        <div className={classes.wrapper}>
            <h4>{t('statistics_document')}</h4>
            <div className={classes.wrapperList}>
                <QuickStats title={t('rating')} value={Number(rating.avg).toFixed(2)} variant="blue" />
                <QuickStats title={t('Received_applications')} value={statuses.all} variant="blue" />
                <QuickStats title={t('Applications_under_review')} value={statuses.in_review} variant="orange" />
                <QuickStats title={t('Rejected_applications')} value={statuses.denied} variant="red" />
                <QuickStats title={t('Completed_applications')} value={statuses.completed} variant="green" />
                <QuickStats title={t('Auto_completed')} value={statuses.auto_completed} variant="cyan" />
            </div>
            <div className={classes.wrapperGraph}>
                {!!months.length && (
                    <div className={classes.graphStats}>
                        <GraphStats
                            title={t('Statistics_of_document')}
                            date={t('one_year_interval')}
                            items={graphItems}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default StatCard
