import React from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';

import * as Helpers from 'helpers';

import * as Icon from 'components/Icon';
import { Nav } from 'components/Modal';

import classes from './Banking.module.scss';

interface IProps {
  mfi: string;
  account: string;
  bank: {
    name: string;
    account: string;
  };
  details: string;
  amount: string;
  close: () => void;
}

const Banking: React.FC<IProps> = ({ mfi, account, bank, details, amount, close }) => {
  const { t } = useTranslation();

  const onCopy = (value) => {
    copy(value);
    toast(`${t('copied')}`);
  };

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={close} variant="light"/>
      <div className={classes.title}>{t('internet_banking')}</div>
      <div className={classes.content}>
        <div className={classes.contentTitle}>{t('requirement_data_for_payment')}</div>

        <div className={classes.step}>1 - {t('internet_banking_step_1')}</div>
        <div className={classes.step}>2 - {t('internet_banking_step_2')}</div>
        <div className={classes.step}>3 - {t('internet_banking_step_3')}</div>
        <div className={classes.info}>{t('internet_banking_info')}</div>

        <div className={classes.detailList}>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>{t('recipient_bank_code_mfi')}</div>
            <div className={classes.detailItemValue}>
              <span>{mfi}</span>
              <div className={classes.detailItemValueCopy} onClick={() => onCopy(mfi)}>
                <Icon.System.Copy/>
              </div>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>{t('recipient_s_account')}</div>
            <div className={classes.detailItemValue}>
              <span>{bank.account}</span>
              <div className={classes.detailItemValueCopy} onClick={() => onCopy(bank.account)}>
                <Icon.System.Copy/>
              </div>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>{t('personal_account')}</div>
            <div className={classes.detailItemValue}>
              <span>{account}</span>
              <div className={classes.detailItemValueCopy} onClick={() => onCopy(account)}>
                <Icon.System.Copy/>
              </div>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>{t('name_of_the_recipient')}</div>
            <div className={classes.detailItemValue}>
              <span>{bank.name}</span>
              <div className={classes.detailItemValueCopy} onClick={() => onCopy(bank.name)}>
                <Icon.System.Copy/>
              </div>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>{t('payment_details')}</div>
            <div className={classes.detailItemValue}>
              <span>{details}</span>
              <div className={classes.detailItemValueCopy} onClick={() => onCopy(details)}>
                <Icon.System.Copy/>
              </div>
            </div>
          </div>
          <div className={classes.detailItem}>
            <div className={classes.detailItemTitle}>{t('payment_amount')}</div>
            <div className={classes.detailItemValue}>
              <span>{t('license_price', { value: Helpers.currency.format(amount) })}</span>
              <div className={classes.detailItemValueCopy} onClick={() => onCopy(amount)}>
                <Icon.System.Copy/>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.step}>4 - {t('internet_banking_step_4')}</div>
        <div className={classes.step}>5 - {t('internet_banking_step_5')}</div>
      </div>
    </div>
  );
};

export default Banking;
