import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  item: Types.IEntity.ApplicationSingle;
  isFetched: boolean;
}

interface IProps {
  id: number;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useSingle = ({ id, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps): IChildren => {
  const dispatch = useDispatch();

  const item = useSelector<Store.Types.IState, Types.IEntity.ApplicationSingle>(state => state.application.single.item);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.application.single.isFetched);

  useEffect(() => {
    dispatch(Actions.Single.request({
      id,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, []);

  return {
    item,
    isFetched
  };
};

export default useSingle;