import React from 'react';
import cx from 'classnames';

import classes from './Table.module.scss';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const HeaderCell: React.FC<IProps> = ({ className, children }) => (
  <th className={cx(classes.headerCell, className)}>{children}</th>
);

export default HeaderCell;
