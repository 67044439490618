import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import config from 'config';

import storage from '../storage';

const language = storage.local.get('language');

const currentLanguage = ['uz', 'oz', 'ru', 'en'].includes(language) ? language : 'oz';

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: currentLanguage,
    fallbackLng: currentLanguage,
    debug: false,
    ns: ['main'],
    defaultNS: 'main',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true,
      wait: true
    },
    backend: {
      loadPath: `${config.api.base_url}/reference/translation/cabinet/FRONT_OFFICE/{{lng}}`
    }
  });

export default i18next;