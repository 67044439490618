import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';
import toast from 'react-hot-toast';
import moment from 'moment';

import * as Helpers from 'helpers';

import * as PaymentModule from 'modules/payment';

import * as Icon from 'components/Icon';
import MonthField from 'components/Fields/Date/Month';
import Button from 'components/Button';
import Modal, { Nav } from 'components/Modal';
import TextBlock from 'components/TextBlock';
import Invoice from 'components/Invoice';
import Spinner from 'components/Spinner';

import classes from '../CertificateVew.module.scss';

interface IProps {
  id: number;
  licenseId: string;
  feeDateEnd: string;
}

const Payment: React.FC<IProps> = ({ id, licenseId, feeDateEnd }) => {
  const { t } = useTranslation();

  const Fees = PaymentModule.Hooks.useFees({ registerId: id });

  const [isOpen, setOpen] = useState(false);
  const [isOpenInvoice, setOpenInvoice] = useState(false);
  const [isBanking, setBanking] = useState(false);
  const [isCashDesk, setCashDesk] = useState(false);
  const [isPaying, setPaying] = useState(false);

  const Check = () => {
    Fees.check({
      onSuccess: () => {
        setOpenInvoice(true);
      },
      onError: () => {
        setOpen(true);
      }
    });
  };

  const ChangeDate = () => {
    setOpen(true);
    setOpenInvoice(false);
  };

  const invoice = Fees.invoice;

  const feeDateEndFormatted = moment(feeDateEnd, 'MM.YYYY').format('MMMM, YYYY');

  return (
    <>
      <Button prefixIcon={<Icon.System.Card />} className="m-r-12" onClick={Check} isLoading={Fees.isLoading}>
        {t('Pay')}
      </Button>

      <Modal {...{ isOpen }} close={() => setOpen(false)} width={600} variant="white">
        <div className={classes.dateModal}>
          <div className={classes.dateModalTitle}>{t('pay_for', { id: licenseId })}</div>
          <TextBlock variant="BLUE" className={classes.dateModalFeeDate}>
            {HtmlParser(t('paid_until', { date: feeDateEndFormatted }))}
          </TextBlock>
          <PaymentModule.Forms.FeePay
            registerId={id}
            end={invoice.end}
            onSuccess={invoice => {
              Fees.setInvoice(invoice);
              setOpen(false);
              setOpenInvoice(true);
            }}
            onError={message => toast.error(message)}
          >
            {({ isSubmitting }) => (
              <>
                {isSubmitting && (
                  <div className={classes.spinner}>
                    <Spinner />
                  </div>
                )}
                <MonthField name="end" label={t('for_period')} required unmask={false} showError={false} />
                <div className={classes.dateModalButtonList}>
                  <Button className={classes.dateModalButton} variant="grey" block onClick={() => setOpen(false)}>
                    {t('Cancel')}
                  </Button>
                  <Button className={classes.dateModalButton} block>
                    {t('Next')}
                  </Button>
                </div>
              </>
            )}
          </PaymentModule.Forms.FeePay>
        </div>
      </Modal>

      <Modal isOpen={isOpenInvoice} close={() => setOpenInvoice(false)} width={900} variant="white">
        <Nav showClose onClose={() => setOpenInvoice(false)} variant="dark" />
        <div className={classes.paymentModal}>
          <div className={classes.paymentModalTitle}>{t('pay_with')}:</div>
          <div className={classes.paymentModalButtonList}>
            <Button
              variant="stroke"
              prefixIcon={<Icon.System.Company />}
              htmlType="button"
              onClick={() => setBanking(true)}
            >
              {t('online_banking')}
            </Button>
            <Button
              variant="stroke"
              prefixIcon={<Icon.System.Cheque />}
              htmlType="button"
              onClick={() => setCashDesk(true)}
            >
              {t('through_the_bank_cash_desk')}
            </Button>
            <Button
              variant="stroke"
              prefixIcon={<Icon.System.Card />}
              htmlType="button"
              onClick={() => setPaying(true)}
            >
              {t('credit_card')}
            </Button>
          </div>
          <TextBlock className={classes.paymentModalInfo} variant="BLUE">
            {HtmlParser(
              t('payment_for_fee_title', {
                id: licenseId,
                begin_date: moment(invoice.begin, 'MM.YYYY').format('MMMM, YYYY'),
                end_date: moment(invoice.end, 'MM.YYYY').format('MMMM, YYYY')
              })
            )}{' '}
            <span className={classes.paymentModalInfoChange} onClick={ChangeDate}>
              {t('change_the_period')}
            </span>
          </TextBlock>
          <Invoice
            date={invoice.issue_date}
            amount={t('license_price', { value: Helpers.currency.format(invoice.amount) })}
            invoiceNumber={Helpers.stringMask(invoice.serial || '', '##############')}
            qr={invoice.qr}
            status={invoice.status as any}
            organization={invoice.payee}
            account={Helpers.stringMask(invoice.budget_account || '', '##### ##### ##### ##### #####')}
            bank={{
              name: invoice.bank.name,
              account: Helpers.stringMask(invoice.bank.account || '', '#### #### #### #### ####'),
              code: invoice.bank.mfi
            }}
            details={invoice.details}
          />
        </div>
      </Modal>

      <Modal size="small" variant="white" isOpen={isPaying} close={() => setPaying(false)}>
        <PaymentModule.Components.Form
          registerId={id}
          serial={invoice.serial}
          amount={Helpers.currency.format(invoice.amount_online, { precision: 2 })}
          close={() => setPaying(false)}
          onSuccess={() => {}}
        />
      </Modal>

      <Modal size="s-middle" isOpen={isCashDesk} close={() => setCashDesk(false)}>
        <PaymentModule.Components.CashDesk serial={invoice.serial} close={() => setCashDesk(false)} />
      </Modal>

      <Modal size="s-middle" isOpen={isBanking} close={() => setBanking(false)}>
        <PaymentModule.Components.Banking
          mfi={invoice.bank.mfi}
          account={invoice.budget_account}
          bank={{
            name: invoice.bank.name,
            account: invoice.bank.account
          }}
          details={invoice.details}
          amount={`${invoice.amount}`}
          close={() => setBanking(false)}
        />
      </Modal>
    </>
  );
};

export default Payment;
