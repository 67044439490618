import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

import * as Helpers from 'helpers';

import { http } from 'services';

import * as ActModule from 'modules/act';
import * as SystemModule from 'modules/system';

import { Nav } from 'components/Modal';
import Type from 'components/Type';
import InfoBlock from 'components/InfoBlock';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import * as Details from 'components/Details';
import * as Icon from 'components/Icon';

import classes from './ActView.module.scss';

interface IProps {
  id: number;
  close: () => void;
}

const View: React.FC<IProps> = ({ id, close }) => {
  const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const { item, isFetched } = ActModule.Hooks.useSingle({
    id,
    onError: () => {
      close();
      toast.error(`${t('internal_server_error')}`)
    }
  });

  const [isDownloading, setDownloading] = useState(false);
  const [isAttachmentDownloading, setAttachmentDownloading] = useState(false);


  if (!item.id || !isFetched) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    )
  }

  const download = () => {
    setDownloading(true);
    http.request
      .get(item.file, {
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${get(item, 'fileName')}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .finally(() => setDownloading(false))
  };

  const downloadAttachment = () => {
    setAttachmentDownloading(true);
    http.request
      .get(get(item, 'conclusionFile.downloadLink'), {
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${get(item, 'conclusionFile.name')}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .finally(() => setAttachmentDownloading(false))
  };

  const CATEGORY = ActModule.Constants.CATEGORY;
  const isResponseTimeVisible = {
    [CATEGORY.CHECKED]: true,
    [CATEGORY.SUSPENSION]: true,
    [CATEGORY.RE_CHECKED]: false,
    [CATEGORY.RENEWAL]: false,
    [CATEGORY.COURT_APPLICATION]: false,
    [CATEGORY.COURT_DECISION]: false
  };

  const downloadButtonTitle = {
    [CATEGORY.CHECKED]: t('download_the_act'),
    [CATEGORY.SUSPENSION]: t('download_suspension_decision'),
    [CATEGORY.RE_CHECKED]: t('download_the_act'),
    [CATEGORY.RENEWAL]: t('download_the_act'),
    [CATEGORY.COURT_APPLICATION]: t('download_claim_application'),
    [CATEGORY.COURT_DECISION]: t('download_court_decision'),
    [CATEGORY.LICENSE_DECISION]: t('download_the_solution'),
    [CATEGORY.LICENSE_CONCLUSION]: t('download_the_summary'),
    [CATEGORY.AGREEMENTS_DECISION]: t('download_the_solution'),
    [CATEGORY.AGREEMENTS_CONCLUSION]: t('download_the_summary'),
  };

  const numberTitle = {
    [CATEGORY.CHECKED]: t('act_number'),
    [CATEGORY.SUSPENSION]: t('resolution_number'),
    [CATEGORY.RE_CHECKED]: t('act_number'),
    [CATEGORY.RENEWAL]: t('act_number'),
    [CATEGORY.COURT_APPLICATION]: t('resolution_number'),
    [CATEGORY.COURT_DECISION]: t('resolution_number'),
    [CATEGORY.LICENSE_DECISION]: t('the_number_of_decision_of_the_licensing_authority'),
    [CATEGORY.LICENSE_CONCLUSION]: t('number_of_the_conclusion_of_the_licensing_authority'),
    [CATEGORY.AGREEMENTS_DECISION]: t('the_number_of_decision_of_the_agreed_authority'),
    [CATEGORY.AGREEMENTS_CONCLUSION]: t('the_number_of_conclusion_of_the_agreed_authority'),
  };

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={close} />
      <div className={classes.headerMobileTitle}>{get(item, `title[${language}]`)}</div>
      <div className={classes.header}>
        <div className={classes.headerImage}>
          <img src={item.category.image} alt={get(item, `category.title[${language}]`)} />
        </div>
        <div className={classes.headerContent}>
          <div className={classes.tags}>
            <Type variant="blue" className="m-r-12">{get(item, `title[${language}]`)} №{item.number}</Type>
            <Type variant="dark-gray">
              {item.register_number ? (
                <>{get(item, `type.title[${language}]`)} №{item.register_number}</>
              ) : (
                <>{t('application', { number: item.applicationId })}</>
              )}
            </Type>
          </div>
          <div className={classes.category}>{get(item, `category.title[${language}]`)}</div>
          <div className={classes.title}>{get(item, `document[${language}]`)}</div>
          <div className={classes.info}>
            <InfoBlock
              variant="white"
              title={t('notification_number_and_type')}
              children={item.id}
              className="m-r-12"
            />
            <InfoBlock
              variant="white"
              title={t('date_of_notification')}
              children={item.registration_date}
              className="m-r-12"
            />
            {!!isResponseTimeVisible[item.key] && !!item.expiryDate && (
              <InfoBlock
                variant="white"
                title={t('response_time')}
                children={item.expiryDate}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.buttonList}>
          <Button className="m-r-12" isLoading={isDownloading} prefixIcon={<Icon.System.Download />} onClick={download}>
            {downloadButtonTitle[item.key] || t('download_the_act')}
          </Button>
          <Button className="m-r-12" isLoading={isAttachmentDownloading} prefixIcon={<Icon.System.Download />} onClick={downloadAttachment}>
            {t('download_attachment')}
          </Button>
          {item.canAnswer && (
            <Button variant="stroke" prefixIcon={<Icon.System.Application />} container={<Link to={`/application/${item.applicationId}`} />}>{t('answer')}</Button>
          )}
        </div>
        <Details.List>
          <Details.Item title={numberTitle[item.key] || t('act_number')}>{get(item, 'number')}</Details.Item>
          <Details.Item title={item.register_number ? get(item, `type.title[${language}]`) : t('Application')}>№{item.register_number || item.applicationId}</Details.Item>
          <Details.Item title={t('sender')}>{get(item, `organization[${language}]`)}</Details.Item>
          <Details.Item title={t('date_of_notification')}>{get(item, 'registration_date')}</Details.Item>
        </Details.List>

        {!!(get(item, 'response.text') || get(item, 'response.files.length') || get(item, 'response.date')) && (
          <>
            <div className={classes.detailTitle}>{t('act_response')}</div>
            <Details.List>
              {!!get(item, 'response.text') && (
                <Details.Item title={t('act_response_text')}>{get(item, 'response.text')}</Details.Item>
              )}
              {!!get(item, 'response.files.length') && (
                <Details.Item title={t('act_response_files')}>
                  <div>
                    {get(item, 'response.files').map(file => (
                      <div className={classes.fieldFile} onClick={() => Helpers.downloadFile({ id: Number(file.id), applicationId: item.applicationId })}>
                        <div className={classes.fieldFileTitle}>{file.name}</div>
                        <div className={classes.fieldFileIcon}>
                          <Icon.System.Download />
                        </div>
                      </div>
                    ))}
                  </div>
                </Details.Item>
              )}
              {!!get(item, 'response.date') && (
                <Details.Item title={t('act_response_date')}>{get(item, 'response.date')}</Details.Item>
              )}
            </Details.List>
          </>
        )}
      </div>
    </div>
  );
};

export default View;
