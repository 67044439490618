import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface IProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<IProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('site_title')}</title>
      </Helmet>
      {children}
    </>
  );
};

export default Wrapper;