import React from 'react';
import cx from 'classnames';

import classes from './Table.module.scss';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Header: React.FC<IProps> = ({ className, children }) => (
  <thead className={cx(classes.header, className)}>
    <tr className={classes.headerRow}>{children}</tr>
  </thead>
);

export default Header;
