import React from 'react';
import cx from 'classnames';

import classes from './InfoBlock.module.scss';

interface IProps {
  title: string;
  children: React.ReactNode;
  variant: 'green' | 'red' | 'orange' | 'gray' | 'white';
  className?: string;
}

const InfoBlock: React.FC<IProps> = ({ title, children, variant, className }) => (
  <div className={cx(classes.wrapper, className, variant && classes[`wrapper--${variant}`])}>
    <div className={classes.title}>{title}</div>
    <div className={classes.description}>{children}</div>
  </div>
);

export default InfoBlock;