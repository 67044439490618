export enum BY_UUID {
    REQUEST = '@@CHECK_DOCS/BY_UUID/REQUEST',
    SUCCESS = '@@CHECK_DOCS/BY_UUID/SUCCESS',
    FAILURE = '@@CHECK_DOCS/BY_UUID/FAILURE'
}

export enum BY_NUMBER {
    REQUEST = '@@CHECK_DOCS/BY_NUMBER/REQUEST',
    SUCCESS = '@@CHECK_DOCS/BY_NUMBER/SUCCESS',
    FAILURE = '@@CHECK_DOCS/BY_NUMBER/FAILURE'
}