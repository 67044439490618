import get from 'lodash/get';

import * as Types from './types';

export const invoice = (item?: Types.IApi.Invoice): Types.IEntity.Invoice => {
  return {
    serial: get(item, 'invoice_SERIAL') || '',
    status: get(item, 'invoice_STATUS') || '',
    amount: get(item, 'invoice_AMOUNT') || 0,
    amount_online: get(item, 'invoice_AMOUNT_ONLINE') || 0,
    qr: get(item, 'invoice_QR_CODE') || '',
    details: get(item, 'invoice_DETAILS') || '',
    issue_date: get(item, 'invoice_ISSUE_DATE') || '',
    payee: get(item, 'invoice_PAYEE') || '',
    payer: {
      name: get(item, 'invoice_PAYER') || '',
      tin: get(item, 'invoice_PAYER_TIN') ? `${get(item, 'invoice_PAYER_TIN')}` : ''
    },
    bank: {
      name: get(item, 'invoice_BANK_NAME') || '',
      account: get(item, 'invoice_BANK_ACCOUNT') || '',
      mfi: get(item, 'invoice_BANK_MFO') || '',
    },
    budget_account: get(item, 'invoice_BUDGET_ACCOUNT') || '',
    begin: get(item, 'begin') || '',
    end: get(item, 'end') || '',
  };
};