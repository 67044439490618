import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <g clipPath="url(#clip0)">
      <path fill="currentColor" d="M17.94 5.56c.27.27.29.69.06.98l-.06.08-5.13 5.13 5.13 5.13a.75.75 0 01-.98 1.13l-.08-.07-5.13-5.13-5.12 5.13a.75.75 0 01-1.13-.99l.07-.07 5.12-5.13-5.12-5.13a.75.75 0 01.98-1.13l.08.07 5.12 5.13 5.13-5.13c.29-.3.76-.3 1.06 0z"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h24v24H0z"/>
      </clipPath>
    </defs>
  </SvgIcon>
);
