import get from 'lodash/get';

import { http } from 'services';

interface IProps {
  id: number;
  applicationId: number;
  onDownloadProgress?: (percent: number) => void,
  endDownloadProgress?: () => void
}

const downloadFile = ({ id, applicationId, onDownloadProgress, endDownloadProgress }: IProps) => {
  http.request
    .get(`/file/application/info/${id}?application_id=${applicationId}`)
    .then(({ data }) => {
      const name = get(data, 'data.name');
      http.request
        .get(`/file/application/download/${id}?application_id=${applicationId}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {
            let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
            return onDownloadProgress && onDownloadProgress(percentCompleted)
          }
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        }).finally(endDownloadProgress && endDownloadProgress)
    });
};

export default downloadFile;