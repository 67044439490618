import React from 'react';
import cx from 'classnames';

import classes from './Table.module.scss';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Table: React.FC<IProps> = ({ className, children }) => (
  <div className={cx(classes.wrapper, className)}>
    <table className={classes.table}>{children}</table>
  </div>
);

export default Table;
