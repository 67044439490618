import React from 'react';
import cx from 'classnames';

import classes from './Details.module.scss';

interface IProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

const Details: React.FC<IProps> = ({ title, className, children }) => (
  <div className={cx(classes.wrapper, className)}>
    {!!title && <div className={classes.title}>{title}</div>}
    <div className={classes.list}>{children}</div>
  </div>
);

export default Details;
