import { useEffect } from 'react';
import moment from 'moment';

import { i18n } from 'services';

import * as Hooks from '../hooks';

const useLanguageListener = () => {
  const { language } = Hooks.useLanguage();

  useEffect(() => {
    i18n.changeLanguage(language).then(() => {});
    moment.locale(language === 'uz' ? 'uz-latn' : language);
  }, [language]);
};

export default useLanguageListener;