import { takeLatest, call, put, all } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { filter, params, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: { registers: Types.IApi.Act.Response[] } }> = yield call(Api.List, { filter, params });
    const items = (get(data, 'data.acts') || []).map(application => Mappers.act(application));
    const meta = Mappers.meta(get(data, 'data', {}) || {});
    yield put(Actions.List.success({ items, meta }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.List.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Single(action: ReturnType<typeof Actions.Single.request>) {
  const { id, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.Act.Response }> = yield call(Api.Single, { id });
    const item = Mappers.act(get(data, 'data'));
    yield put(Actions.Single.success({ item }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { item });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Single.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List),
    takeLatest(Constants.SINGLE.REQUEST, Single)
  ]);
}