import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import * as Actions from '../actions';

interface IChildren {
  cancel: (applicationId: number, callback?: ICallback) => void;
}

interface ICallback {
  onSuccess?: (data) => void;
  onError?: (error: string) => void;
  onFinally?: () => void;
}

let cancelSource = axios.CancelToken.source();

const useProcessCancel = (): IChildren => {
  const dispatch = useDispatch();

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const cancel = (applicationId: number, callback?: { onSuccess: () => {}, onError: () => {}, onFinally: () => {} }) => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.Cancel.request({
      applicationId,
      cancelSource,
      callback: {
        onSuccess: callback?.onSuccess,
        onError: callback?.onError,
        onFinally: callback?.onFinally
      }
    }));
  };

  return { cancel };
};

export default useProcessCancel;