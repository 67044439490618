import { ActionType } from 'typesafe-actions';
import merge from 'lodash/merge';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  entities: {},
  listByCategory: {
    ids: {},
    isFetched: false,
    isLoaded: false,
    updated_date: 0
  },
  list: {
    items: [],
    isFetched: false,
    isLoaded: false
  },
  info: {}
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.ENTITIES: {
      const { items } = action.payload;
      return {
        ...state,
        entities: merge({}, state.entities, items)
      };
    }
    case Constants.LIST.REQUEST: {
      return {
        ...state,
        listByCategory: {
          ...state.listByCategory,
          isFetched: false
        }
      };
    }
    case Constants.LIST.SUCCESS: {
      const { updated_date, idsByCategoryId } = action.payload;
      const listByCategory = Object.entries(idsByCategoryId).reduce(
        (prev, [categoryId, ids]) => ({
          ...prev,
          [categoryId]: {
            ids: uniq([...ids, ...(get(state, `listByCategory[${categoryId}].ids`) || [])])
          }
        }),
        {}
      );

      return {
        ...state,
        listByCategory: {
          ...state.listByCategory,
          ...listByCategory,
          isLoaded: true,
          isFetched: true,
          updated_date
        }
      };
    }
    case Constants.LIST.FAILURE: {
      return {
        ...state,
        listByCategory: {
          ...state.listByCategory,
          isLoaded: true,
          isFetched: true
        }
      };
    }
    case Constants.LIST_BY_APPLICANT.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetched: false
        }
      };
    }
    case Constants.LIST_BY_APPLICANT.SUCCESS: {
      const { items } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          items: items || [],
          isFetched: true,
          isLoaded: true
        }
      };
    }
    case Constants.LIST_BY_APPLICANT.FAILURE: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetched: true,
          isLoaded: true
        }
      };
    }
    case Constants.LIST_BY_CATEGORY.REQUEST: {
      const { categoryId } = action.payload;
      const categoryList = get(state, `listByCategory.${categoryId}`, {});
      return {
        ...state,
        listByCategory: {
          ...state.listByCategory,
          [categoryId]: {
            ...categoryList,
            ids: get(categoryList, `ids`, []),
            isFetched: false
          }
        }
      };
    }
    case Constants.LIST_BY_CATEGORY.SUCCESS: {
      const { categoryId, ids } = action.payload;
      const categoryList = get(state, `listByCategory.${categoryId}`, {});
      return {
        ...state,
        listByCategory: {
          ...state.listByCategory,
          [categoryId]: {
            ...categoryList,
            ids: uniq([...(get(categoryList, 'ids') || {}), ...ids]),
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    case Constants.LIST_BY_CATEGORY.FAILURE: {
      const { categoryId } = action.payload;
      const categoryList = get(state, `listByCategory.${categoryId}`, {});
      return {
        ...state,
        listByCategory: {
          ...state.listByCategory,
          [categoryId]: {
            ...categoryList,
            ids: get(categoryList, `ids`, []),
            isFetched: true
          }
        }
      };
    }
    case Constants.INFO.REQUEST: {
      const { documentId } = action.payload;
      const currentInfo = get(state, `info.${documentId}`, {});
      return {
        ...state,
        info: {
          ...state.info,
          [documentId]: {
            ...currentInfo,
            item: get(currentInfo, `item`, {}),
            isLoaded: !!get(currentInfo, 'isLoaded'),
            isFetched: false
          }
        }
      };
    }
    case Constants.INFO.SUCCESS: {
      const { documentId, info } = action.payload;
      const currentInfo = get(state, `info.${documentId}`, {});
      return {
        ...state,
        info: {
          ...state.info,
          [documentId]: {
            ...currentInfo,
            item: info,
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    case Constants.INFO.FAILURE: {
      const { documentId } = action.payload;
      const currentInfo = get(state, `info.${documentId}`, {});
      return {
        ...state,
        info: {
          ...state.info,
          [documentId]: {
            ...currentInfo,
            item: get(currentInfo, `item`, {}),
            isLoaded: true,
            isFetched: true
          }
        }
      };
    }
    default:
      return state;
  }
};
