import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Selectors from '../selectors';
import * as Types from '../types';
import { useEffect } from 'react';
import * as Actions from '../actions';

interface IChildren {
  item: Types.IEntity.Certificate;
  isFetched: boolean;
  refetch?: () => void
}

interface IProps {
  id: number;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useSingle = ({ id, onSuccess = () => { }, onError = () => { }, onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();

  const item = useSelector<Store.Types.IState, Types.IEntity.Certificate>(state => Selectors.getSingle(state, id));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.certificate.single.isFetched);

  const refetch = () => dispatch(Actions.Single.request({
    id,
    callback: {
      onSuccess,
      onError,
      onFinally
    }
  }));

  useEffect(() => {
    refetch()
  }, []);

  return { item, isFetched, refetch };
};

export default useSingle;