import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as Actions from '../actions';

interface IProps {
  id: string;
  timeout?: number;
}

const useMessage = ({ id, timeout = 3000 }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => dispatch(Actions.RemoveMessage({ id })), timeout);
    return () => clearTimeout(timer);
  }, []);

  return null;
};

export default useMessage;