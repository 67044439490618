import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import axios from 'axios';
import get from 'lodash/get';
import { toast } from 'react-hot-toast';

import * as SystemModule from 'modules/system';

import * as Actions from '../actions';
import * as Hooks from '../hooks';

interface Field {
  name: string;
  actionId: string;
}

interface IProps {
  key: string;
  fields: Field[];
}

let cancelSource = axios.CancelToken.source();

const useAction = ({ key, fields }: IProps) => {
  const dispatch = useDispatch();
  const { language } = SystemModule.Hooks.useLanguage();
  const {
    process: { applicationId }
  } = Hooks.useProcess();
  const [isLoading, setLoading] = useState(false);
  const { values, setValues, validateField } = useFormikContext();

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const load = () => {
    let fieldValues = fields.reduce((prev, field) => {
      const value = get(values, `${field.name}.value`);
      if (!value) {
        setTimeout(() => validateField(field.name), 0);
      }
      return [...prev, { key: field.actionId, value }];
    }, []);

    const isAllFieldHasValue = fieldValues.every(field => !!field.value);

    if (!isAllFieldHasValue) {
      return;
    }

    setLoading(true);
    cancelSource = axios.CancelToken.source();
    dispatch(
      Actions.ActionEvent.request({
        values: {
          applicationId,
          key,
          language,
          fields: fieldValues
        },
        cancelSource,
        callback: {
          onSuccess: ({ fields, values }) => setFieldsValues(fields, values),
          onError: error => {
            const message = get(error, `message.title[${language}]`) || '';
            const type = get(error, `message.type`) || '';
            !!message && (type === 'INFO' ? toast(message) : toast.error(message));
            setFieldsValues(get(error, 'fields') || {}, get(error, 'values') || {});
          },
          onFinally: () => setLoading(false)
        }
      })
    );
  };

  const setFieldsValues = (fields, fieldsValues) => {
    const newValues = Object.entries(fieldsValues).reduce((prev, [key, fieldValue]) => {
      const value = get(fieldValue, 'value');
      const duplicable_values = get(fieldValue, 'duplicable_values');
      const readonly = get(fieldValue, 'readonly');
      if (value === undefined) {
        return { ...prev, [key]: { key, ...(get(values, key) || {}), readonly, duplicable_values } };
      }
      return { ...prev, [key]: { key: key, value, readonly, duplicable_values } };
    }, {});

    setValues({ ...((values || {}) as object), ...newValues });
  };

  return { load, isLoading };
};

export default useAction;
