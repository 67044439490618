import React from 'react';
import { useField } from 'formik';
import { IMaskInput } from 'react-imask';
import IMask from 'imask/esm/imask';
import { useTranslation } from 'react-i18next';

import * as Icon from 'components/Icon';

import Field from '../Field';

import classes from '../Field.module.scss';

interface IProps {
  name: string;
  label: string;
  defaultValue?: string;
  placeholder?: string;
  showError?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  readOnly?: boolean;
  unmask?: boolean;

  min?: number | null;
  max?: number | null;
  decimal?: boolean;

  [key: string]: any;
}

const MonthField: React.FC<IProps> = ({ label, placeholder, showError = true, min, max, disabled, readOnly, unmask, decimal, autoFocus, required, ...props }) => {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField(props);
  const hasError = !!(showError && meta.touched && meta.error);

  return (
    <Field {...{ name: field.name, label, hasError, showError, error: meta.error, autoFocus, disabled, required, readOnly }} icon={<Icon.System.Calendar/>}>
      {({ onFocus, onBlur }) => (
        <IMaskInput
          {...{ placeholder, disabled, onFocus, readOnly, unmask }}
          id={field.name}
          name={field.name}
          value={field.value}
          type="tel"
          onBlur={(e) => {
            field.onBlur(e);
            onBlur();
          }}
          onAccept={value => helpers.setValue(value)}
          className={classes.input}
          placeholderChar=" "
          overwrite
          lazy={false}
          autofix={false}
          mask="m.Y"
          blocks={{
            m: { mask: IMask.MaskedRange, placeholderChar: t('month_placeholder'), from: 1, to: 12, maxLength: 2 },
            Y: { mask: IMask.MaskedRange, placeholderChar: t('year_placeholder'), from: 2020, to: 2999, maxLength: 4 }
          }}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      )}
    </Field>
  );
};

export default MonthField;