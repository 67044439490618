import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Types from './types';

const initialState: Types.IState = {
  list: {
    items: [],
    isFetched: false,
    isLoaded: false,
    meta: {
      current: 1,
      total: 1
    }
  },
  single: {
    item: Mappers.registry({}),
    isFetched: false
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.LIST.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetched: false
        }
      };
    }
    case Constants.LIST.SUCCESS: {
      const { items, meta } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          items,
          isFetched: true,
          isLoaded: true,
          meta
        }
      };
    }
    case Constants.LIST.FAILURE: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetched: true
        }
      };
    }
    case Constants.SINGLE.REQUEST: {
      return {
        ...state,
        single: {
          ...state.single,
          isFetched: false
        }
      };
    }
    case Constants.SINGLE.SUCCESS: {
      const { item } = action.payload;
      return {
        ...state,
        single: {
          ...state.single,
          item,
          isFetched: true
        }
      };
    }
    case Constants.SINGLE.FAILURE: {
      return {
        ...state,
        single: {
          ...state.single,
          isFetched: true
        }
      };
    }
    default:
      return state;
  }
}