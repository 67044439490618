import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  item: Types.IEntity.Info;
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  documentId: number;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useInfo = ({ documentId, onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps): IChildren => {
  const dispatch = useDispatch();

  const { item, isFetched, isLoaded } = useSelector<Store.Types.IState, Types.Info>(state => Selectors.getInfo(state, documentId));

  useEffect(() => {
    dispatch(Actions.Info.request({
      documentId,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, []);

  return {
    item,
    isFetched,
    isLoaded
  };
};

export default useInfo;