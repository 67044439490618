import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { firebase } from 'services';

import * as SystemModule from 'modules/system';

import * as Actions from '../actions';

const useInit = () => {
  const dispatch = useDispatch();
  const { language } = SystemModule.Hooks.useLanguage();

  useEffect(() => {
    if (!firebase.isSupported()) {
      return;
    }

    dispatch(Actions.RequestPermission.request());
  }, [language]);
};

export default useInit;