import React from 'react';
import cx from 'classnames';

import classes from './Details.module.scss';

interface IProps {
  title?: string;
  position?: 'requirement' | 'specialization';
  children?: JSX.Element | string;
}

const Requirement: React.FC<IProps> = ({ title = '', position = 'requirement', children, ...props }) => (
  <div className={cx(classes.item, classes[`item--${position}`])} {...props}>
    {position === 'requirement' ? (
      <div className={classes.itemTitle} dangerouslySetInnerHTML={{ __html: title }}></div>
    ) : (
      <div className={classes.itemValue}>{children}</div>
    )}
  </div>
);

export default Requirement;
