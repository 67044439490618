import { i18n } from 'services';

import * as Constants from './constants';

export const request = (data: { name: string; plugin?: string; arguments?: string[] }) => {
  return new Promise((resolve, reject) => {
    try {
      let socket: WebSocket = new WebSocket(Constants.BASE_URL);
      socket.onopen = () => {
        socket.send(JSON.stringify(data));
      };
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.success) {
          resolve(data);
        } else {
          reject(data.reason);
        }
        socket.close();
      };
      socket.onerror = () => reject(i18n.t('install_the_e_imzo_module_or_the_e_imzo_browser'));
    } catch (e) {
      reject(i18n.t('install_the_e_imzo_module_or_the_e_imzo_browser'));
    }
  });
};