import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({
  params,
  token
}: {
  token: any
  params: Types.IParams;
}): AxiosPromise<{ data: { content: Types.IApi.News.Response[] } }> => {
  return http.request.get<{ data: { content: Types.IApi.News.Response[] } }>(`/admin/open_source/news`, {
    params: {
      page: params.page - 1,
      size: params.limit
    },
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    }
  });
};

export const Single = ({ id, token }: Types.IApi.News.Request): AxiosPromise<{ data: Types.IApi.News.Response }> => {
  return http.request.get<{ data: Types.IApi.News.Response }>(`/admin/open_source/news/${id}`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    }
  });
};
