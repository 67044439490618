import React, { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { yup as yupCreator } from 'services';

import * as Actions from '../actions';
import * as Types from '../types';

interface FormValues extends Types.IForm.RegisterViaEmail {}

interface IChildren extends FormikProps<FormValues> {}

interface IProps {
  onSuccess?: ({ message }: { message: Types.IEntity.LangStrings }) => void;
  onError?: ({ error }: { error: Types.IEntity.LangStrings }) => void;
  onFinally?: () => void;
  children(props: IChildren): JSX.Element;
}

let cancelSource = axios.CancelToken.source();

const RegisterViaEmail: React.FC<IProps> = ({ onSuccess = () => {}, onError = () => {}, onFinally = () => {}, children }) => {
  const dispatch = useDispatch();

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const yup = yupCreator();
  const validationSchema = (
    yup.object().shape<FormValues>({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      middlename: yup.string(),
      countryId: yup.string(),
      email: yup.string().email().required(),
      password: yup.string().min(6).required(),
      confirm_password: yup.string().oneOf([yup.ref('password'), '']).required()
    })
  );

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if (!isSubmitting) {
      setSubmitting(true);
      cancelSource = axios.CancelToken.source();

      dispatch(Actions.RegisterViaEmail.request({
        values,
        cancelSource,
        callback: {
          onSuccess,
          onError: (error) => {
            setSubmitting(false);
            onError(error);
          },
          onFinally
        }
      }));
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        firstname: '',
        lastname: '',
        middlename: '',
        countryId: '',
        email: '',
        password: '',
        confirm_password: ''
      }}
      validateOnMount
      enableReinitialize
      {...{ validationSchema }}
    >
      {(props: FormikProps<FormValues>) => (
        <Form style={{ height: '100%' }}>
          {children(props)}
        </Form>
      )}
    </Formik>
  );
};

export default RegisterViaEmail;
