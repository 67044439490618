import { ActionType } from 'typesafe-actions';
import merge from 'lodash/merge';

import * as Mappers from 'modules/document/mappers';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  process: {
    applicationId: 0,
    applicationType: Constants.TYPE.REGISTRATION,
    documentId: 0,
    currentStep: 0,
    isComplete: false,
    values:[],
    status: ''
  },
  info: {
    document: Mappers.document({}),
    steps: {},
    groups: {
      entities: {},
      byStepId: {},
      byStepPosition: {}
    },
    fields: {
      entities: {},
      byStep: {},
      byGroup: {},
      byActionId: {}
    }
  },
  specialization: {
    documentId: 0,
    items: [],
    isFetched: false,
    isLoaded: false
  },
  requirements: {
    documentId: 0,
    items: [],
    isFetched: false,
    isLoaded: false
  },
  isFetched: false,
  error: ''
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.START.REQUEST: {
      return {
        ...state,
        isFetched: false
      };
    }
    case Constants.START.SUCCESS: {
      const { process, info } = action.payload;
      return {
        ...state,
        process,
        info
      };
    }
    case Constants.START.FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isFetched: true,
        error
      };
    }
    case Constants.UPDATE.SUCCESS:
    case Constants.BACK.SUCCESS: {
      const { process, values } = action.payload;
      return {
        ...state,
        process,
        info: {
          ...state.info,
          fields: {
            ...state.info.fields,
            entities: {
              ...state.info.fields.entities,
              ...values
            }
          }
        },
        isFetched: true,
        error: ''
      };
    }
    case Constants.RESUME.REQUEST: {
      return {
        ...state,
        isFetched: false
      };
    }
    case Constants.RESUME.SUCCESS: {
      const { process, info, values } = action.payload;
      return {
        ...state,
        process,
        info: {
          ...info,
          fields: {
            ...info.fields,
            entities: merge({}, info.fields.entities, values)
          }
        },
        isFetched: true,
        error: ''
      };
    }
    case Constants.RESUME.FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isFetched: true,
        error
      };
    }
    case Constants.SPECIALIZATION.REQUEST: {
      const { documentId } = action.payload;
      const isExists = state.specialization.documentId === documentId;

      return {
        ...state,
        specialization: {
          ...state.specialization,
          documentId,
          items: isExists ? state.specialization.items : [],
          isFetched: isExists,
          isLoaded: isExists,
        }
      }
    }
    case Constants.SPECIALIZATION.SUCCESS: {
      const { specialization } = action.payload;

      return {
        ...state,
        specialization: {
          ...state.specialization,
          items: specialization,
          isFetched: true,
          isLoaded: true,
        }
      }
    }
    case Constants.SPECIALIZATION.FAILURE: {
      return {
        ...state,
        specialization: {
          ...state.specialization,
          isFetched: true,
          isLoaded: true,
        }
      }
    }
    case Constants.REQUIREMENTS.REQUEST: {
      const { documentId } = action.payload;
      const isExists = state.requirements.documentId === documentId;

      return {
        ...state,
        requirements: {
          ...state.requirements,
          documentId,
          items: isExists ? state.requirements.items : [],
          isFetched: false,
          isLoaded: isExists,
        }
      }
    }
    case Constants.REQUIREMENTS.SUCCESS: {
      const { requirements } = action.payload;

      return {
        ...state,
        requirements: {
          ...state.requirements,
          items: requirements,
          isFetched: true,
          isLoaded: true,
        }
      }
    }
    case Constants.REQUIREMENTS.FAILURE: {
      return {
        ...state,
        requirements: {
          ...state.requirements,
          isFetched: true,
          isLoaded: true,
        }
      }
    }
    case Constants.ACTION_EVENT.SUCCESS: {
      const { fields } = action.payload;

      return {
        ...state,
        info: {
          ...state.info,
          fields: {
            ...state.info.fields,
            entities: {
              ...state.info.fields.entities,
              ...fields
            }
          }
        }
      }
    }
    case Constants.STORE_RESET: {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
}
