import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Constants from '../constants';

interface IReturn {
  language: Constants.LANGUAGES;
  methods: {
    set: (language: Constants.LANGUAGES) => void;
  }
}

const useLanguage = (): IReturn => {
  const dispatch = useDispatch();

  const language = useSelector<Store.Types.IState, Constants.LANGUAGES>(state => state.system.language);

  const set = (language: Constants.LANGUAGES) => {
    dispatch(Actions.Language.change({ language }));
  };

  return { language, methods: { set } };
};

export default useLanguage;