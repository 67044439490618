import React, { useEffect } from 'react';

import * as Icon from 'components/Icon';

import classes from './Spinner.module.scss';

interface IProps {
  size?: number;
}

const Spinner: React.FC<IProps> = ({ size = 24 }) => {

  useEffect(() => {
    if ('activeElement' in document) {
      const activeElement = (document.activeElement as HTMLElement);
      activeElement && activeElement.blur && activeElement.blur();
    }
  }, []);

  return (
    <div className={classes.wrapper} style={{ '--spinner-size': `${size}px` } as React.CSSProperties}>
      <div className={classes.spinner}>
        <Icon.System.Loading />
      </div>
    </div>
  );
};

export default Spinner;