import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';
import { useEffect } from 'react';
import { useGoogleCaptcha } from 'hooks';

interface IChildren {
  categories: Types.IEntity.Category[];
  days: Types.IEntity.Day[];
  statuses: Types.IEntity.Status;
}

interface IProps {
  date?: string,
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({ date, onSuccess = () => { }, onError = () => { }, onFinally = () => { } }: IProps = {}): IChildren => {
  const dispatch = useDispatch();
  const { handleLoad } = useGoogleCaptcha()
  const categories = useSelector<Store.Types.IState, Types.IEntity.Category[]>(state => state.stats.stats.categories);
  const days = useSelector<Store.Types.IState, Types.IEntity.Day[]>(state => state.stats.stats.days);
  const statuses = useSelector<Store.Types.IState, Types.IEntity.Status>(state => state.stats.stats.statuses);

  useEffect(() => {
    handleLoad({
      onSuccess(token) {
        if (token) {
          dispatch(
            Actions.List.request({
              token,
              date,
              callback: {
                onSuccess,
                onError,
                onFinally
              }
            })
          );
        }
      },
    })

  }, [date]);

  return {
    categories,
    statuses,
    days
  };
};

export default useList;
