import { takeLatest, takeEvery, call, put, all, select, cancelled } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { normalize, NormalizedSchema } from 'normalizr';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Store from 'store';

import { firebase } from 'services';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import Schema from './schema';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { params, cancelSource, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.Notification[] }> = yield call(Api.List, { params, cancelSource });
    const items = (get(data, 'data.content') || []).map(item => Mappers.notification(item));
    const { entities, result }: NormalizedSchema<{ notification: { [key: number]: Types.IEntity.Notification } }, number[]> = normalize(items, [Schema]);
    const meta = Mappers.meta(get(data, 'data', {}) || {});
    yield put(Actions.Entities({ params, items: entities.notification }));
    yield put(Actions.List.success({ ids: result, params, meta }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.List.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* UnReadCount(action: ReturnType<typeof Actions.UnReadCount.request>) {
  const { callback } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: number }> = yield call(Api.UnReadCount);
    const count = !!get(data, 'data') ? (Number(get(data, 'data')) || 0) : 0;
    yield put(Actions.UnReadCount.success({ count }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { count });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.UnReadCount.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* MarkAsRead(action: ReturnType<typeof Actions.MarkAsRead.request>) {
  const { id, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.Notification }> = yield call(Api.MarkAsRead, { id });
    const item = Mappers.notification((get(data, 'data') || {}));
    const { entities }: NormalizedSchema<{ notification: { [key: number]: Types.IEntity.Notification } }, number[]> = normalize(item, Schema);
    yield put(Actions.Entities({ items: entities.notification }));
    yield put(Actions.MarkAsRead.success());
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, {});
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.MarkAsRead.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* RequestPermission(action: ReturnType<typeof Actions.RequestPermission.request>) {
  const messaging = firebase.messaging;
  try {
    const permission: NotificationPermission = yield Notification.requestPermission();
    if (permission !== 'granted') {
      return;
    }
    yield put(Actions.RequestPermission.success());
    yield put(Actions.SetToken.request());
    const notificationChannel = eventChannel(emit => messaging.onMessage(emit));
    try {
      yield takeEvery(notificationChannel, onMessage);
    } finally {
      if (yield cancelled()) notificationChannel.close();
    }
  } catch (error) {
    yield put(Actions.RequestPermission.failure(error));
  }
}

export function* SetToken(action: ReturnType<typeof Actions.SetToken.request>) {
  const messaging = firebase.messaging;
  try {
    const token = yield messaging.getToken();
    const language = yield select((state: Store.Types.IState) => state.system.language);
    yield call(Api.SetToken, { token, language });
    yield put(Actions.SetToken.success());
  } catch (error) {
    yield put(Actions.SetToken.failure(error));
  }
}

export function* DeleteToken(action: ReturnType<typeof Actions.DeleteToken.request>) {
  const messaging = firebase.messaging;
  try {
    yield messaging.deleteToken();
    yield put(Actions.DeleteToken.success());
  } catch (error) {
    yield put(Actions.DeleteToken.failure(error));
  }
}

export function* onMessage(action: Types.IApi.Message) {
  const item = Mappers.message(action);
  yield put(Actions.onMessage({ item }));
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List),
    takeLatest(Constants.UN_READ_COUNT.REQUEST, UnReadCount),
    takeEvery(Constants.MARK_AS_READ.REQUEST, MarkAsRead),
    takeEvery(Constants.REQUEST_PERMISSION.REQUEST, RequestPermission),
    takeEvery(Constants.SET_TOKEN.REQUEST, SetToken),
    takeEvery(Constants.DELETE_TOKEN.REQUEST, DeleteToken)
  ]);
}