import { takeLatest, call, put, all } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { normalize, NormalizedSchema } from 'normalizr';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import Schema from './schema';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { name, organizationId, params, callback, token } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: { employees: Types.IApi.Employee[] } }> = yield call(Api.List, {
      organizationId,
      params,
      token
    });
    const items = (get(data, 'data.employees') || []).map(rating => Mappers.employee(rating));
    const meta = Mappers.meta({ ...(get(data, 'data', {}) || {}), size: params.limit });
    const { entities, result }: NormalizedSchema<{ employee: Types.IEntities }, number[]> = normalize(items, [Schema]);
    yield put(Actions.Entities({ items: entities?.employee }));
    yield put(Actions.List.success({ name, ids: result, meta }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.List.failure({ name, error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([takeLatest(Constants.LIST.REQUEST, List)]);
}
