import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import get from 'lodash/get';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';
import { useGoogleCaptcha } from 'hooks';

interface IChildren {
  items: Types.IEntity.Employee[];
  meta: Types.IEntity.Meta;
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  name: string;
  organizationId: number;
  params?: Partial<Types.IEntity.Params>;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({
  name,
  organizationId,
  params = {},
  onSuccess = () => { },
  onError = () => { },
  onFinally = () => { }
}: IProps): IChildren => {
  const dispatch = useDispatch();
  const { handleLoad } = useGoogleCaptcha()
  const items = useSelector<Store.Types.IState, Types.IEntity.Employee[]>(state => Selectors.getList(state, name));
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state => get(state, `employee.list[${name}].meta`));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => !!get(state, `employee.list[${name}].isFetched`));
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => !!get(state, `employee.list[${name}].isLoaded`));

  useDeepCompareEffect(() => {
    handleLoad({
      onSuccess(token) {
        if (token) {
          dispatch(
            Actions.List.request({
              token,
              name,
              organizationId,
              params: {
                page: params?.page || 1,
                limit: params?.limit || 15,
                sort: {
                  key: params?.sort?.key || 'id',
                  value: params?.sort?.value ? params?.sort?.value : false
                }
              },
              callback: {
                onSuccess,
                onError,
                onFinally
              }
            })
          );
        }
      },
    })

  }, [params]);

  return {
    items,
    meta,
    isFetched,
    isLoaded
  };
};

export default useList;
