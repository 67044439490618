import { AxiosPromise } from 'axios';
import { http } from 'services';

export const Settings = (): AxiosPromise<any> => {
  return http.request.get<any>(`/admin/settings`, {
    headers: {
      Authorization: ''
    }
  });
};
