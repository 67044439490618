import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import { toast } from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router';

import * as AuthModule from 'modules/auth';
import * as CategoryModule from 'modules/category';
import * as DocumentModule from 'modules/document';
import * as ProcessModule from 'modules/process';
import * as StepperModule from 'modules/stepper';
import * as SystemModule from 'modules/system';

import * as Icon from 'components/Icon';
import InfoBlock from 'components/InfoBlock';
import Button from 'components/Button';
import Modal, { Nav } from 'components/Modal';
import * as Breadcrumb from 'components/Breadcrumb';

import DocumentInfo from './components/DocumentInfo';
import ApplicantType from './components/ApplicantType';

import * as Hooks from '../../../hooks';

import classes from './DocumentView.module.scss';
import StatCard from '../../StatCard';
import TaxiDeliveryType from './components/TaxiDeliveryType';


const ACTION_TYPES = ['SET_CURRENT_MODAL', "SET_APPLICANT", "SET_APPLICATION", "SET_DELIVERY"] as const

interface Action {
  type: typeof ACTION_TYPES[number],
  payload: any
}

interface State {
  currentModal: 'default' | "delivery" | null,
  values: {
    applicant_type: string,
    taxi_delivery_type: string,
  }
}

const initialState: State = {
  currentModal: null,
  values: {
    applicant_type: '',
    taxi_delivery_type: '',
  }
};

function reducer(state: State = initialState, action: Action): State {
  const { type, payload } = action

  switch (type) {
    case "SET_CURRENT_MODAL":
      return { ...state, currentModal: payload }
    case "SET_APPLICANT":
      return {
        ...state,
        values: {
          ...state.values,
          applicant_type: payload,
        }
      }

    case "SET_DELIVERY":
      return {
        ...state,
        values: {
          ...state.values,
          taxi_delivery_type: payload
        }
      }

    default:
      return state
  }
}


const DocumentView: React.FC = () => {
  const location = useLocation()
  const { isAuthenticated, profile } = AuthModule.Hooks.useAuth();
  const { language } = SystemModule.Hooks.useLanguage();
  const { t } = useTranslation();
  const history = useHistory();
  const stepper = StepperModule.useContext();
  const [state, dispatch] = useReducer(reducer, initialState)

  const { state: { categoryId, subCategoryId, documentId }, methods: { setClose } } = CategoryModule.Context.Single.useContext();
  const [isLoading, setLoading] = useState(false)
  const category = Hooks.useSingle({ id: categoryId });
  const subCategory = Hooks.useSingle({ id: subCategoryId });

  const document = DocumentModule.Hooks.useSingle({ id: documentId });

  const closeModal = () => dispatch({
    type: "SET_CURRENT_MODAL", payload: null
  })

  const customDispatcher = (action: Action) => {
    dispatch(action)
    return reducer(state, action)
  }

  const { methods } = ProcessModule.Hooks.useProcess({
    onSuccess({ process }) {
      setLoading(false)
      if (location.pathname.startsWith('/kiosk')) {
        history.push(`/kiosk/application/${process.applicationId}`)
      } else {
        history.push(`/application/${process.applicationId}`)
      }
    },
    onError: (error) => {
      setLoading(false)
      const message = get(error, `message.title[${language}]`) || '';
      const type = get(error, `message.type`) || '';
      !!message && (type === 'INFO' ? toast(message) : toast.error(message));
    }
  });

  const info = DocumentModule.Hooks.useInfo({ documentId });

  // const onStart = (id: number) => {
  //   if (isAuthenticated) {
  //     if (profile.type === AuthModule.Constants.TYPE.EMAIL) {
  //       setApplicantType(true);
  //     } else {
  //       methods.start(id);
  //       setStarting(true);
  //     }
  //   } else {
  //     history.push('/auth');
  //   }
  // };
  const onStart = (newState: State) => {
    if (isAuthenticated) {
      setLoading(true)
      methods.start(documentId, newState.values)
    } else {
      history.push('/auth');
    }
  }


  useEffect(() => {
    const modal = window.document.querySelector('[class^="Modal_wrapper"]');
    modal && modal.scrollTo && modal.scrollTo(0, 0);
  }, []);

  let title = get(category, `title[${language}]`);
  if (category.children.length) {
    title = get(subCategory, `title[${language}]`);
  }

  const renderModal = useMemo(() => {
    switch (state.currentModal) {
      case "default":
        return (
          <ApplicantType
            isLoading={isLoading}
            onSelect={(value) => {
              const newState = customDispatcher(
                {
                  type: "SET_APPLICANT",
                  payload: value
                })


              if (documentId === 14005) {
                dispatch({
                  type: "SET_CURRENT_MODAL",
                  payload: "delivery"
                })
                return;
              }
              onStart(newState)
            }}
            close={closeModal} />
        )

      case "delivery":
        return (
          <TaxiDeliveryType
            isLoading={isLoading}
            onSelect={(value) => {
              const newState = customDispatcher(
                {
                  type: "SET_DELIVERY",
                  payload: value
                })

              onStart(newState)
            }}
            close={closeModal}
          />
        )
    }

  }, [state.currentModal, isLoading])


  const serviceCost = get(info, 'item.priceBegin') ?
    t('license_price_min_max', { min: get(info, 'item.priceBegin'), max: get(info, 'item.price') }) :
    (get(info, 'item.price') ? t('license_price', { value: get(info, 'item.price') }) : t('free'))

  const workingDays = get(info, 'item.review.timeBegin') ?
    t(get(info, 'item.review.isWeekDays') ? 'up_to_working_days_min_max' : 'up_to_working_days_min_max_is_week', { min: get(info, 'item.review.timeBegin'), max: get(info, 'item.review.time') }) :
    get(info, 'item.review.time') ? (t(get(info, 'item.review.isWeekDays') ? 'up_to_working_days' : 'up_to_days', { value: get(info, 'item.review.time') })) : t('document_online_fast')

  return (
    <>
      <div className={classes.wrapper}>
        <Nav showClose onClose={setClose} showPrev onPrev={() => stepper.prev()} />
        <div className={classes.breadcrumb}>
          <Breadcrumb.List variant="dark">
            <Breadcrumb.Item onClick={() => stepper.goTo(!category.children.length ? 'documents' : 'subCategory')}>
              {truncate(get(category, `title[${language}]`), { length: 40 })}
            </Breadcrumb.Item>
            {!!category.children.length && (
              <Breadcrumb.Item onClick={() => stepper.prev()}>{truncate(title, { length: 40 })}</Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{truncate(get(document, `title[${language}]`), { length: 40 })}</Breadcrumb.Item>
          </Breadcrumb.List>
        </div>
        <div className={classes.title}>{get(document, `title[${language}]`)}</div>
        <div className={classes.info}>
          <div className={classes.infoInner}>
            <Button
              size="large"
              variant="green"
              prefixIcon={<Icon.System.Plus />}
              isLoading={isLoading}
              onClick={() => {

                if (documentId === 14005) {
                  dispatch({
                    type: "SET_CURRENT_MODAL",
                    payload: "delivery"
                  })
                  return;
                }

                if (profile.type !== AuthModule.Constants.TYPE.EMAIL) {
                  onStart(state)
                  return;
                }
                dispatch({
                  type: "SET_CURRENT_MODAL", payload: "default"
                })
              }}
              className="m-r-12"
            >
              {t('submit_application')}
            </Button>
            <InfoBlock variant="white" title={t('Document_type', { defaultValue: 'Ҳужжат тури' })} children={get(document, `type.title[${language}]`)} className="m-r-12" />
            <InfoBlock
              variant="white"
              title={t('service_price', { defaultValue: 'Хизмат нархи' })}
              children={serviceCost}
              className="m-r-12"
            />
            {!!get(info, 'item.duration') && (
              <InfoBlock
                variant="white"
                title={t('License_term', { defaultValue: 'Лицензия муддати' })}
                children={(get(info, 'item.expiry') ?
                  `${get(info, 'item.duration')} ${t(get(info, 'item.expiry'))}` :
                  t('license_duration', { count: get(info, 'item.duration') as any }))}
                className="m-r-12"
              />
            )}
            <InfoBlock
              variant="white"
              // title={t(`${get(document, 'type.key')}_issuance_period`)}
              title={t("LICENSE_issuance_period")}
              children={workingDays}
            />
          </div>
        </div>
        <div className={classes.content}>
          <DocumentInfo documentId={document.id} {...info} />
          <StatCard document_id={document.id} />
        </div>
      </div>

      <Modal isOpen={!!state.currentModal} close={closeModal} width={600}>
        {renderModal}
      </Modal>
    </>
  );
};

export default DocumentView;
