export const BASE_URL = (window.location.protocol.toLowerCase() === 'https:' ? 'wss://127.0.0.1:64443' : 'ws://127.0.0.1:64646') + '/service/cryptapi';

export const API_KEYS = [
  'localhost', '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
  '127.0.0.1', 'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
  'null', 'E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D',
  'licenses.uz', 'EC8B0C565A796FFFBCAB3CA1EE2DCA1245E449550C5D455797166F43FBE8C5F1C5AAD44179EBB2807B0684AD1F26569E2FC017135689F00CBCEFA28EFF39028B',
  'license.gov.uz', '04FFE0B82EACFAF0FED75C317F4CAD8C231F04A5BC397AADCB6A93E01552AACB1F4E09F52EFA10278152E8738C1E7EAB2E8C531DB7685145468A3C0BB677DF91'
];

export enum LIST {
  REQUEST = '@@DIGITAL_SIGN/LIST/REQUEST',
  SUCCESS = '@@DIGITAL_SIGN/LIST/SUCCESS',
  FAILURE = '@@DIGITAL_SIGN/LIST/FAILURE'
}