import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Icon from 'components/Icon';

import classes from './NotificationList.module.scss';

const Empty: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.empty}>
      <div className={classes.emptyIcon}>
        <Icon.Placeholder.Bell/>
      </div>
      <div className={classes.emptyTitle}>{t('you_dont_have_notifications_yet')}</div>
      <div className={classes.emptySubtitle}>{t('here_will_be_the_results_of_applications_data_about_licenses_and_system_notifications')}</div>
    </div>
  );
};

export default Empty;