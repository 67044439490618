import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import get from 'lodash/get';

import * as SystemModule from 'modules/system';

import * as Fields from 'components/Fields';
import * as Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import * as Actions from '../actions';
import * as Constants from '../constants';
import * as Hooks from '../hooks';
import * as Types from '../types';
import { useTranslation } from 'react-i18next';

interface IProps {
  field: Types.IEntity.Field;
  applicationId: number;
  name: string;
  label: string;
  placeholder: string;
  disabled: boolean;
  required: boolean;
  action: {
    id: string;
    ids: string[];
    key: string;
    loadOptions: string;
  };
}

let cancelSource = axios.CancelToken.source();

const KEYBOARD = Constants.FIELD_KEYBOARD;

const ActionInput: React.FC<IProps> = ({ field, applicationId, action, name, ...props }) => {
  const dispatch = useDispatch();
  const { language } = SystemModule.Hooks.useLanguage();
  const [isLoading, setLoading] = useState(false);
  const { process: { currentStep }, info: { fields: { byActionId } } } = Hooks.useProcess();
  const { values, isSubmitting, getFieldMeta, setValues, validateField } = useFormikContext();
  const meta = getFieldMeta(name);
  const { t } = useTranslation()
  const hasError = !!(meta.touched && meta.error);

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const Load = () => {
    const stepFields = byActionId[currentStep];
    let fieldValues = [...action.ids, action.id].reduce((prev, id) => {
      const name = stepFields[id];
      if (name) {
        const value = get(values, `${name}.value`);
        if (!value) {
          setTimeout(() => validateField(name), 0);
        }
        return ([...prev, { key: id, value }]);
      }
      return ([...prev]);
    }, []);

    const isAllFieldHasValue = fieldValues.every(field => !!field.value);

    if (!isAllFieldHasValue) {
      return;
    }

    setLoading(true);
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.ActionEvent.request({
      values: {
        applicationId,
        key: action.key,
        language,
        name,
        fields: fieldValues
      },
      cancelSource,
      callback: {
        onSuccess: ({ fields, values }) => setFieldsValue(fields, values),
        onError: (error) => {
          const message = get(error, `message.title[${language}]`) || '';
          const type = get(error, `message.type`) || '';
          !!message && (type === 'INFO' ? toast(message) : toast.error(message));
          setFieldsValue(get(error, 'fields') || {}, get(error, 'values') || {});
        },
        onFinally: () => setLoading(false)
      }
    }));
  };

  const setFieldsValue = (fields, fieldsValues) => {
    const newValues = Object.entries(fieldsValues).reduce((prev, [key, fieldValue]) => {
      const value = get(fieldValue, 'value');
      const duplicable_values = get(fieldValue, 'duplicable_values');
      const readonly = get(fieldValue, 'readonly');
      if (value === undefined) {
        return { ...prev, [key]: { key, ...(get(values, key) || {}), readonly, duplicable_values } };
      }

      const field = get(fields, `${key}`);
      const type = get(field, 'type');
      const options = get(field, `options`) || [];
      if (type === Constants.FIELD_TYPE.DROP_DOWN) {
        const option = options.find(option => String(get(option, 'code')) === String(value));
        if (option) {
          return { ...prev, [key]: { key, value, option: get(option, 'name'), readonly, duplicable_values } }
        }
      }

      return { ...prev, [key]: { key, value, readonly, duplicable_values } };
    }, {});

    setValues((values) => ({ ...(values || {}) as object, ...newValues }));
  };

  const inputProps = {
    prefix: field.input.prefix,
    suffix: field.input.suffix
  };

  const icon = (
    isLoading ? <Spinner size={20} /> : <div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '0 10px' }}>
      <Icon.System.Search />
      {t('search')}
    </div>
  );
  const onIconClick = !hasError ? Load : () => { };

  if (field.input.mask) {
    return (
      <Fields.Mask
        {...props}
        {...inputProps}
        {...{ icon, onIconClick }}
        name={`${name}.value`}
        type={field.input.keyboard === KEYBOARD.NUMBER ? 'NUMBER' : 'TEXT'}
        mask={field.input.mask}
        disabled={isLoading || isSubmitting}
        isIconDisabled={hasError || isSubmitting}
      />
    )
  }

  return (
    <Fields.Text
      {...props}
      {...inputProps}
      {...{ isLoading, icon, onIconClick }}
      name={`${name}.value`}
      disabled={isLoading || isSubmitting}
      isIconDisabled={hasError || isSubmitting}
    />
  );
};

export default ActionInput;
