import { ActionType } from 'typesafe-actions';

import config from 'config';

import { storage } from 'services';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Type from './types';

const accessToken = storage.local.get(config.api.access_token_key);
const refreshToken = storage.local.get(config.api.refresh_token_key);

const initialState: Type.IState = {
  isAuthenticated: false,
  isFetched: !!!accessToken,
  token: {
    accessToken: !!accessToken ? `${accessToken}` : '',
    refreshToken: !!accessToken && !!refreshToken ? `${refreshToken}` : ''
  },
  profile: {
    id: 0,
    type: Constants.TYPE.INDIVIDUAL,
    tin: 0,
    pinfl: '',
    username: '',
    firstName: '',
    lastName: '',
    middleName: '',
    titleName: '',
    fullName: '',
    birthday: '',
    gender: '',
    phone: '',
    email: '',
    passport: {
      number: '',
      place: ''
    },
    registeredAt: ''
  },
  company: {
    name: '',
    tin: '',
    phone: '',
    address: '',
    region: {
      uz: '',
      oz: '',
      ru: '',
      en: ''
    },
    subRegion: {
      uz: '',
      oz: '',
      ru: '',
      en: ''
    },
    csdp: '',
    oked: '',
    opf: {
      uz: '',
      oz: '',
      ru: '',
      en: ''
    },
    register: {
      date: '',
      number: ''
    }
  }
};

export default (state: Type.IState = initialState, action: ActionType<typeof Actions>): Type.IState => {
  switch (action.type) {
    case Constants.LOGIN.REQUEST:
    case Constants.LOGIN_CHANGE_TOKEN.REQUEST:
    // case Constants.LOGIN_VIA_FACE_ID.REQUEST:
    case Constants.PROFILE.REQUEST: {
      return {
        ...state,
        isAuthenticated: false,
        isFetched: false
      };
    }
    case Constants.LOGIN.SUCCESS:
    case Constants.LOGIN_CHANGE_TOKEN.SUCCESS:
    case Constants.LOGIN_VIA_FACE_ID.SUCCESS:
    case Constants.LOGIN_VIA_DS.SUCCESS: {
      const { token, profile, company } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isFetched: true,
        token,
        profile,
        company
      };
    }
    case Constants.PROFILE.SUCCESS: {
      const { profile, company } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isFetched: true,
        profile,
        company
      };
    }
    case Constants.LOGOUT.REQUEST: {
      return {
        ...state,
        isFetched: false
      };
    }
    case Constants.LOGIN.FAILURE:
    case Constants.LOGIN_VIA_FACE_ID.FAILURE:
    case Constants.LOGIN_VIA_DS.FAILURE:
    case Constants.LOGIN_CHANGE_TOKEN.FAILURE:
    case Constants.PROFILE.FAILURE:
    case Constants.LOGOUT.SUCCESS: {
      return {
        ...state,
        isAuthenticated: false,
        isFetched: true,
        token: {
          ...initialState.token,
          accessToken: '',
          refreshToken: ''
        },
        profile: {
          ...initialState.profile
        }
      };
    }
    default:
      return state;
  }
};
