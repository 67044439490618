import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

const SETTINGS_UPDATE_TIME = 15000;

const useSettings = (): Types.IEntity.Settings => {
  const dispatch = useDispatch();

  const settings = useSelector<Store.Types.IState, Types.IEntity.Settings>(state => state.system.settings);

  useEffect(() => {
    dispatch(Actions.Settings.request());

    const interval = setInterval(() => {
      dispatch(Actions.Settings.request());
    }, SETTINGS_UPDATE_TIME);
    return () => clearInterval(interval);
  }, []);


  return settings;
};

export default useSettings;