import React from 'react';
import cx from 'classnames';

import classes from './Table.module.scss';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const ActionCell: React.FC<IProps> = ({ className, children }) => (
  <td className={cx(classes.cell, classes.actionCell, className)}>{children}</td>
);

export default ActionCell;
