import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';

interface IChildren {
  unReadCount: number;
}

interface IProps {
  onSuccess?: ({ count }: { count: number; }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useNotification = ({ onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps = {}): IChildren => {
  const dispatch = useDispatch();

  const unReadCount = useSelector<Store.Types.IState, number>(state => state.notification.unReadCount);

  useEffect(() => {
    dispatch(
      Actions.UnReadCount.request({
        callback: {
          onSuccess,
          onError,
          onFinally
        }
      })
    );
  }, []);

  return { unReadCount };
};

export default useNotification;