import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import axios from 'axios';
import get from 'lodash/get';

import * as SystemModule from 'modules/system';

import * as Fields from 'components/Fields';

import * as Actions from '../actions';
import * as Constants from '../constants';
import * as Types from '../types';

interface IProps {
  applicationId: number;
  name: string;
  label: string;
  placeholder: string;
  disabled: boolean;
  required: boolean;
  action: {
    id: string;
    ids: string[];
    key: string;
    loadOptions: string;
  };
  options: any;
  onChange?: (value, option) => void;
}

let cancelSource = axios.CancelToken.source();

const ActionSelect: React.FC<IProps> = ({ applicationId, action, name, options, onChange, ...props }) => {
  const dispatch = useDispatch();
  const { language } = SystemModule.Hooks.useLanguage();
  const [isLoading, setLoading] = useState(false);
  const { values, setValues, isSubmitting } = useFormikContext();
  const value = get(values, `${name}`);

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  useEffect(() => {
    if (action.loadOptions) {
      setLoading(true);
      cancelSource = axios.CancelToken.source();

      const parentValues: Types.IEntity.Values = get(values, 'parentValues');

      dispatch(Actions.ActionEvent.request({
        values: {
          applicationId,
          key: action.loadOptions,
          language,
          fields: [
            ...Object.values(parentValues || {}).reduce((prev, field) => ([
              ...prev,
              {
                key: field.key,
                value: field.value
              }
            ]), [])
          ]
        },
        cancelSource,
        callback: {
          onFinally: () => setLoading(false)
        }
      }));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && value && action.key) {
      setLoading(true);
      cancelSource = axios.CancelToken.source();

      const parentValues: Types.IEntity.Values = get(values, 'parentValues');

      dispatch(Actions.ActionEvent.request({
        values: {
          applicationId,
          key: action.key,
          language,
          fields: [
            {
              key: action.id,
              value
            },
            ...Object.values(parentValues || {}).reduce((prev, field) => ([
              ...prev,
              {
                key: field.key,
                value: field.value
              }
            ]), [])
          ]
        },
        cancelSource,
        callback: {
          onSuccess: ({ fields, values }) => setFieldsValues(fields, values),
          onError: ({ fields, values }) => setFieldsValues(fields, values),
          onFinally: () => setLoading(false)
        }
      }));
    }
  }, [value]);

  const setFieldsValues = (fields, fieldsValues) => {
    const newValuesOptions = Object.keys(fields || {}).reduce((prev, key) => {
      const type = get(fields, `${key}.type`);
      const options = get(fields, `${key}.options`) || [];
      const field = get(values, `${key}`);
      const readonly = get(field, 'readonly');
      const duplicable_values = get(field, 'duplicable_values');

      if (type === Constants.FIELD_TYPE.DROP_DOWN) {
        const option = options.find(option => String(get(option, 'code')) === String(get(field, 'value')));
        if (!option) {
          return { ...prev, [key]: { key, value: '', option: undefined, readonly, duplicable_values } }
        }
        return { ...prev, [key]: { key, value: get(field, 'value'), option: get(option, 'name'), readonly, duplicable_values: get(field, 'duplicable_values') } }
      }
      return { ...prev };
    }, {});

    const newValues = Object.entries(fieldsValues).reduce((prev, [key, fieldValue]) => {
      const value = get(fieldValue, 'value');
      const readonly = get(fieldValue, 'readonly');
      const duplicable_values = get(fieldValue, 'duplicable_values');

      if (value === undefined) {
        return { ...prev };
      }
      return { ...prev, [key]: { key: key, value, readonly, duplicable_values } };
    }, {});

    setValues((values) => ({ ...(values || {}) as object, ...newValuesOptions, ...newValues }));
  };

  return (
    <Fields.Select
      {...props}
      {...{ name, options, isLoading, onChange }}
      optionLabel={(field) => get(field, `name[${language}]`)}
      optionValue="code"
      disabled={isLoading || isSubmitting}
    />
  );
};

export default ActionSelect;
