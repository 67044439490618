import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import cx from 'classnames';

import classes from './Modal.module.scss';

interface IProps {
  isOpen: boolean;
  close: () => void;
  size?: 'small' | 's-middle' | 'middle' | 'large';
  width?: number;
  variant?: 'white';
  onClose?: () => void;
  backdropVisible?: boolean;
  backdropClose?: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<IProps> = ({ isOpen, close, size = 'middle', width, variant, onClose = () => {}, backdropVisible = true, backdropClose = true, children
}) => {

  useEffect(() => {
    if (isOpen) {
      if ('activeElement' in document) {
        setTimeout(() => (document.activeElement as HTMLElement).blur(), 0);
      }
    }
  }, [isOpen]);

  const handleClose = (e) => {
    if(!backdropClose) return;
    if(e.target !== e.currentTarget) return;
    onClose(); close();
  };

  return (
    <Transition
      in={isOpen}
      timeout={{ enter: 0, exit: 300 }}
      mountOnEnter
      unmountOnExit
    >
      {(state: TransitionStatus) => (
        ReactDOM.createPortal(
          <div id={`${classes.container}-id`} className={classes.container}>
            {backdropVisible && (
              <div className={classes.backdrop} style={{ ...backdropDefault, ...backdropTransition[state] }} />
            )}
            <div className={cx(classes.wrapper, size && classes[`wrapper--size-${size}`], variant && classes[`wrapper--variant-${variant}`])} onClick={handleClose}>
              <div className={classes.dialog} style={!!width ? { maxWidth: width } : {}}>
                <div className={classes.content} style={{ ...modalDefaultTransition, ...modalTransition[state] }}>{children}</div>
              </div>
            </div>
          </div>,
          document.getElementById('modal') as HTMLElement
        )
      )}
    </Transition>
  );
};

const backdropDefault = {
  transition: 'all .2s ease-in-out .1s',
  opacity: 0,
  visibility: 'hidden'
};

const backdropTransition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transition: 'all .1s ease-in-out' },
  exited: { opacity: 0, visibility: 'hidden' }
};

const modalDefaultTransition = {
  transition: 'all .2s ease-in-out',
  opacity: 0,
  visibility: 'hidden',
  transform: 'translateY(30px)'
};

const modalTransition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transform: 'translateY(0)', transition: 'all .2s ease-in-out' },
  exited: { opacity: 0, visibility: 'hidden' }
};

export default Modal;