import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const Prepayment = ({ applicationId, registerId, serial, language, values }: Types.IApi.Prepayment.Request): AxiosPromise => {
  return http.request.post(`/payment/online/send_sms`, {}, {
    params: {
      application_id: applicationId,
      register_id: registerId,
      serial,
      lang: language,
      card_number: values.card.number,
      card_expire_date: values.card.expires
    }
  });
};

export const Confirm = ({ applicationId, registerId, serial, language, values }: Types.IApi.Confirm.Request): AxiosPromise => {
  return http.request.post(`/payment/online/confirm_sms`, {}, {
    params: {
      application_id: applicationId,
      register_id: registerId,
      serial,
      lang: language,
      confirm_sms: values.code
    }
  });
};

export const Fees = ({ registerId, end }: Types.IApi.Fees.Request): AxiosPromise => {
  return http.request.post(`/payment/fees`, {}, {
    params: {
      register_id: registerId,
      end
    }
  });
};