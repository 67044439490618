import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ params }: Types.IApi.Application.Request): AxiosPromise<{ data: { application_list: Types.IApi.Application.Response[] } }> => {
  return http.request.get<{ data: { application_list: Types.IApi.Application.Response[] } }>(`/application/list`, {
    params: {
      search: params.search,
      page: params.page - 1,
      size: params.limit
    }
  });
};

export const Single = ({ id }): AxiosPromise<{ data: Types.IApi.Application.Response }> => {
  return http.request.get<{ data: Types.IApi.Application.Response }>(`/application/view?id=${id}`);
};