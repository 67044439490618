import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useHistory, useLocation } from 'react-router';
import get from 'lodash/get';
import qs from 'qs';

import * as CategoryModule from 'modules/category';
import * as SystemModule from 'modules/system';

import * as Types from '../types';

interface FormValues extends Types.IForm.Filter {
}

interface IChildren extends FormikProps<FormValues> {
  clear: (values: FormValues) => void;
}

interface IProps {
  onSuccess?: () => void;
  onError?: (error: { message: string }) => void;
  onFinally?: () => void;

  children(props: IChildren): JSX.Element;
}

const Filter: React.FC<IProps> = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { filter = {} } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const Category = CategoryModule.Hooks.useList();
  const { language } = SystemModule.Hooks.useLanguage();

  let resetFormFn: any = null;
  const bindResetForm = (resetForm) => {
    resetFormFn = resetForm;
  };

  const setParams = (values?: FormValues) => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        page: undefined,
        filter: {
          category_id: get(values, 'category.value'),
          applicant_type: values?.applicant_type || undefined
        }
      })
    });
  };

  const handleSubmit = (values: FormValues) => {
    setParams(values);
  };

  const clear = (values: FormValues) => {
    setParams(values);
    resetFormFn && resetFormFn(values);
  };

  const category = get(Category.entities, `[${get(filter, 'category_id')}]`);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        category: get(filter, 'category_id') ? { label: get(category, `[title][${language}]`), value: get(category, 'id' )} : undefined,
        applicant_type: get(filter, 'applicant_type') || ''
      }}
      validateOnMount
      enableReinitialize
    >
      {(props: FormikProps<FormValues>) => {
        bindResetForm(props.setValues);
        return (
          <Form>
            {children({ ...props, clear })}
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filter;