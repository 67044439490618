import { useGoogleCaptcha } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Organization[];
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  params?: Partial<Types.IParams>;
  onSuccess?: ({ items }: { items: Types.IEntity.Organization[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({ params = {}, onSuccess = () => { }, onError = () => { }, onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();

  const { handleLoad } = useGoogleCaptcha()

  const items = useSelector<Store.Types.IState, Types.IEntity.Organization[]>(state => Selectors.getList(state, params));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.organization.list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.organization.list.isLoaded);

  useEffect(() => handleLoad({
    onSuccess(token) {
      if (token) {
        dispatch(Actions.List.request({
          token,
          callback: {
            onSuccess,
            onError,
            onFinally
          }
        }));
      }
    },
  }), []);

  return {
    items,
    isFetched,
    isLoaded
  };
};

export default useList;