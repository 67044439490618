import React from 'react';

import * as Hooks from '../hooks';
import * as Types from '../types';

interface IProps {
  children: (settings: Types.IEntity.Settings) => React.ReactNode;
}

const Settings: React.FC<IProps> = ({ children }) => {
  const settings = Hooks.useSettings();

  return (
    <>{children(settings)}</>
  );
};

export default Settings;