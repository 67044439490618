import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { AxiosError } from 'axios';

import { yup as yupCreator } from 'services';

import * as Api from '../api';
import * as Types from '../types';

interface FormValues extends Types.IForm.Confirm {}

interface IProps {
  applicationId?: number;
  registerId?: number;
  serial: string;
  language: string;
  onSuccess?: () => void;
  onError?: (message: string) => void;
  onFinally?: () => void;

  children(props: FormikProps<FormValues>): JSX.Element;
}

const Confirm: React.FC<IProps> = ({ applicationId, registerId, serial, language, onSuccess = () => {}, onError = () => {}, onFinally = () => {}, children }) => {

  const yup = yupCreator();
  const validationSchema = (
    yup.object().shape<FormValues>({
      code: yup.string().min(6).required()
    })
  );

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if(isSubmitting){
      return;
    }

    Api.Confirm({ applicationId, registerId, serial, language, values: values })
      .then((() => onSuccess()))
      .catch((e) => {
        const response = e as AxiosError;
        const message = response?.response?.data?.status_message;
        setSubmitting(false);
        onError(message)
      })
      .finally(() => onFinally())
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        code: ''
      }}
      validateOnMount
      validateOnChange
      {...{ validationSchema }}
    >
      {(props: FormikProps<FormValues>) => (
        <Form>
          {children(props)}
        </Form>
      )}
    </Formik>
  );
};

export default Confirm;