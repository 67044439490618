import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Branches[];
  meta: Types.IEntity.Meta;
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  id: number;
  params?: Partial<Types.IBranchParams>;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({
  id,
  params = {},
  onSuccess = () => { },
  onError = () => { },
  onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.Branches[]>(state => state.branches.list.items);
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state =>
    state.branches.list.meta
  );
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.branches.list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.branches.list.isLoaded);

  useEffect(() => {
    dispatch(Actions.List.request({
      id,
      params: {
        page: params.page || 0,
        limit: params.limit || 10
      },
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, [params.page]);

  return {
    items,
    meta,
    isFetched,
    isLoaded
  };
};

export default useList;