import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Types from './types';

export const news = (item: Types.IApi.News.Response): Types.IEntity.News => {
  const images = orderBy(get(item, 'content_files') || [], ['fileId'], ['desc']);

  return ({
    id: get(item, 'id') || 0,
    title: langString(item, 'title'),
    image: get(images, '[0].download_uri', '') || '',
    date: get(item, 'updated_at', '') || ''
  });
};

export const newsSingle = (item: Types.IApi.News.Response): Types.IEntity.NewsSingle => {
  const images = orderBy(get(item, 'content_files') || [], ['fileId'], ['desc']);

  return ({
    id: get(item, 'id') || 0,
    title: langString(item, 'title'),
    description: langString(item, 'description'),
    image: get(images, '[0].download_uri', '') || '',
    date: get(item, 'updated_at', '') || ''
  });
};

export const langString = (item?: Types.IApi.News.Response | null, fieldName?: string): Types.IEntity.LangStrings => {
  return {
    ru: get(item, `${fieldName}_ru`, '') || '',
    uz: get(item, `${fieldName}_uz`, '') || '',
    oz: get(item, `${fieldName}_oz`, '') || '',
    en: get(item, `${fieldName}_en`, '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'number') ? (get(item, 'number') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};
