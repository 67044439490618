import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import * as AuthModule from 'modules/auth';
import * as SystemModule from 'modules/system';

import * as Details from 'components/Details';
import * as Icon from 'components/Icon';
import { Nav } from 'components/Modal';

import classes from './Settings.module.scss';

enum TAB {
  MY_INFO = 1,
  ORG_INFO = 2,
  SETTINGS = 3
}

const TabComponent: React.FC<{ activeTabId: TAB }> = ({ activeTabId }) => {
  const { profile, company } = AuthModule.Hooks.useAuth();
  const { language } = SystemModule.Hooks.useLanguage();
  const { t } = useTranslation();

  switch (activeTabId) {
    case TAB.MY_INFO:
      return (
        <Details.List className={classes.details}>
          <Details.Item title={t('Full_name')}>{profile.fullName}</Details.Item>
          <Details.Item title={t('Taxpayer_identification_number')}>{profile.tin}</Details.Item>
          <Details.Item title={t('Pinfl')}>{profile.pinfl}</Details.Item>
          <Details.Item title={t('Date_of_birth')}>{profile.birthday}</Details.Item>
          <Details.Item title={t('Passport_number_')}>{profile.passport.number}</Details.Item>
          <Details.Item title={t('Issued')}>{profile.passport.place}</Details.Item>
          <Details.Item title={t('Mobile')}>{profile.phone}</Details.Item>
          <Details.Item title={t('Email')}>{profile.email}</Details.Item>
        </Details.List>
      );
    case TAB.ORG_INFO:
      return (
        <Details.List className={classes.details}>
          <Details.Item title={t('Title_of_company')}>{company.name}</Details.Item>
          <Details.Item title={t('Taxpayer_identification_number')}>{company.tin}</Details.Item>
          <Details.Item title={t('Date_state_registration')}>{company.register.date}</Details.Item>
          <Details.Item title={t('Registration_number_registry')}>{company.register.number}</Details.Item>
          <Details.Item title={t('Organizational_legal_form')}>{get(company, `opf[${language}]`)}</Details.Item>
          <Details.Item title={t('Status_company')}>{company.csdp}</Details.Item>
          <Details.Item title={t('Place_registration')}>
            {[get(company, `region[${language}]`), get(company, `subRegion[${language}]`)].filter(item => !!item).join(', ')}
          </Details.Item>
          <Details.Item title={t('Address')}>{company.address}</Details.Item>
          <Details.Item title={t('Mobile')}>{company.phone}</Details.Item>
          <Details.Item title={t('NCTEA')}>{company.oked}</Details.Item>
        </Details.List>
      );
    case TAB.SETTINGS:
      return (
        <Details.List className={classes.details}>
          <Details.Item title={t('Push_notifications')}> </Details.Item>
          <Details.Item title={t('Language')}>Ўзбекча</Details.Item>
          <Details.Item title={t('Logout')}> </Details.Item>
        </Details.List>
      );
    default:
      return null;
  }
};

interface IProps {
  close: () => void;
  initialTab: TAB;
}

const Settings: React.FC<IProps> = ({ close, initialTab }) => {
  const { profile, company } = AuthModule.Hooks.useAuth();
  const [activeTabId, setActiveTabId] = useState(initialTab);
  const { t } = useTranslation();

  const tabs = [
    {
      id: TAB.MY_INFO,
      title: t('My_details'),
      icon: <Icon.System.User/>,
      isVisible: true
    },
    {
      id: TAB.ORG_INFO,
      title: t('My_organization'),
      icon: <Icon.System.Company/>,
      isVisible: profile.type !== AuthModule.Constants.TYPE.INDIVIDUAL
    },
    {
      id: TAB.SETTINGS,
      title: t('Settings'),
      icon: <Icon.System.Settings/>,
      isVisible: false
    }
  ];

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={close}/>
      <div className={classes.profileWrapper}>
        <div className={classes.profileName}>{profile.titleName}</div>
        {!!company.name && (
          <div className={classes.profileCompany}>{company.name}</div>
        )}
      </div>
      <div className={classes.tabWrapper}>
        <div className={classes.tabWrapperInner}>
          {tabs.filter(tab => tab.isVisible).map(tab => (
            <div
              key={tab.id}
              className={cx(classes.tabItem, tab.id === activeTabId && classes.tabItemActive)}
              onClick={() => setActiveTabId(tab.id)}
            >
              <div className={classes.tabItemTitle}>{tab.title}</div>
              <div className={classes.tabItemIcon}>{tab.icon}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.content}>
        <TabComponent {...{ activeTabId }} />
      </div>
    </div>
  );
};

export default Settings;