import { AxiosPromise } from 'axios';
import get from 'lodash/get';

import { http } from 'services';

import * as Types from './types';

export const List = ({ organizationId, params, token }: { organizationId: number; params: Types.IEntity.Params; token: any }): AxiosPromise<{ data: Types.IApi.Employee[] }> => {
  const page = get(params, 'page') ? Number(get(params, 'page')) : 0;
  const limit = get(params, 'limit') ? Number(get(params, 'limit')) : 10;

  return http.request.get<{ data: Types.IApi.Employee[] }>(`/admin/employee/open_source`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    },
    params: {
      page: page - 1,
      size: limit,
      sort: `${params.sort.key},${params.sort.value ? 'asc' : 'desc'}`,
      level: 2,
      organization_id: organizationId
    } as Types.IApi.Params
  });
};
