import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  entities: Types.IEntities;
  entitiesZero: Types.IEntity.Document[];
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({ onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps = {}): IChildren => {
  const dispatch = useDispatch();
  const entities = useSelector<Store.Types.IState, Types.IEntities>(state => Selectors.getEntities(state));
  const entitiesZero = useSelector<Store.Types.IState, Types.IEntity.Document[]>(state => Selectors.getZeroList(state));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.document.listByCategory.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.document.listByCategory.isLoaded);
  const updated_date = useSelector<Store.Types.IState, number>(state => state.document.listByCategory.updated_date);

  useEffect(() => {
    dispatch(
      Actions.List.request({
        updated_date,
        callback: {
          onSuccess,
          onError,
          onFinally
        }
      })
    );
  }, []);

  return {
    entities,
    entitiesZero,
    isFetched,
    isLoaded
  };
};

export default useList;
