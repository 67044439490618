import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  items: Types.IListItem[];
  meta: Types.IEntity.Meta;
  isFetched: boolean;
  isLoaded: boolean;
  methods: {
    Next: () => void;
  }
}

interface IProps {
  onSuccess?: ({ items }: { items: Types.IEntity.Notification[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

let cancelSource = axios.CancelToken.source();

const useList = ({ onSuccess = () => {}, onError = () => {}, onFinally = () => {} }: IProps = {}): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IListItem[]>(state => Selectors.getGroupedList(state));
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.notification.list.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.notification.list.isLoaded);
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state => state.notification.list.meta);

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  useEffect(() => {
    Load({ page: 1 });
  }, []);

  const Load = ({ page = 1, limit = 10 }: Partial<Types.IParams>) => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.List.request({
      params: { page, limit },
      cancelSource,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  };

  const Next = () => {
    Load({ page: meta.current + 1 });
  };

  return { items, isFetched, isLoaded, meta, methods: { Next } };
};

export default useList;