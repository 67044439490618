import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import * as Hooks from '../../hooks';

import Item from './Item';

import classes from './NotificationMessages.module.scss';

const Messages: React.FC = () => {
  const messages = Hooks.useMessages();

  return (
    <TransitionGroup className={classes.wrapper}>
      {messages.map(message => (
        <Transition key={message.id} in={true} timeout={300} mountOnEnter unmountOnExit>
          {(state: TransitionStatus) => (
            <Item item={message} style={{ ...transition[state] }} />
          )}
        </Transition>
      ))}
    </TransitionGroup>
  );
};

const transition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transform: 'translateY(0)' },
  exited: { opacity: 0, visibility: 'hidden' }
};

export default Messages;