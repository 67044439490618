import { createSelector } from 'reselect';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Store from 'store';

import * as Types from './types';

export const getList = createSelector<Store.Types.IState, string, Types.IEntity.Application[], Types.IEntity.Application[]>(
  ((state, name) => get(state, `application.list[${name}].items`)),
  (items) => {
    items = orderBy(items, ['id'], ['desc']);
    return items;
  }
);