export const ENTITIES = '@@ORGANIZATION/ENTITIES';

export enum LIST {
  REQUEST = '@@ORGANIZATION/LIST/REQUEST',
  SUCCESS = '@@ORGANIZATION/LIST/SUCCESS',
  FAILURE = '@@ORGANIZATION/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@ORGANIZATION/SINGLE/REQUEST',
  SUCCESS = '@@ORGANIZATION/SINGLE/SUCCESS',
  FAILURE = '@@ORGANIZATION/SINGLE/FAILURE'
}