import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import get from 'lodash/get';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.VideoInstruction.Response[];
  meta: Types.IEntity.Meta;
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  name: string;
  params?: Partial<Types.IParams>;
  onSuccess?: ({ items }: { items: Types.IEntity.VideoInstruction.Response[] }) => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useList = ({
  name,
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
}: IProps): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.VideoInstruction.Response[]>(state =>
    Selectors.getList(state, name)
  );
  const meta = useSelector<Store.Types.IState, Types.IEntity.Meta>(state =>
    get(state, `videoInstruction.list[${name}].meta`)
  );
  const isFetched = useSelector<Store.Types.IState, boolean>(
    state => !!get(state, `videoInstruction.list[${name}].isFetched`)
  );
  const isLoaded = useSelector<Store.Types.IState, boolean>(
    state => !!get(state, `videoInstruction.list[${name}].isLoaded`)
  );

  useDeepCompareEffect(() => {
    dispatch(
      Actions.List.request({
        name,
        params: {
          page: params.page || 1,
          limit: params.limit || 10
        },
        callback: {
          onSuccess,
          onError,
          onFinally
        }
      })
    );
  }, [params]);

  return {
    items,
    meta,
    isFetched,
    isLoaded
  };
};

export default useList;
