import React, { useState } from 'react';
import * as Table from 'components/Table';
import * as ProcessModule from 'modules/process';
import classes from '../CertificateVew.module.scss';
import { useTranslation } from 'react-i18next';
import * as Icon from 'components/Icon';
import Button from 'components/Button';
import toast from 'react-hot-toast';
import { get } from 'lodash';
import { useList } from 'modules/branches/hooks';
import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';
import WorkersModal from './Workers';
import Empty from 'components/Empty';

const BranchesModal = ({ id }: { id: number }) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [branch, setBranch] = useState<any>(null);
  const [workers, setWorkers] = useState<string>('');
  const { items, meta, isLoaded, isFetched } = useList({
    id,
    params: {
      limit: 10,
      page
    }
  });

  const [isLoading, setLoading] = useState(false);
  const [isTermination, setTermination] = useState(false);
  const Process = ProcessModule.Hooks.useProcessStart({ registerId: id });
  const PROCESS_TYPES = ProcessModule.Constants.TYPE;

  const pageChange = current => {
    setPage(Number(current));
  };

  const handleTermination = (id: string) => {
    if (!isTermination) {
      setTermination(true);
      Process.branch(
        PROCESS_TYPES.TERMINATION,
        {
          onSuccess: () => {},
          onError: (error: any) => {
            const message = get(error, `message.title[${i18n.language}]`) || '';
            const type = get(error, `message.type`) || '';
            !!message && (type === 'INFO' ? toast(message) : toast.error(message));
          },
          onFinally: () => {
            setTermination(false);
          }
        },
        Number(id)
      );
    }
  };

  const onClick = () => {
    if (!isLoading) {
      setLoading(true);
      Process.branch(PROCESS_TYPES.REGISTRATION, {
        onSuccess: () => {},
        onError: (error: any) => {
          const message = get(error, `message.title[${i18n.language}]`) || '';
          const type = get(error, `message.type`) || '';
          !!message && (type === 'INFO' ? toast(message) : toast.error(message));
        },
        onFinally: () => {
          setLoading(false);
        }
      });
    }
  };

  const onClickSingle = (id: number) => {
    if (!isLoading) {
      Process.branchSingle(PROCESS_TYPES.MODIFICATION, id, {
        onSuccess: () => {},
        onError: (error: any) => {
          const message = get(error, `message.title[${i18n.language}]`) || '';
          const type = get(error, `message.type`) || '';
          !!message && (type === 'INFO' ? toast(message) : toast.error(message));
        },
        onFinally: () => {
          setLoading(false);
        }
      });
    }
  };

  if (!isLoaded && !isFetched) {
    return <Spinner />;
  }

  return (
    <div className={classes.branches}>
      {!!items.length ? (
        <Table.Table className={classes.table}>
          <Table.Header>
            <Table.HeaderCell>{t('branch_region')}</Table.HeaderCell>
            <Table.HeaderCell>{t('branch_sub_region')}</Table.HeaderCell>
            <Table.HeaderCell>{t('branch_address')}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {items?.map(item => (
              <Table.Row key={item.id}>
                <Table.Cell>{item.region[i18n.language]}</Table.Cell>
                <Table.Cell>{item.subRegion[i18n.language]}</Table.Cell>
                <Table.Cell>
                  {item.village[i18n.language] && `${item.village[i18n.language]}, `}
                  {item.address}
                </Table.Cell>
                <Table.ActionCell>
                  <Button
                    variant="grey"
                    prefixIcon={<Icon.System.Show />}
                    onClick={() => setWorkers(item.id.toString())}
                  />
                  <Button variant="grey" prefixIcon={<Icon.System.Edit />} onClick={() => onClickSingle(item.id)} />
                  <Button
                    variant="red"
                    prefixIcon={<Icon.System.Delete />}
                    onClick={() =>
                      setBranch({
                        id: item.id,
                        address: [
                          item.region[i18n.language],
                          item.subRegion[i18n.language],
                          item.village[i18n.language],
                          item.address
                        ]
                          .filter(Boolean)
                          .join(', ')
                      })
                    }
                  />
                </Table.ActionCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Table>
      ) : (
        <Empty hasButton={false} title="you_dont_have_any_branches" />
      )}

      <Pagination total={get(meta, 'total')} current={get(meta, 'current')} onChange={pageChange} />
      <div className={classes.branchesAction}>
        <Button isLoading={isLoading} onClick={onClick} variant="blue" prefixIcon={<Icon.System.Plus />}>
          {t('add_branche')}
        </Button>
      </div>

      <Modal isOpen={!!workers} close={() => setWorkers('')}>
        <WorkersModal close={() => setWorkers('')} id={workers} />
      </Modal>

      <Modal backdropClose={false} width={500} isOpen={!!branch} close={() => setBranch(null)}>
        <div className={classes.content}>
          <p style={{ textAlign: 'center' }}>
            {t('end_of_branch', {
              value: get(branch, 'address')
            })}
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '1rem'
            }}
          >
            <Button variant="red" onClick={() => setBranch(null)}>
              {t('cancel')}
            </Button>
            <Button isLoading={isTermination} variant="blue" onClick={() => handleTermination(get(branch, 'id'))}>
              {t('confirmation')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BranchesModal;
