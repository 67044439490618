import React from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import classes from './FieldCheckboxList.module.scss';
import CheckboxBase from '../Checkbox/CheckboxBase';
import get from 'lodash/get';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  options: Array<any>;
  optionLabel?: string | ((option) => string);
  optionValue?: string | ((option) => string);
  disabled?: boolean;
  required?: boolean;
  isLoading?: boolean;
  onChange?: (value) => void;
}

const CheckboxList: React.FC<IProps> = ({ label, placeholder, options, optionLabel = 'name', optionValue = 'value', disabled, required, isLoading, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  const values = ((get(field, 'value') || '').split(':') || []).filter(v => v);

  const optionLabelFn = option => typeof optionLabel === "function" ? optionLabel(option) : option[optionLabel];
  const optionValueFn = option => typeof optionValue === "function" ? optionValue(option) : option[optionValue];

  return (
    <div className={cx(classes.wrapper, disabled && classes.wrapperDisabled)}>
      {!!label && <div className={classes.label} title={label}>{!!required && <span>*</span>}{label}</div>}
      <div className={classes.optionList}>
        {options.map(option => {
          const label = optionLabelFn(option);
          const value = optionValueFn(option);

          return (
            <label key={value} className={classes.optionItem}>
              <div className={classes.optionItemLabel}>{label}</div>
              <div className={classes.optionItemValue}>
                <CheckboxBase
                  {...{ hasError }}
                  value={values.includes(value)}
                  onChange={() => {
                    const set = new Set(values);
                    if (set.has(value)) {
                      set.delete(value);
                    } else {
                      set.add(value);
                    }
                    helpers.setValue(Array.from(set).join(':'));
                    helpers.setTouched(true);
                  }}
                />
              </div>
            </label>
          )
        })}
      </div>
      {hasError && (
        <div className={classes.errorLabel}>{meta.error}</div>
      )}
    </div>
  );
};

export default CheckboxList;
