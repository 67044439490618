import React from 'react';

import classes from './Online.module.scss';
import { Nav } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import HumoOrVisa from './HumoOrVisa';
import UzCard from './UzCard';

interface IProps {
  serial: string;
  onClose: () => void;
}

const Online: React.FC<IProps> = ({ serial, onClose }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={onClose} variant="light" />
      <div className={classes.title}>{t('online_payment')}</div>
      <div className={classes.content}>
        <UzCard serial={serial}>
          <div className={classes.card}>
            <img src="/uzcard.png" />
          </div>
        </UzCard>
        <HumoOrVisa serial={serial}>
          <div className={classes.card}>
            <img src="/humoandvisa.jpg" />
          </div>
        </HumoOrVisa>
      </div>
    </div>
  );
};

export default Online;
