import React from 'react';

import SvgIcon, { IProps } from '../SvgIcon';

export default (props: IProps) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a10 10 0 11-4.59 18.89L3.6 21.96a1.25 1.25 0 01-1.54-1.54l1.06-3.83A10 10 0 0112 2zm0 1.5a8.5 8.5 0 00-7.43 12.64l.15.27-1.1 3.98 3.98-1.11.27.15A8.5 8.5 0 1012 3.5z"/>
    <path fill="#34CEFF" d="M13 16.5a1 1 0 10-2 0 1 1 0 002 0zM14.75 9.5a2.75 2.75 0 10-5.5 0 .75.75 0 001.5 0v-.13a1.25 1.25 0 012.5.13c0 .58-.16.85-.78 1.47l-.17.17c-.75.79-1.05 1.35-1.05 2.36a.75.75 0 001.5 0c0-.58.16-.85.78-1.47l.17-.17c.75-.79 1.05-1.35 1.05-2.36z"/>
  </SvgIcon>
);
