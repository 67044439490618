import { ActionType } from 'typesafe-actions';
import merge from 'lodash/merge';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  entities: {},
  list: {
    all: {
      ids: [],
      isFetched: false,
      isLoaded: false,
      meta: {
        current: 1,
        total: 1,
        size: 10
      }
    }
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.ENTITIES: {
      const { items } = action.payload;
      return {
        ...state,
        entities: merge({}, state.entities, items)
      };
    }
    case Constants.LIST.REQUEST: {
      const { name } = action.payload;

      const list = state.list[name] || {};

      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...list,
            ids: list.ids || [],
            isFetched: false,
            isLoaded: list.isLoaded
          }
        }
      };
    }
    case Constants.LIST.SUCCESS: {
      const { name, ids, meta } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...(state.list[name] || {}),
            ids,
            meta,
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    case Constants.LIST.FAILURE: {
      const { name } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...(state.list[name] || {}),
            isFetched: true,
            isLoaded: true
          }
        }
      };
    }
    default:
      return state;
  }
};
