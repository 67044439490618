export enum LANGUAGE {
  CHANGE = '@@SYSTEM/LANGUAGE/CHANGE'
}

export enum SETTINGS {
  REQUEST = '@@SYSTEM/SETTINGS/REQUEST',
  SUCCESS = '@@SYSTEM/SETTINGS/SUCCESS',
  FAILURE = '@@SYSTEM/SETTINGS/FAILURE',
}

export enum LANGUAGES {
  UZ = 'uz',
  OZ = 'oz',
  RU = 'ru',
  EN = 'en'
}


export const LANGUAGES_INFO = {
  [LANGUAGES.UZ]: {
    key: LANGUAGES.UZ,
    title: 'UZ'
  },
  [LANGUAGES.OZ]: {
    key: LANGUAGES.OZ,
    title: 'ЎЗ'
  },
  [LANGUAGES.RU]: {
    key: LANGUAGES.RU,
    title: 'РУ'
  },
  [LANGUAGES.EN]: {
    key: LANGUAGES.EN,
    title: 'EN'
  }
};