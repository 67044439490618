import firebase from 'firebase/app';
import 'firebase/messaging';

import config from 'config';

firebase.initializeApp({
  appId: config.firebase.appId,
  apiKey: config.firebase.apiKey,
  projectId: config.firebase.projectId,
  messagingSenderId: config.firebase.messagingSenderId
});

const isSupported = () => {
  return firebase.messaging.isSupported();
};

let messaging: firebase.messaging.Messaging = {} as firebase.messaging.Messaging;

if (isSupported()) {
  messaging = firebase.messaging();
}

export default {
  app: firebase,
  isSupported,
  messaging
};