import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.AppealTopic[];
  isFetched: boolean;
  isLoaded: boolean;
}

const useAppealTopics = (): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.AppealTopic[]>(state => state.feedback.appealTopics.items);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.feedback.appealTopics.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.feedback.appealTopics.isLoaded);

  useEffect(() => {
    dispatch(Actions.AppealTopics.request());
  }, []);

  return {
    items,
    isFetched,
    isLoaded
  };
};

export default useAppealTopics;