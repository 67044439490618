export enum RATE {
  REQUEST = '@@FEEDBACK/RATE/REQUEST',
  SUCCESS = '@@FEEDBACK/RATE/SUCCESS',
  FAILURE = '@@FEEDBACK/RATE/FAILURE'
}

export enum COMPLAINT {
  REQUEST = '@@FEEDBACK/COMPLAINT/REQUEST',
  SUCCESS = '@@FEEDBACK/COMPLAINT/SUCCESS',
  FAILURE = '@@FEEDBACK/COMPLAINT/FAILURE'
}

export enum APPEAL {
  REQUEST = '@@FEEDBACK/APPEAL/REQUEST',
  SUCCESS = '@@FEEDBACK/APPEAL/SUCCESS',
  FAILURE = '@@FEEDBACK/APPEAL/FAILURE'
}

export enum APPEAL_TOPICS {
  REQUEST = '@@FEEDBACK/APPEAL_TOPICS/REQUEST',
  SUCCESS = '@@FEEDBACK/APPEAL_TOPICS/SUCCESS',
  FAILURE = '@@FEEDBACK/APPEAL_TOPICS/FAILURE'
}