import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { AxiosError } from 'axios';
import get from 'lodash/get';

import { yup as yupCreator } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers.ts';
import * as Types from '../types';

interface FormValues extends Types.IForm.FeePay {}

interface IProps {
  registerId: number;
  end?: string;
  onSuccess?: (invoice: Types.IEntity.Invoice) => void;
  onError?: (message: string) => void;
  onFinally?: () => void;

  children(props: FormikProps<FormValues>): JSX.Element;
}

const FeePay: React.FC<IProps> = ({ registerId, end = '', onSuccess = () => {}, onError = () => {}, onFinally = () => {}, children }) => {

  const yup = yupCreator();
  const validationSchema = (
    yup.object().shape<FormValues>({
      end: yup.string().min(7).required()
    })
  );

  const handleSubmit = (values: FormValues, { isSubmitting, setSubmitting }: FormikProps<FormValues>) => {
    if(isSubmitting){
      return;
    }

    Api.Fees({ registerId, end: values.end })
      .then((({ data }) => onSuccess(Mappers.invoice(get(data, 'data')))))
      .catch((e) => {
        const response = e as AxiosError;
        const message = response?.response?.data?.status_message;
        setSubmitting(false);
        onError(message)
      })
      .finally(() => onFinally())
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        end: end || ''
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur={false}
      {...{ validationSchema }}
    >
      {(props: FormikProps<FormValues>) => (
        <Form>
          {children(props)}
        </Form>
      )}
    </Formik>
  );
};

export default FeePay;