import React from 'react';
import cx from 'classnames';

import classes from './QuickStats.module.scss';

interface IProps {
  title: string;
  value: number | string;
  variant?: 'blue' | 'green' | 'red' | 'orange' | 'purple' | 'cyan';
  className?: string;
}

const QuickStats: React.FC<IProps> = ({ title, value, variant = 'blue', className }) => (
  <div className={cx(classes.wrapper, classes[`wrapper--${variant}`], className)}>
    <div className={classes.title}>{title}</div>
    <div className={classes.value}>{value}</div>
  </div>
);

export default QuickStats;