import config from "config";

export const getCaptchaToken = (): Promise<string> =>
  new Promise((res, rej) => {
    // @ts-ignore
    window.grecaptcha.ready(() => {
      // @ts-ignore
      window.grecaptcha.execute(config.captcha.key).then(res).catch(rej);
    });
  });
