import React from 'react';

import classes from './List.module.scss';

interface IProps {
  number?: number;
  title?: React.ReactNode;
  children?: React.ReactNode;
}

const Item: React.FC<IProps> = ({ number, title, children }) => (
  <div className={classes.item}>
    {!!number && (
      <div className={classes.itemNumber}>{number}</div>
    )}
    <div className={classes.itemContent}>
      {!!title && (
        <div className={classes.itemTitle}>{title}</div>
      )}
      {!!children && (
        <div className={classes.itemDescription}>{children}</div>
      )}
    </div>
  </div>
);

export default Item;