import React from 'react';
import { useField } from 'formik';
import ReactSelect, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Spinner from 'components/Spinner';

import classes from './FieldSelect.module.scss';

interface IProps {
  name: string;
  label?: string;
  placeholder?: string;
  options: any;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  onChange?: (option) => void;
}

const Select: React.FC<IProps> = ({ label, placeholder, options, disabled, required, readOnly, isLoading, onChange, ...props }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  const styles = {
    container: (provided, state) => ({
      position: 'relative',
      userSelect: 'none'
    }),
    control: (provided, state) => ({
      borderRadius: '8px',
      cursor: 'pointer',
      padding: 16,
      height: 56,
      display: 'flex',
      alignItems: 'center',
      transition: 'all .3s ease-in-out',
      backgroundColor: readOnly ? '#FFFAE7' : '',
      border: '1px solid var(--content-thirdary)',
      borderColor: readOnly ? 'var(--status-orange)' : (hasError ? 'var(--status-red)' : 'var(--content-thirdary)'),
      position: 'relative'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--background-primary)',
      boxShadow: '0 2px 40px rgba(9, 17, 38, 0.4)',
      overflow: 'hidden',
      borderRadius: 8,
      zIndex: 10
    }),
    menuList: (provided) => ({
      ...provided,
      webkitOverflowScrolling: 'touch',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '::-webkit-scrollbar': {
        width: 0,
        height: 0
      }
    }),
    option: (provided, state) => ({
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '140%',
      color: 'var(--content-primary)',
      outline: 'none',
      width: '100%',
      display: 'block',
      userSelect: 'none',
      transition: 'all .2s ease',
      webkitTapHighlightColor: 'rgba(0,0,0,0)',
      borderBottom: '1px solid var(--background-line)',
      padding: state.isSelected ? '16px' : (state.isFocused ? '16px 16px 16px 20px' : '16px'),
      ':last-child': {
        borderBottom: 0
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      flexWrap: 'nowrap',
      padding: 0,
      paddingRight: 20
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '140%',
      color: (state.isDisabled && !readOnly) ? 'var(--content-thirdary)' : 'var(--content-primary)',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      height: 22
    }),
    indicatorsContainer: (provided) => ({
      width: 20,
      height: 20,
      position: 'absolute',
      right: 16,
      top: 0,
      bottom: 0,
      margin: 'auto',
      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 24 24\'%3E%3Cpath fill=\'%23202022\' d=\'M12 13.17l4.95-4.95 1.41 1.42L12 16 5.64 9.64l1.41-1.42L12 13.17z\'/%3E%3C/svg%3E")',
      backgroundSize: '24px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      color: 'var(--background-secondary)',
      opacity: disabled ? '.3' : 1,
      '> div': {
        display: 'none'
      }
    }),
    placeholder: (provided) => ({
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '140%',
      color: 'var(--field-placeholder)',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '140%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'center',
      cursor: 'pointer',
      padding: 16,
      height: 56
    }),
    group: (provided) => ({
      ...provided,
      padding: '16px 12px 0 12px',
      ':not(:first-child)': {
        borderTop: '1px solid var(--background-line)',
      }
    }),
    groupHeading: (provided) => ({
      ...provided,
      padding: 0
    }),
  };

  return (
    <div className={cx(classes.wrapper, hasError && classes.wrapperError, disabled && classes.wrapperDisabled, readOnly && classes.wrapperReadOnly)}>
      <ReactSelect
        {...field}
        {...props}
        {...{ options, styles }}
        onBlur={field.onBlur}
        value={field.value}
        onChange={option => {
          helpers.setValue(option);
          onChange && onChange(option);
        }}
        minMenuHeight={250}
        // menuIsOpen
        menuPlacement="auto"
        isDisabled={disabled || readOnly}
        placeholder={placeholder ? placeholder : t('select_placeholder')}
        noOptionsMessage={() => t('select_options_empty')}
        components={{
          Control: (props) => (
            <div className={classes.inner}>
              <label>
                <div className={classes.label} title={label}>{!!required && <span>*</span>}{label}</div>
                <div className={classes.inputWrapper}>
                  <components.Control {...props} />
                  {!!isLoading && (
                    <div className={classes.loading}>
                      <Spinner size={18}/>
                    </div>
                  )}
                </div>
              </label>
              {hasError && (
                <div className={classes.errorLabel}>{meta.error}</div>
              )}
            </div>
          )
        }}
      />
    </div>
  );
};

export default Select;
