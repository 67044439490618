import { action } from 'typesafe-actions';

import * as Constants from './constants';
import * as Types from './types';

export const Entities = (args: Types.IAction.Entities) => action(Constants.ENTITIES, args);

export const List = {
  request: (args: Types.IAction.List.Request) => action(Constants.LIST.REQUEST, args),
  success: (args: Types.IAction.List.Success) => action(Constants.LIST.SUCCESS, args),
  failure: (args: Types.IAction.List.Failure) => action(Constants.LIST.FAILURE, args)
};
