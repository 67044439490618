import React, { useEffect } from 'react';
import get from 'lodash/get';
import cx from 'classnames';

import * as CategoryModule from 'modules/category';
import * as StepperModule from 'modules/stepper';
import * as SystemModule from 'modules/system';

import * as Icon from 'components/Icon';
import { Nav } from 'components/Modal';

import * as Hooks from '../../../hooks';

import classes from './SubCategory.module.scss';

interface IProps {
  isSelected: boolean;
}

const SubCategory: React.FC<IProps> = ({ isSelected }) => {
  const { language } = SystemModule.Hooks.useLanguage();
  const stepper = StepperModule.useContext();
  const { state: { categoryId, subCategoryId }, methods } = CategoryModule.Context.Single.useContext();
  const category = Hooks.useSingle({ id: categoryId });
  const subCategory = Hooks.useSingle({ id: subCategoryId });

  useEffect(() => {
    setTimeout(() => {
      isSelected && onClick(subCategory.id);
    }, 0)
  }, []);

  const onClick = (id: number) => {
    methods.setSubCategory(id);
    stepper.next();
  };

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={methods.setClose}/>
      <div className={cx(classes.breadcrumb, !stepper.canPrev && classes.breadcrumbNoPrev)}/>
      <div className={classes.header}>
        <div className={classes.image}>
          <img src={category.image}/>
        </div>
        <div className={classes.headerContent}>
          <div className={classes.title}>{get(category, `title[${language}]`)}</div>
          <div className={classes.description}>{get(category, `description[${language}]`)}</div>
        </div>
      </div>
      <div className={classes.list}>
        {category.children.map(item => (
          <div key={item.id} className={classes.listItem} onClick={() => onClick(item.id)}>
            <div className={classes.listItemTitle}>{get(item, `title[${language}]`)}</div>
            <div className={classes.listItemIcon}>
              <Icon.System.Right/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubCategory;