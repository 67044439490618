import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import * as Icon from 'components/Icon';

import classes from './Empty.module.scss';

interface IProps {
    title?: string
    button?: React.ReactNode,
    hasButton?: boolean
}

const Empty: React.FC<IProps> = ({ button, title = 'you_don_t_have_a_license_yet_do_you_want_to_apply', hasButton = true }) => {
    const { t } = useTranslation();

    return (
        <div className={classes.emptyWrapper}>
            <div className={classes.emptyImage}>
                <img src={require('assets/images/illustrations/no-license.png')} alt="illustration" />
            </div>
            <div className={classes.emptyTitle}>{t(title)}</div>
            {button ? button : hasButton && <Button
                className={classes.button}
                variant="green"
                prefixIcon={<Icon.System.Plus />}
                size="large"
                container={<Link to="/information" />}
            >
                {t('submit_application')}
            </Button>}
        </div>
    );
};

export default Empty;