import React from 'react';
import cx from 'classnames';

import classes from './Details.module.scss';

interface IProps {
  title?: string;
  className?: string;
  position?: 'vertical' | 'horizontal';
  children?: JSX.Element | string;
}

const Item: React.FC<IProps> = ({ title, position = 'horizontal', className = '', children, ...props }) => (
  <div className={cx(classes.item, classes[`item--${position}`], classes[className])} {...props}>
    {!!title && <span className={classes.itemTitle}>{title}</span>}
    <span className={classes.itemValue}>{children}</span>
  </div>
);

export default Item;
