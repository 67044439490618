import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Types from './types';

const initialState: Types.IState = {
  list: {
    items: [],
    isFetched: false,
    error: ''
  }
};

export default (state: Types.IState = initialState, action: ActionType<typeof Actions>): Types.IState => {
  switch (action.type) {
    case Constants.LIST.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetched: false
        }
      };
    }
    case Constants.LIST.SUCCESS: {
      const { items } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          items,
          isFetched: true
        }
      };
    }
    case Constants.LIST.FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          isFetched: true,
          error
        }
      };
    }
    default:
      return state;
  }
};
