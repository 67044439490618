import React from 'react';

import * as Types from '../types';

import Mask, { MASK_TYPE } from '../Mask';

interface IProps extends Types.FieldProps {
  nextElementId?: string;
}

const NumberField: React.FC<IProps> = ({ ...props }) => (
  <Mask {...props} type={MASK_TYPE.NUMBER} lazy mask={/^\d*\.?\d*$/} />
);

export default NumberField;
