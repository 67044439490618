import React, { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useOuterClick } from 'react-outer-click';
import cx from 'classnames';

import * as NotificationModule from 'modules/notification';

import * as Icon from 'components/Icon';

import List from './List';

import classes from './Notification.module.scss';

const Notification: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const el = useRef(null);
  useOuterClick(el, () => setVisible(false));
  const { unReadCount } = NotificationModule.Hooks.useNotification();

  return (
    <div ref={el} className={classes.wrapper}>
      <div className={cx(classes.button, isVisible && classes.buttonVisible, !unReadCount && classes.buttonEmpty)} onClick={() => setVisible(!isVisible)}>
        <div className={classes.buttonIcon}>
          <Icon.System.Bell/>
        </div>
        {!!unReadCount && (
          <div className={classes.buttonBadge}>{unReadCount > 99 ? 99 : unReadCount}</div>
        )}
      </div>
      <Transition
        in={isVisible}
        timeout={{ enter: 0, exit: 300 }}
        mountOnEnter
        unmountOnExit
      >
        {(state: TransitionStatus) => (
          <div className={classes.dropdown} style={{ ...transition[state] }}>
            <List close={() => setVisible(false)}/>
          </div>
        )}
      </Transition>
      <NotificationModule.Components.Messages/>
    </div>
  );
};

const transition = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1, visibility: 'visible', transform: 'translateX(-50%) translateY(0)' },
  exited: { opacity: 0, visibility: 'hidden' }
};

export default Notification;