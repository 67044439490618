import React, { useState } from 'react'

import styles from './styles.module.scss'
import { useQuery } from 'react-query'
import { http } from 'services'
import { SheetQueryList } from './types'
import { get } from 'lodash'
import Spinner from 'components/Spinner'
import * as T from 'components/Table';
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import * as Icon from 'components/Icon';
import Pagination from 'components/Pagination';
import Modal, { Nav } from 'components/Modal'
import config from 'config';

export default function Sheets({ uuid }: { uuid: string }) {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    const [single, setSingle] = useState({ uuid: '', open: false })

    const { data, isFetched } = useQuery<SheetQueryList, Error, SheetQueryList>({
        queryKey: ["sheets", uuid, page],
        queryFn: async () => {
            const { data } = await http.request.get(`/register/open_source/${uuid}/sheets`)

            return get(data, "data")
        },
        initialData: {
            sheets: [],
            total_items: 0,
            total_pages: 0,
            current_page: 0
        },
        retry: false,
        refetchOnWindowFocus: false
    })

    if (!isFetched) {
        return <Spinner />
    }

    if (!data) {
        return <h4 className={styles.empty}>{t("no_sheets_found")}</h4>
    }

    return (
        <div className={styles.wrapper}>
            <T.Table>
                <T.Header>
                    <T.HeaderCell>{t("sheet.serial")}</T.HeaderCell>
                    <T.HeaderCell>{t("sheet.number")}</T.HeaderCell>
                    <T.HeaderCell>{t("sheet.model")}</T.HeaderCell>
                    <T.HeaderCell>{t("sheet.federal_number")}</T.HeaderCell>
                    <T.HeaderCell>{t("sheet.registration_date")}</T.HeaderCell>
                    <T.HeaderCell>{t("sheet.issued")}</T.HeaderCell>
                    <T.HeaderCell>{t("sheet.expires")}</T.HeaderCell>
                    <T.HeaderCell></T.HeaderCell>

                </T.Header>
                <T.Body>
                    {data.sheets.map((sheet, index) => (
                        <T.Row key={index}>
                            <T.Cell>{sheet.serial}</T.Cell>
                            <T.Cell>{sheet.number}</T.Cell>
                            <T.Cell>{sheet.model}</T.Cell>
                            <T.Cell>{sheet.federal_number}</T.Cell>
                            <T.Cell>{sheet.registration_date}</T.Cell>
                            <T.Cell>{sheet.issued}</T.Cell>
                            <T.Cell>{sheet.expires}</T.Cell>
                            <T.ActionCell>
                                <Button
                                    onClick={() => setSingle({ uuid: sheet.uuid, open: true })}
                                    prefixIcon={<Icon.System.DocPdf />}
                                />
                            </T.ActionCell>
                        </T.Row>
                    ))}
                </T.Body>
            </T.Table>
            <Pagination total={data.total_pages} current={data.current_page} onChange={(val => setPage(val))} />
            <Modal variant="white" isOpen={single.open} close={() => setSingle({ ...single, open: false })}>
                <div className={styles.view}>
                    <Nav showClose variant='dark' />
                    <div className={styles.actions}>
                        <Button
                            variant="stroke"
                            prefixIcon={<Icon.System.Print />}
                            container={<a href={`${config.document.base_url}/sheet/uuid/${single.uuid}/pdf`} target="_blank" rel="noreferrer" />}
                        >
                            {t('Print')}
                        </Button>
                    </div>
                    <iframe width="100%" height={840} src={`${config.document.base_url}/sheet/uuid/${single.uuid}/pdf#toolbar=0&navpanes=0&scrollbar=0&view=FitH`} frameBorder="0" />
                </div>
            </Modal>
        </div>
    )
}
