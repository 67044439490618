import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import classes from './ErrorBoundary.module.scss';

interface IProps {
  children: React.ReactNode;
}

interface IState {
  error: React.ErrorInfo | null
}

class ErrorBoundary extends Component<IProps, IState> {

  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className={classes.wrapper}>
          <div className={classes.icon}>
            <svg fill="#ff5d5d" viewBox="0 0 512 512">
              <path d="M256 0a256 256 0 1 0 0 512 256 256 0 0 0 0-512zm0 472a216 216 0 1 1 0-432 216 216 0 0 1 0 432z" />
              <path d="M256 129c-11 0-20 9-20 20v129a20 20 0 1 0 40 0V149c0-11-9-20-20-20z" />
              <circle cx="256" cy="349" r="27" />
            </svg>
          </div>
          <div className={classes.text}>Что-то пошло не так !!!</div>
          <div onClick={() => Sentry.showReportDialog()} className={classes.button}>Обратная связь</div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;